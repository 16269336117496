import { useQuery } from '@tanstack/react-query';
import getCurrentEnvironment from 'src/utils/environment/getCurrentEnvironment';
import get from 'src/utils/http/get';

export type PriceEstimate = {
    mileage: number;
    marketMaxPrice: number;
    marketMinPrice: number;
    offerMaxPrice: number;
    offerMinPrice: number;
    createdAt: string;
};

export const useGetCarPriceEstimate = (vin: string, milage: number) => {
    const { bilholdApiUrl } = getCurrentEnvironment();

    return useQuery<PriceEstimate[]>({
        queryKey: ['car-price-estimate', vin, milage],
        queryFn: () =>
            get<PriceEstimate[]>(
                `${bilholdApiUrl}/v1/cars/${vin}/price-estimates`
            ).then((res) => res.data),

        staleTime: 1000 * 60 * 60,
    });
};
