import Brand from 'src/types/Brand';
import FuelType from 'src/utils/enums/FuelType';

export enum CarStatus {
    EXISTING = 'Existing',
    PENDING = 'Pending',
    ORDERED = 'Ordered',
    RESERVED = 'Reserved',
}

export enum PreferredDealer {
    BILHOLD = 'Bilhold',
    MOLLER = 'Møller Bil',
    GUMPEN = 'Gumpen auto',
    DAHLES = 'Dahles Auto',
    AUTORIA = 'Autoria',
}

export type BaseCar = {
    CarStatus: CarStatus;
    vin: string;
    brand: Brand;
    model: string;
    registrationYear: number;
    fuelType: FuelType;
    futureAppointments: number;
    pastAppointments: number;
    hasUpdatedEuControl: boolean;
    hasUpdatedMileage: boolean;
    mobilityGuarantee: boolean;
    hasTireHotel: boolean;
    preferredDealer: PreferredDealer;
    originBilholdApi: boolean | undefined;
};

export type BaseData = {
    cars: BaseCar[];
};

export const formatDate = (date: Date) => {
    return `${date.getFullYear()}-${(date.getMonth() + 1)
        .toString()
        .padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
};

const getMockData = (baseData: BaseData): MockData => {
    return {
        cars: baseData.cars.map((car, index) => {
            const nextControlDate = car.hasUpdatedEuControl
                ? new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 365)
                : new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 60);

            const nextControlDateString = formatDate(nextControlDate);

            const lastUpdatedMileage = car.hasUpdatedMileage
                ? new Date()
                : new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 365);

            const lastUpdatedMileageString = formatDate(lastUpdatedMileage);

            return {
                CarStatus: car.CarStatus,
                vin: car.vin || `FAKEVIN123456789${index}`,
                licensePlate: `BS1234${index}`,
                brand: car.brand,
                model: car.model || 'Demo',
                registrationYear: car.registrationYear,
                fuelType: car.fuelType,
                futureAppointments: car.futureAppointments,
                pastAppointments: car.pastAppointments,
                nextControlDate: nextControlDateString,
                lastUpdatedMileage: lastUpdatedMileageString,
                mobilityGuarantee: true,
                hasTireHotel: true,
                preferredDealer: car.preferredDealer,
                originBilholdApi: car.originBilholdApi,
            };
        }),
    };
};

export const initMockData = (baseData: BaseData) => {
    mockData = getMockData(baseData);
};

export type MockCar = {
    CarStatus: CarStatus;
    vin: string;
    licensePlate: string;
    brand: Brand;
    model: string;
    registrationYear: number;
    fuelType: FuelType;
    futureAppointments: number;
    pastAppointments: number;
    nextControlDate: string;
    lastUpdatedMileage: string;
    mobilityGuarantee: boolean;
    hasTireHotel: boolean;
    preferredDealer: PreferredDealer;
    originBilholdApi: boolean | undefined;
};

export type MockData = {
    cars: MockCar[];
};

export let mockData: MockData | null = null;
