import { Browser } from '@capacitor/browser';
import { PrimaryButton } from '@moller/design-system';
import { PATH_COMPETITION_ABOUT } from 'src/routing/routes';
import { isMobileApp } from 'src/utils/mobile';
import { stravaConfig } from '../utils/stravaConfig';
import { StravaConnectIcon } from './StravaConnectIcon';

const url = (redirect: string) => {
    return `https://www.strava.com/oauth/mobile/authorize?client_id=${stravaConfig.clientId}&response_type=code&redirect_uri=${redirect}&scope=${stravaConfig.scope}`;
};

const redirectToStravaAuth = (redirect: string): void => {
    window.location.href = url(redirect);
};

const openCapacitorSite = async (redirect: string) => {
    await Browser.open({ url: url(redirect) });
};
const handleLogin = (returnPath: string | undefined) => {
    const path = returnPath ?? PATH_COMPETITION_ABOUT;
    try {
        if (isMobileApp()) {
            const redirect = `https://mittbilhold.no${path}`;
            void openCapacitorSite(redirect);
        } else {
            redirectToStravaAuth(window.location.origin + path);
        }
    } catch (error) {
        console.error('Login initiation failed', error);
    }
};

interface Props {
    isLoading: boolean;
    isDisabled: boolean;
    returnPath?: string;
}

export function ConnectToStravaButton({
    isLoading,
    isDisabled,
    returnPath,
}: Props) {
    return (
        <PrimaryButton
            disabled={isDisabled}
            onClick={() => handleLogin(returnPath)}
            style={{ backgroundColor: isDisabled ? '#6d3925' : '#fc4c01' }}
            loading={{
                isLoading: isLoading,
                loadingText: 'Laster...',
            }}
        >
            {isLoading ? (
                ''
            ) : (
                <StravaConnectIcon
                    color={isDisabled ? '#83807c' : '#fff'}
                    backgroundColor={isDisabled ? '#6d3925' : '#fc4c01'}
                />
            )}
        </PrimaryButton>
    );
}
