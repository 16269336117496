import trimUserName from 'src/utils/auth/trimUserName';
import getCurrentEnvironment from 'src/utils/environment/getCurrentEnvironment';
import { CREATE_NEW_USER } from 'src/utils/http/endpoints';
import post from 'src/utils/http/post';
import { buf2hex, generateHashedKey } from './idempotencyKey';

export interface PostUserInfo {
    countryCode?: string;
    userInfo: {
        email: string;
        firstName: string;
        lastName: string;
        addressLine: string;
        zipCode: string;
        city: string;
    };
}

type IdempotencyMaterialParts = PostUserInfo['userInfo'] & {
    phoneNumber: string;
};

function buildIdempotencyMaterial(p: IdempotencyMaterialParts) {
    return `${p.city}${p.email}${p.zipCode}${p.firstName}${p.lastName}${p.addressLine}${p.phoneNumber}`;
}

export const postCreateUser = (
    input: PostUserInfo,
    otp: string,
    phoneNumber: string,
    setPostError: (errorKey: string) => void
) => {
    const { userInfo } = input;
    const { bilholdApiUrl } = getCurrentEnvironment();
    const url = `${bilholdApiUrl}/${CREATE_NEW_USER}`;

    const trimmedUserInfo = {
        ...userInfo,
        firstName: userInfo.firstName.trim(),
        lastName: userInfo.lastName.trim(),
        addressLine: userInfo.addressLine.trim(),
        email: userInfo.email.trim(),
    };
    const payload = {
        countryCode: 'NOR',
        otp,
        phoneCountryCode: 'NOR',
        phoneNumber: trimUserName(phoneNumber),
        userInfo: { ...trimmedUserInfo, addressCountry: 'NOR' },
    };

    const material = buildIdempotencyMaterial({
        ...trimmedUserInfo,
        phoneNumber,
    });
    const encode = (x: string) => new TextEncoder().encode(x);
    const digest = (x: Uint8Array) => window.crypto.subtle.digest('SHA-256', x);
    const idempotencyKey = generateHashedKey(material, encode, buf2hex, digest);

    return post(url, payload, {
        axiosConfig: { idempotencyKey },
        isAnonymous: true,
    }).catch(() => {
        setPostError('an_error_has_occured');
    });
};
