import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useFetchersContext } from 'external-apis';
import React from 'react';
import { appointmentsQueryKey } from './useAppointments';

const useDeleteBookings = () => {
    const [fetchers] = useFetchersContext();
    return fetchers.port.fetcher
        .path('/bookings/{bookingId}')
        .method('delete')
        .create({ adapterId: true });
};

export const useCancelAppointment = (
    bookingId: string,
    deleteAppointmentSuccess: () => void,
    setCancelAppointmentError?: React.Dispatch<React.SetStateAction<string>>
) => {
    const queryClient = useQueryClient();
    const deleteBooking = useDeleteBookings();

    //todo change and get as param after we get booking from port
    const adapterId = 'mnet-adapter';

    return useMutation({
        mutationFn: () => deleteBooking({ bookingId, adapterId }),
        onSuccess: () => {
            deleteAppointmentSuccess();
            void queryClient.invalidateQueries({
                queryKey: [appointmentsQueryKey],
            });
        },
        onError: () => setCancelAppointmentError?.('error_try_again_later'),
    });
};
