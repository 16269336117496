import { PreferredDealer } from 'src/features/home/queries/usePreferredDealer';
import { MockData } from '../data';

const getPreferredDealers = (mockData: MockData): PreferredDealer[] => {
    return mockData.cars.map((car) => {
        return {
            dealerId: car.vin,
            dealerName: car.model,
            address: car.model,
            zipCode: car.model,
            city: car.model,
            phoneNumber: car.model,
            brand: car.brand,
        };
    });
};

export default getPreferredDealers;
