import { Headline, Subtitle, TextContainer } from '@moller/design-system';
import { CenteredPageContent } from 'src/shared/CenteredPageContent';

const WarrantySkoda = () => {
    return (
        <CenteredPageContent showBackButton>
            <TextContainer>
                <Headline>Garantier på bilen</Headline>
                <p>
                    Det skal være trygt å kjøre og eie en Škoda og våre biler
                    leveres med en omfattende garantipakke. Škoda kjennetegnes
                    av kvalitet, som bidrar til lavere servicekostnader og en
                    høy andrehåndsverdi.
                </p>
                <Subtitle>5 års Norgesgaranti</Subtitle>
                <p>
                    Alle nye Škoda personbiler som er registrert etter
                    01.01.2011 og importert av Harald A. Møller AS har en
                    Norgesgaranti som gjelder i 5 år/100.000 km, avhengig av hva
                    som først inntreffer.
                </p>
                <p>
                    For at garantien skal være gjeldende må service, vedlikehold
                    og reparasjoner til enhver tid utføres i henhold til Škoda
                    sine fastsatte normer.
                </p>
                <Subtitle>5 års Norgesgaranti på Škoda originaldeler®</Subtitle>
                <p>
                    Garantien gjelder i 5 år eller 100.000 km, avhengig av hva
                    som først inntreffer og gjelder for deler importert av
                    Harald A. Møller AS. Direkte slitasjedeler er unntatt fra
                    garantien.
                </p>
                <Subtitle>Lakkgaranti</Subtitle>
                <p>
                    Alle nye Škoda personbiler garanteres i 3 år fra
                    leveringsdato mot skader i lakken som kan føres tilbake til
                    feil i produksjonen. Skader som er oppstått på grunn av ytre
                    påvirkning, faller naturlig nok utenfor garantien.
                    Vedlikehold av lakken i henhold til bilens instruksjonsbok
                    forutsettes.
                </p>
                <Subtitle>Batterigaranti ladbare biler</Subtitle>
                <p>
                    Alle nye ladbare biler fra Škoda leveres fra fabrikk med 8
                    år/160.000 km garanti på batteripakken. Škoda garanterer at
                    batteriet skal holde minst 70% kapasitet i inntil 8
                    år/160.000 km. Garantien forutsetter lading i henhold til
                    bilens instruksjonsbok.
                </p>
                <Subtitle>12 års garanti mot gjennomrusting</Subtitle>
                <p>
                    Alle nye biler fra Škoda leveres med 12 års garanti mot
                    gjennomrusting av karosseriet. Med gjennomrusting menes her
                    at det er oppstått hull i karosseriplatene som har utviklet
                    seg innenfra (fra hulrom) som ikke kan forventes å bli
                    oppdaget tidligere i forbindelse med lakkvedlikehold i
                    henhold til anbefalinger i bilens instruksjonsbok. Skadene
                    må ikke være forårsaket av ytre påvirkning.
                </p>
                <Subtitle>Reklamasjonsrettigheter</Subtitle>
                <p>
                    Garantien begrenser ikke garantitakerens lovfestede
                    rettigheter i henhold til norsk lov (Lov om kjøp, Lov om
                    Forbrukerkjøp) ovenfor selger ved mangler, og heller ikke
                    eventuelle krav i hht. Lov om produktansvar.
                </p>
                <p>
                    Direkte slitasjedeler og justeringsarbeider er unntatt fra
                    garantien.
                </p>
            </TextContainer>
        </CenteredPageContent>
    );
};

export default WarrantySkoda;
