import { Column } from '@moller/design-system';
import { useFlag } from 'feature-toggle';
import { useAuthContext } from '../../../lib/auth/authContext';
import { useFastTrack } from '../../../lib/useFastTrack';
import { Done } from '../../sectionUtils';
import { ConfirmBookingDependencies } from '../ConfirmBookingSection';
import {
    useAdobeTrackingConfirmBooking,
    useReportConfirmBooking,
} from '../confirmBookingReport';
import { ConfirmBookingDoneInternal } from './ConfirmBookingDoneInternal';

export function ConfirmBookingDone({
    dependencies,
    isBookingRequest,
}: {
    dependencies: Done<ConfirmBookingDependencies>;
    isBookingRequest: boolean;
}) {
    const authScheme = useAuthContext();

    const dealerNumber = dependencies.chooseDealer.data.dealerNumber;

    const reportingProps = {
        registrationNumber: dependencies.chooseVehicle.data.registrationNumber,
        selectedDealerNumber: dependencies.chooseDealer.data.dealerNumber,
        standardServices: dependencies.standardServices.data.selectedServices,
        reservedTimeSlot: dependencies.chooseTimeSlot.data.reservedTimeSlot,
        additionalServices: dependencies.additionalServices,
        transportServices: dependencies.transportServices,
        availableRecommendedServices:
            dependencies.standardServices.data.fetchedData
                .availableRecommendedServices,
    };

    const enabled = useFlag('enable-adobe-tracking');

    const fastTrackType = useFastTrack();
    const isFastTrack = !!fastTrackType.fastTrack;

    useReportConfirmBooking(reportingProps);
    useAdobeTrackingConfirmBooking({
        formName: isFastTrack ? 'Booking Fasttrack' : 'Booking',
        enabled,
        ...reportingProps,
    });
    return (
        <Column gap="m">
            <ConfirmBookingDoneInternal
                email={authScheme.user.email}
                reservedTimeSlot={
                    dependencies.chooseTimeSlot.data.reservedTimeSlot
                }
                selectedDealerNumber={
                    dependencies.chooseDealer.data.dealerNumber
                }
                registrationNumber={
                    dependencies.chooseVehicle.data.registrationNumber
                }
                phone={authScheme.user.phone}
                isBookingRequest={isBookingRequest}
                dealerNumber={dealerNumber}
            />
        </Column>
    );
}
