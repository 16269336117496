import {
    ONLY_NUMBERS,
    VALID_DEV_PHONE_NUMBER,
    VALID_PHONE_NUMBER,
} from 'src/utils/string/regex';

export const validPhoneNumber = (value: string) =>
    value.match(VALID_PHONE_NUMBER);

export const validDevPhoneNumber = (value: string) =>
    value.match(VALID_DEV_PHONE_NUMBER);

export const onlyNumbers = (value: string) => value.match(ONLY_NUMBERS);
