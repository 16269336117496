import { CardContent } from '@bilhold/sanity';
import {
    AllowedIcons,
    Column,
    Icon,
    Link,
    PrimaryButton,
    Subtitle,
    breakpointTablet,
} from '@moller/design-system';
import { Link as ReactLink } from 'react-router-dom';

import { datadogRum } from '@datadog/browser-rum';
import { removeUnit } from '@moller/design-system/utilities/tokens';
import { useEffect, useState } from 'react';
import RichText, { Variables } from 'src/sanity/components/RichText';
import SanityImage, {
    SanityImageSourceWithAlt,
} from 'src/sanity/components/SanityImage';
import { styled } from 'styled-components';

const CardContainer = styled.div`
    display: grid;
    grid-template-areas:
        'image'
        'content';

    @media screen and (min-width: ${breakpointTablet}) {
        grid-template-areas: 'content image';
    }
`;

const ImageContainer = styled.div`
    grid-area: image;

    @media screen and (min-width: ${breakpointTablet}) {
        width: 100%;
    }

    img {
        border-radius: var(--moller-spacing-base);
        object-fit: cover;
        object-position: 50% 50%;
        width: 100%;
        height: 135px;
        @media screen and (min-width: ${breakpointTablet}) {
            height: 100%;
            width: 260px;
        }
    }
`;

const ButtonContainer = styled.div`
    grid-area: image;

    margin-top: var(--moller-spacing-s);
    gap: var(--moller-spacing-l);
    display: flex;
    flex-direction: row;
    align-items: center;

    @media screen and (min-width: ${breakpointTablet}) {
        margin-top: var(--moller-spacing-xl);
        gap: var(--moller-spacing-xl);
    }
`;

const ContentContainer = styled(Column)`
    @media screen and (min-width: ${breakpointTablet}) {
        grid-area: content;
        justify-content: space-between;
        height: 100%;
    }
`;

const ExternalArticleLink = styled(Link)`
    display: flex;
    gap: 4px;
`;

const Highlighted = styled.div`
    margin-bottom: var(--moller-spacing-xxs);
    right: var(--moller-spacing-xs);
    height: var(--moller-spacing-s);
    font-size: var(--moller-spacing-xs);
    line-height: 150%;
    border: none;
    padding: var(--moller-spacing-base);
    padding-right: var(--moller-spacing-xs);
    border-radius: var(--moller-spacing-base);
    gap: var(--moller-spacing-base);
    align-items: center;
    display: flex;
    flex-direction: row;

    @media screen and (min-width: ${breakpointTablet}) {
        font-size: var(--moller-typeface-size-s);
    }
`;

const HighlightedContainer = styled.div`
    gap: var(--moller-spacing-base);
    align-items: center;
    display: flex;
    flex-direction: row;
`;

const DescriptionContainer = styled.div`
    font-size: var(---moller-size-s);
    margin-top: var(--moller-spacing-xxs);

    @media screen and (min-width: ${breakpointTablet}) {
        margin-right: var(--moller-spacing-s);
    }
`;

const highlightedStyling = {
    color: 'var(--moller-keycolor-warning)',
    backgroundColor: 'var(--moller-color-warning-container)',
    iconColor: 'var(--moller-keycolor-warning)',
    iconTextColor: 'var(--moller-color-on-warning-container)',
};

interface SanityCardLayoutProps {
    title: string;
    isExternalArticle?: boolean;
    body?: CardContent;
    image?: SanityImageSourceWithAlt;
    iconColor?: string;
    icon?: AllowedIcons;
    iconTextBackground?: string;
    iconTextColor?: string;
    variables?: Variables;
    openDrawer: () => void;
    buttonText?: string;
    buttonLink?: string;

    overlineText?: string;
    useReadMore?: boolean;
}

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height,
    };
}

export const SanityCardLayout = ({
    title,
    body,
    isExternalArticle,
    image,
    openDrawer,
    variables,
    buttonText,
    buttonLink,
    overlineText,
    useReadMore,
}: SanityCardLayoutProps) => {
    const [windowDimensions, setWindowDimensions] = useState(
        getWindowDimensions()
    );

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <CardContainer>
            <ImageContainer>
                <SanityImage sanityImageData={image} />
            </ImageContainer>
            <ContentContainer>
                {overlineText && (
                    <HighlightedContainer>
                        <Highlighted style={highlightedStyling}>
                            <Icon
                                icon={'warning'}
                                size={
                                    windowDimensions.width >
                                    removeUnit(breakpointTablet)
                                        ? 'm'
                                        : 's'
                                }
                            />
                            <span>{overlineText}</span>
                        </Highlighted>
                    </HighlightedContainer>
                )}
                <Subtitle $as="h3">{title}</Subtitle>
                <DescriptionContainer>
                    {body && <RichText value={body} variables={variables} />}
                </DescriptionContainer>
                <ButtonContainer>
                    {!isExternalArticle && (
                        <>
                            <PrimaryButton
                                $as={ReactLink}
                                to={useReadMore ? buttonLink : ''}
                                onClick={() => {
                                    datadogRum.addAction(
                                        `User clicks ${title} card with Sanity data's action button`
                                    );
                                    if (!useReadMore) openDrawer();
                                }}
                            >
                                {buttonText}
                            </PrimaryButton>
                            {useReadMore && (
                                <Link
                                    $as="a"
                                    href="#"
                                    onClick={() => {
                                        datadogRum.addAction(
                                            `User clicks ${title} card with Sanity data's read more button`
                                        );
                                        openDrawer();
                                    }}
                                >
                                    Les mer
                                </Link>
                            )}
                        </>
                    )}
                    {isExternalArticle && (
                        <ExternalArticleLink
                            $as="a"
                            href={buttonLink}
                            onClick={() =>
                                datadogRum.addAction(
                                    `User clicks ${title} card with Sanity data's external article redirect button`
                                )
                            }
                        >
                            {buttonText}
                            <Icon icon="external" />
                        </ExternalArticleLink>
                    )}
                </ButtonContainer>
            </ContentContainer>
        </CardContainer>
    );
};
