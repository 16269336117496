import { Navigate, useParams } from 'react-router-dom';
import { datadogLogs } from '@datadog/browser-logs';

const DamageRedirect = () => {
    const { id } = useParams();
    const path = id === undefined ? '/damage' : '/damage/' + id;
    datadogLogs.logger.log('Redirecting to new damage appraiser: ' + path);
    return <Navigate replace to={path} />;
};

export default DamageRedirect;
