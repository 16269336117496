import { Position } from '@capacitor/geolocation';
import { datadogRum } from '@datadog/browser-rum';
import {
    AlertBanner,
    Column,
    InputFieldValidity,
    Row,
    TextButton,
    TextField,
} from '@moller/design-system';
import { DealerViewModel } from 'external-apis/src/types/port';
import { useCallback, useMemo, useState } from 'react';
import { styled } from 'styled-components';
import { BilholdInnerViewLayout } from '../../../components/bilhold/BilholdView';
import { Subheading } from '../../../components/Subheading';
import { useLanguageContext } from '../../../lib/languages/languageContext';
import {
    datadogLocationActionPrefix,
    useUserLocation,
} from '../../../lib/useUserLocation';
import { getKilometersBetween } from '../../../lib/util/getKilometersBetween';
import { ChosenDealer } from './ChosenDealer';
import { ShowMoreDealers } from './ShowMoreDealers';
import { SingleChoiceDealerList } from './SingleChoiceDealerList';

function useDealerSorting(dealers: DealerViewModel[]): {
    isLoadingLocation: boolean;
    locationError: string | undefined;
    sortOrder: DealerListSortOrder;
    setSortOrder: (order: DealerListSortOrder) => void;
    sortedDealers: DealerViewModel[];
} {
    const [sortOrderState, internalSetSortOrder] =
        useState<DealerListSortOrder>('byDistance');

    const {
        location,
        errorMessage: locationError,
        isLoading: isLoadingLocation,
        enableLocation,
    } = useUserLocation(true);

    const sortOrder =
        location === undefined ? 'alphabetically' : sortOrderState;
    const sortedDealers = useMemo(
        () =>
            sortOrder === 'byDistance' && location
                ? sortDealersByDistance(location, dealers)
                : sortDealersByName(dealers),
        [dealers, location, sortOrder]
    );

    const setSortOrder = useCallback(
        (sortOrder: DealerListSortOrder) => {
            internalSetSortOrder(sortOrder);
            if (sortOrder === 'byDistance') {
                enableLocation();
            }
        },
        [enableLocation]
    );

    return {
        isLoadingLocation,
        locationError,
        sortOrder,
        setSortOrder,
        sortedDealers,
    };
}

function useDealerSearchField(dealers: DealerViewModel[]): {
    dealerSearchProps: DealerSearchProps;
    filteredDealers: DealerViewModel[];
} {
    const [searchValue, setSearchValue] = useState<string>('');

    function lowerCaseSearch(str: string) {
        return str.toLowerCase().includes(searchValue.toLowerCase());
    }
    const filteredDealers = dealers.filter(
        (x) =>
            lowerCaseSearch(x.city) ||
            lowerCaseSearch(x.name) ||
            lowerCaseSearch(x.streetAddress) ||
            lowerCaseSearch(x.zipCode)
    );
    return {
        dealerSearchProps: {
            setSearchValue,
        },
        filteredDealers,
    };
}

function useDealerShowMoreButtons(
    dealers: DealerViewModel[],
    dealersPerPage = 5
) {
    const [countSearchItems, setCountSearchItems] =
        useState<number>(dealersPerPage);
    const showMore = useCallback(
        () => setCountSearchItems((items) => items + dealersPerPage),
        [dealersPerPage]
    );

    const firstXDealers = dealers.slice(0, countSearchItems);
    const totalDealerCount = dealers.length;
    const shownDealerCount = Math.min(totalDealerCount, countSearchItems); // eller `firstXDealers.length` ?
    return {
        showMore,
        firstXDealers,
        totalDealerCount,
        shownDealerCount,
    };
}

function sortDealersByDistance(
    location: Position,
    dealers: DealerViewModel[]
): DealerViewModel[] {
    return [...dealers].sort((a, b) => {
        const distanceToA =
            getKilometersBetween(a.coordinates, location.coords) ?? -1;
        const distanceToB =
            getKilometersBetween(b.coordinates, location.coords) ?? -1;
        return distanceToA - distanceToB;
    });
}

function sortDealersByName(dealers: DealerViewModel[]): DealerViewModel[] {
    return [...dealers].sort((a, b) => (a.name > b.name ? 1 : -1));
}

export interface DealerPickerProps {
    dealers: DealerViewModel[];
    onChange: (dealer: DealerViewModel) => void;
    selectedDealerId?: string;
    validity?: InputFieldValidity;
}

export function DealerPicker({
    dealers,
    selectedDealerId,
    onChange,
    validity,
}: DealerPickerProps) {
    const [lc] = useLanguageContext();
    const { locationError, sortOrder, setSortOrder, sortedDealers } =
        useDealerSorting(dealers);
    const { dealerSearchProps, filteredDealers } =
        useDealerSearchField(sortedDealers);
    const { showMore, firstXDealers, totalDealerCount, shownDealerCount } =
        useDealerShowMoreButtons(filteredDealers);

    const chosenDealer = dealers.find((x) => x.id === selectedDealerId);

    return (
        <div>
            <Subheading>{lc.chooseDealer.chosen_dealer}</Subheading>
            <ChosenDealer dealer={chosenDealer} />
            <Subheading>{lc.chooseDealer.all_dealers}</Subheading>
            <Column gap="xs">
                <DealerSearch {...dealerSearchProps} />
                <DealerSortButtons
                    sortOrder={sortOrder}
                    setSortOrder={setSortOrder}
                />
                {locationError && (
                    <AlertBanner type="warning" message={locationError} />
                )}
                <BilholdInnerViewLayout>
                    <SingleChoiceDealerList
                        firstXDealers={firstXDealers}
                        totalNumberOfDealers={totalDealerCount}
                        onChange={onChange}
                        selectedDealerId={selectedDealerId}
                        validity={validity}
                        sortOrder={sortOrder}
                    />
                    <ShowMoreDealers
                        showMore={showMore}
                        shownDealerCount={shownDealerCount}
                        totalDealerCount={totalDealerCount}
                    />
                </BilholdInnerViewLayout>
            </Column>
        </div>
    );
}

export type DealerListSortOrder = 'byDistance' | 'alphabetically';

interface DealerSortButtonsProps {
    sortOrder: DealerListSortOrder;
    setSortOrder: (order: DealerListSortOrder) => void;
}

const DealerSortButtons = ({
    sortOrder,
    setSortOrder,
}: DealerSortButtonsProps) => {
    const [lc] = useLanguageContext();
    return (
        <Row gap="xs">
            <TextButton
                onClick={() => {
                    datadogRum.addAction(
                        datadogLocationActionPrefix + 'sort by distance'
                    );
                    setSortOrder('byDistance');
                }}
                underline={sortOrder === 'byDistance'}
            >
                {lc.chooseDealer.sort_by_distance}
            </TextButton>
            <TextButton
                onClick={() => setSortOrder('alphabetically')}
                underline={sortOrder === 'alphabetically'}
            >
                {lc.chooseDealer.sort_alphabetically}
            </TextButton>
        </Row>
    );
};

const DealerSearchContainer = styled.div`
    .search-textfield {
        max-width: 280px;
    }

    .search-textfield div:nth-child(2) {
        // TODO: Make it possible to disable reserved space for validation text in TextField (design system)
        display: none;
    }
`;

type DealerSearchProps = {
    setSearchValue: (current: string) => void;
};

function DealerSearch({ setSearchValue }: DealerSearchProps) {
    const [lc] = useLanguageContext();
    return (
        <DealerSearchContainer>
            <TextField
                label={lc.chooseDealer.find_dealer}
                className="search-textfield"
                onChange={(e) => setSearchValue(e.currentTarget.value)}
            />
        </DealerSearchContainer>
    );
}
