import { useQuery } from '@tanstack/react-query';
import get from 'src/utils/http/get';
import getCurrentEnvironment from 'src/utils/environment/getCurrentEnvironment';

export const prizeKey = 'prize';

export interface PrizeData {
    streakPrizeDealer: string;
    hasWonStreakPrize: boolean;
    elevationPrizeDealer: string;
    hasWonElevationPrize: boolean;
    distancePrizeDealer: string;
    hasWonDistancePrize: boolean;
}

export const useCompetitionPrizeData = (
    hasCompetitionStarted: boolean,
    hasStravaAuthenticated: boolean
) => {
    const { bilholdApiUrl } = getCurrentEnvironment();
    const url = `${bilholdApiUrl}/v1/competition/prizes`;
    return useQuery({
        queryKey: [prizeKey],
        queryFn: () => get<PrizeData>(url).then((response) => response.data),
        enabled: hasCompetitionStarted && hasStravaAuthenticated,
    });
};
