import { useQuery } from '@tanstack/react-query';
import get from 'src/utils/http/get';
import getCurrentEnvironment from 'src/utils/environment/getCurrentEnvironment';

export const startPrizeKey = 'start-prize';

export interface StartPrizeInterface {
    dealer: string | undefined;
    prize: string | undefined;
    hasWon: boolean;
}

export const useCompetitionStartPrize = (
    hasStravaAuthenticated: boolean,
    hasCompetitionStarted: boolean
) => {
    const { bilholdApiUrl } = getCurrentEnvironment();
    const url = `${bilholdApiUrl}/v1/competition/start-prize`;
    return useQuery({
        queryKey: [startPrizeKey],
        queryFn: () =>
            get<StartPrizeInterface>(url).then((response) => response.data),
        enabled: hasStravaAuthenticated && hasCompetitionStarted,
    });
};
