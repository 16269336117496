import loginRedirect from 'src/utils/auth/loginRedirect';
import { Section, SectionTextLayout } from '../components';

import nybil from '../images/ny_bil.jpg';

export const NewCar = () => {
    return (
        <Section>
            <img src={nybil} alt="" />
            <SectionTextLayout
                title={'Venter du på ny bil?'}
                cta={{
                    label: 'Sjekk ut din nye bil',
                    onClick: () => loginRedirect(),
                }}
            >
                <p>
                    I Bilhold får du tilgang til din ordreinformasjon når du har
                    kjøpt ny eller brukt bil hos oss. I tillegg vil du, for de
                    aller fleste modeller, finne informasjon og videoer om bilen
                    du har bestilt.
                </p>
            </SectionTextLayout>
        </Section>
    );
};
