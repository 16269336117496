import { BaseFriendlyQueryError } from 'external-apis/src/fetchers/errorUtils';
import { ErrorResponse } from 'external-apis/src/types/port';

/**
 * A user-friendly warning for wrapping an error in a query or mutation
 */
export class FriendlyQueryWarning extends BaseFriendlyQueryError {}
/**
 * A user-friendly error for wrapping an error in a query or mutation
 */
export class FriendlyQueryError extends BaseFriendlyQueryError {}
//used in queries to assert if error is an ErrorResponse (defined in the ports schema)
export function isErrorResponse(response: { status: number; data: object }) {
    if (response.status === 0) {
        return response as { status: number };
    }
    if (
        ('success' in response.data && 'message' in response.data) ||
        ('Success' in response.data && 'Message' in response.data)
    ) {
        return response as { status: number; data: ErrorResponse };
    }
    return undefined;
}
