import {
    Column,
    Description,
    Icon,
    IconButton,
    LabelText,
    ProgressBar,
    Row,
} from '@moller/design-system';
import { useNavigate } from 'react-router-dom';
import { WeightItem } from 'src/features/electric-car-playground/payload/payload-calculator/types/WeightItems';
import { InfoContainerCard } from 'src/features/my-car/sva-service-deals/SvaService';
import { VehicleData } from 'src/types/generated/CarInformation/CarInformationTypes';
import lang from 'src/utils/lang';
import { isMobileApp } from 'src/utils/mobile';
import { styled } from 'styled-components';

const HeaderPage = styled(Column)`
    border-radius: var(--moller-border-radius-large);
`;

const StyledHeader = styled(Column)`
    gap: var(--moller-spacing-xxs);
`;

const HeaderTextContainer = styled(Column)`
    padding-bottom: var(--moller-size-xxs);
`;

const HeaderContainer = styled(HeaderTextContainer)`
    padding-top: var(--moller-size-xs);
`;

const HeaderCarDescription = styled(Row)`
    align-items: center;
    justify-content: center;
`;

const ProgressBarLabel = styled.p`
    margin-top: var(--moller-spacing-xs);
`;

interface PayloadCalculatorHeaderProps {
    technicalDetails: VehicleData | undefined;
    sum: number;
    items: WeightItem[];
}
const PayloadCalculatorHeader = ({
    technicalDetails,
    sum,
}: PayloadCalculatorHeaderProps) => {
    const navigate = useNavigate();
    const payload =
        technicalDetails?.maxWeight && technicalDetails?.minimumWeight
            ? technicalDetails?.maxWeight -
              (technicalDetails?.minimumWeight + 75)
            : 0;

    const percentage = (sum / payload) * 100;
    return (
        <HeaderPage>
            <StyledHeader>
                <IconButton
                    icon="arrow_backward"
                    label={lang.back}
                    showLabel="right"
                    onClick={() => navigate(-1)}
                    style={
                        isMobileApp()
                            ? { marginLeft: 'var(--moller-spacing-s)' }
                            : {
                                  marginLeft: '0',
                              }
                    }
                />
                <HeaderContainer gap={'xs'}>
                    <HeaderTextContainer gap={'xxs'}>
                        <InfoContainerCard>
                            <LabelText>
                                Hvor mye kan du laste i din bil?
                            </LabelText>
                            <ProgressBar
                                animateOnChange
                                percentage={
                                    percentage <= 100 ? percentage : 100
                                }
                            />
                            <ProgressBarLabel>
                                {sum} av {payload} kg
                            </ProgressBarLabel>
                        </InfoContainerCard>
                        <HeaderCarDescription gap={'xxs'}>
                            <Icon icon="car" />
                            <Description>{technicalDetails?.model}</Description>
                        </HeaderCarDescription>
                    </HeaderTextContainer>
                </HeaderContainer>
            </StyledHeader>
        </HeaderPage>
    );
};

export default PayloadCalculatorHeader;
