export default (licensePlate?: string): string => {
    if (!licensePlate) {
        return '';
    }

    const licensePlateRegex = /^\s*([A-z]{2})\s*(\d{5})\s*$/;
    const match = licensePlateRegex.exec(licensePlate);
    return (
        match ? `${match[1]} ${match[2]}` : licensePlate.replace(/\s+/g, ' ')
    )
        .trim()
        .toUpperCase();
};
