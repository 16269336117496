import {
    breakpointMobile,
    Column,
    Headline,
    Row,
    Spinner,
} from '@moller/design-system';
import React from 'react';
import { BookingViewModel } from 'src/features/appointment/api/useAppointments';
import { ddRumPrivacySetting } from 'src/utils/datadog';
import lang from 'src/utils/lang';
import { styled } from 'styled-components';
import { Edit } from './components/Edit';
import { Information } from './components/Information';
import { Map } from './components/Map';

const AppointmentContainer = styled(Column)`
    display: flex;
    width: 100%;
    max-width: 820px;
    margin: auto;
    padding-top: var(--moller-spacing-s);
`;

const AppointmentRow = styled(Row)`
    gap: var(--moller-spacing-xl);
    @media screen and (max-width: ${breakpointMobile}) {
        flex-direction: column;
    }
`;

const SpinnerContainer = styled.div`
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
`;
type AppointmentDetailsProps = {
    appointment?: BookingViewModel;
    setDrawerIsOpen?: React.Dispatch<React.SetStateAction<boolean>>;
    isLoading: boolean;
};

export const DetailedAppointmentContent = ({
    appointment,
    setDrawerIsOpen,
    isLoading,
}: AppointmentDetailsProps) => {
    if (isLoading) {
        return (
            <SpinnerContainer>
                <Spinner
                    backgroundColor="on-background"
                    diameter="var(--moller-size-3xl)"
                    textDescription="Loading appointment"
                />
            </SpinnerContainer>
        );
    }

    if (!appointment) {
        return <Headline>{lang.appointmentError}</Headline>;
    }

    return (
        <AppointmentContainer {...ddRumPrivacySetting('allow')}>
            <Map {...appointment} />
            <AppointmentRow>
                <Information appointment={appointment} />
                <Edit
                    appointment={appointment}
                    setDrawerIsOpen={setDrawerIsOpen}
                />
            </AppointmentRow>
        </AppointmentContainer>
    );
};
