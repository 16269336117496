import { useEffect, useState } from 'react';
import getCurrentEnvironment from 'src/utils/environment/getCurrentEnvironment';
import get from 'src/utils/http/get';
import lang from 'utils/lang';

interface ZipCodeData {
    valid: boolean;
    city?: string;
}

export const useCityFromZipCode = (zipCode: string) => {
    const { bilholdApiUrl } = getCurrentEnvironment();
    const [city, setPlace] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [disableInput, setDisableInput] = useState(true);
    const url = `${bilholdApiUrl}/v1/postal-codes/${zipCode}`;

    useEffect(() => {
        if (zipCode?.length === 4) {
            setDisableInput(true);
            get<ZipCodeData>(url)
                .then(({ data }) => {
                    if (!data.valid) {
                        setPlace('');
                        setErrorMessage(lang.invalid_zip_code);
                    } else if (data.valid && data.city) {
                        setPlace(data.city);
                        setErrorMessage('');
                    }
                })
                .catch(() => {
                    setDisableInput(false);
                });
        } else {
            setErrorMessage('');
            setPlace('');
        }
    }, [url, zipCode]);

    return { city, disableUserInput: disableInput, errorMessage };
};
