import { LogoBrand } from '@moller/design-system/foundation/logos/Logo.js';
import React, { useEffect } from 'react';
import { useCarTransitionContext } from 'src/utils/CarTransistionContext';
import { styled } from 'styled-components';

interface TransitionProps {
    /** animation delay in seconds */
    delay?: number;
    /** animation duration in seconds */
    duration?: number;
    brand?: LogoBrand;
    children: React.ReactNode;
}
const AnimationContainer = styled.div<TransitionProps>`
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    animation-duration: ${({ duration }) => duration || 1}s;
    animation-name: fadeout;
    animation-delay: ${({ delay }) => delay || 2}s;
    animation-fill-mode: forwards;
    background-color: ${({ brand }) =>
        brand === 'audi'
            ? 'var(--moller-color-tertiary)'
            : 'var(--moller-color-background)'};
    position: absolute;
    z-index: 100;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;

    @keyframes fadeout {
        from {
            opacity: 1;
        }
        75% {
            pointer-events: none;
        }
        to {
            opacity: 0;
            display: none;
            z-index: -1;
        }
    }
`;

export const CarTransition = ({
    children,
    delay,
    duration,
    brand,
}: TransitionProps) => {
    const { shouldRunCarTransition, setShouldRunCarTransition } =
        useCarTransitionContext();

    useEffect(() => {
        /*
            This avoids running transition coming from other pages than home
        */
        if (shouldRunCarTransition) {
            const timer = setTimeout(() => {
                setShouldRunCarTransition(false);
            }, 2000);

            return () => {
                clearTimeout(timer);
                setShouldRunCarTransition(false);
            };
        }
    }, [setShouldRunCarTransition, shouldRunCarTransition]);

    return (
        <AnimationContainer delay={delay} duration={duration} brand={brand}>
            {children}
        </AnimationContainer>
    );
};
