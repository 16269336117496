import { useEffect } from 'react';
import loginRedirect from 'src/utils/auth/loginRedirect';

const LoginRedirecter = () => {
    useEffect(() => {
        loginRedirect();
    }, []);

    return <></>;
};

export default LoginRedirecter;
