import { AlertBanner, IconButton, Modal } from '@moller/design-system';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PATH_HOME } from 'src/routing/routes';
import Car from 'src/types/Car';
import lang from 'utils/lang';
import { useDeleteCar } from '../query/useDeleteCar';

interface DeleteCarProps {
    readonly car: Car;
}
export const DeleteCar = ({ car }: DeleteCarProps) => {
    const [openModal, setOpenModal] = useState(false);
    const navigate = useNavigate();
    const onSuccess = () => {
        navigate(PATH_HOME);
    };
    const { mutate, error, isLoading } = useDeleteCar(car.vin, onSuccess, () =>
        setOpenModal(false)
    );

    return (
        <div>
            <IconButton
                icon={'trash'}
                label={lang.remove_car}
                showLabel="right"
                onClick={() => setOpenModal(true)}
            />
            <Modal
                title={lang.remove_car}
                primaryAction={{
                    label: lang.remove_car,
                    onClick: () => {
                        mutate();
                    },
                    loading: {
                        isLoading: isLoading,
                        loadingText: lang.deletingCar,
                    },
                }}
                secondaryAction={{
                    label: lang.cancel,
                    onClick: () => setOpenModal(false),
                }}
                isOpen={openModal}
                handleClose={() => setOpenModal(false)}
            >
                {lang.delete_car_confirmation}
            </Modal>
            {error ? (
                <AlertBanner type={'error'} message={lang.deleteCarError} />
            ) : null}
        </div>
    );
};
