import { LogoBrand } from '@moller/design-system/foundation/logos/Logo.js';
import { useEffect, useLayoutEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
    AnimatedAutoriaLogo,
    AnimatedBilholdLogo,
    AnimatedDahlesLogo,
    AnimatedGumpenLogo,
    AnimatedMollerLogo,
    AnimationContainer,
} from 'src/features/home/transition/transition';
import SkodaTransition from 'src/features/my-car/car-brands/skoda/SkodaTransition';
import { AnimatedLogo } from 'src/features/my-car/components/AnimatedLogo';
import { PATH_BEGIN, PATH_HOME } from 'src/routing/routes';
import { useThemeContext } from 'theming';

const getAnimatedTransition = (dealerBrand: LogoBrand) => {
    switch (dealerBrand) {
        case 'mollerbil':
            return <AnimatedMollerLogo />;
        case 'gumpen':
            return <AnimatedGumpenLogo />;
        case 'dahles':
            return <AnimatedDahlesLogo />;
        case 'autoria':
            return <AnimatedAutoriaLogo />;
        case 'vw':
            return <AnimatedLogo brand="vw" />;
        case 'skoda':
            return <SkodaTransition />;
        case 'audi':
            return <AnimatedLogo brand="audi" />;
        case 'cupra':
            return <AnimatedLogo brand="cupra" />;

        default:
            return <AnimatedBilholdLogo />;
    }
};

let hasSplashBeenCalled = false;
/*
    Splash screen when entering app
*/
const SplashScreen = () => {
    const location = useLocation();
    const { theme } = useThemeContext();

    const [shouldRender, setShouldRender] = useState(false);

    useLayoutEffect(() => {
        const isOnSplashRoutes =
            location.pathname === '/' ||
            location.pathname === PATH_BEGIN ||
            location.pathname === PATH_HOME;

        const minSplashIntervalInSeconds = 30;
        const currentTimeStamp = new Date().getTime();
        const prevTimeStamp = localStorage.getItem('prevTimeStamp') || '0';
        const secondsSincePrevSplash =
            (currentTimeStamp - parseInt(prevTimeStamp, 10)) / 1000;

        if (
            !hasSplashBeenCalled &&
            isOnSplashRoutes &&
            secondsSincePrevSplash > minSplashIntervalInSeconds
        ) {
            setShouldRender(true);
        }

        hasSplashBeenCalled = true;
    }, [location.pathname]);

    useEffect(() => {
        const setPrevTimeVisitedPage = () => {
            localStorage.setItem(
                'prevTimeStamp',
                new Date().getTime().toString()
            );
        };
        window.addEventListener('beforeunload', setPrevTimeVisitedPage);

        return () => {
            window.removeEventListener('beforeunload', setPrevTimeVisitedPage);
        };
    }, []);

    if (!shouldRender) {
        return null;
    }

    const transition = getAnimatedTransition(theme);

    return (
        <AnimationContainer id="animation-container">
            {transition}
        </AnimationContainer>
    );
};

export default SplashScreen;
