import { PrizeComponentBase } from 'src/features/competition/components/myPrizes/PrizeComponentBase';
import { useUpdateElevationPrizeData } from 'src/features/competition/utils/useUpdatePrizeData';
import blanket from '../../images/Blanket.png';

interface Props {
    hasWon: boolean | undefined;
    dealer: string | undefined;
}

export const WhiteElevationShirtPrize = ({ hasWon, dealer }: Props) => {
    const {
        mutate: updatePrize,
        isLoading: isUpdating,
        isError,
    } = useUpdateElevationPrizeData();
    return (
        <PrizeComponentBase
            isError={isError}
            isUpdating={isUpdating}
            updatePrize={updatePrize}
            hasWon={hasWon}
            dealer={dealer}
            hasNotWonText={
                'Sykle tilsvarende opp Galdhøpiggen i høydemeter i løpet av konkurranseperioden! De første 250 som klarer klatretrøya får premie.'
            }
            hasWonText={
                'Du syklet tilsvarende opp Galdhøpiggen i løpet av konkurranseperioden og fikk derfor klatretrøya! Du var også blant de 250 første som klarte dette. Derfor får du premie!'
            }
            heading={'Klatretrøya'}
            color={'white'}
            prizeName={'Škoda piknikteppe'}
            image={blanket}
        />
    );
};
