import { datadogRum } from '@datadog/browser-rum';
import { Headline, OverlineText, Subtitle } from '@moller/design-system';
import { useUserHasSeenElement } from 'src/utils/hooks/useInView';
import { styled } from 'styled-components';
import { PriceEstimate } from '../../queries/useGetCarPriceEstimate';
import { BenefitCard } from './BenefitCard';

const StyledHeadline = styled(Headline)`
    margin: var(--moller-spacing-m) 0;
    align-self: flex-start;
`;

const NonBreakingText = styled.span`
    white-space: nowrap;
`;

const InfoText = styled.p`
    margin-bottom: var(--moller-spacing-m);
`;

export const CardOverlineText = styled(OverlineText)`
    font-weight: 500;
    color: var(--moller-keycolor-tertiary);
    margin: 0 0 var(--moller-spacing-xxs);
`;

export const CardSubTitlePrefix = styled.p`
    font-size: var(--moller-typeface-size-base);
    font-weight: 400;
`;

export const CardSubTitle = styled.h2`
    font-size: var(--moller-typeface-size-xl);
    font-weight: 500;
    line-height: var(--moller-typeface-line-height);
    margin: 0 0 var(--moller-spacing-xxs);
`;

const PriceEstimateContainer = styled.div`
    background-color: var(--moller-color-primary);
    color: var(--moller-color-on-primary);
    border-radius: var(--moller-border-radius-x-large);
    padding: var(--moller-spacing-l);
`;

const StyledSubtitle = styled(Subtitle)`
    margin: var(--moller-spacing-l) 0 var(--moller-spacing-xxs) 0;
`;

interface PriceEstimateContentProps {
    priceEstimate: PriceEstimate;
    modelYear?: string;
}

export const PriceEstimateContent = ({
    priceEstimate,
    modelYear,
}: PriceEstimateContentProps) => {
    const ref = useUserHasSeenElement<HTMLDivElement>(() => {
        datadogRum.addAction('User sees price offer estimate', {
            data: modelYear,
        });
    });

    return (
        <>
            <StyledHeadline>
                Vi selger biler som din for{' '}
                <NonBreakingText>
                    {priceEstimate.marketMinPrice.toLocaleString()} -{' '}
                    {priceEstimate.marketMaxPrice.toLocaleString()} kr
                </NonBreakingText>
            </StyledHeadline>
            <InfoText>
                Tallet er basert på faktiske salgspriser fra våre butikker den
                siste tiden. Vi har oppnådd denne prisen etter å ha investert
                all vår ekspertise som merkeverksted på å sette bilen i tipp
                topp stand før vi selger den. Prisen er derfor ikke
                representativ for bilen din sånn den er i dag.
                <br />
                <br />
                Det er flere grunner til å la oss ta over salget. Vi tar
                selvfølgelig en bit av kaken, men du får i gjengjeld fordelene:
            </InfoText>
            <BenefitCard type="warranty" />
            <BenefitCard type="preparation" />
            <BenefitCard type="stressFree" />
            <BenefitCard type="nonBinding" />
            <StyledSubtitle>Ønsker du å selge bilen til oss?</StyledSubtitle>
            <PriceEstimateContainer ref={ref}>
                <CardOverlineText>Vi tror vi kan tilby deg</CardOverlineText>
                <CardSubTitle>{`${priceEstimate?.offerMinPrice.toLocaleString()} - ${priceEstimate?.offerMaxPrice.toLocaleString()} kr`}</CardSubTitle>

                <InfoText>
                    Forventet du å få mer? Prisen vi kan tilby deg er basert på
                    faktiske salgspriser og kostnadene vi investerer i bilen for
                    å få så høy pris som mulig.
                    <br />
                    <br />
                    For å kunne gi et konkret tilbud for akkurat din bil trenger
                    våre bruktbileksperter litt mer informasjon fra deg.
                </InfoText>
            </PriceEstimateContainer>
        </>
    );
};
