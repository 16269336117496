import { useGetRegisteredVehicles } from '../../../_api/http/vehicle';
import { QueryError } from '../../../components/QueryError';
import { useLanguageContext } from '../../../lib/languages/languageContext';
import { ChooseVehicleLoader } from '../ChooseVehicleLoader';
import { VehicleSectionState } from '../ChooseVehicleSection';
import { ChooseRegisteredVehicle } from './ChooseRegisteredVehicle';

type AuthVehicleSectionProps = {
    setSectionState: (x: VehicleSectionState) => void;
};

export const MyVehicles: React.FC<AuthVehicleSectionProps> = ({
    setSectionState,
}) => {
    const response = useGetRegisteredVehicles();
    const [lc] = useLanguageContext();

    if (response.isLoading) {
        return <ChooseVehicleLoader />;
    }

    return (
        <>
            {response.isSuccess && (
                <>
                    {response.data.length > 0 && (
                        <p>{lc.chooseVehicle.choose_vehicle}</p>
                    )}
                    <ChooseRegisteredVehicle
                        {...{ setSectionState }}
                        registeredVehicles={response.data}
                    />
                </>
            )}
            <QueryError isError={response.isError} error={response.error} />
            {!response.isError && <p>{lc.chooseVehicle.not_in_carcare}</p>}
        </>
    );
};
