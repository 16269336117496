import { enUS, lv, nb } from 'date-fns/locale';
import { createContext, useContext } from 'react';
import English from './translations/en.json';
import Latvian from './translations/lv.json';
import Norwegian from './translations/no.json';

export type LanguageContextType = typeof Norwegian;

const languageIdentifiers = ['no', 'en', 'lv'] as const;
export type LanguageIdentifier = (typeof languageIdentifiers)[number];

export const languages: Record<LanguageIdentifier, LanguageContextType> = {
    no: Norwegian,
    en: English,
    lv: Latvian,
};

type LanguageState = {
    language: LanguageContextType;
    locale: GuiLocaleConfig;
};
type GuiLocaleConfig = {
    languageId: LanguageIdentifier;
    fnsLocale: Locale;
    stringLocale: string;
};

export const LanguageContext = createContext<LanguageState | null>(null);

const guiLocaleConfig: Map<LanguageIdentifier, GuiLocaleConfig> = new Map([
    [
        'no',
        {
            languageId: 'no',
            fnsLocale: nb,
            stringLocale: 'nb',
        },
    ],
    [
        'en',
        {
            languageId: 'en',
            fnsLocale: enUS,
            stringLocale: 'en-US',
        },
    ],
    [
        'lv',
        {
            languageId: 'lv',
            fnsLocale: lv,
            stringLocale: 'lv',
        },
    ],
]);

type LanguageProviderProps = {
    languageId: LanguageIdentifier;
    children: JSX.Element;
};

export function LanguageProvider({
    children,
    languageId,
}: LanguageProviderProps) {
    const locale = guiLocaleConfig.get(languageId);

    if (!locale) {
        throw new Error('Language provider was not provided a default locale.');
    }

    const language = {
        language: languages[languageId],
        locale,
    };

    return (
        <LanguageContext.Provider value={language}>
            {children}
        </LanguageContext.Provider>
    );
}

export function useLanguageContext() {
    const language = useContext(LanguageContext);

    if (!language) {
        throw new Error(
            'Language provider was not provided a default language.'
        );
    }

    return [language.language, language.locale] as const;
}
