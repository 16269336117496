import { styled } from 'styled-components';
import { audiImages } from '../car-brands/audi/car-images';
import { cupraImages } from '../car-brands/cupra/car-images';
import { skodaImages } from '../car-brands/skoda/car-images';
import { vwImages } from '../car-brands/vw/car-images';
import { Brand } from 'external-apis/src/types/bilhold';

const StyledImage = styled.img<{ size: 'small' | 'medium' }>`
    max-height: ${(props) => (props.size === 'small' ? '80px' : '140px')};
    max-width: 100%;
    object-fit: contain;
`;

type CarImageProps = {
    carName: string;
    brand: Brand;
    size?: 'small' | 'medium';
};

const selectImageList = (brand: Brand) => {
    switch (brand) {
        case 'Skoda':
            return skodaImages;
        case 'Volkswagen':
        case 'VolkswagenCommercialVehicles':
            return vwImages;
        case 'Audi':
            return audiImages;
        case 'Cupra':
            return cupraImages;
        default:
            return [];
    }
};

export const CarImage = ({
    carName,
    brand,
    size = 'medium',
    ...delegated
}: CarImageProps) => {
    const currentCar = selectImageList(brand).find((carImage) => {
        if (
            carImage.name.every((i) => carName?.toLocaleLowerCase().includes(i))
        )
            return carImage;
    });

    if (currentCar) {
        return (
            <StyledImage
                src={currentCar.src}
                alt={`Siden av bilen ${currentCar.name.toString()}`}
                size={size}
                {...delegated}
            />
        );
    }

    return null;
};
