import { useState } from 'react';
import { PATH_MESSAGES } from 'src/routing/routes';
import { AlertBanner, GhostButton, Icon, Modal } from '@moller/design-system';
import lang from 'src/utils/lang';
import { useNavigate } from 'react-router-dom';
import { useDeleteMessage } from 'src/utils/hooks/useMessageThreads';

interface DeleteThreadProps {
    threadId: string;
}

const DeleteThread = ({ threadId }: DeleteThreadProps) => {
    const [showDialog, setShowDialog] = useState(false);

    const navigate = useNavigate();

    const onDeleteSuccess = () => {
        setShowDialog(false);
        navigate(PATH_MESSAGES);
    };

    const {
        mutate: deleteThread,
        reset,
        isLoading,
        isError,
    } = useDeleteMessage(threadId, () => onDeleteSuccess());

    const handleCloseModal = () => {
        setShowDialog(false);
        reset();
    };

    return (
        <>
            <GhostButton onClick={() => setShowDialog(true)}>
                <Icon icon="trash" />
                {lang.deleteMessageThread}
            </GhostButton>
            {showDialog && (
                <Modal
                    isOpen={showDialog}
                    handleClose={() => handleCloseModal()}
                    title={lang.deleteMessageTitle}
                    primaryAction={{
                        label: lang.delteMessageConfirmButton,
                        onClick: () => deleteThread(),
                        loading: {
                            isLoading: isLoading,
                            loadingText: 'Loading',
                        },
                    }}
                    secondaryAction={{
                        label: lang.cancel,
                        onClick: () => handleCloseModal(),
                    }}
                    size="large"
                >
                    {lang.deleteMessageDesc}
                    {isError && (
                        <AlertBanner
                            type="error"
                            heading={lang.somethingWentWrong}
                            message={lang.deleteThreadErrorContactUsLink}
                        />
                    )}
                </Modal>
            )}
        </>
    );
};

export default DeleteThread;
