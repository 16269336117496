import { Dealer } from 'src/features/appointment/api/useAppointments';

const formatDealerObject = (dealer: Dealer) => ({
    location: {
        address: dealer.streetAddress,
        zipCode: dealer.zipCode,
        postalArea: dealer.city,
        coordinates: {
            latitude: dealer.coordinates?.latitude ?? 0,
            longitude: dealer.coordinates?.longitude ?? 0,
        },
    },
    mnetDealerId: dealer.id,
    name: dealer.name,
    phoneNumber: dealer.phoneNumber,
});

export default formatDealerObject;
