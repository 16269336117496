import { InfoCard } from '@moller/design-system';
import { DelayedRender } from '../../lib/DelayedRender';

export function AdditionalServicesLoader() {
    const card = <InfoCard isLoading density="compact" />;
    return (
        <DelayedRender>
            {card}
            {card}
            {card}
            {card}
            {card}
        </DelayedRender>
    );
}
