import { createContext, useContext } from 'react';
import { ApiLocaleIdentifier } from '../../_api/apiLocale';
import { BookingParamValues } from '../../_api/urlSearchParameters';

export type Config = {
    apiLocaleId: string;
    searchableDealerNumbers: string[];
    defaultApiLocale: ApiLocaleIdentifier;
    bookingParameters: BookingParamValues;
};

export type ValidConfig = {
    apiLocaleId: ApiLocaleIdentifier;
} & Config;

const configContext = createContext<ValidConfig | null>(null);

export function useAppConfig() {
    const config = useContext(configContext);
    if (!config) {
        throw new Error('Config context was not provided with default config');
    }
    return config;
}

export const ConfigProvider = ({
    config,
    children,
}: React.PropsWithChildren<{
    config: ValidConfig;
}>) => (
    <configContext.Provider value={config}>{children}</configContext.Provider>
);
