import { Checkbox, Link, Row } from '@moller/design-system';
import lang from 'src/utils/lang';
import { openWindowHref } from 'src/utils/navigation/openSystemHref';

interface TermsOfUseProps {
    termsAccepted: boolean;
    setTermsAccepted: (value: boolean) => void;
}

export const TermsOfUse = ({
    termsAccepted,
    setTermsAccepted,
}: TermsOfUseProps) => {
    return (
        <Row verticalAlign="center">
            <Checkbox
                label={lang.acceptPrivacy}
                value={termsAccepted}
                onChange={() => setTermsAccepted(!termsAccepted)}
            />
            <Link
                onClick={() => {
                    openWindowHref('https://www.mittbilhold.no/privacy');
                }}
            >
                {lang.termsOfUse}
            </Link>
        </Row>
    );
};
