import { Column, InfoCard, Row } from '@moller/design-system';
import { styled } from 'styled-components';
import { TDFShirt } from '../assets/TDFShirt';
import earthImage from '../images/Earth.png';
import mountainImage from '../images/Galdohpiggen.png';
import { TDFTextLarge, TDSTextSmall } from '../textComponents/TDFText';
import { useGetCompetitionStatistics } from '../utils/useGetCompetitionStatistics';

const StatisticsCardWrapper = styled(InfoCard)`
    background-color: #c4c6c7;
    padding: var(--moller-spacing-xs) var(--moller-spacing-m);
    border: 0;
    color: black;
`;

const CustomAccordion = styled.details`
    background-color: '#C4C6C7';

    summary {
        list-style: none;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: var(--moller-spacing-xxs);
    }

    &:last-child summary {
        border: none;
    }

    summary::after {
        content: '';
        width: 0;
        height: 0;
        border-top: 10px solid black;
        border-inline: 7px solid transparent;
        transition: 0.2s;
    }
    &[open] summary::after {
        transform: rotate(180deg);
    }
`;

const ColumnWrapper = styled(Column)`
    padding: var(--moller-spacing-xxs);
`;

export const StatisticsAccordion = () => {
    const { data: competitionStatistics } = useGetCompetitionStatistics();
    const HEIGHT_OF_GALDHOPIGGEN_IN_METERS = 2469;
    const CIRCUMFERENCE_OF_EARTH_IN_KILOMETERS = 40075;
    const totalTimesUpGaldhopiggen = Math.floor(
        (competitionStatistics?.totalElevation ?? 0) /
            HEIGHT_OF_GALDHOPIGGEN_IN_METERS
    );
    const totalTimesAroundEarth = Math.floor(
        (competitionStatistics?.totalKilometers ?? 0) /
            CIRCUMFERENCE_OF_EARTH_IN_KILOMETERS
    );
    const formatter = new Intl.NumberFormat('nb-NO', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    });
    return (
        <>
            <TDFTextLarge>Felles-touren</TDFTextLarge>
            <StatisticsCardWrapper>
                <CustomAccordion>
                    <summary>Tour-statistikk</summary>
                    <ColumnWrapper gap="m">
                        <TDSTextSmall>
                            Alle konkurransedeltakerne i Tour de Škoda
                            sammenlagt
                        </TDSTextSmall>
                        <StatisticsRow
                            label="Antall km syklet"
                            value={`${formatter.format(
                                competitionStatistics?.totalKilometers ?? 0
                            )} km`}
                        />
                        <StatisticsRow
                            label="Antall ganger rundt jordkloden"
                            value={`${totalTimesAroundEarth} x`}
                            imgSrc={earthImage}
                        />
                        <StatisticsRow
                            label="Antall høydemetere syklet"
                            value={`${formatter.format(
                                competitionStatistics?.totalElevation ?? 0
                            )} m`}
                        />
                        <StatisticsRow
                            label="Antall ganger opp Galdhøpiggen"
                            value={`${totalTimesUpGaldhopiggen} x`}
                            imgSrc={mountainImage}
                        />
                        <StatisticsRow
                            label="Antall oppnådde oppstartspremier"
                            value={
                                competitionStatistics?.totalStartPrizesWon ?? 0
                            }
                        />
                        <StatisticsRow
                            label="Antall oppnådde grønne trøyer"
                            value={`${competitionStatistics?.totalStreakPrizesWon} x`}
                            ShirtProps={{
                                fill: 'var(--moller-palette-primary-70)',
                            }}
                        />
                        <StatisticsRow
                            label="Antall oppnådde klatretrøyer"
                            value={`${competitionStatistics?.totalElevationPrizesWon} x`}
                            ShirtProps={{ fill: 'white' }}
                        />
                        <StatisticsRow
                            label="Antall oppnådde gule trøyer"
                            value={`${competitionStatistics?.totalDistancePrizesWon} x`}
                            ShirtProps={{ fill: '#FAEB67' }}
                        />
                    </ColumnWrapper>
                </CustomAccordion>
            </StatisticsCardWrapper>
        </>
    );
};
interface StatisticsRowProps {
    label: string;
    value: string | number;
    imgSrc?: string;
    ShirtProps?: {
        fill: string;
    };
}

const StatisticsRow = ({
    label,
    value,
    imgSrc,
    ShirtProps,
}: StatisticsRowProps) => (
    <Row horizontalAlign="spaceBetween" verticalAlign="center">
        <Column style={{ maxWidth: '60%' }}>
            <TDFTextLarge>{label}</TDFTextLarge>
        </Column>
        <Column>
            <Row verticalAlign="center" gap="xs">
                <TDFTextLarge>{value}</TDFTextLarge>

                {imgSrc && <img src={imgSrc} alt={label} />}
                {ShirtProps && (
                    <TDFShirt
                        isElevationShirt={ShirtProps.fill === 'white'}
                        {...ShirtProps}
                        width="48"
                        height="40"
                    />
                )}
            </Row>
        </Column>
    </Row>
);
