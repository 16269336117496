import { Icon, PrimaryButton } from '@moller/design-system';
import { Link } from 'react-router-dom';
import { styled } from 'styled-components';

const FooterContainer = styled.div`
    display: flex;
    justify-content: center;
    padding: var(--moller-spacing-base) 0;
`;

interface StickyContainerButtonProps {
    buttonText: string;
    buttonLink: string;
    buttonClick?: () => void;
}

const StickyFooterContainer = ({
    buttonText,
    buttonLink,
    buttonClick,
}: StickyContainerButtonProps) => {
    const isExternalLink = buttonLink.startsWith('https');
    return (
        <FooterContainer>
            {isExternalLink ? (
                <PrimaryButton
                    style={{ width: '100%' }}
                    $as="a"
                    href={buttonLink}
                    title={buttonText}
                    target={'_blank'}
                    onClick={buttonClick}
                >
                    {buttonText}
                    <Icon icon="external" />
                </PrimaryButton>
            ) : (
                <PrimaryButton $as={Link} to={buttonLink} onClick={buttonClick}>
                    {buttonText}
                </PrimaryButton>
            )}
        </FooterContainer>
    );
};

export default StickyFooterContainer;
