import { createContext, ReactNode, useContext, useState } from 'react';

const CarTransitionContext = createContext<
    | {
          shouldRunCarTransition: boolean;
          setShouldRunCarTransition: (state: boolean) => void;
      }
    | undefined
>(undefined);

export const CarTransitionContextProvider = ({
    children,
}: {
    children: ReactNode;
}) => {
    const [shouldRunCarTransition, setShouldRunCarTransition] = useState(true);

    return (
        <CarTransitionContext.Provider
            value={{
                shouldRunCarTransition: shouldRunCarTransition,
                setShouldRunCarTransition: setShouldRunCarTransition,
            }}
        >
            {children}
        </CarTransitionContext.Provider>
    );
};

export const useCarTransitionContext = () => {
    const context = useContext(CarTransitionContext);
    if (context === undefined) {
        throw new Error(
            'useTransitionContext must be used within a TransitionContextProvider'
        );
    }
    return context;
};
