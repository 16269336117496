import { ServiceViewModel } from 'external-apis/src/types/port';
import { FastTrackType } from '../_api/urlSearchParameters';
import { useAppConfig } from './config/configContext';

export function useFastTrack() {
    const { fastTrack } = useAppConfig().bookingParameters;
    return {
        fastTrack,
    };
}

function getServiceCategory(
    serviceModels: ServiceViewModel[],
    category: Exclude<ServiceViewModel['category'], undefined>
) {
    return serviceModels.filter((service) => service.category === category);
}

export function getFastTrackServices(
    serviceModels: ServiceViewModel[],
    fastTrack: FastTrackType | undefined
): { services: ServiceViewModel[]; preselected: string[] } | undefined {
    if (!fastTrack) {
        return undefined;
    }

    switch (fastTrack) {
        case 'glass-damage': {
            const services = getServiceCategory(serviceModels, 'GlassDamage');
            return { services, preselected: services.map((x) => x.id) };
        }
        case 'tire-change': {
            const services = getServiceCategory(serviceModels, 'Tire');
            return {
                services,
                preselected: [
                    sortServicesByPrice(services).map((x) => x.id)[0],
                ],
            };
        }
    }
}

function sortServicesByPrice(services: ServiceViewModel[]) {
    return services.sort(
        (a, b) =>
            (b.dealerSpecificInformation[0]?.price?.fixedPrice || 0) -
            (a.dealerSpecificInformation[0]?.price?.fixedPrice || 0)
    );
}
