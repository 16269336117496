import { ProactiveArticleSanity } from 'src/features/home/current-actions/SanityProactiveArticle';

export const SystemUpgradeCard = () => {
    return (
        <ProactiveArticleSanity
            variables={{}}
            name="Vi ruster oss for fremtiden"
            useReadMore={false}
            buttonLink="/booking"
        />
    );
};
