import { ServiceViewModel } from 'external-apis/src/types/port';
import {
    fakeSetState,
    fakeState,
    tireHotelService,
    tireService,
} from '../../testData';

type SelectService = {
    id: string;
    selectedServiceIds: string[];
    setSelectedServiceIds: (x: string[]) => void;
    services: ServiceViewModel[];
};

export function selectService({
    id,
    selectedServiceIds,
    setSelectedServiceIds,
    services,
}: SelectService) {
    const serviceIds = services.map((x) => x.id);
    setSelectedServiceIds([
        ...selectedServiceIds.filter((x) => !serviceIds.includes(x)),
        id,
    ]);
}

if (import.meta.vitest) {
    const { it, expect, describe } = import.meta.vitest;
    const services = [tireService, tireHotelService];
    describe('selectServices', () => {
        it.concurrent('select service with given id', () => {
            const state = fakeState<string[]>([]);
            const setState = fakeSetState(state);
            selectService({
                id: tireHotelService.id,
                selectedServiceIds: state.value,
                setSelectedServiceIds: setState,
                services,
            });
            expect(state.value).toEqual([tireHotelService.id]);
        });
        it.concurrent('unselect previously selected tire service', () => {
            const state = fakeState(services.map((x) => x.id));
            const setState = fakeSetState(state);
            selectService({
                id: tireService.id,
                selectedServiceIds: state.value,
                setSelectedServiceIds: setState,
                services,
            });
            expect(state.value).toEqual([tireService.id]);
        });
    });
}
