import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { adobeEvent } from 'adobe-utils';
import { differenceInBusinessDays, differenceInCalendarDays } from 'date-fns';
import {
    ReservedTimeSlotViewModel,
    ServiceViewModel,
} from 'external-apis/src/types/port';
import { useRef } from 'react';
import {
    useGetDealerAffiliation,
    useGetDealers,
} from '../../_api/http/dealers';
import { useGetVehicle } from '../../_api/http/vehicle';
import { useBookingSearchParameters } from '../../_api/urlSearchParameters';
import { SERVICE_WITH_PPS_DATADOG } from '../../lib/constants/serviceConstants';
import { useBookingStepAction } from '../../lib/datadog/hooks';
import { formatDealerName } from '../../lib/util/datadogFormatDealerName';
import { AdditionalServicesState } from '../additional-services/AdditionalServicesSection';
import { Done } from '../sectionUtils';
import { StandardServicesData } from '../standard-services/useStandardServices';
import { TransportServicesState } from '../transport-services/TransportServicesSection';

const REPORTER_LABEL = 'R-Confirm-Booking';

type ReportInformationProps = {
    registrationNumber: string;
    selectedDealerNumber: string;
    standardServices: StandardServicesData['selectedServices'];
    reservedTimeSlot?: ReservedTimeSlotViewModel;
    additionalServices?: Done<{
        additionalServices: AdditionalServicesState;
    }>['additionalServices'];
    transportServices?: Done<{
        transportServices: TransportServicesState;
    }>['transportServices'];
    availableRecommendedServices: ServiceViewModel[];
    adapterId?: string;
};

function useReportInformation({
    registrationNumber,
    selectedDealerNumber,
    standardServices,
    reservedTimeSlot,
    additionalServices,
    transportServices,
    availableRecommendedServices,
}: ReportInformationProps) {
    const { dealerGroup } = useBookingSearchParameters();

    const vehicle = useGetVehicle(registrationNumber);
    const dealers = useGetDealers(dealerGroup);

    if (!vehicle.isSuccess || !dealers.isSuccess) {
        return;
    }

    const selectedServices = [
        ...standardServices,
        ...(transportServices?.data.selectedServices ?? []),
        ...(additionalServices?.data.selectedServices ?? []),
    ];

    const mainServicesNames = standardServices.map((x) => x.name);

    const additionalServiceNames = (
        additionalServices?.data.selectedServices ?? []
    ).map((x) => x.name);

    const transportServicesNames = (
        transportServices?.data.selectedServices ?? []
    )
        .filter((x) => x.serviceCategory === 'Transport')
        .map((x) => x.name);

    const deliveryServicesNames = (
        transportServices?.data.selectedServices ?? []
    )
        .filter((x) => x.serviceCategory === 'Delivery')
        .map((x) => x.name);

    const hasRecommendedService = standardServices.some(
        (x) => x.serviceType === 'Recommended'
    );

    const unSelectedRecommendedServices = availableRecommendedServices.filter(
        (s) =>
            hasRecommendedService &&
            !selectedServices.some(
                (selectedService) => s.id === selectedService.serviceId
            )
    );

    const numberOfMainServices = hasRecommendedService
        ? mainServicesNames.length + 1
        : mainServicesNames.length;

    const dealer = dealers.data.find((x) => x.id === selectedDealerNumber);

    if (!dealer) {
        datadogLogs.logger.warn(
            `${REPORTER_LABEL}: Dealer corresponding to dealer number ${selectedDealerNumber} not found.`
        );
        return;
    }

    const comments = standardServices
        .filter((x) => x.comment !== undefined)
        .map((x) => x.comment)
        .join('# new comment#: ');

    const dealerName = formatDealerName(dealer.name);
    const dealerNumber = dealer.id;
    const adapterId = dealer.adapterId;
    const calendarDaysUntilAppointment =
        reservedTimeSlot?.startTime !== undefined
            ? differenceInCalendarDays(
                  new Date(reservedTimeSlot.startTime),
                  new Date()
              )
            : null;
    const businessDaysUntilAppointment =
        reservedTimeSlot?.estimatedFinished !== undefined
            ? differenceInBusinessDays(
                  new Date(reservedTimeSlot.startTime),
                  new Date()
              )
            : null;
    const carBrand = vehicle.data.brandCode;

    return {
        vehicle,
        dealers,
        dealerName,
        dealerNumber,
        calendarDaysUntilAppointment,
        businessDaysUntilAppointment,
        carBrand,
        adapterId,
        selectedServices,
        numberOfServices: selectedServices.length,
        numberOfMainServices,
        services: selectedServices.map((x) => x.name),
        comments,
        unselectedRecommendedServices: unSelectedRecommendedServices.map(
            (x) => x.name
        ),
        numberOfSelectedUnRecommendedServices:
            unSelectedRecommendedServices.length,
        mainServices: hasRecommendedService
            ? mainServicesNames.concat(SERVICE_WITH_PPS_DATADOG)
            : mainServicesNames,
        additionalServices: additionalServiceNames,
        transportServices: transportServicesNames,
        deliveryServices: deliveryServicesNames,
    };
}

export function useAdobeTrackingConfirmBooking({
    formName,
    enabled,
    ...props
}: {
    formName: 'Booking' | 'Booking Fasttrack';
    enabled?: boolean;
} & ReportInformationProps) {
    const info = useReportInformation(props);
    const hasExecuted = useRef(false);
    if (enabled && !hasExecuted.current) {
        const product = info?.mainServices.map((name) => ({
            productInfo: [
                {
                    productSKU: name,
                    productName: name,
                },
            ],
        }));
        if (!product) return;
        adobeEvent.push(
            'formCompleted',
            {
                formName,
                product,
            },
            ''
        );
        hasExecuted.current = true;
    }
}

export function useReportConfirmBooking(props: ReportInformationProps) {
    const info = useReportInformation(props);
    const affiliatedDealer = useReportAffiliatedDealer(info);
    const actionContext = info
        ? {
              dealerName: info.dealerName,
              dealerNumber: info.dealerNumber,
              adapterId: info.adapterId,
              numberOfServices: info.numberOfServices,
              services: info.services,
              mainServices: info.mainServices,
              numberOfUnselectedRecommendedServices:
                  info.numberOfSelectedUnRecommendedServices,
              calendarDaysUntilAppointment: info.calendarDaysUntilAppointment,
              businessDaysUntilAppointment: info.businessDaysUntilAppointment,
              numberOfMainServices: info.numberOfMainServices,
              carBrand: info.carBrand,
              affiliatedDealerName: affiliatedDealer.name,
              affiliatedDealerNumber: affiliatedDealer.number,
              comments: info.comments,
              bookedWithAffiliatedDealer:
                  affiliatedDealer.bookedWithAffiliatedDealer,
          }
        : undefined;

    useReportBookedServices(info);

    const additionalActions = info
        ? [`Confirm booking at dealer ${info.dealerName}`]
        : undefined;

    useBookingStepAction('Confirm booking', {
        enabled: !!info,
        actionContext,
        additionalActions,
    });
}

export function useReportAffiliatedDealer(
    info: ReturnType<typeof useReportInformation>
) {
    const affiliatedDealerResponse = useGetDealerAffiliation(
        info?.vehicle.data.vehicleIdentificationNumber ?? ''
    );
    const affiliatedDealer = info?.dealers.data.find(
        (x) => x.id === affiliatedDealerResponse.data?.preferredDealerId
    );
    const affiliatedDealerName = formatDealerName(
        affiliatedDealer?.name ?? 'User has no preferred dealer'
    );
    const affiliatedDealerNumber = affiliatedDealer?.id || '';
    const bookedWithAffiliatedDealer =
        affiliatedDealer?.id === info?.dealerNumber
            ? 'Booked with affiliated dealer'
            : 'Booked without affiliated dealer';

    return {
        name: affiliatedDealerName,
        number: affiliatedDealerNumber,
        bookedWithAffiliatedDealer,
    };
}

function useReportBookedServices(
    info: ReturnType<typeof useReportInformation>
) {
    const mainServices = info?.mainServices ?? [];
    mainServices.forEach((service) => {
        datadogRum.addAction('Booked main service', {
            service,
            dealerName: info?.dealerName,
        });
    });

    const unselectedRecommendedServices =
        info?.unselectedRecommendedServices ?? [];
    unselectedRecommendedServices.forEach((service) => {
        datadogRum.addAction('Unselected recommended service', {
            service,
            dealerName: info?.dealerName,
        });
    });
    const additionalServices = info?.additionalServices ?? [];
    additionalServices.forEach((service) => {
        datadogRum.addAction('Booked additional service', {
            service,
            dealerName: info?.dealerName,
        });
    });

    const transportServices = info?.transportServices ?? [];
    transportServices.forEach((service) => {
        datadogRum.addAction('Booked transport service', {
            service,
            dealerName: info?.dealerName,
        });
    });

    const deliveryServices = info?.deliveryServices ?? [];
    deliveryServices.forEach((service) => {
        datadogRum.addAction('Booked delivery service', {
            service,
            dealerName: info?.dealerName,
        });
    });
}
