import { LogoBrand } from '@moller/design-system/foundation/logos/Logo.js';
import { Brand } from 'external-apis/src/types/bilhold';
import { isMobileApp } from 'src/utils/mobile';
import { getThemeForCarBrand } from 'src/utils/themes/selectTheme';

export const renderLogoSize = (brandLogoName: LogoBrand) => {
    switch (brandLogoName) {
        case 'vw':
        case 'cupra':
            return 'xxl';
        default:
            if (isMobileApp()) {
                return 'm';
            }
            return 'l';
    }
};

export const brandToLogoName = (carBrand: Brand | undefined): LogoBrand => {
    if (!carBrand) {
        return 'bilhold';
    }
    if (carBrand === 'VolkswagenCommercialVehicles') {
        return 'vw';
    }
    return getThemeForCarBrand(carBrand);
};
