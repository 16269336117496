import { App } from '@capacitor/app';
import { Browser } from '@capacitor/browser';
import { datadogRum } from '@datadog/browser-rum';
import {
    AuthorizationRequest,
    AuthorizationServiceConfiguration,
    DefaultCrypto,
    FetchRequestor,
    LocalStorageBackend,
    RedirectRequestHandler,
} from '@openid/appauth';
import { useNavigate } from 'react-router-dom';
import {
    getCurrentState,
    isPendingLogout,
} from 'src/utils/auth/authenticationUtils';
import NoHashQueryStringUtils from 'src/utils/auth/NoHashQueryStringUtils';
import {
    environmentOverride,
    isAppReviewLoginOverrideEnabled,
} from 'src/utils/dev-tools/devToolsUtils';
import getCurrentEnvironment from 'src/utils/environment/getCurrentEnvironment';
import { useEffectOnce } from 'src/utils/hooks/useEffectOnce';
import { isMobileApp } from 'utils/mobile';

// We need to prevent the app from triggering multiple attempts at logging in
// at the same time as the login opens in a in-app browser, and new attempts
// can start in the background. If multiple attempts happen at the same time,
// it may fail.
let appLoggingIn = false;

export const useLoginRedirect = () => {
    const navigate = useNavigate();
    useEffectOnce(() => {
        void App.addListener('appUrlOpen', (event) => {
            if (event.url.includes('carcare://auth/')) {
                console.log('appUrlOpen - auth', event.url);
                navigate(event.url.split('carcare://auth')[1]);
                void Browser.close();
                appLoggingIn = false;
            }
        });
    });
};

const getLoginLocationHandler = () => {
    if (isMobileApp()) {
        return {
            ...window.location,
            assign: (url: string) => {
                Browser.open({
                    url,
                    presentationStyle: 'fullscreen',
                    windowName: '_self',
                }).catch((e) => {
                    // opening browser failed for some reason, so we must reset appLoggingIn to allow more attempts
                    appLoggingIn = false;
                    const rethrown = new Error(`Failed to open URL ${url}`);
                    if (e instanceof Error) {
                        rethrown.message += `\n${e.message}`;
                        rethrown.stack = e.stack ?? rethrown.stack;
                    } else if (typeof e === 'string') {
                        rethrown.message += `\n${e}`;
                    }
                    throw rethrown;
                });
                void Browser.addListener('browserFinished', () => {
                    appLoggingIn = false;
                });
            },
        };
    }
    return window.location;
};

async function performAuthorizationRequest(
    oAuthServer: string,
    authorizationHandler: RedirectRequestHandler,
    authRequest: AuthorizationRequest
) {
    const serviceConfiguration =
        await AuthorizationServiceConfiguration.fetchFromIssuer(
            oAuthServer,
            new FetchRequestor()
        );

    authorizationHandler.performAuthorizationRequest(
        serviceConfiguration,
        authRequest
    );
}

const loginRedirect = (state?: string | undefined) => {
    if (isPendingLogout) {
        return;
    }

    datadogRum.addAction('loginRedirect');
    if (isMobileApp()) {
        if (appLoggingIn) {
            console.warn("Didn't open new login portal as one is already open");
            return;
        }
        appLoggingIn = true;
    }

    const environment = getCurrentEnvironment();
    const authorizationHandler = new RedirectRequestHandler(
        new LocalStorageBackend(),
        new NoHashQueryStringUtils(),
        getLoginLocationHandler(),
        new DefaultCrypto()
    );

    const connectionNameOverride =
        isAppReviewLoginOverrideEnabled() && environmentOverride === 'staging'
            ? { connectionNameOverride: 'app-review' }
            : undefined;

    const authRequest = new AuthorizationRequest({
        client_id: environment.auth0.clientId,
        redirect_uri: isMobileApp()
            ? environment.appRedirectURL
            : environment.redirectURL,
        scope: environment.auth0.scope,
        response_type: AuthorizationRequest.RESPONSE_TYPE_CODE,
        state: state || getCurrentState(),
        extras: {
            locale: 'no',
            ...{ audience: environment.auth0.audience },
            ...connectionNameOverride,
        },
    });

    void performAuthorizationRequest(
        `https://${environment.auth0.domain}`,
        authorizationHandler,
        authRequest
    );
};

export default loginRedirect;
