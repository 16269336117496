import { AlertBanner, Column, PrimaryButton } from '@moller/design-system';
import HTMLReactParser from 'html-react-parser';
import lang, { TextKey } from 'src/utils/lang';

interface AddCarProps {
    readonly onClick: () => void;
    readonly addCarWithCode?: boolean;
    readonly isLoading?: boolean;
    readonly errorMessage?: string;
    readonly disabled?: boolean;
}

const ConfirmWrapper = ({
    onClick,
    addCarWithCode,
    isLoading,
    errorMessage,
    disabled = false,
}: AddCarProps) => {
    return (
        <Column gap="s">
            <>
                {addCarWithCode ? (
                    <p>{lang.addCarWithCodeDescription}</p>
                ) : (
                    <p>{lang.addCarManuallyDescription}</p>
                )}
            </>
            <PrimaryButton
                onClick={() => onClick()}
                loading={{ isLoading: isLoading!, loadingText: 'Loading' }}
                disabled={disabled}
            >
                {addCarWithCode ? lang.addCarWithCodeConfirm : lang.sendRequest}
            </PrimaryButton>
            {errorMessage && (
                <AlertBanner
                    className="b-add-car__confirm-wrapper__error"
                    type={'error'}
                    message={
                        HTMLReactParser(lang[errorMessage as TextKey]) as string
                    }
                />
            )}
        </Column>
    );
};

export default ConfirmWrapper;
