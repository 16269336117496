import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { useAutoAnimate } from '@formkit/auto-animate/react';
import {
    Checkbox,
    Column,
    formInputs,
    FormProvider,
    InputHelperText,
    Modal,
    ModalProps,
    Subtitle,
    useInputHelperText,
    useLocalFormState,
} from '@moller/design-system';
import {
    noValidation,
    required,
    validation,
} from '@moller/design-system/utilities/validation';
import { DealerViewModel } from 'external-apis/src/types/port';
import { useFlag } from 'feature-toggle';
import { useEffect, useState } from 'react';
import { useBlocker } from 'react-router-dom';
import { styled } from 'styled-components';
import { useGetDealers } from '../../_api/http/dealers';
import { useBookingSearchParameters } from '../../_api/urlSearchParameters';
import { useLanguageContext } from '../../lib/languages/languageContext';
import { formatDealerName } from '../../lib/util/datadogFormatDealerName';
import { DDRumPrivacySetting } from '../DDRumPrivacySetting';

type State = {
    id: string;
    primaryAction: ModalProps['primaryAction'];
    secondaryAction: ModalProps['secondaryAction'];
    closeModal: ModalProps['handleClose'];
};

interface IWarnOnExitDialogue {
    dealerNumber?: string;
    shouldShow: boolean;
}

function useTimer(countDownFrom: number) {
    const [seconds, setSeconds] = useState(countDownFrom);

    useEffect(() => {
        if (seconds > 0) {
            const timer = setInterval(() => setSeconds(seconds - 1), 1000);
            return () => clearInterval(timer);
        }
    }, [seconds]);

    return {
        timeout: seconds < 1,
    };
}

export function WarnOnExitDialogue({
    dealerNumber,
    shouldShow,
}: IWarnOnExitDialogue) {
    const [lc] = useLanguageContext();
    const [state, setState] = useState<State>();
    const blocker = useBlocker(shouldShow);
    const showSurveyOnCancel = useFlag('show-survey-on-cancel');
    const timer = useTimer(120);

    const closeModal = () => {
        if (blocker.reset) {
            blocker.reset();
        } else {
            datadogLogs.logger.warn(
                'blocker.reset was undefined. Should be impossible.'
            );
        }
    };

    const proceed = () => {
        if (blocker.proceed) {
            blocker.proceed();
        } else {
            datadogLogs.logger.warn(
                'blocker.proceed was undefined. Should be impossible.'
            );
        }
    };

    let datadogLogsReady = false;
    let datadogRumReady = false;
    datadogLogs.onReady(() => {
        datadogLogsReady = true;
        datadogRumReady = true;
    });

    const shouldShowForm =
        showSurveyOnCancel &&
        datadogLogsReady &&
        datadogRumReady &&
        timer.timeout;

    const states = {
        form: {
            id: 'form',
            primaryAction: {
                label: lc.components.warn_on_exit_form_primary,
                onClick: () => {
                    // it would be better if we could use ref, but we cannot forward
                    // ref through formprovider.
                    const cancelForm = [
                        ...document.getElementsByTagName('form'),
                    ].find((x) =>
                        x.getAttribute('itemId')?.match('cancel-form')
                    );

                    if (!cancelForm) {
                        proceed();
                        return;
                    }

                    cancelForm.dispatchEvent(
                        new Event('submit', {
                            bubbles: true,
                            cancelable: true,
                        })
                    );
                },
            },
            secondaryAction: {
                label: lc.components.warn_on_exit_form_secondary,
                onClick: () => {
                    datadogRum.addAction('reject-cancel-survey');
                    proceed();
                },
            },
            closeModal: () => {
                closeModal();
                setState(undefined);
            },
        },
        modal: {
            id: 'modal',
            primaryAction: {
                label: lc.components.warn_on_exit_modal_primary,
                onClick: () => {
                    if (shouldShowForm) {
                        setState(states.form);
                    } else {
                        proceed();
                    }
                },
            },
            secondaryAction: {
                label: lc.components.warn_on_exit_modal_secondary,
                onClick: () => {
                    closeModal();
                    setState(undefined);
                },
            },
            closeModal,
        },
    };

    // set initial state
    useEffect(() => {
        if (blocker.state === 'blocked' && !state) {
            setState(states.modal);
        }
    }, [blocker.state, state, states.modal]);

    if (!state) return null;

    return (
        <Modal
            isOpen={blocker.state === 'blocked'}
            size={'large'}
            handleClose={state.closeModal}
            primaryAction={state.primaryAction}
            secondaryAction={state.secondaryAction}
            title={
                state.id === 'modal'
                    ? lc.components.warn_on_exit_title
                    : lc.components.warn_on_exit_form_title
            }
        >
            <DDRumPrivacySetting privacyLevel="allow">
                <div>
                    {state.id === 'modal' && (
                        <p>{lc.components.warn_on_exit_description}</p>
                    )}
                    {state.id === 'form' && (
                        <CancelForm
                            proceed={proceed}
                            dealerNumber={dealerNumber}
                        />
                    )}
                </div>
            </DDRumPrivacySetting>
        </Modal>
    );
}

function submitFormResponse(
    answers: {
        value: string;
        label: string;
        text: string | undefined;
    }[],
    dealer?: DealerViewModel
) {
    datadogRum.addAction('submit-cancel-survey', {
        cancelSurveyId: answers.map((x) => x.value),
        cancelSurveyReasons: answers.map((x) => x.label),
        cancelSurveyText: answers.map((x) => x.text),
        dealerId: dealer ? dealer.id : undefined,
        dealerName: dealer ? formatDealerName(dealer.name) : undefined,
    });
}

interface IOptions {
    value: string;
    label: string;
}

interface ICancelForm {
    textField: string | undefined;
    reasons: IOptions[];
}

const { TextArea } = formInputs<ICancelForm>();

const FormTextArea = styled(TextArea)`
    width: 100%;
`;

function CancelForm({
    proceed,
    dealerNumber,
}: {
    proceed: () => void;
    dealerNumber?: string;
}) {
    const [animationParent] = useAutoAnimate();
    const [lc] = useLanguageContext();

    const { dealerGroup } = useBookingSearchParameters();
    const dealers = useGetDealers(dealerGroup);
    const dealer = dealers.data?.find((x) => x.id === dealerNumber);

    const validator = validation({
        steps: [
            (x: IOptions[]) => ({
                isValid: x.length > 0,
                errorMessage: lc.components.warn_on_exit_reasons_validation_msg,
            }),
        ],
    });

    const cancelForm = useLocalFormState<ICancelForm>(
        {
            textField: undefined,
            reasons: [],
        },
        {
            textField: noValidation(),
            reasons: required(validator),
        }
    );

    const options = [
        {
            value: 'A',
            label: lc.components.warn_on_exit_reason_a,
        },
        {
            value: 'B',
            label: lc.components.warn_on_exit_reason_b,
        },
        {
            value: 'C',
            label: lc.components.warn_on_exit_reason_c,
        },
        {
            value: 'D',
            label: lc.components.warn_on_exit_reason_other,
        },
    ];

    const reasons = cancelForm.inputProps('reasons');
    const inputHelperText = useInputHelperText<{ errorMsgRequired: true }>({
        id: 'reasons',
        reserveSpaceForMessage: false,
        validity: reasons.validity,
    });
    const validityWithoutMsg = reasons.validity?.type && {
        type: reasons.validity.type,
    };

    return (
        <FormProvider
            id={'cancel-form-provider'}
            form={cancelForm}
            hideNecessityText={true}
            onSubmit={(event) => {
                submitFormResponse(
                    event.reasons.map((x) => ({
                        ...x,
                        text: x.value === 'D' ? event.textField : undefined,
                    })),
                    dealer
                );
                proceed();
            }}
            itemID={'cancel-form'}
        >
            <p>{lc.components.warn_on_exit_form_description}</p>
            <br />
            <Column gap="xxs" ref={animationParent}>
                {options.map((x) => (
                    <Checkbox
                        key={x.value}
                        label={x.label}
                        value={reasons.value.some((y) => y.value === x.value)}
                        validity={validityWithoutMsg}
                        {...inputHelperText.inputAriaProps}
                        onChange={() => {
                            if (
                                reasons.value.some((y) => y.value === x.value)
                            ) {
                                reasons.setValue(
                                    reasons.value.filter(
                                        (y) => y.value !== x.value
                                    )
                                );
                            } else {
                                reasons.setValue([...reasons.value, x]);
                            }
                        }}
                    ></Checkbox>
                ))}
                <InputHelperText {...inputHelperText.helperTextProps} />
                {cancelForm.state.validated.reasons?.some(
                    (x) => x.value === 'D'
                ) && (
                    <div>
                        <br />
                        <Subtitle>
                            {lc.components.warn_on_exit_form_text_area_title}
                        </Subtitle>
                        <FormTextArea
                            label={''}
                            helperText={
                                lc.components.warn_on_exit_form_text_area_helper
                            }
                            field={'textField'}
                        />
                    </div>
                )}
            </Column>
        </FormProvider>
    );
}
