import Brand from 'src/types/Brand';
import { Brand as BrandType } from 'external-apis/src/types/bilhold';
import { LogoBrand } from '@moller/design-system/foundation/logos/Logo.js';
export const selectTheme = (brand: string | undefined | null) => {
    if (brand === Brand.SKODA) {
        return Brand.SKODA;
    }
    if (brand === Brand.NYTTE) {
        return Brand.NYTTE;
    }
    if (brand === Brand.VW) {
        return Brand.VW;
    }
    if (brand === Brand.AUDI) {
        return Brand.AUDI;
    }
    if (brand === Brand.CUPRA) {
        return Brand.CUPRA;
    }

    return 'bilhold';
};

export const getThemeForCarBrand = (
    brand: BrandType | undefined | null
): LogoBrand => {
    if (brand === 'Skoda') {
        return 'skoda';
    }
    if (brand === 'VolkswagenCommercialVehicles') {
        return 'vwNytte';
    }
    if (brand === 'Volkswagen') {
        return 'vw';
    }
    if (brand === 'Audi') {
        return 'audi';
    }
    if (brand === 'Cupra') {
        return 'cupra';
    }

    return 'bilhold';
};

export type Themes =
    | Brand.AUDI
    | Brand.VW
    | Brand.SKODA
    | Brand.NYTTE
    | Brand.CUPRA
    | 'bilhold';
