import {
    Column,
    Description,
    Icon,
    LabelText,
    LinkCard,
    Row,
} from '@moller/design-system';
import { Link } from 'react-router-dom';
import MessageThread from 'src/features/contact/types/MessageThread';
import { messageIsSeen } from 'src/features/contact/utils/messagesUtils';
import { PATH_MESSAGES } from 'src/routing/routes';
import { ddRumPrivacySetting } from 'src/utils/datadog';
import { formatMessageDate, sortByTime } from 'src/utils/time/timeUtils';
import { styled } from 'styled-components';

const MessageLinkCard = styled(LinkCard)<{ ismessageseen: string }>`
    ${({ ismessageseen }) =>
        ismessageseen === 'true' &&
        'background-color: var(--moller-color-warning-container)'}
`;

const DateLabelText = styled(LabelText)`
    text-align: end;
`;

const MessageList = ({ messages }: { messages: MessageThread[] }) => {
    const sortedMessages = [...messages].sort((a, b) =>
        sortByTime(b.updatedAt, a.updatedAt)
    );

    if (sortedMessages?.length === 0) {
        return null;
    }

    const linkToThread = (message: MessageThread) => {
        if (message.category === 'CHAT') {
            return `${PATH_MESSAGES}/messages-contact/thread/${message.threadId}`;
        } else {
            return `${PATH_MESSAGES}/${message.threadId}`;
        }
    };

    return (
        <Column {...ddRumPrivacySetting('mask')} gap={'xs'}>
            {sortedMessages.map((message) => (
                <MessageLinkCard
                    key={message.threadId}
                    forwardedAs={Link}
                    to={linkToThread(message)}
                    ismessageseen={messageIsSeen(message) ? 'false' : 'true'}
                >
                    <Row horizontalAlign="spaceBetween" gap="base">
                        <p>{message.title}</p>
                        <Row verticalAlign="center" gap="base">
                            <DateLabelText>
                                {formatMessageDate(message.updatedAt ?? '')}
                            </DateLabelText>
                            <Icon icon="chevron_right" />
                        </Row>
                    </Row>
                    <Description>{message.subtitle}</Description>
                </MessageLinkCard>
            ))}
        </Column>
    );
};

export default MessageList;
