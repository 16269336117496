import { Config, ValidConfig } from './configContext';
import { ApiLocaleIdentifier } from '../../_api/apiLocale';

function getValidApiLocaleId({
    apiLocaleId,
    defaultApiLocale,
}: Config): ApiLocaleIdentifier {
    switch (apiLocaleId) {
        case 'no':
            return 'no';
        case 'lv':
            return 'lv';
    }
    return defaultApiLocale;
}

export function getValidConfig(config: Config): ValidConfig {
    const apiLocaleId = getValidApiLocaleId(config);
    return {
        ...config,
        apiLocaleId,
    };
}
