import { datadogRum } from '@datadog/browser-rum';
import { useState } from 'react';
import { getBookingPath } from 'src/features/booking/usePathToBooking';
import { CampaignDrawer } from 'src/features/home/components/CampaignDrawer';
import { CampaignModal } from 'src/features/home/components/CampaignModal';
import { PreferredAffiliationDealerResponse } from 'src/features/home/queries/usePreferredDealer';
import Car from 'src/types/Car';
import useFeatureToggle from 'src/utils/dev-tools/useFeatureToggle';
import { isMobileApp } from 'src/utils/mobile';
import getFastTrackPath, {
    FastTrackType,
} from 'src/utils/url/getFastTrackPath';
import { ProactiveArticleSanity } from '../SanityProactiveArticle';
import { TireCard } from './TireCard';
import { TireModalContent } from './TireModalContent';
import tireChange from './tire-change.webp';
import tireHotel from './tire-hotel-modal.webp';
import { ContentType, useTireCardContent } from './useTireCardContent';

interface TireChangeHotelProps {
    car: Car;
    preferredDealer: PreferredAffiliationDealerResponse;
}

export const TireChangeHotel = ({
    car,
    preferredDealer,
}: TireChangeHotelProps) => {
    const [campaignModalIsOpen, setCampaignModalIsOpen] = useState(false);
    const content: ContentType | null = useTireCardContent(
        car.vin,
        preferredDealer
    );

    const showSanityData = useFeatureToggle('ShowSanityTierChange');

    const params = { vehicleIdentificationNumber: car.vin };

    const fastTrackUrl = (x: FastTrackType) =>
        getFastTrackPath(getBookingPath(params), params, x);

    const buttonLink = fastTrackUrl('tire-change');

    if (!content) {
        return null;
    }

    if (showSanityData) {
        return (
            <>
                {content.type === 'standard' && (
                    <ProactiveArticleSanity
                        variables={{}}
                        name="Hjulskift"
                        buttonLink={buttonLink}
                    />
                )}
                {content.type === 'internal' && (
                    <ProactiveArticleSanity
                        variables={{}}
                        name="Dekkhotell"
                        buttonLink={buttonLink}
                    />
                )}
            </>
        );
    }

    const logActionButtonClick = () => {
        datadogRum.addAction(
            `User clicks ${content.type} Tire article action button`
        );
    };

    return (
        <>
            <TireCard
                toggleDrawer={() => {
                    setCampaignModalIsOpen(true);
                }}
                preferredDealer={preferredDealer}
                vin={car.vin}
            />
            {isMobileApp() ? (
                <CampaignDrawer
                    drawerIsOpen={campaignModalIsOpen}
                    setDrawerIsOpen={setCampaignModalIsOpen}
                    imageUrl={
                        content.type === 'standard' ? tireChange : tireHotel
                    }
                    title={content.modalHeader}
                    buttonText={content.modalButton}
                    buttonLink={buttonLink}
                    buttonClick={logActionButtonClick}
                >
                    <TireModalContent
                        type={content.type}
                        modalHeader={content.modalHeader}
                        modalTitle={content.modalTitle}
                        modalDescription={content.modalDescription}
                        modalDetail={content.modalDetail}
                        infoText={content.infoText}
                    />
                </CampaignDrawer>
            ) : (
                <CampaignModal
                    campaignModalIsOpen={campaignModalIsOpen}
                    setCampaignModalIsOpen={setCampaignModalIsOpen}
                    buttonText={content.modalButton}
                    buttonLink={buttonLink}
                    buttonClick={logActionButtonClick}
                >
                    <TireModalContent
                        type={content.type}
                        modalHeader={content.modalHeader}
                        modalTitle={content.modalTitle}
                        modalDescription={content.modalDescription}
                        modalDetail={content.modalDetail}
                        infoText={content.infoText}
                    />
                </CampaignModal>
            )}
        </>
    );
};
