import { datadogLogs } from '@datadog/browser-logs';
import { validRegistrationNumber } from '@moller/design-system/utilities/validation';
import { useAppConfig } from '../lib/config/configContext';
import { DealerGroup } from 'external-apis/src/types/port';

function isDealerGroup(dealerGroup: string | undefined) {
    const dealerGroups = ['MollerBil'];
    if (!dealerGroup) {
        return false;
    }
    return dealerGroups.includes(dealerGroup);
}

const fastTrackTypes = ['glass-damage', 'tire-change'] as const;
export type FastTrackType = (typeof fastTrackTypes)[number];

const bookingQueryParams = [
    'dealerNumber',
    'serviceId',
    'category',
    'registrationNumber',
    'vin',
    'dealerGroup',
    'fastTrack',
] as const;

export type BookingQueryParams = (typeof bookingQueryParams)[number];

export function isBookingQueryParam(
    param: string
): param is BookingQueryParams {
    return bookingQueryParams.some((x) => x === param);
}

export interface BookingParamValues {
    dealerNumber?: string;
    serviceIds: string[];
    categories: string[];
    registrationNumber?: string;
    vin?: string;
    dealerGroup?: DealerGroup;
    fastTrack?: FastTrackType;
}

const LIKELY_VALID_DEALER_NUMBER = /^\d{3,4}$/;

const LIKELY_VALID_VIN = /^[A-Z0-9]{17}$/i;

export function getBookingParamValues(
    unvalidatedSearchParams: URLSearchParams
): BookingParamValues {
    const searchParams = new URLSearchParams(
        Array.from(unvalidatedSearchParams.entries()).filter(
            ([param, value]) => {
                if (isBookingQueryParam(param)) {
                    let level: 'warn' | 'error' | undefined;
                    switch (param) {
                        case 'dealerGroup':
                            if (value !== 'MollerBil') {
                                level = 'warn';
                            }
                            break;
                        case 'dealerNumber':
                            if (!LIKELY_VALID_DEALER_NUMBER.test(value)) {
                                level = 'error';
                            }
                            break;
                        case 'registrationNumber':
                            if (
                                validRegistrationNumber.steps.some(
                                    (fn) => !fn(value, undefined).isValid
                                )
                            ) {
                                level = 'error';
                            }
                            break;
                        case 'vin':
                            if (!LIKELY_VALID_VIN.test(value)) {
                                level = 'error';
                            }
                            break;
                        case 'fastTrack':
                            if (!fastTrackTypes.some((x) => x === value)) {
                                level = 'error';
                            }
                            break;
                    }
                    if (level) {
                        datadogLogs.logger[level](
                            `Booking URL search parameter with invalid value was ignored`,
                            { param, value }
                        );
                        return false;
                    }
                    return true;
                }
            }
        )
    );
    const dealerGroup = searchParams.get('dealerGroup') ?? undefined;
    const dealerNumber = searchParams.get('dealerNumber') ?? undefined;
    const registrationNumber =
        searchParams.get('registrationNumber') ?? undefined;
    const vin = searchParams.get('vin') ?? undefined;
    const serviceIds = searchParams.getAll('serviceId') ?? undefined;
    const categories = searchParams.getAll('category') ?? undefined;
    const rawFastTrack = searchParams.get('fastTrack');
    const fastTrack: FastTrackType | undefined = fastTrackTypes.includes(
        rawFastTrack as FastTrackType
    )
        ? (rawFastTrack as FastTrackType)
        : undefined;

    const parameters = {
        dealerNumber,
        serviceIds: serviceIds ?? [],
        categories: categories,
        registrationNumber,
        vin,
        fastTrack,
    };

    if (isDealerGroup(dealerGroup)) {
        return {
            dealerGroup: dealerGroup as DealerGroup,
            ...parameters,
        };
    }

    return {
        dealerGroup: undefined,
        ...parameters,
    };
}

export function useBookingSearchParameters() {
    return useAppConfig().bookingParameters;
}
