import { ReactElement, useEffect, useState } from 'react';

export function DelayedRender({
    delay = 150,
    children,
}: {
    delay?: number;
    children: ReactElement | ReactElement[];
}) {
    const [render, setRender] = useState(false);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setRender(true);
        }, delay);

        return () => {
            clearTimeout(timeout);
        };
    }, [render, delay]);

    if (render && Array.isArray(children)) {
        return <>{children}</>;
    }

    if (render && !Array.isArray(children)) {
        return children;
    }

    return null;
}
