import { datadogRum } from '@datadog/browser-rum';
import {
    STORAGE_KEY_ACCESS_TOKEN,
    STORAGE_KEY_REFRESH_TOKEN,
} from 'src/utils/auth/constants';
import { RUM_CONTEXT_ACCESS_TOKEN } from 'src/utils/datadog';
import { removeFromStorage, setInStorage } from 'src/utils/storage';

let accessToken: string | null = null;
let refreshToken: string | null = null;
let isLoggedIn = false;
export let isPendingLogout = false;
let partyID: string | null = null;
const eventName = 'accessTokenUpdated';
const event = new Event(eventName);

export const addTokenUpdatedEventListener = (
    listener: EventListenerOrEventListenerObject
) => {
    document.body.addEventListener(eventName, listener);
};

export const removeTokenUpdatedEventListener = (
    listener: EventListenerOrEventListenerObject
) => {
    document.body.removeEventListener(eventName, listener);
};

const dispatchTokenUpdatedEvent = () => {
    document.body.dispatchEvent(event);
};

export const setRefreshToken = async (token: string) => {
    if (!token) {
        return;
    }

    refreshToken = token;
    await setInStorage(STORAGE_KEY_REFRESH_TOKEN, token);
    isLoggedIn = true;
};

export const setAccessToken = async (token: string) => {
    if (token == null) {
        return;
    }

    accessToken = token;
    isLoggedIn = true;
    isPendingLogout = false;
    partyID = partyID ?? parseToken(accessToken).partyId;
    await setInStorage(STORAGE_KEY_ACCESS_TOKEN, accessToken);
    const parsedToken = parseToken(token);
    dispatchTokenUpdatedEvent();

    datadogRum.setUser({
        id: parsedToken.partyId,
        entryUUID: parsedToken.entryUUID,
    });
    datadogRum.setGlobalContextProperty(RUM_CONTEXT_ACCESS_TOKEN, {
        exp: parsedToken.exp,
        iat: parsedToken.iat,
    });
};

export const getPartyId = (): string | null => partyID;
export const getAccessToken = (): string | null => accessToken;
export const getRefreshToken = (): string | null => refreshToken;
export const getIsLoggedIn = (): boolean => isLoggedIn;

const base64ToUtf8 = (str: string) => decodeURIComponent(window.atob(str));

export interface Token {
    sub: string;
    exp: number;
    iat: number;
    partyId: string;
    entryUUID: string;
}

const emptyToken: Token = {
    sub: '',
    exp: 0,
    iat: 0,
    partyId: '',
    entryUUID: '',
};

export const parseToken = (jwt: string): Token => {
    if (!jwt) return emptyToken;
    const encoded = jwt && jwt.split('.')[1];
    const jsonString = base64ToUtf8(encoded);
    return JSON.parse(jsonString) as Token;
};

const isExpired = (jwt: string): boolean => {
    try {
        const claims = parseToken(jwt);
        const epochNow = new Date().getTime() / 1000;
        return claims.exp <= epochNow - 10;
    } catch (e) {
        return true;
    }
};

export const hasValidAccessToken = (): boolean => {
    const accessTokenString = getAccessToken();
    if (accessTokenString) {
        return !isExpired(accessTokenString);
    }

    return false;
};

export const hasValidRefreshToken = (): boolean => {
    const refreshTokenString = getRefreshToken();
    if (refreshTokenString) {
        try {
            parseToken(refreshTokenString);
            return !isExpired(refreshTokenString);
        } catch (e) {
            // assume that token is from auth0, not jwt which causes it to fail on parse
            return true;
        }
    }

    return false;
};

export const getUsername = (): string => {
    const accessToken = getAccessToken();

    if (accessToken == null) {
        throw new Error('User is not authenticated!');
    }

    const parsedToken = parseToken(accessToken);
    return parsedToken.sub;
};

export const getUuid = (): { uuid: string } => {
    const parsedToken = parseToken(getAccessToken() ?? '');
    return { uuid: parsedToken.entryUUID };
};

const getApplicationRoot = (): string => window.location.origin;

export const getCurrentState = (): string =>
    window.location.href.replace(getApplicationRoot(), '');

export const clearTokens = async () => {
    datadogRum.addAction('autenthicationUtils.clearTokens');
    accessToken = null;
    refreshToken = null;
    isLoggedIn = false;
    await removeFromStorage(STORAGE_KEY_ACCESS_TOKEN);
    await removeFromStorage(STORAGE_KEY_REFRESH_TOKEN);
    clearLocalStorage();
    dispatchTokenUpdatedEvent();
};

export const clearBrowserSession = async () => {
    datadogRum.addAction('autenthicationUtils.clearBrowserSession');
    isPendingLogout = true;
    await clearTokens();
    datadogRum.setGlobalContextProperty(RUM_CONTEXT_ACCESS_TOKEN, {});
};

export const clearAccessToken = async () => {
    accessToken = null;
    await removeFromStorage(STORAGE_KEY_ACCESS_TOKEN);
};

const clearLocalStorage = () => {
    const showDevTools = localStorage.getItem('ShowDevTools');
    const devToolsFeatureToggle = localStorage.getItem(
        'devTools.featureToggles'
    );
    const devToolsEnvironmentOverride = localStorage.getItem(
        'devTools.environmentOverride'
    );
    const devToolsThemeOverride = localStorage.getItem(
        'devTools.themeOverride'
    );
    localStorage.clear();
    showDevTools && localStorage.setItem('ShowDevTools', showDevTools);
    devToolsFeatureToggle &&
        localStorage.setItem('devTools.featureToggles', devToolsFeatureToggle);
    devToolsEnvironmentOverride &&
        localStorage.setItem(
            'devTools.environmentOverride',
            devToolsEnvironmentOverride
        );
    devToolsThemeOverride &&
        localStorage.setItem('devTools.themeOverride', devToolsThemeOverride);
};
