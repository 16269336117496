import {
    DealerViewModel,
    ServiceViewModel,
} from 'external-apis/src/types/port';
import { ServicesDone } from '../../components/services/ServicesDone';
import { useBookingStepAction } from '../../lib/datadog/hooks';
import { StandardServicesData } from './useStandardServices';

export function StandardServicesDone(props: {
    selectedServices: StandardServicesData['selectedServices'];
    services: ServiceViewModel[];
    vin: string;
    dealerNumbers: string[];
    dealers: DealerViewModel[] | undefined;
}) {
    const selectedServices = props.selectedServices;

    //TODO: note - this will break when doing services first.
    const dealer = props.dealers?.find((x) => x.id === props.dealerNumbers[0]);
    const additionalActions = selectedServices.map(
        (x) => `Choose main service ${x.name}`
    );

    if (dealer) {
        additionalActions.push(`Choose main services ${dealer.name}`);
    }

    useBookingStepAction('Choose main services', {
        actionContext: {
            services: selectedServices.map((x) => x.name),
            adapterId: dealer?.adapterId,
        },
        additionalActions,
    });

    return (
        <>
            {dealer && (
                <ServicesDone
                    selectedServices={selectedServices}
                    selectedDealerNumber={dealer.id}
                />
            )}
        </>
    );
}
