import { Brand } from 'external-apis/src/types/bilhold';

type Dictionary<Key extends string, Value> = {
    [key in Key]: Value; // Mapped types syntax
};

const servicePricesForModel: Dictionary<
    Brand,
    Dictionary<string, number> | undefined
> = {
    Other: undefined,
    Seat: undefined,
    VolkswagenCommercialVehicles: undefined,
    Volkswagen: {
        'id.3': 4937.5,
        'id.4': 5250.0,
        'id.5': 5250.0,
        'id.7': 5250.0,
        'id buzz': 6312.5,
    },
    Skoda: {
        enyaq: 5437.5, // same for enyaq coupe
        elroc: 5437.5,
    },
    Audi: {
        'q4 e-tron': 5500.0,
        'q6 e-tron': 5500.0,
        'a6 e-tron': 5500.0,
    },
    Cupra: {
        born: 5250.0,
        tavascan: 5250.0,
    },
};

export function getModelPrice(
    model: string | undefined,
    brand: Brand | undefined
): number | null {
    if (!model || !brand) {
        return null;
    }

    const normalizedModel = model.toLowerCase();
    const modelsForGivenBrand = servicePricesForModel[brand];

    if (!modelsForGivenBrand) {
        return null;
    }

    for (const modelName in modelsForGivenBrand) {
        if (normalizedModel.includes(modelName.toLowerCase())) {
            return modelsForGivenBrand[modelName];
        }
    }

    return null;
}
