import { Subtitle } from '@moller/design-system';
import { BookingViewModel } from 'src/features/appointment/api/useAppointments';
import lang from 'src/utils/lang';
import { styled } from 'styled-components';
import { buildMapUrl } from '../../utililties/buildMapUrl';

const MapContainer = styled.div`
    display: flex;
    margin-bottom: var(--moller-spacing-xl);
`;

const HoverText = styled(Subtitle)`
    transition: filter 0.2s;
    visibility: hidden;
    text-decoration: none;
    display: flex;
    position: absolute;
    color: var(--moller-color-on-background);
`;

const MapImg = styled.a`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
        transition: filter 0.2s;
        max-width: 100%;
    }
    &:hover {
        img {
            filter: blur(2px);
        }
        ${HoverText} {
            visibility: visible;
        }
    }
`;

export const Map = (appointment: BookingViewModel) => {
    const map = appointment.dealer
        ? buildMapUrl(
              appointment.dealer.name,
              appointment.dealer.streetAddress,
              appointment.dealer.zipCode
          )
        : null;
    return (
        <MapContainer>
            {map && (
                <MapImg href={map.url} target="_blank">
                    <img
                        src={map.image}
                        alt="dealer-location"
                        referrerPolicy="no-referrer"
                    />
                    <HoverText>{lang.openInMaps}</HoverText>
                </MapImg>
            )}
        </MapContainer>
    );
};
