import { useQuery } from '@tanstack/react-query';
import { brandMapper } from 'src/types/Brand';
import { VehicleData } from 'src/types/generated/CarInformation/CarInformationTypes';
import proxyGet from 'src/utils/data-mocks/proxyGet';
import getCurrentEnvironment from 'src/utils/environment/getCurrentEnvironment';

export const useCarInformation = (vin: string | undefined) => {
    const { carInformationUrl } = getCurrentEnvironment();

    return useQuery({
        queryKey: ['useCarEuControl', vin],
        queryFn: () =>
            proxyGet<VehicleData>(
                `${carInformationUrl}/car-information/${vin}`
            ).then((res) => {
                res.data;
                const newData: VehicleData = {
                    ...res.data,
                    brand: brandMapper(res?.data?.brand),
                };
                return newData;
            }),
        enabled: !!vin,
        staleTime: 1000 * 60 * 60,
    });
};
