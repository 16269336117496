import { useMutation, useQueryClient } from '@tanstack/react-query';
import { carProfileQueryKey } from 'src/features/home/queries/useCarProfile';
import getCurrentEnvironment from 'src/utils/environment/getCurrentEnvironment';
import del from 'src/utils/http/httpDelete';

export const useDeleteCar = (
    vin: string,
    onSuccess: () => void,
    onError: () => void
) => {
    const queryClient = useQueryClient();
    const { bilholdApiUrl } = getCurrentEnvironment();

    return useMutation({
        mutationFn: () => del(`${bilholdApiUrl}/v1/cars/${vin}/relations`),
        onSuccess: () => {
            void queryClient.invalidateQueries({
                queryKey: [carProfileQueryKey],
            });
            onSuccess();
        },
        onError: () => {
            onError();
        },
    });
};
