import { Column, InfoCard, Row } from '@moller/design-system';
import {
    TDSTextSmall,
    TDSThinHeadline,
} from 'src/features/competition/textComponents/TDFText';
import { styled } from 'styled-components';
import thuleTent from '../../images/Thule_tent.jpg';

const Wrapper = styled.div`
    width: 100%;
`;

const StyledCard = styled(InfoCard)`
    border: 0;
    object-fit: contain;
    padding: 0;
`;

const ImageWrapper = styled.div`
    border-top-right-radius: var(--moller-radius-card);
    border-bottom-right-radius: var(--moller-radius-card);
    width: 100%;
    min-height: 10rem;
    max-height: 14rem;
`;

const ImageFill = styled.img`
    object-fit: cover;
    border-top-right-radius: var(--moller-radius-card);
    border-bottom-right-radius: var(--moller-radius-card);
    width: 100%;
    height: 100%;
`;

const ColumnWrapper = styled(Column)`
    width: 80%;
    padding: var(--moller-spacing-xs) var(--moller-spacing-m);
`;

export const FirstPrizeCard = () => {
    return (
        <Wrapper>
            <StyledCard>
                <Row horizontalAlign={'spaceBetween'}>
                    <ColumnWrapper horizontalAlign={'start'}>
                        <Column
                            verticalAlign={'spaceEvenly'}
                            horizontalAlign={'start'}
                        >
                            <TDSThinHeadline>Hovedpremie</TDSThinHeadline>
                            <TDSTextSmall>
                                Vinneren kan velge mellom leiebil i 2 uker eller
                                taktelt fra Thule.
                            </TDSTextSmall>
                        </Column>
                    </ColumnWrapper>
                    <ImageWrapper>
                        <ImageFill src={thuleTent} />
                    </ImageWrapper>
                </Row>
            </StyledCard>
        </Wrapper>
    );
};
