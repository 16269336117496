import { Product } from '../../utils/productType';
import { AudiProducts } from './product-data/audi-products';

import { SkodaProducts } from './product-data/skoda-products';
import { VwProducts } from './product-data/vw-products';

export const pocProducts: Product[] = [
    ...VwProducts,
    ...SkodaProducts,
    ...AudiProducts,
];
