import { ApiError } from 'openapi-typescript-fetch';

export const status4x = new RegExp('4[0-9]*');

export function getWrappedError(error: Error) {
    return error instanceof BaseFriendlyQueryError ? error.innerError : error;
}

export function getFriendlyMessageIfWrapped(error: Error) {
    if (error instanceof BaseFriendlyQueryError)
        return { friendlyMessage: error.message };
}

/**
 * Base class for friendly error messages from our `@tanstack/query` queries / mutations.
 * The constructor ensures that we always keep the actual error around for logging purposes.
 */
export abstract class BaseFriendlyQueryError extends Error {
    readonly innerError: Error;
    readonly statusCode: number;
    constructor(message: string, actualError: Error, statusCode: number) {
        super(message);
        this.innerError = actualError;
        this.statusCode = statusCode;
    }
}

export function getApiError(error: unknown) {
    if (error instanceof ApiError) {
        return error;
    }
    if (
        error instanceof BaseFriendlyQueryError &&
        error.innerError instanceof ApiError
    ) {
        return error.innerError;
    }
}
