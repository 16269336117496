import { Capacitor } from '@capacitor/core';
import { Device } from '@capacitor/device';
import { AppLauncher } from '@capacitor/app-launcher';

export async function goToAppStore() {
    // Note: Launching app stores doesn't work properly on simulators because the app stores are not installed.
    // On iOS, Safari will claim the address is invalid. On Android, the Play store is opened in the browser instead.
    const isAppleDevice = (await Device.getInfo()).platform === 'ios';
    await AppLauncher.openUrl({
        url: isAppleDevice
            ? 'https://apps.apple.com/app/id1006579487'
            : 'https://play.google.com/store/apps/details?id=no.moller.carcare',
    });
}

export function isIOS() {
    return Capacitor.getPlatform() === 'ios';
}

export function isIOSSafari() {
    const ua = window.navigator.userAgent;
    const iOS = /iP(ad|hone)/i.test(ua);
    const webkit = /WebKit/i.test(ua);
    const isChromeFirefoxOrEdge = /(Cr|Fx|Edg)iOS/i.test(ua);
    return iOS && webkit && !isChromeFirefoxOrEdge;
}

export function isAndroid() {
    return Capacitor.getPlatform() === 'android';
}

export const isMobileApp = (): boolean => Capacitor.getPlatform() !== 'web';
