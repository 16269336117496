import Car from 'src/types/Car';

export default (models: string[], car: Car) => {
    return models.some((model) => {
        const substring = model.split(' ');
        return substring.every((s) =>
            car?.name?.toLowerCase()?.includes(s.toLowerCase())
        );
    });
};
