import { useMutation } from '@tanstack/react-query';
import { queryClient } from 'src/Layout';
import getCurrentEnvironment from 'src/utils/environment/getCurrentEnvironment';
import httpDelete from 'src/utils/http/httpDelete';
import { hasStravaAuthKey } from './useHasAuthenticatedToStrava';

export const useDeauthorizeStrava = (onSuccess: () => void) => {
    const { bilholdApiUrl } = getCurrentEnvironment();
    const url = `${bilholdApiUrl}/v1/competition/strava-deauthorize`;
    return useMutation({
        mutationFn: () => httpDelete(url),
        onSuccess: async () => {
            onSuccess();
            return await queryClient.invalidateQueries({
                queryKey: [hasStravaAuthKey],
            });
        },
    });
};
