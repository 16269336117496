import { Column, Icon, IconButton, PrimaryButton } from '@moller/design-system';
import Popover from '@mui/material/Popover';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PATH_ADD_CAR } from 'src/routing/routes';
import { CarStatusType, CarWithStatus } from 'src/types/CarWithStatus';
import { OrderStatus } from 'src/types/OrderedCar';
import formatBrandNames from 'src/utils/string/formatBrandNames';
import { styled } from 'styled-components';
import { CarsResponse, useCarProfile } from './queries/useCarProfile';
import getCarsWithStatus from './utils/getCarsWithStatus';

const activeCarSelectStorageKey = 'activeCar';

interface Props {
    activeCar?: CarWithStatus;
    setActiveCar: (car?: CarWithStatus) => void;
}

function getCarLabelWithStatus(car: CarWithStatus | undefined) {
    switch (car?.statusType) {
        case CarStatusType.EXISTING_CAR:
            return `${car.car.licensePlate}`;
        case CarStatusType.PENDING_CAR:
            return `${car.car.licensePlate} (behandles)`;
        case CarStatusType.ORDERED_CAR:
            return `${formatBrandNames(car.car.brand)} ${
                car.car.model.text.split(' ')[0]
            } (snart din)`;
        default:
            return '';
    }
}

function getCarLabel(car: CarWithStatus | undefined) {
    switch (car?.statusType) {
        case CarStatusType.EXISTING_CAR:
            return car.car.licensePlate;
        case CarStatusType.PENDING_CAR:
            return car.car.licensePlate;
        case CarStatusType.ORDERED_CAR:
            return formatBrandNames(car.car.brand);
        default:
            return '';
    }
}

function getContractIdOrLicenseNumber(car: CarWithStatus | undefined) {
    switch (car?.statusType) {
        case CarStatusType.EXISTING_CAR:
        case CarStatusType.PENDING_CAR:
            return car.car.licensePlate;
        case CarStatusType.ORDERED_CAR:
            return car.car.contractId.toString();
        default:
            return '';
    }
}

const StyledButton = styled(PrimaryButton)`
    min-width: 12rem; /* To avoid layout shifts when changing theme */
    padding: var(--moller-spacing-s);
    display: flex;
    justify-content: space-between;
    gap: var(--moller-spacing-xs);
    margin-bottom: var(--moller-spacing-xxs);
`;

const DropdownItemButton = styled(IconButton)<{ $hasIcon: boolean }>`
    display: flex;
    width: 100%;
    justify-content: flex-start;
    padding: var(--moller-spacing-s);
    padding-left: ${({ $hasIcon }) =>
        $hasIcon
            ? `var(--moller-spacing-s)`
            : `calc(var(--moller-spacing-s) + 24px + 4px)`}; // Adjust for icon-size and gap
`;

const PopoverContainer = styled(Column)`
    padding: var(--moller-spacing-xs) 4px;
`;

export function CarSelect({ activeCar, setActiveCar }: Props) {
    const { data: cars } = useCarProfile();
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const carList = useMemo(() => {
        const sortedCars: CarsResponse | undefined = cars
            ? {
                  ...cars,
                  orderedCars: cars.orderedCars.filter(
                      (car) =>
                          !car.status ||
                          (car.status as OrderStatus) === OrderStatus.SIGNED
                  ),
              }
            : undefined;
        return getCarsWithStatus(sortedCars);
    }, [cars]);

    useEffect(() => {
        if (carList) {
            const storedCarId = localStorage.getItem(activeCarSelectStorageKey);

            const selectedCar = carList.find((car) => {
                const identifier = getContractIdOrLicenseNumber(car);
                return identifier === storedCarId;
            });

            setActiveCar(selectedCar || carList[0]);
        }
    }, [carList, setActiveCar]);

    const handleSelectCar = (carId: string | number) => {
        localStorage.setItem(activeCarSelectStorageKey, carId.toString());

        if (carId === 'add-car') {
            setActiveCar(undefined);
            navigate(PATH_ADD_CAR);
        }

        const selectedCar = carList.find((car) => {
            const identifier = getContractIdOrLicenseNumber(car);
            return identifier === carId;
        });

        if (selectedCar) {
            setActiveCar(selectedCar);
        }
        setAnchorEl(null);
    };

    const handleOpenPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClosePopover = () => {
        setAnchorEl(null);
    };

    const isDropdownOpen = Boolean(anchorEl);
    const id = isDropdownOpen ? 'car-select-popover' : undefined;

    function isCurrentCar(car: CarWithStatus) {
        return (
            getContractIdOrLicenseNumber(car) ===
            getContractIdOrLicenseNumber(activeCar)
        );
    }

    if (!carList.length) {
        return (
            <StyledButton
                aria-describedby={id}
                onClick={() => navigate(PATH_ADD_CAR)}
            >
                <Icon icon="car" />
                Legg til bil
                <Icon icon="add" />
            </StyledButton>
        );
    }

    return (
        <>
            <StyledButton aria-describedby={id} onClick={handleOpenPopover}>
                <Icon icon="car" />
                {getCarLabel(activeCar) || 'Velg bil'}
                <Icon icon={isDropdownOpen ? 'expand_less' : 'expand_more'} />
            </StyledButton>
            <Popover
                id={id}
                open={isDropdownOpen}
                anchorEl={anchorEl}
                onClose={handleClosePopover}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <PopoverContainer gap="base">
                    {carList.map((car) => (
                        <DropdownItemButton
                            $hasIcon={isCurrentCar(car)}
                            icon={isCurrentCar(car) && 'check'}
                            label={getCarLabelWithStatus(car)}
                            showLabel="right"
                            key={getContractIdOrLicenseNumber(car)}
                            onClick={() =>
                                handleSelectCar(
                                    getContractIdOrLicenseNumber(car)
                                )
                            }
                            disabled={isCurrentCar(car)}
                            aria-current={
                                isCurrentCar(car) ? 'true' : undefined
                            }
                        />
                    ))}
                    <DropdownItemButton
                        icon="add"
                        $hasIcon
                        label="Legg til bil"
                        showLabel="right"
                        onClick={() => handleSelectCar('add-car')}
                    />
                </PopoverContainer>
            </Popover>
        </>
    );
}
