import { useLanguageContext } from '../../../lib/languages/languageContext';
import { useUserLocation } from '../../../lib/useUserLocation';
import { getKilometersBetween } from '../../../lib/util/getKilometersBetween';
import { LabelText } from '@moller/design-system';
import { DealerListSortOrder } from './DealerPicker';
import { DealerViewModel } from 'external-apis/src/types/port';

interface DistanceToDealerProps {
    dealer: DealerViewModel;
    sortOrder?: DealerListSortOrder;
}

export const DistanceToDealer: React.FC<DistanceToDealerProps> = ({
    sortOrder,
    dealer,
}) => {
    const [lc] = useLanguageContext();

    const { location } = useUserLocation(true);

    if (!location || !sortOrder || sortOrder !== 'byDistance') {
        return null;
    }

    const kmBetween = getKilometersBetween(location.coords, dealer.coordinates);
    return (
        <LabelText>
            {kmBetween} {lc.chooseDealer.km_from_your_position}
        </LabelText>
    );
};
