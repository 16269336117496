import { breakpointTablet } from '@moller/design-system';
import React, { Children } from 'react';
import { styled } from 'styled-components';

interface SectionProps {
    children: React.ReactNode;
    alignRigth?: boolean;
    withBackgroundcolor?: boolean;
    alignImageRigth?: boolean;
}

const StyledSection = styled.section<{
    withBackgroundcolor: boolean;
}>`
    width: 100%;
    justify-content: center;
    display: flex;
    ${({ withBackgroundcolor }) =>
        withBackgroundcolor
            ? `
                  background-color: var(--moller-palette-neutral-95);
              `
            : null};
`;

const Container = styled.div<{
    alignRigth?: boolean;
    alignImageRigth: boolean;
}>`
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 1700px;
    flex-direction: column;
    box-sizing: border-box;

    @media screen and (min-width: ${breakpointTablet}) {
        flex-direction: ${({ alignImageRigth }) =>
            alignImageRigth ? `row-reverse` : `row`};
        padding-right: 10vw;
        justify-content: ${({ alignRigth, alignImageRigth }) =>
            alignRigth || alignImageRigth ? `flex-end` : `flex-start`};
    }
    @media screen and (min-width: 1700px) {
        padding-right: 150px;
    }
`;

const SubSection = styled.div<{ alignRight: boolean }>`
    flex-basis: auto;
    flex-grow: 1;
    flex-wrap: wrap;

    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px 0;
    @media screen and (min-width: ${breakpointTablet}) {
        padding: 80px 0 80px 10vw;
        width: 50%;
        align-items: ${({ alignRight }) => (alignRight ? `end` : `start`)};
    }
    @media screen and (min-width: 1700px) {
        padding-left: 150px;
    }
    & > :not(img) {
        padding-left: 20px;
        padding-right: 20px;
        @media screen and (min-width: ${breakpointTablet}) {
            padding-right: 0;
            padding-left: 0;
        }
    }
    & > img {
        object-fit: cover;
        height: auto;
        width: 100vw;
        @media screen and (min-width: ${breakpointTablet}) {
            width: 100%;
        }
    }
`;

export const Section = ({
    children,
    alignRigth = false,
    withBackgroundcolor = false,
    alignImageRigth = false,
}: SectionProps) => {
    const arrayChildren = Children.toArray(children);

    return (
        <StyledSection withBackgroundcolor={withBackgroundcolor}>
            <Container
                alignRigth={alignRigth}
                alignImageRigth={alignImageRigth}
            >
                {arrayChildren.map((child, index) => (
                    <SubSection key={index} alignRight={alignRigth}>
                        {child}
                    </SubSection>
                ))}
            </Container>
        </StyledSection>
    );
};
