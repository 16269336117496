import { useQuery } from '@tanstack/react-query';
import { useFetchersContext } from 'external-apis';
import { CustomerInfoViewModel } from 'external-apis/src/types/port';
import { FriendlyQueryError } from '../../lib/errors/PortError';
import {
    LanguageContextType,
    useLanguageContext,
} from '../../lib/languages/languageContext';
import { STALE_TIME } from '../../lib/query-client/config';

const useGetCustomerInfo = () => {
    const [fetchers] = useFetchersContext();
    return fetchers.port.fetcher.path('/customers/me').method('get').create();
};

export function useGetCustomer() {
    const [lc] = useLanguageContext();
    const getCustomerInfo = useGetCustomerInfo();
    return useQuery<CustomerInfoViewModel[], Error>({
        queryKey: ['getCustomerInfo'],
        queryFn: () => getCustomer(lc, getCustomerInfo),
        staleTime: STALE_TIME.hour,
        cacheTime: STALE_TIME.hour,
    });
}

async function getCustomer(
    lc: LanguageContextType,
    getCustomerInfo: ReturnType<typeof useGetCustomerInfo>
) {
    try {
        const result = await getCustomerInfo({});
        return result.data;
    } catch (e) {
        if (e instanceof getCustomerInfo.Error) {
            throw new FriendlyQueryError(
                lc.errors.port_services_default,
                e,
                e.status
            );
        }
        throw e;
    }
}
