import born from './born-58.webp';
import bornEBoost from './born-58-77-e-boost.webp';

const bornImage = {
    src: born,
    name: ['born'],
};

const bornEBoostImage = {
    src: bornEBoost,
    name: ['born', 'e-boost'],
};

export const cupraImages = [bornEBoostImage, bornImage];
