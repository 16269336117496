import { getBookingPath } from 'src/features/booking/usePathToBooking';
import Car from 'src/types/Car';
import { ProactiveArticleSanity } from '../SanityProactiveArticle';

interface InspectionServiceProps {
    car: Car;
}

export const InspectionService = ({ car }: InspectionServiceProps) => {
    const bookingPath = getBookingPath({
        vehicleIdentificationNumber: car.vin,
        registrationNumber: car.licensePlate,
        serviceIds: ['GS-1'],
    });

    return (
        <ProactiveArticleSanity
            variables={{ car }}
            name="Service"
            buttonLink={bookingPath}
        />
    );
};
