import {
    AlertBanner,
    breakpointMobile,
    Column,
    GhostButton,
    Logo,
    Row,
    SecondaryButton,
} from '@moller/design-system';
import { useToggle } from 'feature-toggle';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PATH_APP, PATH_BEGIN } from 'src/routing/routes';
import loginRedirect from 'src/utils/auth/loginRedirect';
import { ddRumPrivacySetting } from 'src/utils/datadog';
import lang from 'src/utils/lang';
import { isMobileApp } from 'src/utils/mobile';
import { styled } from 'styled-components';

const HeaderContainer = styled(Column)`
    padding-top: env(safe-area-inset-top);
    background-color: var(--moller-color-background);
    color: var(--moller-color-on-background);
    width: 100%;
    transition: top 0.2s ease-in-out;
    position: fixed;
    z-index: 5;
    top: 0;
`;

const SpreadRow = styled(Row)`
    justify-content: space-between;
    align-items: center;
    padding: var(--moller-spacing-m);
    padding-right: var(--moller-spacing-xl);
    @media screen and (max-width: ${breakpointMobile}) {
        padding: var(--moller-spacing-s);
        padding-right: var(--moller-spacing-l);
    }
`;
const ButtonRow = styled(Row)`
    gap: var(--moller-spacing-s);
`;

const BackButton = styled.a`
    cursor: pointer;
`;

const DownloadButton = styled.div`
    @media screen and (max-width: ${breakpointMobile}) {
        display: none;
    }
`;

const HeaderDivider = styled.div`
    width: 100%;
    border-bottom: var(--moller-stroke-small) solid var(--moller-color-outline);
`;

const AlertBannerContainer = styled.div`
    width: 50%;
    min-width: 630px;
    padding: 0 12px;
    box-sizing: border-box;
    left: 0;
    @media screen and (max-width: ${breakpointMobile}) {
        width: 100%;
        min-width: 100%;
        padding: 0;
    }
`;

type InfoMessage = {
    type: 'info' | 'success' | 'warning' | 'error';
    message: string;
};

export const LoginHeader = () => {
    const navigate = useNavigate();
    const isOnMobileWeb = window.innerWidth <= 480;
    const showInfoMessage = useToggle<InfoMessage>('ShowInfoMessage');
    const [toggleInformationMessage, setToggleInformationMessage] =
        useState(true);
    return (
        <HeaderContainer {...ddRumPrivacySetting('allow')}>
            <SpreadRow>
                <BackButton onClick={() => navigate(PATH_BEGIN)}>
                    <Logo
                        brand="bilhold"
                        type="wordmark"
                        size={isOnMobileWeb ? 'l' : 'xxl'}
                    />
                </BackButton>
                {!isMobileApp() && (
                    <ButtonRow>
                        <SecondaryButton onClick={() => loginRedirect()}>
                            {lang.login}
                        </SecondaryButton>
                        {window.location.pathname === PATH_BEGIN && (
                            <DownloadButton>
                                <GhostButton onClick={() => navigate(PATH_APP)}>
                                    {lang.downloadApp}
                                </GhostButton>
                            </DownloadButton>
                        )}
                    </ButtonRow>
                )}
            </SpreadRow>
            {!isMobileApp() && <HeaderDivider />}
            {toggleInformationMessage &&
                showInfoMessage?.variation.value.message &&
                showInfoMessage?.variation.value.type && (
                    <AlertBannerContainer>
                        <AlertBanner
                            type={showInfoMessage.variation.value.type}
                            message={showInfoMessage.variation.value.message}
                            dismiss={() => setToggleInformationMessage(false)}
                        />
                    </AlertBannerContainer>
                )}
        </HeaderContainer>
    );
};
