import { Column, InfoCard, Row } from '@moller/design-system';
import {
    TDSTextSmall,
    TDSThinHeadline,
} from 'src/features/competition/textComponents/TDFText';
import { styled } from 'styled-components';

const Wrapper = styled.div`
    width: 100%;
`;

const StyledCard = styled(InfoCard)`
    border: 0;
    object-fit: contain;
    padding: 0;
`;

const ImageWrapper = styled(Row)`
    border-top-right-radius: var(--moller-radius-card);
    border-bottom-right-radius: var(--moller-radius-card);
    width: 100%;
    min-height: 10rem;
    max-height: 14rem;
    background-color: white;
    justify-content: center;
`;

const ImageFill = styled.img`
    object-fit: scale-down;
    border-top-right-radius: var(--moller-radius-card);
    border-bottom-right-radius: var(--moller-radius-card);
    width: 95%;
    height: 95%;
    align-self: center;
`;

const ColumnWrapper = styled(Column)`
    width: 80%;
    padding: var(--moller-spacing-xs) var(--moller-spacing-m);
`;

interface Props {
    heading: string;
    text: string;
    picture: string;
}
export const StandardPrizeCard = ({ heading, text, picture }: Props) => {
    return (
        <Wrapper>
            <StyledCard>
                <Row horizontalAlign={'spaceBetween'}>
                    <ColumnWrapper horizontalAlign={'start'}>
                        <Column
                            verticalAlign={'spaceEvenly'}
                            horizontalAlign={'start'}
                        >
                            <TDSThinHeadline>{heading}</TDSThinHeadline>
                            <TDSTextSmall>{text}</TDSTextSmall>
                        </Column>
                    </ColumnWrapper>
                    <ImageWrapper>
                        <ImageFill src={picture} />
                    </ImageWrapper>
                </Row>
            </StyledCard>
        </Wrapper>
    );
};
