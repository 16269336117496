import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { useFeatureToggleClient } from 'feature-toggle';
import {
    createContext,
    Dispatch,
    SetStateAction,
    useContext,
    useRef,
    useState,
} from 'react';

export function useBookingExperimentActions() {
    const client = useFeatureToggleClient();
    const { setInitialized } = useBookingExperimentContext();
    client.useReady(() => {
        const toggles = client.getToggles();
        toggles.forEach((toggle) => {
            if (client.isVariation(toggle)) {
                if (toggle.name.startsWith('booking-experiment')) {
                    const variation = toggle.variation;
                    if (
                        typeof variation.value === 'boolean' ||
                        typeof variation.value === 'string' ||
                        typeof variation.value === 'number'
                    ) {
                        datadogRum.addAction(
                            `${toggle.name} ${variation.value.toString()}`
                        );
                    } else {
                        datadogLogs.logger.error(
                            `Invalid booking-experiment flag "${
                                toggle.name
                            }": Unsupported value type "${typeof variation.value}"`
                        );
                    }
                }
            }
        });
        setInitialized(true);
    });
}

interface IBookingExperimentContext {
    initialized: boolean;
    setInitialized: Dispatch<SetStateAction<boolean>>;
}

const BookingExperimentContext =
    createContext<IBookingExperimentContext | null>(null);

export function BookingExperimentProvider({
    children,
}: {
    children: JSX.Element | JSX.Element[];
}) {
    const [state, setState] = useState(false);
    const value: IBookingExperimentContext = {
        initialized: state,
        setInitialized: setState,
    };
    return (
        <BookingExperimentContext.Provider value={value}>
            {children}
        </BookingExperimentContext.Provider>
    );
}

function useBookingExperimentContext() {
    const context = useContext(BookingExperimentContext);
    if (!context) {
        throw new Error(
            'Attempting to use BookingExperimentProvider without context.'
        );
    }
    return context;
}

interface BookingStepActionOptions<TContext> {
    enabled?: boolean;
    actionContext?: TContext;
    additionalActions?: string[];
}

export function useBookingStepAction<TContext extends object>(
    action: string,
    options?: BookingStepActionOptions<TContext>
) {
    const defaultOptions: typeof options = { enabled: true };
    const opts = { ...defaultOptions, ...options };
    const client = useFeatureToggleClient();
    const flags = client.getToggles().map((x) => x.name);

    const hasExecuted = useRef(false);
    const { initialized } = useBookingExperimentContext();

    if (initialized && opts.enabled && !hasExecuted.current) {
        hasExecuted.current = true;
        const context = { ...opts.actionContext, flags: { ...flags } };
        datadogRum.addAction(action, context);
        opts?.additionalActions?.forEach((extraAction) => {
            datadogRum.addAction(extraAction);
        });
    }
}
