import { datadogRum } from '@datadog/browser-rum';
import {
    AlertBanner,
    Column,
    Icon,
    IconButton,
    InfoCard,
    Modal,
    Row,
    SecondaryButton,
    Subtitle,
    TextArea,
} from '@moller/design-system';
import { RefObject, useEffect, useRef, useState } from 'react';
import ImageUploading, {
    ImageListType,
    ImageType,
} from 'react-images-uploading';
import Car from 'src/types/Car';
import { ddRumPrivacySetting } from 'src/utils/datadog';
import lang from 'src/utils/lang';
import { css, styled } from 'styled-components';
import { NextStepButton, SectionTitle } from '../Damage';
import { DamageAppraisal } from 'external-apis/src/types/damageappraisal';
import { hasValidImages } from '../utils/damageValidation';
import { useUpdateDamageAppraisal } from 'src/features/damage/utils/useDamageAppraisals';
import { useDeleteMedia, useGetMedia, useUploadMedia } from '../utils/useMedia';

export const StyledImage = styled.img`
    height: 200px;
    max-width: 200px;
    object-fit: cover;
    ${({ onClick }) =>
        onClick &&
        css`
            cursor: pointer;
        `}
`;

const StyledParagraph = styled.p`
    margin-bottom: var(--moller-spacing-base);
`;

declare module 'react-images-uploading' {
    export interface ImageType {
        tag: string;
        id: number;
    }
}

const UploadImage = ({
    tag,
    images,
    damageId,
}: {
    tag: string;
    images?: ImageType[];
    damageId: number;
}) => {
    const { mutate: uploadMedia, isLoading: isSubmitting } =
        useUploadMedia(damageId);

    const uploadImage = (images: ImageListType, addUpdateIndex?: number[]) => {
        if (addUpdateIndex) {
            const uploadImages = images
                .filter((_, index) => addUpdateIndex.includes(index))
                .map((img) => {
                    if (!img.dataURL || !img.file) {
                        throw new Error(
                            'File upload is missing file and dataURL. This should be impossible.'
                        );
                    }
                    return {
                        ...img,
                        relatedId: damageId,
                        tag: tag,
                        dataURL: img.dataURL,
                        file: img.file,
                    };
                });
            uploadMedia(uploadImages);
        }
    };

    // Turning on multiple upload is problematic on Android devices.
    // See: https://github.com/ionic-team/capacitor/pull/6778
    return (
        <>
            <ImageUploading
                multiple
                value={images ?? []}
                onChange={uploadImage}
                dataURLKey="dataURL"
            >
                {({ imageList, onImageUpload }) => (
                    <Column gap="xxs">
                        <SecondaryButton
                            onClick={onImageUpload}
                            loading={{
                                isLoading: isSubmitting,
                                loadingText: 'laster bilde',
                            }}
                        >
                            <Icon icon="file_upload" />
                            Last opp bilde
                        </SecondaryButton>
                        <Row gap={'m'} flexWrap="wrap">
                            {imageList.map((image) => (
                                <ImageWithDelete
                                    key={image.id}
                                    tag={tag}
                                    damageId={damageId}
                                    image={image}
                                />
                            ))}
                        </Row>
                    </Column>
                )}
            </ImageUploading>
        </>
    );
};

interface DetailsProps {
    damage: DamageAppraisal;
    detailsStepDone: boolean;
    setDetailsStepDone: (value: boolean) => void;
    car?: Car;
    headingRef: RefObject<HTMLHeadingElement>;
}

const DamageDetails = ({
    damage,
    detailsStepDone,
    setDetailsStepDone,
    car,
    headingRef,
}: DetailsProps) => {
    const { mutate: updateDamage, isError } = useUpdateDamageAppraisal(
        damage.id
    );
    const datadogSent = useRef(false);
    const [changedDesc, setChangedDesc] = useState(damage?.description ?? '');
    const { data: initialImages } = useGetMedia(damage?.id);
    const isStepInvalid = !damage.description || !hasValidImages(initialImages);
    useEffect(() => {
        if (isStepInvalid) {
            setDetailsStepDone(false);
        }
    }, [isStepInvalid, setDetailsStepDone]);

    if (car && !datadogSent.current) {
        datadogRum.addAction('Damage details');
        datadogSent.current = true;
    }

    return (
        <div {...ddRumPrivacySetting('allow')}>
            <Subtitle ref={headingRef}>{lang.damageDetailsTitle}</Subtitle>
            {damage && damage.id ? (
                <Column gap="m">
                    <div>
                        <div>{lang.damageDetailsDescription}</div>
                        <TextArea
                            label={lang.damageDescription}
                            helperText={lang.damageDescriptionPlaceholder}
                            value={changedDesc}
                            onChange={(e) =>
                                setChangedDesc(e.target.value.trimStart())
                            }
                            maxLength={4000}
                            onBlur={() => {
                                if (damage.description !== changedDesc) {
                                    updateDamage({
                                        description: changedDesc,
                                    });
                                }
                            }}
                        />
                    </div>
                    <Column gap="base">
                        <SectionTitle>{lang.imagesOfDamage}</SectionTitle>
                        <StyledParagraph>
                            {lang.closeupImagesDescription}
                        </StyledParagraph>
                        <UploadImage
                            tag="car-closeup"
                            images={initialImages?.filter(
                                (img) => img.tag === 'car-closeup'
                            )}
                            damageId={damage?.id}
                        />
                    </Column>
                    <Column gap="base">
                        <SectionTitle>{lang.imageOverview}</SectionTitle>
                        <StyledParagraph>
                            {lang.overviewImagesDescription}
                        </StyledParagraph>
                        <UploadImage
                            tag="car-overview"
                            images={initialImages?.filter(
                                (img) => img.tag === 'car-overview'
                            )}
                            damageId={damage?.id}
                        />
                    </Column>
                    <Column gap="base">
                        <SectionTitle>{lang.mileageImagesTitle}</SectionTitle>
                        <StyledParagraph>
                            {lang.mileageImagesDescription}
                        </StyledParagraph>
                        <UploadImage
                            tag="car-mileage"
                            images={initialImages?.filter(
                                (img) => img.tag === 'car-mileage'
                            )}
                            damageId={damage?.id}
                        />
                    </Column>
                    {!detailsStepDone && (
                        <NextStepButton
                            disabled={isStepInvalid}
                            setStepDone={setDetailsStepDone}
                        />
                    )}
                </Column>
            ) : car && !damage?.id ? (
                <InfoCard isLoading />
            ) : (
                <p>Du må velge bil før du kan beskrive skaden</p>
            )}
            {isError && (
                <AlertBanner type="error" message={lang.damageGeneralError} />
            )}
        </div>
    );
};

const AccessibleFullscreenButton = styled(IconButton)`
    position: absolute;
    top: 0;
    right: 0;
    background: var(--moller-color-background);
    opacity: 0;
    &:focus {
        opacity: 1;
    }
`;
const ImgContainer = styled.div`
    width: 100%;
`;

const BigImage = styled.img`
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: calc(100% - 12px);
`;

const RelativeColumn = styled(Column)`
    position: relative;
`;

export default DamageDetails;

const ImageWithDelete = ({
    image,
    tag,
    damageId,
}: {
    image: ImageType;
    tag: string;
    damageId: number;
}) => {
    const [isFullscreen, setIsFullscreen] = useState(false);
    const { mutate: deleteMedia, isLoading: isDeleting } =
        useDeleteMedia(damageId);
    return (
        <InfoCard density="compact">
            <RelativeColumn key={image.id} gap="xxs">
                <StyledImage
                    src={image.dataURL}
                    alt={tag}
                    onClick={
                        // This onClick handler is not accessible for screen readers or keyboard
                        // navigation, but this is handled by AccessibleFullscreenButton below.
                        isDeleting ? undefined : () => setIsFullscreen(true)
                    }
                />
                <AccessibleFullscreenButton
                    icon="open"
                    label="se i fullskjerm"
                    disabled={isDeleting}
                    onClick={() => setIsFullscreen(true)}
                />
                <IconButton
                    icon="trash"
                    label="Slett"
                    showLabel="right"
                    disabled={isDeleting}
                    onClick={() => deleteMedia({ id: image.id })}
                />
                <Modal
                    isOpen={isFullscreen}
                    size="large"
                    handleClose={() => setIsFullscreen(false)}
                >
                    <ImgContainer>
                        <BigImage src={image.dataURL} alt={tag} />
                    </ImgContainer>
                </Modal>
            </RelativeColumn>
        </InfoCard>
    );
};
