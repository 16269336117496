import {
    AlertBanner,
    formInputs,
    FormProvider,
    PrimaryButton,
    useLocalFormState,
} from '@moller/design-system';
import {
    matchesRegex,
    validation,
    ValidatorMap,
} from '@moller/design-system/utilities/validation';
import lang from 'src/utils/lang';
import { styled } from 'styled-components';
import { useUpdateMileage } from '../query/useUpdateMileage';

export const VALID_DIGITS = /^[0-9]+$/;
export const validKm = validation({
    steps: [matchesRegex(VALID_DIGITS, 'Kan kun inneholde siffer.')],
    showMessageImmediately: () => true,
});

const FlexRow = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: var(--moller-spacing-xs);
`;

const StyledAlertBanner = styled(AlertBanner)`
    margin-top: var(--moller-spacing-xxs);
`;
type UpdateMileageForm = {
    newMileage: string;
};

const initialFormValues: UpdateMileageForm = {
    newMileage: '',
};

const formValidator: ValidatorMap<UpdateMileageForm> = {
    newMileage: validKm,
};

const { TextField } = formInputs<UpdateMileageForm>();

export const EditMileage = ({
    vin,
    exitEditMode,
}: {
    vin: string;
    exitEditMode: () => void;
}) => {
    const form = useLocalFormState<UpdateMileageForm>(
        initialFormValues,
        formValidator
    );
    const { mutate, isLoading, error } = useUpdateMileage(vin, () => {
        form.reset();
        blockOnBlur = false;
        exitEditMode();
    });
    const onSubmit = (data: UpdateMileageForm) => {
        mutate(data.newMileage);
    };

    let blockOnBlur = false;

    return (
        <FormProvider
            id="update-milage-form"
            form={form}
            onSubmit={(data) => onSubmit(data)}
            hideNecessityText={true}
            submitOnEnter
            onBlur={() => {
                if (blockOnBlur) {
                    return;
                }
                exitEditMode();
            }}
        >
            <FlexRow>
                <TextField
                    field="newMileage"
                    label={'Oppdater km'}
                    suffix={'km'}
                    type={'tel'}
                    inputMode={'numeric'}
                    density={'compact'}
                    autoFocus
                    onPointerDown={() => {
                        blockOnBlur = true;
                    }}
                    onPointerUp={() => {
                        blockOnBlur = false;
                    }}
                />
                <PrimaryButton
                    type="submit"
                    density={'compact'}
                    onPointerDown={() => {
                        blockOnBlur = true;
                    }}
                    loading={{
                        loadingText: 'Lagrer kilometerstand',
                        isLoading: isLoading,
                    }}
                >
                    Lagre
                </PrimaryButton>
            </FlexRow>
            {error ? (
                <StyledAlertBanner
                    density="compact"
                    type={'error'}
                    message={lang.updateMileageError}
                />
            ) : null}
        </FormProvider>
    );
};
