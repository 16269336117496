import { goToAppStore, isMobileApp } from 'utils/mobile';
import lang, { stringFormat } from 'utils/lang';
import { Device } from '@capacitor/device';
import { Dialog } from '@capacitor/dialog';

let forceUpdateInProgress = false;

let disabledBackground = false;

export const getForceUpdateInProgress = (): boolean => forceUpdateInProgress;

const createForceUpdateDialog = async () => {
    const isAppleDevice = (await Device.getInfo()).platform === 'ios';
    const result = await Dialog.confirm({
        message: stringFormat(
            lang.forceUpdateAppDialog,
            isAppleDevice ? 'App Store' : 'Google Play'
        ),
        okButtonTitle: lang.update,
        cancelButtonTitle: lang.cancel,
    });
    if (result.value) {
        void goToAppStore();
    }
};

const outsideBoxClick = (event: Event) => {
    event.stopPropagation();
    event.preventDefault();
    event.stopImmediatePropagation();
    void createForceUpdateDialog();
    return false;
};

export default () => {
    if (isMobileApp()) {
        if (forceUpdateInProgress) {
            return;
        }

        forceUpdateInProgress = true;

        if (!disabledBackground) {
            document.body.innerHTML +=
                '<div style="background-color:hsla(0, 0%, 42%, 0.33)  ;bottom: 0;left: 0; position: fixed;right: 0;top: 0;"></div>';
            disabledBackground = true;
        }

        document.body.onclick = outsideBoxClick;

        void createForceUpdateDialog();
    }
};
