import { datadogRum } from '@datadog/browser-rum';
import { useEffect } from 'react';
import { CardLayout } from 'src/features/home/components/CardLayout';
import { PreferredAffiliationDealerResponse } from 'src/features/home/queries/usePreferredDealer';
import { SuggestedActionCard } from '../../components/HomeStyledComponents';
import tireChange from './tire-change.webp';
import tireHotel from './tire-hotel.webp';
import { ContentType, useTireCardContent } from './useTireCardContent';

interface TireCardProps {
    toggleDrawer: () => void;
    preferredDealer: PreferredAffiliationDealerResponse;
    vin: string;
}

export const TireCard = ({
    preferredDealer,
    vin,
    toggleDrawer,
}: TireCardProps) => {
    const content: ContentType | null = useTireCardContent(
        vin,
        preferredDealer
    );

    useEffect(() => {
        if (!content) {
            return;
        }
        datadogRum.addAction(`User sees Tire card for ${content.type} service`);
    }, [content]);

    if (!content) {
        return null;
    }

    return (
        <SuggestedActionCard
            clickName={`Tire card for ${content.type} service`}
            toggleDrawer={() => {
                datadogRum.addAction(
                    `User clicks ${content.type} Tire article`
                );
                toggleDrawer();
            }}
        >
            <CardLayout
                title={content.cardTitle}
                body={content.cardDescription}
                ImageSrc={content.type === 'standard' ? tireChange : tireHotel}
            />
        </SuggestedActionCard>
    );
};
