import { Column, Icon, TextButton } from '@moller/design-system';
import { removeUnit } from '@moller/design-system/utilities/tokens';
import { atoms } from '@moller/gnist-themes/atoms.css.js';
import { debounce } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { css, styled } from 'styled-components';
import { useLanguageContext } from '../../lib/languages/languageContext';

const ServiceDescriptionStyle = styled.div<{
    linesToShow: number;
    totalLines: number;
    fullHeight: number;
    fadeOut: boolean;
}>`
    ${({ linesToShow, totalLines, fullHeight, fadeOut }) =>
        css`
            transition-duration: 200ms;
            transition-property: max-height;
            position: relative;
            max-height: ${
                linesToShow < totalLines
                    ? css`calc(
                ${linesToShow}em * var(--moller-typeface-line-height)
            );`
                    : css`
                          ${fullHeight}px;
                      `
            }
            overflow: hidden;
            &::after {
                pointer-events: none;
                content: '';
                display: block;
                position: absolute;
                inset: 0;
                background: ${
                    fadeOut && linesToShow < totalLines
                        ? css`linear-gradient(
                        transparent,
                        transparent,
                        var(--moller-color-surface-variant)
                    );`
                        : css`transparent;`
                };
            }
        `}
`;

const ReadMoreButtonContent = styled.div`
    font-weight: var(--moller-typeface-weight-bold);
`;

interface ExpandableServiceDescriptionProps {
    description: string;
    preferredLineCount?: number;
    forceExpanded?: boolean;
}

/**
Shows a service description, which is collapsed if it takes up more than X number of lines,
where X is given by the `preferredLineCount` parameter (default: 2 lines).

When the text contains a single paragraph, the logic is somewhat more complex.
This is to avoid feeling annoyed when "Read more" only reveals one more line.
- If the text takes up (X+1) lines or less, show all lines.
- If the text takes up (X+2) lines or more, show the first X lines with a fadeout and a "Read more" button.

When the text contains multiple paragraphs (split by <br>), we always show a "Read more" button, and:
- If the first paragraph is 2 lines or less, show the entire first paragraph WITHOUT fadeout,
  because the paragraph is a self-contained unit of text.
- If the first paragraph is longer, show the first 2 lines with a fadeout.

The "Read more" button expands the description to show the full text.

Setting `forceExpanded={true}` shows the entire description and hides the "Read more" button
 */
export const ExpandableServiceDescription = ({
    description: rawDescription,
    preferredLineCount = 2,
    forceExpanded,
}: ExpandableServiceDescriptionProps) => {
    const description = rawDescription.split('<br>').filter((x) => !!x);
    const [lc] = useLanguageContext();
    const textRef = useRef<HTMLDivElement>(null);
    const [linesToShowWhenCollapsed, setLinesToShowWhenCollapsed] =
        useState(preferredLineCount);
    const [totalLines, setTotalLines] = useState(preferredLineCount);
    const [firstParagraphLineCount, setFirstParagraphLineCount] = useState(0);
    const [fullHeight, setFullheight] = useState(0);
    const [expanded, setExpanded] = useState(false);
    useEffect(() => {
        const setTextHeightStates = debounce(() => {
            if (!textRef.current) {
                return;
            }
            const childElements = textRef.current.childElementCount;
            const gapSize =
                removeUnit(getComputedStyle(textRef.current).gap) *
                (childElements - 1);
            const currentLineHeight = Number(
                removeUnit(
                    getComputedStyle(textRef.current).lineHeight
                ).toFixed(2)
            );
            const completeTextHeight = Number(
                textRef.current.getBoundingClientRect().height.toFixed(2)
            );
            const totalTextLines = Math.round(
                (completeTextHeight - gapSize) / currentLineHeight
            );
            const firstParagrahTextHeight = Number(
                textRef.current.children
                    .item(0)
                    ?.getBoundingClientRect()
                    .height.toFixed(2)
            );
            const firstParagraphLines = Math.round(
                firstParagrahTextHeight / currentLineHeight
            );
            setTotalLines(totalTextLines);
            setFirstParagraphLineCount(firstParagraphLines);
            setFullheight(completeTextHeight);
            if (totalTextLines === preferredLineCount + 1) {
                setLinesToShowWhenCollapsed(preferredLineCount + 1);
            } else if (totalTextLines > preferredLineCount) {
                setLinesToShowWhenCollapsed(preferredLineCount);
            }
        }, 50);

        setTextHeightStates();
        window.addEventListener('resize', setTextHeightStates);
        return () => window.removeEventListener('resize', setTextHeightStates);
    }, [preferredLineCount]);

    const hasMultipleParagraphs = description.length > 1;
    let linesToShow = linesToShowWhenCollapsed;
    if (expanded || forceExpanded) {
        linesToShow = totalLines;
    } else if (hasMultipleParagraphs) {
        linesToShow = Math.min(firstParagraphLineCount, preferredLineCount);
    }

    return (
        <Column gap="xs">
            <ServiceDescriptionStyle
                linesToShow={linesToShow}
                totalLines={totalLines}
                fullHeight={fullHeight}
                fadeOut={firstParagraphLineCount > preferredLineCount}
            >
                <Column gap="xxs" ref={textRef}>
                    {description.map((x, i) => (
                        <div key={i}>{x}</div>
                    ))}
                </Column>
            </ServiceDescriptionStyle>
            {!forceExpanded &&
                (description.length > 1 ||
                    linesToShowWhenCollapsed < totalLines) && (
                    <TextButton
                        type="button"
                        onClick={() => {
                            setExpanded((prev) => !prev);
                        }}
                    >
                        <ReadMoreButtonContent
                            className={atoms({
                                typography: 'description-large',
                            })}
                        >
                            {
                                lc.standardServices[
                                    expanded ? 'read_less' : 'read_more'
                                ]
                            }{' '}
                            <Icon
                                icon={expanded ? 'expand_less' : 'expand_more'}
                            />
                        </ReadMoreButtonContent>
                    </TextButton>
                )}
        </Column>
    );
};
