import { Headline, Subtitle } from '@moller/design-system';
import { ddRumPrivacySetting } from 'booking/components/DDRumPrivacySetting';
import { PATH_EXPLORE_SKODA } from 'src/routing/routes';
import { CenteredPageContent } from 'src/shared/CenteredPageContent';
import FeedbackPrompt from 'src/shared/feedback-card/FeedbackPrompt';
import { themeOverride } from 'src/utils/dev-tools/devToolsUtils';
import { styled } from 'styled-components';
import { ChildThemeProvider } from 'theming';
import { ExternalBrandLink } from './components/ExternalBrandLink';
import { InternalBrandLink } from './components/InternalBrandLink';
import { TourDeSkodaShortcut } from './components/TourDeSkodaShortcut';

const MainHeadline = styled(Headline)`
    margin: var(--moller-spacing-m) 0;
`;

const BrandList = styled.ul`
    list-style: none;
    padding: 0;
    margin-block: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

// Correct order: audi, volkswagen, skoda, seat og cupra
const brandLinks = {
    audi: {
        name: 'Audi',
        url: 'https://www.audi.no/no/web/no.html',
        description: 'Vi former fremtidens premium mobilitet',
    },
    skoda: {
        name: 'Škoda',
        url: PATH_EXPLORE_SKODA,
        description: 'Velkommen til Škoda.',
    },
    volkswagen: {
        name: 'Volkswagen',
        url: 'https://www.volkswagen.no/no.html',
        description: 'Elbil, suv, Coupé, familiebil og varebil.',
    },
    seat: {
        name: 'SEAT',
        url: 'https://seat.no/',
        description: 'Eier du en SEAT? Vi tar vare på deg.',
    },
    cupra: {
        name: 'Cupra',
        url: 'https://www.cupraofficial.no/',
        description: 'Velkommen til CUPRA. Bli kjent med våre elbiler.',
    },
} as const;

export default function Explore() {
    return (
        <ChildThemeProvider theme="bilhold" overrideTheme={themeOverride}>
            <CenteredPageContent {...ddRumPrivacySetting('allow')}>
                <section style={{ marginBottom: '2rem' }}>
                    <MainHeadline>Utforsk</MainHeadline>
                    <TourDeSkodaShortcut />
                </section>
                <section>
                    <Subtitle>Bli kjent med våre merker</Subtitle>
                    <BrandList>
                        <ExternalBrandLink
                            url={brandLinks['audi'].url}
                            description={brandLinks['audi'].description}
                            name={brandLinks['audi'].name}
                        />
                        <ExternalBrandLink
                            url={brandLinks['volkswagen'].url}
                            description={brandLinks['volkswagen'].description}
                            name={brandLinks['volkswagen'].name}
                        />
                        <InternalBrandLink
                            url={brandLinks['skoda'].url}
                            description={brandLinks['skoda'].description}
                            name={brandLinks['skoda'].name}
                        />
                        <ExternalBrandLink
                            url={brandLinks['seat'].url}
                            description={brandLinks['seat'].description}
                            name={brandLinks['seat'].name}
                        />
                        <ExternalBrandLink
                            url={brandLinks['cupra'].url}
                            description={brandLinks['cupra'].description}
                            name={brandLinks['cupra'].name}
                        />
                    </BrandList>
                </section>
                <FeedbackPrompt />
            </CenteredPageContent>
        </ChildThemeProvider>
    );
}
