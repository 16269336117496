import { datadogRum } from '@datadog/browser-rum';
import {
    Column,
    Description,
    LabelText,
    ProgressBar,
    TextContainer,
} from '@moller/design-system';
import { Divider } from '@mui/material';
import { useEffect, useState } from 'react';
import { useCarInformation } from 'src/features/home/queries/useCarInformation';
import { useGetCarMileage } from 'src/features/home/queries/useCorrectDetails';
import Car from 'src/types/Car';
import { styled } from 'styled-components';
import { InfoContainerCard } from '../sva-service-deals/SvaService';
import energyConsumptionForModel from './EnergyComsumptionForModel';

const StyledDivNoMarginP = styled.div`
    p:first-child {
        margin-top: 0;
    }
`;

const StyledDivider = styled(Divider)`
    border-color: var(--moller-color-on-disabled) !important;
`;

const EmissionsDescription = styled.p`
    font-weight: bold;
`;

const CARBON_INTENSITY = 19;
const AVG_FOSSIL_EMISSION = 157;
const FLIGHTS_OSLO_TO_BERLIN_EMISSION = 136;

export const EmissionsCalculator = ({ car }: { car: Car }) => {
    const { data: technicalDetails } = useCarInformation(car.vin);
    const { data: mileage } = useGetCarMileage(car.vin);

    let energyConsumption = technicalDetails?.energyConsumption;

    if (energyConsumption == null) {
        energyConsumption = energyConsumptionForModel(car.name, car.brand);
    } else {
        energyConsumption = energyConsumption / 1000;
    }

    useEffect(() => {
        datadogRum.addAction(`Energy consumption from car-information`, {
            brand: car.brand,
            hasEnergyConsumption: technicalDetails?.energyConsumption != null,
        });
    }, [car.brand, energyConsumption, technicalDetails?.energyConsumption]);

    const [CO2EmissionEl, setCO2EmissionEl] = useState(0);
    const [CO2EmissionFossil, setCO2EmissionFossil] = useState(0);
    const [unit, setUnit] = useState('kg CO2e');

    const emissionDifference = parseFloat(
        (CO2EmissionFossil - CO2EmissionEl).toFixed(1)
    );

    const [flights, setFlights] = useState(0);

    const calculate = () => {
        if (mileage?.currentMileage && energyConsumption) {
            const emissionEl =
                parseFloat(
                    (
                        mileage?.currentMileage *
                        energyConsumption *
                        CARBON_INTENSITY
                    ).toFixed(2)
                ) / 1000;

            const emissionFossil =
                parseFloat(
                    (mileage?.currentMileage * AVG_FOSSIL_EMISSION).toFixed(1)
                ) / 1000;

            if (emissionEl > 10000 || emissionFossil > 10000) {
                setCO2EmissionFossil(
                    parseFloat((emissionFossil / 1000).toFixed(1))
                );
                setCO2EmissionEl(parseFloat((emissionEl / 1000).toFixed(1)));
                setUnit('tonn CO2e');
                setFlights(
                    Math.round(
                        (emissionDifference * 1000) /
                            FLIGHTS_OSLO_TO_BERLIN_EMISSION
                    )
                );
            } else {
                setCO2EmissionEl(parseFloat(emissionEl.toFixed(1)));
                setCO2EmissionFossil(parseFloat(emissionFossil.toFixed(1)));
                setUnit('kg CO2e');
                setFlights(
                    Math.round(
                        emissionDifference / FLIGHTS_OSLO_TO_BERLIN_EMISSION
                    )
                );
            }
        }
    };

    useEffect(() => {
        calculate();
    });

    return (
        <TextContainer>
            <Column gap="l">
                <div>
                    <InfoContainerCard>
                        <LabelText>
                            Utslipp med din kjøring er {CO2EmissionEl} {unit}
                        </LabelText>
                        <ProgressBar
                            animateOnChange={true}
                            percentage={
                                (CO2EmissionEl / CO2EmissionFossil) * 100 || 0
                            }
                            size={'large'}
                        />
                        <EmissionsDescription>
                            Du har{'  '}
                            {Math.round(
                                100 -
                                    (CO2EmissionEl / CO2EmissionFossil) * 100 ||
                                    0
                            )}
                            % mindre utslipp enn en fossilbil.
                        </EmissionsDescription>
                    </InfoContainerCard>
                    {flights > 0 && (
                        <p>
                            Du har registrert en kilometerstand på{' '}
                            {mileage?.currentMileage}. Ettersom du har kjørt
                            elbil og ikke fossilbil har du redusert ditt utslipp
                            tilsvarende{' '}
                            <b>
                                {flights}{' '}
                                {flights === 1 ? 'flytur' : 'flyturer'}{' '}
                            </b>
                            mellom Oslo og Berlin.
                        </p>
                    )}
                    <p>
                        Oppdater kilometerstanden for å se hvor mye du har spart
                        akkurat nå! Det gjør du ved å gå tilbake til bilsiden og
                        endre på kilometerstanden under bilens
                        registreringsnummer.
                    </p>
                </div>
                <StyledDivider />
                <StyledDivNoMarginP>
                    <p>
                        Ditt energiforbruk tilsvarer{' '}
                        <b>
                            {CO2EmissionEl} {unit}
                        </b>
                    </p>
                    <Description>
                        Basert på dine {mileage?.currentMileage} km kjørt,
                        CO2-faktor for strømforbruk i Norge og bilens
                        strømforbruk per km.
                    </Description>
                </StyledDivNoMarginP>
                <div>
                    <p>
                        Tilsvarende utslipp fra fossilbil{' '}
                        <b>
                            {CO2EmissionFossil} {unit}
                        </b>
                    </p>
                    <Description>
                        Basert på dine {mileage?.currentMileage} km kjørt og
                        gjennomsnittlig utslipp per km fra en mellomstor
                        dieselbil.
                    </Description>
                </div>
                <div>
                    <p>
                        Din kjøring har hittil spart miljøet{' '}
                        <b>
                            {emissionDifference} {unit}
                        </b>
                    </p>
                    <Description>
                        Basert på ditt energiforbruk sammenlignet med utslippet
                        fra en mellomstor dieselbil. Tallene er omtrentlige, for
                        mer nøyaktige tall, sjekk din merke-app.
                    </Description>
                </div>
                <StyledDivider />
            </Column>
        </TextContainer>
    );
};
