import { breakpointDesktop, IconButton } from '@moller/design-system';
import { useNavigate } from 'react-router-dom';
import { useAppointments } from 'src/features/appointment/api/useAppointments';
import { PATH_HOME } from 'src/routing/routes';
import { styled } from 'styled-components';
import { DetailedAppointmentContent } from './DetailedAppointmentContent';

const AppointmentDetails = styled.div`
    margin: 1.5rem;
`;

const StickyButton = styled(IconButton)`
    @media screen and (min-width: ${breakpointDesktop}) {
        position: fixed;
        top: 3.5rem;
    }
`;

export const DetailedAppointment = () => {
    const navigate = useNavigate();
    const id = window.location.pathname.split('/appointment/')[1];
    const { data, isLoading } = useAppointments();
    const appointment = data?.find((booking) => booking.id === id);

    return (
        <AppointmentDetails>
            <StickyButton
                label="Min biloversikt"
                icon="arrow_backward"
                showLabel="right"
                onClick={() => navigate(PATH_HOME)}
            />

            <DetailedAppointmentContent
                appointment={appointment}
                isLoading={isLoading}
            />
        </AppointmentDetails>
    );
};
