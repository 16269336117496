import { Column } from '@moller/design-system';
import { BookingViewModel } from 'src/features/appointment/api/useAppointments';
import { AppointmentCard } from 'src/features/appointment/components/AppointmentCard';
import { StyledOverline } from '../HomeStyledComponents';

const UpcomingActions = ({
    appointments,
    isLoading,
}: {
    appointments: BookingViewModel[];
    isLoading: boolean;
}) => {
    return (
        <>
            <StyledOverline>Kommende avtaler</StyledOverline>

            <Column>
                {appointments.map((appointment) => {
                    return (
                        <AppointmentCard
                            key={appointment.id}
                            appointment={appointment}
                            isLoading={isLoading}
                        />
                    );
                })}
            </Column>
        </>
    );
};

export default UpcomingActions;
