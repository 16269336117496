import { useQuery } from '@tanstack/react-query';
import getCurrentEnvironment from 'src/utils/environment/getCurrentEnvironment';
import get from 'src/utils/http/get';

export interface StravaCompetitionData {
    distanceInKm: number;
    elevationInMeters: number;
    streakInDays: number;
    athleteId: number;
}

export const useCompetitionData = (
    hasCompetitionStarted: boolean,
    hasStravaAuthenticated: boolean
) => {
    const { bilholdApiUrl } = getCurrentEnvironment();
    const url = `${bilholdApiUrl}/v1/competition/strava-competition-data`;
    return useQuery({
        queryKey: ['totaldistance'],
        queryFn: () =>
            get<StravaCompetitionData>(url).then((response) => response.data),
        enabled: hasCompetitionStarted && hasStravaAuthenticated,
        staleTime: 1000 * 60,
    });
};
