import { DealerWithDistance } from './useDealers';

interface DealerSorter {
    readonly name: string;
    readonly sortFunction: (
        a: DealerWithDistance,
        b: DealerWithDistance
    ) => number;
}
export const dealerSorters: DealerSorter[] = [
    {
        name: 'position',
        sortFunction: (a, b) => {
            if (
                a.distanceFromClient !== undefined &&
                b.distanceFromClient !== undefined
            ) {
                return a.distanceFromClient - b.distanceFromClient;
            } else {
                return 0;
            }
        },
    },
    {
        name: 'aToAA',
        sortFunction: (a, b) =>
            a.name && b.name ? a.name.localeCompare(b.name) : 0,
    },
];
