import React from 'react';
import { CampaignDrawer } from 'src/features/home/components/CampaignDrawer';
import lang from 'src/utils/lang';
import { BookingViewModel } from '../api/useAppointments';
import { DetailedAppointmentContent } from './DetailedAppointmentContent';

type DrawerProps = {
    drawerIsOpen: boolean;
    setDrawerIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    appointment: BookingViewModel;
    isLoading: boolean;
};

export const DetailedAppointmentDrawer = ({
    drawerIsOpen,
    setDrawerIsOpen,
    appointment,
    isLoading,
}: DrawerProps) => {
    // The undefined buttons are here for edit of an online booking.
    // The functionality is not implemented, so they are currently set to undefined
    return (
        <CampaignDrawer
            drawerIsOpen={drawerIsOpen}
            setDrawerIsOpen={setDrawerIsOpen}
            title={lang.future_appointment}
            imageUrl=""
            buttonText={undefined}
            buttonLink={undefined}
        >
            <DetailedAppointmentContent
                appointment={appointment}
                setDrawerIsOpen={setDrawerIsOpen}
                isLoading={isLoading}
            />
        </CampaignDrawer>
    );
};
