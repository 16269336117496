import {
    AlertBanner,
    Column,
    PrimaryButton,
    TextArea,
} from '@moller/design-system';
import { useState } from 'react';
import { usePostMessage } from 'src/utils/hooks/useMessageThreads';
import lang from 'src/utils/lang';
import { styled } from 'styled-components';

const MessageArea = styled(TextArea)`
    width: 100%;
    max-width: 500px;
    margin-bottom: var(--moller-spacing-xs);
`;

const StyledAlert = styled(AlertBanner)`
    max-width: 500px;
    margin-bottom: var(--moller-spacing-xs);
    box-sizing: border-box;
`;
interface SendMessageProps {
    threadId: string;
    senderName: string;
    onMessageSent?: () => void;
}

export const SendMessageInExistingThread = ({
    threadId,
    senderName,
    onMessageSent,
}: SendMessageProps) => {
    const [content, setContent] = useState('');

    const onSuccess = () => {
        if (onMessageSent) {
            onMessageSent();
        }
        setContent('');
    };

    const {
        mutate: postMessage,
        isError,
        isLoading,
    } = usePostMessage(threadId, senderName, onSuccess);

    return (
        <Column horizontalAlign="end">
            {isError && (
                <StyledAlert
                    type="error"
                    message={lang.postMessageErrorContactUsLink}
                />
            )}
            <MessageArea
                label="Send en melding"
                helperText={lang.provideMoreInfo}
                value={content}
                onChange={(e) => {
                    setContent(e.target.value);
                }}
                maxLength={500}
            />
            <PrimaryButton
                onClick={() => {
                    postMessage(content);
                }}
                loading={{
                    isLoading: isLoading,
                    loadingText: 'Sending message',
                }}
                disabled={!content || content.trim().length === 0}
            >
                {lang.send}
            </PrimaryButton>
        </Column>
    );
};
