import {
    BookingQueryParams,
    isBookingQueryParam,
} from 'booking/_api/urlSearchParameters';
import { useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { PATH_BOOKING_ROOT } from 'src/routing/routes';
import { isDefined } from 'src/utils/isDefined';
import { datadogLogs } from '@datadog/browser-logs';

/**
 * Map old booking query params to the new params
 */
const paramNameMap: Record<string, BookingQueryParams | undefined> = {
    dealer: 'dealerNumber',
    serviceIds: 'serviceId',
    services: 'serviceId',
};
function mapToNewParamName(name: string): string {
    return paramNameMap[name] ?? name;
}

function transformParamValue(name: string, value: string): [string, string] {
    if (isBookingQueryParam(name) && name === 'dealerGroup') {
        const newValue = value === 'Møller Bil' ? 'MollerBil' : value;
        return [name, newValue];
    }
    return [name, value];
}

/**
 * Handle external links to the old booking flow, translate to new url format.
 * Should be removed when new booking flow has completely taken over.
 */
export function useHandleLegacyBookingParams() {
    const pathParams = useParams();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const newSearchParams = new URLSearchParams(
        Array.from(searchParams.entries())
            .map(([param, value]) => {
                return transformParamValue(mapToNewParamName(param), value);
            })
            .filter(isDefined)
    );

    if (pathParams.vin) {
        newSearchParams.append('vin', pathParams.vin);
    }

    useEffect(() => {
        if (newSearchParams.toString() !== searchParams.toString()) {
            datadogLogs.logger.log(
                'Redirecting to new booking from useHandleLegacyBookingParams:',
                {
                    searchParams,
                    newSearchParams,
                    pathParams,
                }
            );
            navigate(`${PATH_BOOKING_ROOT}?${newSearchParams}`, {
                replace: true,
            });
        }
    });
}
