import { Navigate } from 'react-router-dom';
import { PATH_BEGIN, PATH_HOME } from 'src/routing/routes';
import { getIsLoggedIn } from 'src/utils/auth/authenticationUtils';

const NoContent = () => {
    const loggedIn = getIsLoggedIn();

    const queryParams = window.location.search;
    return (
        <Navigate
            replace
            to={
                loggedIn
                    ? `${PATH_HOME}${queryParams}`
                    : `${PATH_BEGIN}${queryParams}`
            }
        />
    );
};

export default NoContent;
