import {
    breakpointTablet,
    Column,
    Headline,
    LeadText,
} from '@moller/design-system';
import { AppStoreButtons } from 'src/shared/appstore-buttons/AppStoreButtons';
import { styled } from 'styled-components';
import { Section } from '../components';

const TextBlock = styled(Column)`
    gap: var(--moller-spacing-l);
`;

const ButtonContainer = styled.div`
    max-width: 300px;
    padding: 0 auto;

    @media screen and (max-width: ${breakpointTablet}) {
        position: var(--moller-spacing-m) 0 0 0;
    }
`;

export const DownloadBlock = () => {
    return (
        <Section withBackgroundcolor={true}>
            <TextBlock>
                <Headline>
                    Last ned Bilhold-appen
                    <br />
                    til din mobil
                </Headline>
                <LeadText>
                    Få en samlet oversikt over dine biler og ditt bilhold.
                </LeadText>
            </TextBlock>

            <ButtonContainer>
                <AppStoreButtons />
            </ButtonContainer>
        </Section>
    );
};
