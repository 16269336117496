import { ActionCard } from '@moller/design-system';
import React from 'react';
import { openWindowHref } from 'src/utils/navigation/openSystemHref';
import { styled } from 'styled-components';

const StyledActionCard = styled(ActionCard)`
    min-width: 160px;
    padding: 0;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    margin-bottom: var(--moller-spacing-s);
`;

const CompetitionImage = styled.img`
    min-width: 160px;
    width: 100%;
    height: 100%;
    max-height: 150px;
    object-fit: cover;
`;

const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: var(--moller-spacing-m);
    gap: var(--moller-spacing-xs);
    height: 100%;
`;

interface CompetitionCardProps {
    image: string;
    link: string;
    children: React.ReactNode;
}

export const CompetitionCard = ({
    image,
    children,
    link,
}: CompetitionCardProps) => {
    return (
        <StyledActionCard
            onClick={() => {
                openWindowHref(link);
            }}
        >
            <CompetitionImage src={image} alt="Competition illustration" />
            <ContentContainer>{children}</ContentContainer>
        </StyledActionCard>
    );
};
