import { Language, InputFieldValidity } from '@moller/design-system';
import { ValidationResult } from '@moller/design-system/utilities/validation';
import { LanguageIdentifier } from '../languages/languageContext';

function getDesignSystemLanguage(lang: LanguageIdentifier): Language {
    switch (lang) {
        case 'no':
            return 'no';
        default:
            return 'en';
    }
}
export function getErrorMessage(
    validation: ValidationResult<string>,
    language: LanguageIdentifier
): InputFieldValidity<{ errorMsgRequired: true }> {
    const firstError = validation.errorMessages[0];
    const errorMessage =
        typeof firstError === 'function'
            ? firstError(getDesignSystemLanguage(language))
            : firstError;
    return {
        type: 'error',
        message: errorMessage,
    };
}
