import { Modal, TextContainer } from '@moller/design-system';
import React, { ReactElement, useEffect } from 'react';
import StickyFooterContainer from 'src/features/home/components/StickyFooterContainer';
import { styled } from 'styled-components';

const Container = styled.div`
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    max-width: 800px;
    margin-bottom: 20px;
    ${TextContainer.selector} {
        padding-top: var(--moller-spacing-xs);
    }

    img {
        width: 100%;
        padding-bottom: var(--moller-spacing-s);
    }
`;

const Center = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
`;
interface CampaignModalProps {
    campaignModalIsOpen: boolean;
    setCampaignModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    buttonText?: string;
    buttonLink?: string;
    children?: ReactElement;
    buttonClick?: () => void;
}
export const CampaignModal = ({
    campaignModalIsOpen,
    setCampaignModalIsOpen,
    buttonText,
    buttonLink,
    children,
    buttonClick,
}: CampaignModalProps) => {
    useEffect(() => {
        if (campaignModalIsOpen) {
            (document.activeElement as HTMLElement)?.blur();
        }
    }, [campaignModalIsOpen]);

    return (
        <>
            <Modal
                size={'fullscreen'}
                isOpen={campaignModalIsOpen}
                handleClose={() => setCampaignModalIsOpen(false)}
            >
                <Center>
                    <Container>{children}</Container>
                    {buttonText && buttonLink && (
                        <StickyFooterContainer
                            buttonText={buttonText}
                            buttonLink={buttonLink}
                            buttonClick={buttonClick}
                        />
                    )}
                </Center>
            </Modal>
        </>
    );
};
