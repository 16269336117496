import moment from 'moment/moment';
import Car from 'src/types/Car';

const maximumMileage = 200000;
const maximumAgeInYears = 15;

export const isEligibleForValuation = (car: Car) => {
    const carAgeInYears = Math.abs(
        moment(car.registrationDate).diff(moment(), 'years')
    );
    const validAge = carAgeInYears <= maximumAgeInYears;
    const validMileage = car.mileage <= maximumMileage;

    return validAge && validMileage;
};
