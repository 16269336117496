import {
    Component,
    ComponentType,
    ErrorInfo,
    ForwardRefExoticComponent,
    ForwardedRef,
    PropsWithoutRef,
    ReactNode,
    RefAttributes,
    createElement,
    forwardRef,
} from 'react';
import { datadogRum } from '@datadog/browser-rum';

interface ErrorBoundaryProps {
    fallback: (retry: () => void) => ReactNode;
    logMessage: string;
    children?: ReactNode;
}
export class ErrorBoundary extends Component<
    ErrorBoundaryProps,
    { hasError: boolean }
> {
    public state: { hasError: boolean } = {
        hasError: false,
    };

    public static getDerivedStateFromError(_: Error): { hasError: boolean } {
        return { hasError: true };
    }

    public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        datadogRum.addError(error, { errorInfo });
        datadogRum.addError(new Error(this.props.logMessage), {
            error,
            errorInfo,
        });
    }

    private resetError = () => {
        this.setState({ hasError: false });
    };

    public render() {
        if (this.state.hasError) {
            {
                return this.props.fallback(this.resetError);
            }
        }

        return this.props.children;
    }
}

export function withErrorBoundary<Props extends object>(
    componentName: string,
    component: ComponentType<Props>,
    errorBoundaryProps: Omit<ErrorBoundaryProps, 'children' | 'logMessage'>
): ForwardRefExoticComponent<
    PropsWithoutRef<Props> & RefAttributes<ComponentType<Props>>
> {
    // Format for display in DevTools
    const name =
        componentName || component.displayName || component.name || 'Unknown';
    const Wrapped = forwardRef<ComponentType<Props>, Props>(
        (props: Props, ref: ForwardedRef<ComponentType<Props>>) =>
            createElement(
                ErrorBoundary,
                {
                    ...errorBoundaryProps,
                    logMessage: `Component crashed: ${name}`,
                },
                createElement(component, { ...props, ref })
            )
    );

    Wrapped.displayName = `withErrorBoundary(${name})`;

    return Wrapped;
}
