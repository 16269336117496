import {
    Column,
    Headline,
    InputFieldValidity,
    LoadingSpinner,
    RadioButton,
    TextField,
} from '@moller/design-system';
import { CarRelationType } from 'external-apis/src/types/bilhold';
import { useState } from 'react';
import { ddRumPrivacySetting } from 'src/utils/datadog';
import lang from 'src/utils/lang';
import { formatCarName } from 'src/utils/string/formatCarVariables';
import { ActionBar } from '../home/components/action/ActionBar';
import { useAddCarRequestFeasibility } from '../home/queries/useAddCarFunctions';
import { CarRelationContent } from './components/car-relation-content/CarRelationContent';
import isRegNoValid from './utils/isRegNoValid';

export const AddCar = () => {
    const [registrationNumber, setRegistrationNumber] = useState('');
    const [carRelation, setCarRelation] = useState<CarRelationType>();

    const { data, isLoading, isError } =
        useAddCarRequestFeasibility(registrationNumber);
    const options: {
        id: CarRelationType;
        label: 'iOwnCar' | 'iAmCarUser' | 'leasingCar' | 'companyCar';
    }[] = [
        { id: 'Owner', label: 'iOwnCar' },
        { id: 'User', label: 'iAmCarUser' },
        { id: 'Leaser', label: 'leasingCar' },
        { id: 'CompanyCar', label: 'companyCar' },
    ];

    const textFieldValidity: () => InputFieldValidity | undefined = () => {
        if (isError)
            return {
                type: 'error',
                message: lang.general_error_contact_support,
            };
        if (data) {
            if (data.result != 'Ok') {
                return {
                    type: 'error',
                    message: lang[`addCar${data.result}`],
                };
            }
            if (data.relationDetails) {
                return {
                    type: 'success',
                    message: formatCarName(
                        data.relationDetails.brand,
                        data.relationDetails.carModel
                    ),
                };
            }
        }
        return undefined;
    };

    return (
        <Column gap="s" {...ddRumPrivacySetting('allow')}>
            <Headline>{lang.addCar}</Headline>
            <TextField
                {...ddRumPrivacySetting('mask-user-input')}
                onChange={(e) =>
                    setRegistrationNumber(e.target.value.toUpperCase())
                }
                label={lang.addCarInputLabel}
                value={registrationNumber}
                autoFocus
                validity={textFieldValidity()}
            />
            {isLoading && isRegNoValid(registrationNumber) && (
                <LoadingSpinner />
            )}
            {data && data.result == 'Ok' && (
                <>
                    <Headline>{lang.carRelationship}</Headline>
                    <Column gap="s">
                        {options.map((option) => (
                            <RadioButton
                                key={option.id}
                                name={option.id}
                                value={carRelation == option.id}
                                label={lang[option.label]}
                                onChange={() => setCarRelation(option.id)}
                            />
                        ))}
                    </Column>
                </>
            )}
            {data && data.result == 'Ok' ? (
                <CarRelationContent
                    registrationNumber={registrationNumber}
                    carRelation={carRelation}
                    addCarRelationResult={data}
                />
            ) : (
                <ActionBar />
            )}
        </Column>
    );
};
