import { useEffect } from 'react';
import { Capacitor } from '@capacitor/core';
import { StatusBar, Style } from '@capacitor/status-bar';
import { useThemeContext } from 'theming';
import { App } from '@capacitor/app';
import { SplashScreen } from '@capacitor/splash-screen';
import { isAndroid } from './mobile';
import { LogoBrand } from '@moller/design-system/foundation/logos/Logo.js';

async function checkStatusBar(themeName: LogoBrand) {
    if (!Capacitor.isPluginAvailable('StatusBar')) {
        return;
    }

    const isDarkTheme =
        themeName === 'skoda' ||
        themeName === 'audi' ||
        themeName === 'cupra' ||
        (await SplashScreen.hide());

    if (isAndroid()) {
        await StatusBar.setBackgroundColor({
            color: isDarkTheme ? '#000000' : '#ffffff',
        });
    }

    await StatusBar.setStyle({
        style: isDarkTheme ? Style.Dark : Style.Light,
    });
}

const StatusBarHandler = () => {
    const { theme } = useThemeContext();

    useEffect(() => {
        void checkStatusBar(theme);
        const appStateChangeListener = App.addListener(
            'appStateChange',
            (state) => {
                if (state.isActive) {
                    void checkStatusBar(theme);
                }
            }
        );

        return () => {
            void appStateChangeListener.then((listener) => listener.remove());
        };
    }, [theme]);

    return null;
};

export default StatusBarHandler;
