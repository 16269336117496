import { datadogRum } from '@datadog/browser-rum';
import {
    AlertBanner,
    breakpointMobile,
    Column,
    Description,
    Headline,
    IconButton,
    OverlineText,
    Row,
} from '@moller/design-system';
import { useNavigate } from 'react-router-dom';

import { Divider } from '@mui/material';
import { useToggle } from 'feature-toggle';
import { PropsWithChildren } from 'react';
import { OTHER_SERVICES } from 'src/constants';
import {
    getMessageWithAppointmentId,
    partialAppointmentGuidToSafeNumberId,
} from 'src/features/contact/utils/messagesUtils';
import { EuControlCampaignAccordion } from 'src/features/home/current-actions/eu-control/EuControlCampaignAccordion';
import { useCarInformation } from 'src/features/home/queries/useCarInformation';
import { ContactItem } from 'src/shared/contact-item/ContactItem';
import addToCalendar from 'src/utils/calendar/addToCalendar';
import { dealerIsUpdatingSystems } from 'src/utils/dealerIsUpdatingSystems';
import useFeatureToggle from 'src/utils/dev-tools/useFeatureToggle';
import FuelType from 'src/utils/enums/FuelType';
import { formatDealerName } from 'src/utils/formatDealerName';
import { useMessageThreads } from 'src/utils/hooks/useMessageThreads';
import lang from 'src/utils/lang';
import { formatTime, getFormatDateWithDay } from 'src/utils/time/timeUtils';
import { styled } from 'styled-components';
import { BookingViewModel } from '../../api/useAppointments';

const InformationContainer = styled(Column)`
    width: 70%;
    @media screen and (max-width: ${breakpointMobile}) {
        width: 100%;
    }
`;

const StyledInformationBlock = styled(Column)`
    width: 100%;
    padding: var(--moller-spacing-s) 0px;
    p {
        margin-top: 0px;
    }
`;

const StyledDescription = styled(Description)`
    color: var(--moller-color-on-surface-variant);
`;

const TimeInformation = styled(Row)`
    width: 100%;
    ${Column.selector} {
        width: 50%;
    }
`;

const CalendarButton = styled(IconButton)`
    margin-left: -4px;
    margin-top: 8px;
`;

interface InformationBlockProps {
    headline: string;
}

const InformationBlock = ({
    headline,
    children,
}: PropsWithChildren<InformationBlockProps>) => {
    return (
        <StyledInformationBlock>
            <OverlineText>{headline}</OverlineText>
            {children}
        </StyledInformationBlock>
    );
};

const chargeableFuelTypes =
    FuelType.ELECTRIC || FuelType.DIESEL_HYBRID || FuelType.PETROL_HYBRID;

interface InformationProps {
    appointment: BookingViewModel;
}

export const Information = ({ appointment }: InformationProps) => {
    const ShowSystemUpgradeInfo = useFeatureToggle('ShowSystemUpgradeInfo');
    const navigate = useNavigate();

    const messageFunctionality = useToggle<string[]>(
        'DealersWithMessageFunctionality'
    );
    const dealersWithMessageFunctionality =
        messageFunctionality?.variation.value ?? ([] as string[]);

    const appointmentDate = getFormatDateWithDay(appointment.startTime);

    const isEuControl = appointment.bookedServices?.find((service) =>
        service.name.includes('EU-kontroll')
    );
    const isOtherService = appointment.bookedServices?.find(
        (service) => service.id === OTHER_SERVICES
    );
    const { data: technicalDetails } = useCarInformation(appointment.vin);

    const { data: messages } = useMessageThreads();

    const appointmentIdAsNumber = partialAppointmentGuidToSafeNumberId(
        appointment.id
    );

    const earlierSentMessage = getMessageWithAppointmentId(
        messages,
        appointmentIdAsNumber
    );

    const isServiceRequiresCharger = appointment.bookedServices?.some(
        (service) =>
            // Names and ids in service catalog are not consistent yet, so checking name instead of id
            service.name.includes('service') && !service.name.includes('klima')
    );

    const hasCarUsesCharger =
        technicalDetails?.fuelType === chargeableFuelTypes;

    const shouldShowChargerBanner =
        hasCarUsesCharger && (isServiceRequiresCharger || isEuControl);

    /*
        Even though we have turned off message in unleash for certain dealers, 
        we want to allow users who have started messages to easily see shortcut to started threads
    */
    const shouldShowSendMessage =
        !!earlierSentMessage ||
        dealersWithMessageFunctionality.includes(appointment.dealer.id);

    function handleNavigateToMessage() {
        datadogRum.addAction('bilhold-send-message-information');

        if (earlierSentMessage) {
            navigate(
                `/messages/messages-contact/thread/${earlierSentMessage.threadId}`
            );
        } else {
            navigate(`/messages/messages-contact/new/${appointment.id}`);
        }
    }

    return (
        <InformationContainer>
            <Headline>Kommende avtale</Headline>
            <InformationBlock headline={lang.workshop}>
                <p>{formatDealerName(appointment.dealer?.name ?? '')}</p>
                <Column gap="xxs">
                    <StyledDescription>
                        {appointment.dealer?.streetAddress ?? ''},{' '}
                        {appointment.dealer?.zipCode ?? ''}{' '}
                        {appointment.dealer?.city ?? ''}
                    </StyledDescription>
                    <Row gap="xxs" flexWrap="wrap">
                        {shouldShowSendMessage && (
                            <IconButton
                                icon="chat"
                                label={
                                    earlierSentMessage
                                        ? 'Fortsett samtale'
                                        : 'Send melding'
                                }
                                showLabel="right"
                                onClick={handleNavigateToMessage}
                            />
                        )}
                        {appointment.dealer?.phoneNumber && (
                            <ContactItem
                                phoneLabel="Ring verksted"
                                phoneNumber={appointment.dealer.phoneNumber}
                            />
                        )}
                        {appointment.dealer?.email && (
                            <IconButton
                                $as="a"
                                icon="mail"
                                href={`mailto:${appointment.dealer.email}`}
                                label={appointment.dealer.email}
                                showLabel="right"
                            />
                        )}
                    </Row>
                </Column>
            </InformationBlock>
            <Divider />
            <InformationBlock headline={lang.date}>
                <p>
                    {appointmentDate.substring(
                        0,
                        appointmentDate.lastIndexOf(' ')
                    )}
                </p>
                <CalendarButton
                    icon="calendar"
                    label="Legg i kalender"
                    showLabel="right"
                    onClick={() => void addToCalendar(appointment)}
                />
            </InformationBlock>
            <Divider />
            <TimeInformation>
                <InformationBlock headline={lang.appointmentTime}>
                    <p>{formatTime(appointment.startTime)}</p>
                </InformationBlock>
                {appointment.estimatedFinished && (
                    <>
                        <Divider
                            orientation="vertical"
                            variant="middle"
                            style={{
                                height: 'auto',
                                marginRight: '16px',
                            }}
                        />
                        {!isOtherService && (
                            <InformationBlock
                                headline={lang.estimatedWorkshopTimeTitle}
                            >
                                <p>
                                    {formatTime(appointment.estimatedFinished)}
                                </p>
                            </InformationBlock>
                        )}
                    </>
                )}
            </TimeInformation>
            <Divider />
            {dealerIsUpdatingSystems(appointment.dealer?.name) &&
                ShowSystemUpgradeInfo && (
                    <AlertBanner
                        type="info"
                        message={lang.delaysDueToNewSystems}
                    />
                )}
            {appointment.bookedServices &&
                appointment.bookedServices.length > 0 && (
                    <StyledInformationBlock>
                        <OverlineText>{lang.services}</OverlineText>
                        {appointment.bookedServices.map(
                            (service) =>
                                service && (
                                    <p key={service.name}>
                                        {service.name.replace(
                                            /[^a-zA-ZÆØÅæøå0-9+.,/:\-() ]/g,
                                            ''
                                        )}
                                    </p>
                                )
                        )}
                    </StyledInformationBlock>
                )}
            {shouldShowChargerBanner && (
                <AlertBanner
                    type="info"
                    heading={lang.rememberChargingCableHeading}
                    message={lang.rememberChargingCableDescription}
                />
            )}
            {isEuControl && <EuControlCampaignAccordion />}
        </InformationContainer>
    );
};
