import {
    Checkbox,
    IconButton,
    InfoCard,
    LabelText,
    RadioButton,
    TextField,
} from '@moller/design-system';
import React, { useEffect, useState } from 'react';
import Brand from 'src/types/Brand';
import FuelType from 'src/utils/enums/FuelType';
import { styled } from 'styled-components';
import { BaseCar, PreferredDealer } from '../data';

export type BaseCarWithId = BaseCar & { id: string };

const RadioButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 5px;
    flex-wrap: wrap;
`;

const RemoveButton = styled(IconButton)`
    position: absolute;
    top: 10px;
    right: 10px;
`;

const registrationYears = [
    new Date().getFullYear(),
    new Date().getFullYear() - 1,
    new Date().getFullYear() - 2,
    new Date().getFullYear() - 3,
    new Date().getFullYear() - 4,
];

const CarComponent: React.FC<{
    car: BaseCarWithId;
    onCarUpdate: (car: BaseCarWithId) => void;
    onCarRemove: (car: BaseCarWithId) => void;
}> = ({ car, onCarUpdate, onCarRemove }) => {
    const [brand, setBrand] = useState<Brand>(car.brand);
    const [model, setModel] = useState<string>(car.model);
    const [registrationYear, setRegistrationYear] = useState<number>(
        car.registrationYear
    );
    const [fuelType, setFuelType] = useState(car.fuelType);
    const [futureAppointments, setFutureAppointments] = useState(
        car.futureAppointments
    );
    const [pastAppointments, setPastAppointments] = useState(
        car.pastAppointments
    );
    const [hasUpdatedEuControl, setHasUpdatedEuControl] = useState(
        car.hasUpdatedEuControl
    );
    const [hasUpdatedMileage, setHasUpdatedMileage] = useState(
        car.hasUpdatedMileage
    );
    const [mobilityGuarantee, setMobilityGuarantee] = useState(
        car.mobilityGuarantee
    );
    const [hasTireHotel, setHasTireHotel] = useState(car.hasTireHotel);
    const [preferredDealer, setPreferredDealer] = useState(car.preferredDealer);

    useEffect(() => {
        const updatedCar = {
            ...car,
            brand,
            model,
            registrationYear,
            fuelType,
            futureAppointments,
            pastAppointments,
            hasUpdatedEuControl,
            hasUpdatedMileage,
            mobilityGuarantee,
            hasTireHotel,
            preferredDealer,
        };

        onCarUpdate(updatedCar);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        brand,
        model,
        registrationYear,
        fuelType,
        futureAppointments,
        pastAppointments,
        hasUpdatedEuControl,
        hasUpdatedMileage,
        mobilityGuarantee,
        hasTireHotel,
        preferredDealer,
    ]);

    return (
        <InfoCard>
            <RemoveButton
                label="Fjern bil"
                icon="close"
                onClick={() => onCarRemove(car)}
            />
            <LabelText>Brand</LabelText>
            <RadioButtonContainer>
                {Object.keys(Brand).map((key) => (
                    <RadioButton
                        key={key}
                        id={`${car.id}_${key}_brand`}
                        label={Brand[key as keyof typeof Brand]}
                        value={brand === Brand[key as keyof typeof Brand]}
                        onChange={() =>
                            setBrand(Brand[key as keyof typeof Brand])
                        }
                    />
                ))}
            </RadioButtonContainer>
            <div style={{ marginBottom: '-15px' }}>
                <TextField
                    onChange={(e) => {
                        setModel(e.target.value);
                    }}
                    label="model"
                    value={model}
                    autoCorrect="off"
                />
            </div>
            <LabelText>Registration year</LabelText>
            <RadioButtonContainer>
                {registrationYears.map((year) => (
                    <RadioButton
                        key={year}
                        id={`${car.id}_${year}_registrationYear`}
                        label={year.toString()}
                        value={registrationYear === year}
                        onChange={() => setRegistrationYear(year)}
                    />
                ))}
            </RadioButtonContainer>
            <LabelText>Fuel type</LabelText>
            <RadioButtonContainer>
                {Object.keys(FuelType).map((key) => (
                    <RadioButton
                        key={key}
                        id={`${car.id}_${key}_fuelType`}
                        label={FuelType[key as keyof typeof FuelType]}
                        value={
                            fuelType === FuelType[key as keyof typeof FuelType]
                        }
                        onChange={() =>
                            setFuelType(FuelType[key as keyof typeof FuelType])
                        }
                    />
                ))}
            </RadioButtonContainer>
            <LabelText>Future appointments</LabelText>
            <RadioButtonContainer>
                {[0, 1, 2, 3].map((number) => (
                    <RadioButton
                        key={number}
                        id={`${car.id}_${number}_futureAppointments`}
                        label={number.toString()}
                        value={futureAppointments === number}
                        onChange={() => setFutureAppointments(number)}
                    />
                ))}
            </RadioButtonContainer>
            <LabelText>Past appointments</LabelText>
            <RadioButtonContainer>
                {[0, 1, 2, 3].map((number) => (
                    <RadioButton
                        key={number}
                        id={`${car.id}_${number}_pastAppointments`}
                        label={number.toString()}
                        value={pastAppointments === number}
                        onChange={() => setPastAppointments(number)}
                    />
                ))}
            </RadioButtonContainer>
            <LabelText>Preferred dealer</LabelText>
            <RadioButtonContainer>
                {Object.keys(PreferredDealer).map((key) => (
                    <RadioButton
                        key={key}
                        id={`${car.id}_${key}_preferredDealer`}
                        label={
                            PreferredDealer[key as keyof typeof PreferredDealer]
                        }
                        value={
                            preferredDealer ===
                            PreferredDealer[key as keyof typeof PreferredDealer]
                        }
                        onChange={() =>
                            setPreferredDealer(
                                PreferredDealer[
                                    key as keyof typeof PreferredDealer
                                ]
                            )
                        }
                    />
                ))}
            </RadioButtonContainer>
            <LabelText>Misc</LabelText>
            <RadioButtonContainer>
                <Checkbox
                    label="Updated EU-Control"
                    id={`${car.id}_hasUpdatedEuControl`}
                    value={hasUpdatedEuControl}
                    onChange={(e) => {
                        setHasUpdatedEuControl(e.target.checked);
                    }}
                />
                <Checkbox
                    label="Updated mileage"
                    id={`${car.id}_hasUpdatedMileage`}
                    value={hasUpdatedMileage}
                    onChange={(e) => {
                        setHasUpdatedMileage(e.target.checked);
                    }}
                />
                <Checkbox
                    label="Valid Mobility guarantee"
                    id={`${car.id}_mobilityGuarantee`}
                    value={mobilityGuarantee}
                    onChange={(e) => {
                        setMobilityGuarantee(e.target.checked);
                    }}
                />
                <Checkbox
                    label="Tire hotel"
                    id={`${car.id}_hasTireHotel`}
                    value={hasTireHotel}
                    onChange={(e) => {
                        setHasTireHotel(e.target.checked);
                    }}
                />
            </RadioButtonContainer>
        </InfoCard>
    );
};

export default CarComponent;
