import { datadogRum } from '@datadog/browser-rum';
import {
    InputFieldValidity,
    PrimaryButton,
    TextField,
} from '@moller/design-system';
import { useState } from 'react';
import { WeightItem } from 'src/features/electric-car-playground/payload/payload-calculator/types/WeightItems';
import { styled } from 'styled-components';

const AddButton = styled(PrimaryButton)`
    font-size: var(--moller-size-s);
    margin-top: var(--moller-size-xxs);
    @media only screen and (max-width: 600px) {
        width: 100%;
    }
`;

const CustomWeightContent = styled.div`
    display: flex;
    flex-direction: row;
    gap: var(--moller-spacing-xs);
    max-width: 100%;
    flex-wrap: wrap;

    @media only screen and (max-width: 600px) {
        display: block;
    }
`;

interface CustomWeightProps {
    addItem: (value: WeightItem) => void;
}
const CustomWeightItem = ({ addItem }: CustomWeightProps) => {
    const [customWeightName, setCustomWeightName] = useState<string>('');
    const [customWeightKg, setCustomWeightKg] = useState<string>('');
    const validKgInput = ():
        | InputFieldValidity<{ errorMsgRequired: true }>
        | undefined => {
        if (isNaN(+customWeightKg)) {
            return {
                type: 'error',
                message: 'Skriv inn tall',
            };
        }
        return;
    };

    const item = {
        name: customWeightName,
        kg: parseInt(customWeightKg),
        amount: 1,
    };
    return (
        <>
            <p>Legg til egendefinert vekt</p>
            <CustomWeightContent>
                <TextField
                    id="weightUnitName"
                    value={customWeightName}
                    label="Navn"
                    onChange={(e) => setCustomWeightName(e.target.value)}
                />
                <TextField
                    id="weightUnitKg"
                    label="Vekt i kg"
                    value={customWeightKg}
                    inputMode="numeric"
                    validity={validKgInput()}
                    onChange={(e) => setCustomWeightKg(e.target.value)}
                />
                <AddButton
                    disabled={customWeightName === '' || customWeightKg === ''}
                    onClick={() => {
                        addItem(item);
                        setCustomWeightKg('');
                        setCustomWeightName('');
                        datadogRum.addAction(`User adds custom weight`);
                    }}
                >
                    Legg til
                </AddButton>
            </CustomWeightContent>
        </>
    );
};

export default CustomWeightItem;
