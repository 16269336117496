import { ChildThemeProvider } from 'theming';
import { InformationArticleSanity } from './SanityInformationArticle';

const TermsOfService = () => {
    return (
        <ChildThemeProvider theme="bilhold">
            <InformationArticleSanity articleSlug="terms-of-service" />
        </ChildThemeProvider>
    );
};

export default TermsOfService;
