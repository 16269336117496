import { useState } from 'react';
import { isMobileApp } from 'src/utils/mobile';
import { CampaignDrawer } from 'src/features/home/components/CampaignDrawer';
import { CampaignModal } from 'src/features/home/components/CampaignModal';
import lang from 'src/utils/lang';
import { UsedCarValuationCard } from 'src/features/home/current-actions/used-car-valuation/UsedCarValuationCard';
import { UsedCarValuationContent } from 'src/features/home/current-actions/used-car-valuation/UsedCarValuationContent';
import drawerImage from './keys.webp';

export const UsedCarValuation = () => {
    const [campaignModalIsOpen, setCampaignModalIsOpen] = useState(false);
    const buttonLink = 'https://verdivurdering.moller.no/?src=bilhold';

    return (
        <>
            <UsedCarValuationCard
                toggleDrawer={() => {
                    setCampaignModalIsOpen(true);
                }}
            />
            {isMobileApp() ? (
                <CampaignDrawer
                    drawerIsOpen={campaignModalIsOpen}
                    setDrawerIsOpen={setCampaignModalIsOpen}
                    imageUrl={drawerImage}
                    title={lang.valuationCardTitle}
                    buttonText={lang.requestValuation}
                    buttonLink={buttonLink}
                >
                    <UsedCarValuationContent />
                </CampaignDrawer>
            ) : (
                <CampaignModal
                    campaignModalIsOpen={campaignModalIsOpen}
                    setCampaignModalIsOpen={setCampaignModalIsOpen}
                    buttonText={lang.requestValuation}
                    buttonLink={buttonLink}
                >
                    <UsedCarValuationContent />
                </CampaignModal>
            )}
        </>
    );
};
