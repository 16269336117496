import { IconButton, Subtitle } from '@moller/design-system';
import React from 'react';
import { useLanguageContext } from '../../lib/languages/languageContext';

type BilholdViewTitleProps = {
    title: string;
    isEditable?: boolean;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
};
export const BilholdViewTitle: React.FC<BilholdViewTitleProps> = ({
    title,
    isEditable = false,
    onClick = () => {
        return;
    },
}) => {
    const [lc] = useLanguageContext();

    return (
        <>
            <Subtitle>{title}</Subtitle>
            {isEditable && (
                <IconButton
                    icon={'edit'}
                    onClick={onClick}
                    label={lc.components.button_change}
                    showLabel={'left'}
                />
            )}
        </>
    );
};
