import { datadogRum } from '@datadog/browser-rum';
import { IconButton, TextContainer } from '@moller/design-system';
import { useState } from 'react';
import { themeOverride } from 'src/utils/dev-tools/devToolsUtils';
import { useEffectOnce } from 'src/utils/hooks/useEffectOnce';
import lang from 'src/utils/lang';
import { styled } from 'styled-components';
import { ChildThemeProvider } from 'theming';
import NpsScale from './NpsScale';

const InputBox = styled.div`
    position: fixed;
    padding: 12px;
    padding-bottom: env(safe-area-inset-bottom);
    bottom: 0px;
    left: 0px;
    right: 0px;
    background: var(--moller-color-surface-variant);
    color: var(--moller-color-on-surface-variant);
    animation-duration: 2s;
    animation-name: NPSInput-slidein;
    z-index: var(--z-index-nps-modal);
    box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.16);

    @keyframes NPSInput-slidein {
        from {
            bottom: -100%;
        }

        to {
            bottom: 0px;
        }
    }
`;

const InnerText = styled.div`
    width: 100%;
    max-width: 1000px;
    margin: 12px auto;
    text-align: center;
`;

const CloseButton = styled(IconButton)`
    float: right;
`;

interface NpsInputProps {
    hideNpsFor30Days: () => void;
    trigger: string;
}

const NpsInput = ({ hideNpsFor30Days, trigger }: NpsInputProps) => {
    const [show, setShow] = useState(false);
    const [dismissed, setDismissed] = useState(false);
    const [score, setScore] = useState<number | null>(null);

    useEffectOnce(() => {
        const timeout = setTimeout(() => setShow(true), 1000);
        return () => clearTimeout(timeout);
    });

    const dismissNps = () => {
        if (!score) {
            datadogRum.addAction('User dismissed NPS modal');
        }
        setDismissed(true);

        hideNpsFor30Days();
    };

    const submitScore = (value: number) => {
        setScore(value);
        datadogRum.addAction('User submitted NPS', {
            score: value,
            trigger: trigger,
        });

        hideNpsFor30Days();
    };

    if (dismissed || !show) {
        return null;
    }

    return (
        <ChildThemeProvider theme="bilhold" overrideTheme={themeOverride}>
            <InputBox>
                <CloseButton
                    icon="close"
                    label={lang.close}
                    onClick={() => dismissNps()}
                />
                <InnerText>
                    {score !== null ? (
                        <TextContainer>
                            <p>{lang.npsThankYou}</p>
                        </TextContainer>
                    ) : (
                        <>
                            <TextContainer>
                                <p>{lang.npsQuestion}</p>
                            </TextContainer>
                            <NpsScale submitScore={submitScore} />
                        </>
                    )}
                </InnerText>
            </InputBox>
        </ChildThemeProvider>
    );
};

export default NpsInput;
