import { LabelText, TextButton, breakpointMobile } from '@moller/design-system';
import { styled } from 'styled-components';
import { useLanguageContext } from '../../../lib/languages/languageContext';

export interface ShowMoreDealersProps {
    shownDealerCount: number;
    totalDealerCount: number;
    showMore: () => void;
}

export const ShowMoreDealers = ({
    shownDealerCount,
    totalDealerCount,
    showMore,
}: ShowMoreDealersProps) => (
    <ShowMoreRow>
        <DealerCounterLabelText
            dealersShown={shownDealerCount}
            dealersMatchingSearch={totalDealerCount}
        />
        <ShowMoreButton
            showMore={showMore}
            disableShowMore={totalDealerCount <= shownDealerCount}
        />
    </ShowMoreRow>
);

const ShowMoreRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media screen and (min-width: ${breakpointMobile}) {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        > :nth-child(2) {
            justify-self: center;
        }
    }
`;
type ShowMoreButtonProps = {
    showMore: () => void;
    disableShowMore: boolean;
};

function ShowMoreButton({ showMore, disableShowMore }: ShowMoreButtonProps) {
    const [lc] = useLanguageContext();
    return (
        <TextButton
            onClick={() => showMore()}
            disabled={disableShowMore}
            underline
        >
            {lc.chooseDealer.button_show_more}
        </TextButton>
    );
}

type DealerCounterLabelTextProps = {
    dealersShown: number;
    dealersMatchingSearch: number;
};

function DealerCounterLabelText({
    dealersShown,
    dealersMatchingSearch,
}: DealerCounterLabelTextProps) {
    const [lc] = useLanguageContext();

    return (
        <LabelText>
            {lc.chooseDealer.showing_x_dealers.replace(
                '{0}',
                `${dealersShown}/${dealersMatchingSearch}`
            )}
        </LabelText>
    );
}
