import { AxiosResponse } from 'axios';
import { RequestConfig, doRequest } from './doRequest';

const post = <T = unknown>(
    path: string,
    data?: unknown,
    config?: RequestConfig
): Promise<AxiosResponse<T>> => doRequest('POST', path, data, config);

export default post;
