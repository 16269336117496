import { ReactNode } from 'react';
import { styled } from 'styled-components';

export type DDPrivacyLevels = 'mask-user-input' | 'mask' | 'allow';

const Styling = styled.div`
    display: contents;
`;

export function DDRumPrivacySetting({
    privacyLevel,
    children,
}: {
    privacyLevel: DDPrivacyLevels;
    children: ReactNode;
}) {
    return <Styling data-dd-privacy={privacyLevel}>{children}</Styling>;
}

export function ddRumPrivacySetting(privacyLevel: DDPrivacyLevels) {
    return { 'data-dd-privacy': privacyLevel };
}
