import { Column, InfoCard, Row } from '@moller/design-system';
import { Skeleton } from '@mui/material';
import { styled } from 'styled-components';

const StyledColumn = styled(Column)`
    gap: var(--moller-spacing-base);
    width: 100%;
`;

const StyledRow = styled(Row)`
    gap: var(--moller-spacing-base);
`;
export const CarSkeleton = () => {
    return (
        <InfoCard>
            <StyledColumn>
                <Skeleton variant="rounded" />
                <Skeleton variant="rounded" />
                <StyledRow>
                    <Skeleton variant="rounded" height={'2rem'} width={'33%'} />
                    <Skeleton variant="rounded" height={'2rem'} width={'33%'} />
                    <Skeleton variant="rounded" height={'2rem'} width={'33%'} />
                </StyledRow>
                <Skeleton variant="rounded" />
            </StyledColumn>
        </InfoCard>
    );
};
