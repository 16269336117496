import { ActionCard } from '@moller/design-system';
import { DelayedRender } from '../../lib/DelayedRender';

export function ChooseVehicleLoader() {
    return (
        <DelayedRender>
            <ActionCard isLoading />
        </DelayedRender>
    );
}
