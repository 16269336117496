import { Fetcher } from 'openapi-typescript-fetch';
import { Environment } from '../..';
import { ENVIRONMENTS, backendUrl } from '../environment';
import { paths } from '../schema/port';
import { FetcherConfiguration } from './fetchersProvider';

const urls: { [x in (typeof ENVIRONMENTS)[number]]: string } = {
    prod: 'https://booking-api.moller.no',
    stage: 'https://workshop-booking-port.stage.mollercloud.no',
    test: 'https://workshop-booking-port.test.mollercloud.no',
    dev: 'https://workshop-booking-port.dev.mollercloud.no',
    local: 'http://localhost:3004',
};

export const fetcher: ReturnType<typeof Fetcher.for<paths>> =
    Fetcher.for<paths>();

export function configurePortFetcher({
    localEnvironmentOverride,
    environment,
    middlewares,
}: FetcherConfiguration) {
    const baseUrl = backendUrl({
        environment,
        urls,
        localEnvironmentOverride,
    });
    fetcher.configure({
        baseUrl: baseUrl,
        init: {
            headers: {
                'Content-Type': 'application/json',
            },
        },
        use: middlewares,
    });
    return {
        baseUrl,
        fetcher,
        environment,
    };
}

export function portBaseUrl(
    environment: Environment,
    localEnvironmentOverride?: string
) {
    return backendUrl({
        environment,
        urls,
        localEnvironmentOverride,
    });
}
