import { BookingViewModel } from 'src/features/appointment/api/useAppointments';
import { PreferredAffiliationDealerResponse } from 'src/features/home/queries/usePreferredDealer';
import { HistoricBooking } from 'src/features/my-car/query/useWorkshopHistory';
import Car from 'src/types/Car';
import { VehicleData } from 'src/types/generated/CarInformation/CarInformationTypes';
import { getNumberOfRemainingDays } from 'src/utils/time/timeUtils';
import { useTireCardContent } from '../current-actions/tire-change-hotel/useTireCardContent';
import { isDateLessThanXMonthsAgo } from './isDateWithinRange';
import isValidModel from './isValidModel';

export const hasUpcomingEuControl = (nextDate?: string | null) => {
    const isValidDate =
        nextDate &&
        getNumberOfRemainingDays(nextDate) >= 0 &&
        getNumberOfRemainingDays(nextDate) <= 90;
    return !!isValidDate;
};

export const useEuControlCard = (
    appointments?: BookingViewModel[],
    nextDate?: string | null
) => {
    const hasAppointments = appointments && appointments.length > 0;
    return hasUpcomingEuControl(nextDate) && !hasAppointments;
};

export const useInspectionServiceCard = (
    car: Car,
    appointments?: BookingViewModel[],
    carInformation?: VehicleData,
    workshopHistory?: HistoricBooking[]
) => {
    const hasAppointments = appointments && appointments.length > 0;

    if (!carInformation?.registrationDate || hasAppointments) {
        return false;
    }

    const isValidVehicle = isValidModel(
        ['id.3', 'id.4', 'enyaq', 'q4 e-tron'],
        car
    );

    const vehicleIsNewerThan22Months = isDateLessThanXMonthsAgo(
        carInformation?.registrationDate,
        22
    );

    const hasHadEuControl = hasHadAppointment(
        'eu-kontroll',
        22,
        workshopHistory
    );

    const hasHadInspectionService = hasHadAppointment(
        'Inspeksjon',
        22,
        workshopHistory
    );

    return (
        isValidVehicle &&
        !vehicleIsNewerThan22Months &&
        !hasHadEuControl &&
        !hasHadInspectionService
    );
};

export const useTireChangeCard = (
    vin: string,
    preferredDealer?: PreferredAffiliationDealerResponse,
    appointments?: BookingViewModel[],
    workshopHistory?: HistoricBooking[]
) => {
    const showTireCard = useTireCardContent(vin, preferredDealer);
    const hasAppointments = appointments && appointments.length > 0;
    const hasHadTireChange = hasHadAppointment('hjulskift', 3, workshopHistory);
    return showTireCard && !hasAppointments && !hasHadTireChange;
};

export const useRecallCard = (
    appointments?: BookingViewModel[],
    workshopHistory?: HistoricBooking[]
) => {
    const hasRecallAppointment =
        appointments &&
        appointments.length > 0 &&
        appointments.some((p) =>
            p.bookedServices?.some((p) =>
                p?.name?.toLowerCase()?.includes('oppgradering')
            )
        );
    const hasHadRecall = hasHadAppointment('oppgradering', 3, workshopHistory);
    return !hasRecallAppointment && !hasHadRecall;
};

const hasHadAppointment = (
    checkService: string,
    monthRange: number,
    workshopHistory?: HistoricBooking[]
) => {
    return workshopHistory?.some(
        (workshopAppointment) =>
            workshopAppointment.taskNames?.some((tasks) =>
                tasks?.toLowerCase().includes(checkService.toLowerCase())
            ) && isDateLessThanXMonthsAgo(workshopAppointment.date, monthRange)
    );
};
