import type { ComponentProps } from 'react';

type Props = ComponentProps<'svg'>;
export function TDFFlag({ ...props }: Props): JSX.Element {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={props.width || 'auto'}
            height={props.height || '22'}
            fill="none"
            viewBox="0 0 16 30"
            {...props}
        >
            <g clipPath="url(#clip0_2306_6306)">
                <path stroke="#fff" d="M15.5 0L15.5 31.25"></path>
                <path fill="#fff" d="M0 0H3.75V3.75H0z"></path>
                <path fill="#fff" d="M0 7.5H3.75V11.25H0z"></path>
                <path fill="#fff" d="M3.75 3.75H7.5V7.5H3.75z"></path>
                <path fill="#fff" d="M11.25 3.75H15V7.5H11.25z"></path>
                <path fill="#fff" d="M7.5 0H11.25V3.75H7.5z"></path>
                <path fill="#fff" d="M7.5 7.5H11.25V11.25H7.5z"></path>
                <path fill="#161718" d="M3.75 0H7.5V3.75H3.75z"></path>
                <path fill="#161718" d="M3.75 7.5H7.5V11.25H3.75z"></path>
                <path fill="#161718" d="M11.25 0H15V3.75H11.25z"></path>
                <path fill="#161718" d="M11.25 7.5H15V11.25H11.25z"></path>
                <path fill="#161718" d="M7.5 3.75H11.25V7.5H7.5z"></path>
                <path fill="#161718" d="M0 3.75H3.75V7.5H0z"></path>
                <path stroke="#fff" d="M15.5 0L15.5 31.25"></path>
            </g>
        </svg>
    );
}
