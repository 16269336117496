import { initPushNotificationDevice } from 'src/utils/pushNotifications/initDevice';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import initializeAdobeLaunch from 'src/utils/analytics/initializeAdobeLaunch';
import { useUserIds } from 'src/utils/hooks/useUserIds';
import { isMobileApp } from 'utils/mobile';
import useInitializeCookieBanner from './useInitializeCookieBanner';

export default () => {
    const navigate = useNavigate();
    const userIds = useUserIds();

    useEffect(() => {
        if (isMobileApp()) {
            document.body.classList.add('bilhold-native-app');
        }
    }, []);

    useEffect(
        () => initPushNotificationDevice(userIds, navigate),
        [userIds, navigate]
    );

    useEffect(() => {
        initializeAdobeLaunch();
    }, []);

    useInitializeCookieBanner();
};
