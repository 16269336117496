import { fakeSetState, fakeState, tireHotelService } from '../../testData';

type ToggleFeature = {
    id: string;
    selectedServiceIds: string[];
    setSelectedServiceIds: (x: string[]) => void;
};

export function toggleFeature({
    selectedServiceIds,
    id,
    setSelectedServiceIds,
}: ToggleFeature) {
    if (selectedServiceIds.includes(id)) {
        setSelectedServiceIds(selectedServiceIds.filter((x) => x !== id));
    } else {
        setSelectedServiceIds([...selectedServiceIds, id]);
    }
}

if (import.meta.vitest) {
    const { describe, it, expect } = import.meta.vitest;
    describe('toggleFeature', () => {
        it.concurrent('remove service id when deselecting tire service', () => {
            const state = fakeState<string[]>([tireHotelService.id]);
            const setState = fakeSetState(state);
            toggleFeature({
                selectedServiceIds: state.value,
                id: tireHotelService.id,
                setSelectedServiceIds: setState,
            });
            expect(state.value).toEqual([]);
        });
        it.concurrent('add service id when selecting tire service', () => {
            const state = fakeState<string[]>([]);
            const setState = fakeSetState(state);
            toggleFeature({
                id: tireHotelService.id,
                selectedServiceIds: state.value,
                setSelectedServiceIds: setState,
            });
            expect(state.value).toEqual([tireHotelService.id]);
        });
    });
}
