const companies = [
    '',
    'Gjensidige',
    'IF',
    'Tryg',
    'Sparebank 1',
    'Storebrand',
    'Eika',
    'Frende',
    'KLP',
    'Codan',
    'DNB',
    'Nemi',
    'Vardia',
    'Landsbruksforsikring',
    'Troll',
    'OBOS',
    'KNIF',
    'Protector',
    'Jernbanepersonalets bank og forsikring',
    'Enter',
];

export default companies
    .map((company) => ({
        label: company,
        value: company,
    }))
    .sort((a, b) => a.value.localeCompare(b.value));
