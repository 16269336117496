import { Middleware } from 'openapi-typescript-fetch';

export interface AuthCallbacks {
    getFreshAuthToken: () => Promise<string>;
    onUnauthorized: () => Promise<void>;
}

export function authMiddleware(authCallbacks?: AuthCallbacks): Middleware {
    return async (url, init, next) => {
        const token = await authCallbacks?.getFreshAuthToken();
        if (token) {
            init.headers.append('Authorization', `Bearer ${token}`);
        }
        const res = await next(url, init);
        if (res.status === 401) {
            await authCallbacks?.onUnauthorized?.();
        }
        return res;
    };
}
