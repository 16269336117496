import { useAutoAnimate } from '@formkit/auto-animate/react';
import { InfoCard } from '@moller/design-system';
import { DealerViewModel } from 'external-apis/src/types/port';
import { BoldText } from '../../../components/BoldText';
import { DealerAddressLink } from '../../../components/DealerAddressLink';
import { useLanguageContext } from '../../../lib/languages/languageContext';

type ChosenDealerProps = {
    dealer?: DealerViewModel;
};

export const ChosenDealer: React.FC<ChosenDealerProps> = ({ dealer }) => {
    const [lc] = useLanguageContext();
    const [animationParent] = useAutoAnimate();

    return (
        <InfoCard density="compact" ref={animationParent}>
            {dealer ? (
                <>
                    <BoldText>{dealer.name}</BoldText>
                    <DealerAddressLink dealer={dealer} />
                </>
            ) : (
                <BoldText>{lc.chooseDealer.no_chosen_dealer}</BoldText>
            )}
        </InfoCard>
    );
};
