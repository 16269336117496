import { useMutation } from '@tanstack/react-query';
import patch from 'src/utils/http/patch';
import getCurrentEnvironment from 'src/utils/environment/getCurrentEnvironment';

export const useUnsubscribeEmail = (onSuccess: () => void) => {
    const { bilholdApiUrl } = getCurrentEnvironment();
    const url = `${bilholdApiUrl}/v1/competition/email-unsubscribe`;
    return useMutation({
        mutationFn: () => patch(url),
        onSuccess: () => {
            onSuccess();
        },
    });
};
