import { useMutation, useQueryClient } from '@tanstack/react-query';
import patch from 'src/utils/http/patch';
import { startPrizeKey } from 'src/features/competition/utils/useCompetitionStartPrize';
import getCurrentEnvironment from 'src/utils/environment/getCurrentEnvironment';

export type UpdateStartPrizeDataPayload = Partial<{
    dealer: string | undefined;
    prize: string | undefined;
}>;

export const useUpdateCompetitionStartPrize = () => {
    const queryClient = useQueryClient();
    const { bilholdApiUrl } = getCurrentEnvironment();
    const url = `${bilholdApiUrl}/v1/competition/update-start-prize`;
    return useMutation({
        mutationFn: (payload: UpdateStartPrizeDataPayload) =>
            patch(url, payload),
        onSuccess: async () => {
            return await queryClient.invalidateQueries({
                queryKey: [startPrizeKey],
            });
        },
    });
};
