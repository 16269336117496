enum FuelType {
    PETROL = 'Bensin',
    DIESEL = 'Diesel',
    ELECTRIC = 'Elektrisk',
    METHANOL = 'Metanol',
    GAS = 'Gass',
    PETROL_HYBRID = 'Ben hybrid',
    DIESEL_HYBRID = 'Die hybrid',
}

export default FuelType;
