import { SuccessButton } from '@moller/design-system';
import { forwardRef, useRef, useState } from 'react';
import { ddRumPrivacySetting } from '../../components/DDRumPrivacySetting';
import { useLanguageContext } from '../../lib/languages/languageContext';
import { useScrollRefIntoView } from '../../lib/util/scroll';
import { AdditionalServicesResult } from '../additional-services/AdditionalServicesSection';
import { ChooseDealerResult } from '../choose-dealer/ChooseDealerSection';
import { ChooseTimeSlotResult } from '../choose-timeslot/ChooseTimeslotSection';
import { ChooseVehicleResult } from '../choose-vehicle/ChooseVehicleSection';
import { SectionState, checkDoneDependencies } from '../sectionUtils';
import { StandardServicesResult } from '../standard-services/StandardServicesSection';
import { TransportServicesResult } from '../transport-services/TransportServicesSection';
import { ConfirmBookingEdit } from './confirm-booking-edit/ConfirmBookingEdit';

export type ConfirmBookingDependencies = {
    chooseVehicle: ChooseVehicleResult;
    chooseDealer: ChooseDealerResult;
    standardServices: StandardServicesResult;
    chooseTimeSlot: ChooseTimeSlotResult;
    transportServices: TransportServicesResult;
    additionalServices: AdditionalServicesResult;
};

interface UseConfirmBooking {
    initialState: SectionState<null>;
    dependencies: ConfirmBookingDependencies;
}

export function useConfirmBooking({
    initialState,
    dependencies,
}: UseConfirmBooking) {
    const [state, setState] = useState(initialState);
    const depsAreDone = checkDoneDependencies(dependencies);

    if (depsAreDone && state.viewMode === 'done') {
        const done = {
            result: {
                viewMode: state.viewMode,
                data: state.data,
            },
            props: {
                viewMode: state.viewMode,
                dependencies,
                setSectionState: setState,
                data: state.data,
            },
        };
        return done;
    }

    if (state.viewMode === 'edit') {
        const edit = {
            result: {
                viewMode: state.viewMode,
            },
            props: {
                viewMode: state.viewMode,
                dependencies,
                setSectionState: setState,
            },
        };
        return edit;
    }

    return {
        result: {
            viewMode: 'hidden' as const,
        },
        props: {
            viewMode: 'hidden' as const,
            setSectionState: setState,
        },
    };
}

type ConfirmBookingPropsBase = ReturnType<typeof useConfirmBooking>['props'];
type ConfirmBookingProps = ConfirmBookingPropsBase & {
    invalidateAppointmentsQuery: () => void;
};

export type ConfirmBookingForm = {
    otp: string;
    acceptedTerms: boolean;
};

export function ConfirmBooking(props: ConfirmBookingProps) {
    const { viewMode } = props;
    const sectionRef = useRef(null);
    useScrollRefIntoView(props.viewMode === 'edit', sectionRef);

    return (
        <div ref={sectionRef} {...ddRumPrivacySetting('allow')}>
            {viewMode === 'edit' && (
                <ConfirmBookingEdit
                    setSectionState={props.setSectionState}
                    dependencies={props.dependencies}
                    invalidateAppointmentsQuery={
                        props.invalidateAppointmentsQuery
                    }
                />
            )}
        </div>
    );
}

type ConfirmButtonProps = {
    isLoading: boolean;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
    hidden?: boolean;
};

export const ConfirmButton = forwardRef<HTMLButtonElement, ConfirmButtonProps>(
    ({ onClick, isLoading, hidden = false }, ref) => {
        const [lc] = useLanguageContext();

        if (hidden) {
            return <p>{lc.confirmBooking.finish_to_order}</p>;
        }

        return (
            <SuccessButton
                type="submit"
                id="confirm-booking-submit-booking"
                loading={{
                    isLoading,
                    loadingText: 'Loading...',
                }}
                {...{ onClick }}
                ref={ref}
            >
                {lc.confirmBooking.submit_booking}
            </SuccessButton>
        );
    }
);

ConfirmButton.displayName = 'ConfirmButton';
