import { WeightItem } from 'src/features/electric-car-playground/payload/payload-calculator/types/WeightItems';
export type AvailableWeightUnitsPerson = WeightItem & { categories: string[] };
export const WeightUnitPersonList: AvailableWeightUnitsPerson[] = [
    {
        name: 'Passasjer',
        kg: 95,
        amount: 0,
        categories: ['default'],
    },
    {
        name: 'Passasjer',
        kg: 75,
        amount: 0,
        categories: ['default'],
    },
    {
        name: 'Passasjer',
        kg: 45,
        amount: 0,
        categories: ['default'],
    },
];
