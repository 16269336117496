import { ChildThemeProvider } from 'theming';
import { InformationArticleSanity } from './SanityInformationArticle';

const Cookies = () => {
    return (
        <ChildThemeProvider theme="bilhold">
            <InformationArticleSanity articleSlug="cookies" />
        </ChildThemeProvider>
    );
};

export default Cookies;
