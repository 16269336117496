import { MockData } from '../data';

interface MileageResponse {
    vin: string;
    currentMileage: number;
    lastUpdatedMileage: string;
}

const getMileage = (
    vin: string,
    mockData: MockData
): MileageResponse | null => {
    const car = mockData.cars.find((car) => car.vin === vin);

    if (!car) {
        return null;
    }

    return {
        vin: 'TMBJC9NE2F0015998',
        currentMileage: 133542,
        lastUpdatedMileage: car.lastUpdatedMileage,
    };
};

export default getMileage;
