import React from 'react';
import { CenteredPageContent } from 'src/shared/CenteredPageContent';

export const PageLayout = ({
    children,
    showBackButton = false,
}: {
    children: React.ReactNode;
    showBackButton?: boolean;
}) => {
    return (
        <CenteredPageContent showBackButton={showBackButton}>
            {children}
        </CenteredPageContent>
    );
};
