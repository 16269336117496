import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import logout from 'src/utils/auth/logout';
import DeleteAccountErrorType from 'src/utils/enums/DeleteAccountErrorType';
import { useFetchersContext } from 'external-apis';

const useDeleteUser = () => {
    const [fetchers] = useFetchersContext();
    return fetchers.bilhold.fetcher.path('/v1/user').method('delete').create();
};

export const DeleteUser = () => {
    const deleteUser = useDeleteUser();

    return useMutation<unknown, AxiosError<ErrorResponseData>>({
        mutationFn: () => deleteUser({}),
        onSuccess: () => logout(),
    });
};

interface ErrorResponseData {
    errors?: Messages[];
    httpCode: number;
}

interface Messages {
    message: DeleteAccountErrorType;
}
