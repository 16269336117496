import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { defaultConfig, pages } from 'src/utils/pages';
import Page from 'src/utils/pageType';

const getCurrentPageFromPath = (path: string): Page =>
    pages
        .filter((p) => p.url)
        .find((p) => p.url.startsWith(path.split(/(\/[^/]+)/)[1])) ??
    defaultConfig;

const useCurrentPage = () => {
    const { pathname } = useLocation();

    return useMemo(() => {
        return getCurrentPageFromPath(pathname);
    }, [pathname]);
};

export default useCurrentPage;
