import { datadogRum } from '@datadog/browser-rum';
import {
    Headline,
    LeadText,
    Subtitle,
    TextContainer,
} from '@moller/design-system';
import { useEffect } from 'react';
import { isMobileApp } from 'src/utils/mobile';
import { styled } from 'styled-components';
import ImageSrc from './keys.webp';

const FlexContainer = styled.div<{ reverseOrder: boolean }>`
    display: flex;
    flex-direction: ${({ reverseOrder }) =>
        reverseOrder ? 'column-reverse' : 'column'};
    h2 {
        margin-top: var(--moller-spacing-s) !important;
    }
`;

export const UsedCarValuationContent = () => {
    useEffect(() => {
        datadogRum.addAction('User sees used car valuation article');
    }, []);

    return (
        <TextContainer>
            <FlexContainer reverseOrder={isMobileApp()}>
                {!isMobileApp() && <img src={ImageSrc} alt="Bruktbil" />}
                <Headline>Ønsker du å selge bilen din?</Headline>
            </FlexContainer>
            <LeadText>
                Det er enkelt, stressfritt og vi overtar hele ansvaret når du
                selger bilen til oss.
            </LeadText>
            <p>
                Fyll ut skjemaet, så tar en av våre hyggelige medarbeidere
                kontakt med deg for en uforpliktende verdivurdering.
            </p>
            <Subtitle>Fordeler med å selge til forhandler</Subtitle>
            <ul>
                <li>
                    Du slipper å ta bilder, lage annonse og tilby prøvekjøring.
                </li>
                <li>
                    Du slipper å bruke tid på service, reparasjoner, vask og
                    polering.
                </li>
                <li>
                    Du slipper å ta NAF-test eller annen teknisk sjekk av bilen.
                </li>
                <li>
                    Vi tar oss av alt det praktiske rundt papirarbeid
                    (omregistrering og kontrakt).
                </li>
                <li>
                    Er det heftelser eller pant og gjeld på bilen, tar vi
                    kontakt med banken din for deg, så du slipper å tenke på
                    det.
                </li>
                <li>Du får raskt oppgjør og penger på konto.</li>
                <li>
                    Vi står ansvarlig dersom det skulle dukke opp feil med bilen
                    etter at den er solgt.
                </li>
            </ul>
        </TextContainer>
    );
};
