import { AlertBanner, OverlineText } from '@moller/design-system';
import MessageThread from 'src/features/contact/types/MessageThread';
import lang from 'src/utils/lang';
import { styled } from 'styled-components';
import MessageList from './MessageList';

const StyledOverlineText = styled(OverlineText)`
    margin-top: var(--moller-spacing-l);
    margin-bottom: var(--moller-spacing-xs);
    font-weight: bold;
`;

interface MessagesProps {
    readonly messageThreads?: MessageThread[];
    readonly hasError: boolean;
}

export const MessagesChat = ({ messageThreads, hasError }: MessagesProps) => {
    return (
        <>
            <StyledOverlineText>Meldinger</StyledOverlineText>
            {!hasError && messageThreads?.length === 0 && (
                <AlertBanner
                    type={'info'}
                    heading={lang.noMessagesTitle}
                    message={lang.noMessagesDescription}
                />
            )}
            {hasError && (
                <AlertBanner
                    type={'error'}
                    heading={lang.somethingWentWrong}
                    message={lang.general_error_contact_support}
                />
            )}
            {!hasError && messageThreads && (
                <MessageList messages={messageThreads} />
            )}
        </>
    );
};
