import getCountryCode from 'src/utils/region/getCountryCode';

const createOtpCredentials = (
    phoneCountryCode: string,
    phoneNumber: string,
    otp: string
) => ({
    phoneNumber,
    phoneCountryCode,
    otp,
    countryCode: getCountryCode(),
});

export default createOtpCredentials;
