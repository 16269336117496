import { Column, LeadText } from '@moller/design-system';
import { LogoBrand } from '@moller/design-system/foundation/logos/Logo.js';
import { Divider } from '@mui/material';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import CustomWeightItem from 'src/features/electric-car-playground/payload/payload-calculator/components/CustomWeightItem';
import PayloadCalculatorHeader from 'src/features/electric-car-playground/payload/payload-calculator/components/PayloadCalculatorHeader';
import WeightItemsContent from 'src/features/electric-car-playground/payload/payload-calculator/components/WeightItemsContent';
import { WeightItem } from 'src/features/electric-car-playground/payload/payload-calculator/types/WeightItems';
import { WeightUnitPersonList } from 'src/features/electric-car-playground/payload/payload-calculator/utils/AvailableWeightUnits';
import { useCarInformation } from 'src/features/home/queries/useCarInformation';
import { PageLayout } from 'src/features/my-car/components/PageLayout';
import { UsefulContentInput } from 'src/features/my-car/emissions-calculator/UsefulContentInput';
import { themeOverride } from 'src/utils/dev-tools/devToolsUtils';
import { selectTheme } from 'src/utils/themes/selectTheme';
import { styled } from 'styled-components';
import { ChildThemeProvider } from 'theming';
import { TrailerInfo } from './components/TrailerInfo';

const CommonStyling = styled(Column)`
    border-radius: var(--moller-border-radius-large);
`;

const Container = styled(CommonStyling)`
    padding: var(--moller-spacing-s) 0;
`;

const StyledDivider = styled(Divider)`
    border-color: var(--moller-color-on-disabled) !important;
`;

export const PayloadCalculator = () => {
    const vin = useParams().vin;
    const { data: technicalDetails } = useCarInformation(vin);
    const [sum, setSum] = useState<number>(0);

    const [checkedItems, setCheckedItems] =
        useState<WeightItem[]>(WeightUnitPersonList);
    const isExistingItem = (newItem: WeightItem) => {
        return checkedItems?.some(
            (item) => item.name === newItem.name && item.kg === newItem.kg
        );
    };

    const theme = selectTheme(technicalDetails?.brand);
    const addItem = (item: WeightItem) => {
        if (isExistingItem(item)) {
            const newList = checkedItems.map((listItem) =>
                listItem.name === item.name && listItem.kg === item.kg
                    ? { ...listItem, amount: listItem?.amount + 1 }
                    : listItem
            );

            calculateSum(newList);
            setCheckedItems(newList);
        } else {
            checkedItems.push(item);
            calculateSum(checkedItems);
        }
    };
    const calculateSum = (checkedItems: WeightItem[]) => {
        let newSum = 0;
        checkedItems.map((item) => (newSum += item.kg * item.amount));

        setSum(newSum);
    };

    const decrementAmount = (itemToDecrement: WeightItem) => {
        const updatedItems = checkedItems?.map((item) => {
            if (
                item.kg == itemToDecrement.kg &&
                item.name == itemToDecrement.name
            ) {
                return {
                    ...item,
                    amount: Math.max(item.amount - 1),
                };
            }
            return item;
        });

        const newItems = updatedItems.filter(
            (p) => p.categories?.includes('default') || p.amount > 0
        );
        setCheckedItems(newItems);
        calculateSum(newItems);
    };
    const itemAmountInCheckedList = (item: WeightItem) => {
        let existingAmount = 0;

        checkedItems.map((listItem) => {
            item.name === listItem.name &&
                item.kg === listItem.kg &&
                (existingAmount = listItem.amount);
        });

        return existingAmount;
    };

    return (
        <ChildThemeProvider
            theme={theme?.toLowerCase() as LogoBrand}
            overrideTheme={themeOverride}
        >
            <PageLayout>
                <PayloadCalculatorHeader
                    technicalDetails={technicalDetails}
                    sum={sum}
                    items={checkedItems}
                />
                <StyledDivider />
                <Container>
                    <Container gap={'m'}>
                        <LeadText>Legg til vektenheter i bilen din</LeadText>
                        <>
                            <WeightItemsContent
                                addItem={addItem}
                                removeItem={decrementAmount}
                                clickedAmount={itemAmountInCheckedList}
                                checkedItems={checkedItems}
                            />
                            <CustomWeightItem addItem={addItem} />
                        </>
                    </Container>
                </Container>
                <StyledDivider />
                <Container>
                    <TrailerInfo
                        maxWeightWithBrakes={
                            technicalDetails?.maxWeightTrailerWithBrakes ?? 0
                        }
                        maxWeightWithoutBrakes={
                            technicalDetails?.maxWeightTrailerNoBrakes ?? 0
                        }
                    />
                </Container>
                <StyledDivider />
                <Container>
                    <UsefulContentInput
                        usefulText={'User finds payload-calc useful'}
                        notUsefulText={'User finds payload-calc not useful'}
                    />
                </Container>
            </PageLayout>
        </ChildThemeProvider>
    );
};
