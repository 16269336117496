import type { ComponentProps } from 'react';

type Props = ComponentProps<'svg'>;

interface TDFShirtProps extends Props {
    isElevationShirt?: boolean;
}
export const TDFShirt = ({ ...props }: TDFShirtProps): JSX.Element => {
    return (
        <svg
            width={props.width || 'auto'}
            height={props.height || 'auto'}
            viewBox="0 0 62 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            {props.isElevationShirt ? (
                <g opacity={props.opacity || '1'}>
                    <path
                        d="M49.2033 4.44865L36.5225 1.14062C36.5225 1.14062 33.7658 2.2433 30.4578 2.2433C27.1498 2.2433 23.8417 1.14062 23.8417 1.14062L11.161 4.44865L0.685547 22.6428L8.95561 27.6048L15.0203 19.8861L15.5717 48.5557H44.7926L45.3439 19.8861L51.4086 27.6048L59.6787 22.6428L49.2033 4.44865Z"
                        fill={props.fill}
                        stroke={props.stroke || 'white'}
                    />
                    <circle
                        cx="33.2146"
                        cy="27.0525"
                        r="1.65401"
                        fill="#C80102"
                    />
                    <circle
                        cx="23.2907"
                        cy="24.8493"
                        r="1.65401"
                        fill="#C80102"
                    />
                    <circle
                        cx="25.4958"
                        cy="32.5681"
                        r="1.65401"
                        fill="#C80102"
                    />
                    <circle
                        cx="22.1872"
                        cy="40.2868"
                        r="1.65401"
                        fill="#C80102"
                    />
                    <circle
                        cx="17.7771"
                        cy="31.4646"
                        r="1.65401"
                        fill="#C80102"
                    />
                    <circle
                        cx="17.7771"
                        cy="19.3337"
                        r="1.65401"
                        fill="#C80102"
                    />
                    <circle
                        cx="27.7009"
                        cy="17.1306"
                        r="1.65401"
                        fill="#C80102"
                    />
                    <circle
                        cx="36.5232"
                        cy="18.2321"
                        r="1.65401"
                        fill="#C80102"
                    />
                    <circle
                        cx="40.9333"
                        cy="25.9509"
                        r="1.65401"
                        fill="#C80102"
                    />
                    <circle
                        cx="34.3171"
                        cy="35.8747"
                        r="1.65401"
                        fill="#C80102"
                    />
                    <circle
                        cx="29.906"
                        cy="43.5935"
                        r="1.65401"
                        fill="#C80102"
                    />
                    <circle
                        cx="39.8308"
                        cy="44.697"
                        r="1.65401"
                        fill="#C80102"
                    />
                    <path
                        d="M44.4455 36.9971C44.4274 37.9104 44.4127 38.6508 44.4127 38.6508C43.3865 38.6305 42.5693 37.8736 42.5874 36.9603C42.6055 36.047 43.452 35.3231 44.4782 35.3434C44.4782 35.3434 44.4636 36.0838 44.4455 36.9971Z"
                        fill="#C80102"
                    />
                    <path
                        d="M23.2903 48.0056C22.3768 48.0056 21.6363 48.0056 21.6363 48.0056C21.6363 47.0921 22.3768 46.3516 23.2903 46.3516C24.2038 46.3516 24.9443 47.0921 24.9443 48.0056C24.9443 48.0056 24.2038 48.0056 23.2903 48.0056Z"
                        fill="#C80102"
                    />
                    <path
                        d="M16.0739 44.7417C16.0593 43.8284 16.0474 43.0879 16.0474 43.0879C17.022 43.0723 17.824 43.8 17.8387 44.7134C17.8533 45.6268 17.0751 46.3799 16.1005 46.3955C16.1005 46.3955 16.0886 45.6551 16.0739 44.7417Z"
                        fill="#C80102"
                    />
                    <path
                        d="M4.67194 16.8576C5.12278 16.0631 5.48825 15.419 5.48825 15.419C6.33871 15.9016 6.66266 16.9369 6.21182 17.7314C5.76099 18.5259 4.70608 18.7787 3.85562 18.2961C3.85562 18.2961 4.2211 17.652 4.67194 16.8576Z"
                        fill="#C80102"
                    />
                    <path
                        d="M51.3638 9.32942C51.8178 10.1221 52.1858 10.7647 52.1858 10.7647C51.4734 11.1727 50.5279 10.8608 50.0739 10.0681C49.62 9.27542 49.8295 8.30209 50.5418 7.89411C50.5418 7.89411 50.9099 8.53672 51.3638 9.32942Z"
                        fill="#C80102"
                    />
                    <path
                        d="M57.1384 137.514C56.3546 137.983 55.7191 138.363 55.7191 138.363C55.1652 137.437 55.3516 136.307 56.1354 135.838C56.9193 135.369 58.0037 135.739 58.5577 136.664C58.5577 136.664 57.9223 137.045 57.1384 137.514Z"
                        fill="#C80102"
                    />
                    <circle
                        cx="45.3435"
                        cy="14.9235"
                        r="1.65401"
                        fill="#C80102"
                    />
                    <circle
                        cx="34.3171"
                        cy="9.41183"
                        r="1.65401"
                        fill="#C80102"
                    />
                    <circle
                        cx="22.1872"
                        cy="10.5134"
                        r="1.65401"
                        fill="#C80102"
                    />
                    <path
                        d="M28.5347 2.72857C28.5347 2.72857 28.1849 3.92628 27.0989 4.11711C26.1991 4.27486 25.3731 3.85138 25.1798 2.74883C25.0196 1.83549 25.083 2.19707 26.8089 2.46295C27.8253 2.61954 28.5347 2.72857 28.5347 2.72857Z"
                        fill="#C80102"
                    />
                    <circle
                        cx="14.4685"
                        cy="6.10323"
                        r="1.65401"
                        fill="#C80102"
                    />
                    <circle
                        cx="12.2634"
                        cy="13.822"
                        r="1.65401"
                        fill="#C80102"
                    />
                    <circle
                        cx="8.95577"
                        cy="22.6423"
                        r="1.65401"
                        fill="#C80102"
                    />
                    <circle
                        cx="43.1384"
                        cy="6.10323"
                        r="1.65401"
                        fill="#C80102"
                    />
                    <circle
                        cx="50.8571"
                        cy="19.3337"
                        r="1.65401"
                        fill="#C80102"
                    />
                </g>
            ) : (
                <path
                    d="M50.4261 4.36996L37.5079 1C37.5079 1 34.6996 2.12332 31.3296 2.12332C27.9597 2.12332 24.5897 1 24.5897 1L11.6715 4.36996L1 22.9047L9.4249 27.9597L15.6032 20.0964L16.1648 49.3027H45.9328L46.4944 20.0964L52.6727 27.9597L61.0976 22.9047L50.4261 4.36996Z"
                    fill={props.fill}
                    stroke={props.stroke || 'white'}
                    opacity={props.opacity || '1'}
                />
            )}
        </svg>
    );
};
