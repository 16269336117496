import { parse } from 'query-string';
import { useLocation } from 'react-router-dom';
import { QUERY_PARAM_STORAGE } from 'src/constants';
import LocalStorage from 'src/utils/browser/LocalStorage';

export const useNamedQueryParams = (paramName: string): string[] => {
    const queryParams = useLocation().search.replace('?', '');
    const queryParamsList = parse(queryParams);
    let values = queryParamsList[paramName];
    if (!values) {
        const queryParams = LocalStorage.get(QUERY_PARAM_STORAGE);
        if (!queryParams) {
            return [];
        }
        values = parse(queryParams.replace('?', ''))?.[paramName];
        if (!values) {
            return [];
        }
    }
    return values instanceof Array ? values : [values];
};
