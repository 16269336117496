import { useQuery } from '@tanstack/react-query';
import CarVideos from 'src/types/CarVideos';
import get from 'src/utils/http/get';

interface CarVideoResponse {
    myCarMediaContentList: CarVideos[];
}
export const useCarVideos = (vin: string | undefined) => {
    return useQuery({
        queryKey: ['videos', vin],
        enabled: !!vin,
        queryFn: () =>
            get<CarVideoResponse>(`api/carMedia/vin/${vin}?video=true`).then(
                (res) => res.data
            ),
    });
};
