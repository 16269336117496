import { Column, PrimaryButton } from '@moller/design-system';
import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
} from '@mui/material';
import { TextSmallThin } from 'src/features/competition/textComponents/TDFText';
import { useClientLocation } from 'src/features/user-profile/my-preferred-dealer/utils/useClientLocation';
import { useDealers } from 'src/features/user-profile/my-preferred-dealer/utils/useDealers';
import { useNamedQueryParams } from 'src/utils/browser/useNamedQueryParams';

interface Props {
    disableButton: boolean;
    disableOrderButton: boolean;
    isUpdating: boolean;
    newPreferredDealerId: string | undefined;
    onOrder: () => void;
    onUpdate: (event: SelectChangeEvent) => void;
}

export const PickDealer = ({
    disableButton,
    disableOrderButton,
    isUpdating,
    newPreferredDealerId,
    onOrder,
    onUpdate,
}: Props) => {
    const location = void useClientLocation();
    const dealerGroup = useNamedQueryParams('dealerGroup')[0] || '';
    const { dealers } = useDealers(undefined, location, dealerGroup);
    const skodaDealer = dealers?.filter((dealer) =>
        dealer.salesCertifications?.includes('Skoda')
    );

    const optionsDealer = skodaDealer
        ?.map((dealer) => ({
            label: dealer.name,
            value: dealer.dealerNumber,
        }))
        .concat([
            {
                label: 'Vil ikke ha premie',
                value: '0',
            },
        ]);

    return (
        <Column
            gap={'xxs'}
            verticalAlign={'start'}
            horizontalAlign={'start'}
            style={{ width: '100%' }}
        >
            <TextSmallThin>
                Premien må hentes hos din foretrukne bilforhandler. Velg her:
            </TextSmallThin>
            <FormControl fullWidth>
                <InputLabel id="forhandler">Velg bilforhandler</InputLabel>
                <Select
                    disabled={disableButton}
                    id="preferredDealer"
                    label="Forhandler"
                    onChange={onUpdate}
                    value={newPreferredDealerId ? newPreferredDealerId : ''}
                >
                    {optionsDealer?.map((dealer) => (
                        <MenuItem key={dealer.value} value={dealer.value}>
                            {dealer.label}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            <PrimaryButton
                onClick={() => onOrder()}
                disabled={disableOrderButton}
                loading={{
                    isLoading: isUpdating,
                    loadingText: 'Laster...',
                }}
                style={{ alignSelf: 'center' }}
            >
                Lagre valg
            </PrimaryButton>
            <TextSmallThin style={{ alignSelf: 'center' }}>
                Du må lagre valget ditt innen 11.august 2024.
            </TextSmallThin>
        </Column>
    );
};
