import { AlertBanner, Headline } from '@moller/design-system';
import { QueryClientProvider } from '@tanstack/react-query';
import { Environment, FetchersProvider } from 'external-apis';
import { errorUrlEnhancerMiddleware } from 'external-apis/src/middleware/errorUrlEnhancerMiddleware';
import { FeatureToggleProvider, useToggle } from 'feature-toggle';
import { useEffect, useRef } from 'react';
import { dealerThemes, useThemeContext, useUpdateThemeOnMount } from 'theming';
import { LocalOverrideUrls } from '.';
import { authMiddleware } from './_api/middlewares/authMiddleware';
import { sessionAppenderMiddleware } from './_api/middlewares/sessionAppenderMiddleware';
import {
    SellVehicleCard,
    SellVehicleProvider,
} from './components/SellVehicleCard';
import { BilholdLayout } from './components/bilhold/BilholdLayout';
import { CancelButton } from './components/bilhold/CancelButton';
import { WarnOnExitDialogue } from './components/bilhold/WarnOnExitDialogue';
import {
    AuthProvider,
    AuthScheme,
    useAuthContext,
} from './lib/auth/authContext';
import { Config, ConfigProvider } from './lib/config/configContext';
import { getValidConfig } from './lib/config/validateConfig';
import {
    BookingExperimentProvider,
    useBookingExperimentActions,
    useBookingStepAction,
} from './lib/datadog/hooks';
import {
    LanguageIdentifier,
    LanguageProvider,
    useLanguageContext,
} from './lib/languages/languageContext';
import { getQueryClient } from './lib/query-client/config';
import { useFastTrack } from './lib/useFastTrack';
import { AdditionalServices } from './sections/additional-services/AdditionalServicesSection';
import { ChooseDealer } from './sections/choose-dealer/ChooseDealerSection';
import { ChooseTimeSlot } from './sections/choose-timeslot/ChooseTimeslotSection';
import { ChooseVehicle } from './sections/choose-vehicle/ChooseVehicleSection';
import { ConfirmBooking } from './sections/confirm-booking/ConfirmBookingSection';
import { ConfirmBookingDone } from './sections/confirm-booking/confirm-booking-done/ConfirmBookingDone';
import { StandardServices } from './sections/standard-services/StandardServicesSection';
import { TransportServices } from './sections/transport-services/TransportServicesSection';
import { useBookingState } from './useBookingState';

type WorkshopBookingProps = {
    authScheme: AuthScheme;
    environment: Environment;
    languageId: LanguageIdentifier;
    localOverrideUrls: LocalOverrideUrls;
    config: Config;
    invalidateAppointmentsQuery: () => void;
    setInterruptUpdateTimestamp: React.Dispatch<React.SetStateAction<number>>;
};

const bookingQueryClient = getQueryClient();

export function WorkshopBooking({
    environment,
    languageId,
    config,
    authScheme,
    localOverrideUrls,
    invalidateAppointmentsQuery,
    setInterruptUpdateTimestamp,
}: WorkshopBookingProps) {
    const validConfig = getValidConfig(config);
    const bookingViewRef = useRef(null);

    const userId = authScheme.user.partyId;

    // set theme to bilhold if no dealertheme is selected
    const { theme } = useThemeContext();
    const dealerTheme = dealerThemes.some((x) => x === theme)
        ? theme
        : 'bilhold';
    useUpdateThemeOnMount(dealerTheme);

    useEffect(() => {
        window.scroll(0, 0);
    });

    const fetcherConfigs = {
        port: {
            localEnvironmentOverride: localOverrideUrls.PORT_BASE_URI,
            middlewares: [
                authMiddleware(authScheme.callbacks),
                errorUrlEnhancerMiddleware,
                sessionAppenderMiddleware(),
            ],
        },
        carcare: {
            localEnvironmentOverride: localOverrideUrls.CAR_CARE_BASE_URI,
            middlewares: [
                authMiddleware(authScheme.callbacks),
                errorUrlEnhancerMiddleware,
            ],
        },
        bilhold: {
            localEnvironmentOverride: localOverrideUrls.BILHOLD_BASE_URI,
            middlewares: [
                authMiddleware(authScheme.callbacks),
                errorUrlEnhancerMiddleware,
                sessionAppenderMiddleware(),
            ],
        },
        damageappraisal: {
            localEnvironmentOverride: localOverrideUrls.DAMAGE_APPRAISAL_URI,
            middlewares: [
                authMiddleware(authScheme.callbacks),
                errorUrlEnhancerMiddleware,
                sessionAppenderMiddleware(),
            ],
        },
    };
    return (
        <FeatureToggleProvider
            env={environment}
            app={'booking'}
            context={{ userId }}
            ref={bookingViewRef}
        >
            <AuthProvider authScheme={authScheme}>
                <LanguageProvider languageId={languageId}>
                    <FetchersProvider
                        environment={environment}
                        configs={fetcherConfigs}
                    >
                        <QueryClientProvider client={bookingQueryClient}>
                            <BilholdLayout>
                                <ConfigProvider config={validConfig}>
                                    <SellVehicleProvider env={environment}>
                                        <BookingExperimentProvider>
                                            <div
                                                ref={bookingViewRef}
                                                style={{
                                                    display: 'contents',
                                                }}
                                            >
                                                <BookingView
                                                    invalidateAppointmentsQuery={
                                                        invalidateAppointmentsQuery
                                                    }
                                                    setInterruptUpdateTimestamp={
                                                        setInterruptUpdateTimestamp
                                                    }
                                                />
                                            </div>
                                        </BookingExperimentProvider>
                                    </SellVehicleProvider>
                                </ConfigProvider>
                            </BilholdLayout>
                        </QueryClientProvider>
                    </FetchersProvider>
                </LanguageProvider>
            </AuthProvider>
        </FeatureToggleProvider>
    );
}

type TopLevelWarningFlag =
    | {
          show: true;
          warning: {
              heading: string;
              message: string;
          };
      }
    | {
          show: false;
      };

interface BookingViewProps {
    invalidateAppointmentsQuery: () => void;
    setInterruptUpdateTimestamp: React.Dispatch<React.SetStateAction<number>>;
}

function BookingView({
    invalidateAppointmentsQuery,
    setInterruptUpdateTimestamp,
}: BookingViewProps) {
    useBookingExperimentActions();
    const fastTrackType = useFastTrack();
    let visitPageActionName: string;
    if (fastTrackType.fastTrack !== undefined) {
        visitPageActionName = `Visit fastTrack: ${fastTrackType.fastTrack}`;
    } else {
        visitPageActionName = 'Visit page';
    }

    const [lc] = useLanguageContext();
    const {
        additionalServices,
        chooseVehicle,
        standardServices,
        transportServices,
        chooseTimeSlot,
        chooseDealer,
        confirmBooking,
        isFastrack,
        isBookingRequest,
        setIsBookingRequest,
    } = useBookingState(setInterruptUpdateTimestamp);
    useBookingStepAction(visitPageActionName);
    const authContext = useAuthContext();

    const topLevelWarningToggle =
        useToggle<TopLevelWarningFlag>('top-level-warning');
    const topLevelWarning = topLevelWarningToggle?.variation.value;

    if (confirmBooking.props.viewMode === 'done') {
        return (
            <ConfirmBookingDone
                dependencies={confirmBooking.props.dependencies}
                isBookingRequest={isBookingRequest}
            />
        );
    }

    return (
        <>
            <Headline>{lc.header.title}</Headline>
            {topLevelWarningToggle?.isEnabled &&
                topLevelWarning &&
                topLevelWarning.show && (
                    <AlertBanner
                        type={'warning'}
                        heading={topLevelWarning.warning.heading}
                        message={topLevelWarning.warning.message}
                    />
                )}
            <CancelButton />
            <ChooseVehicle {...chooseVehicle.props} />
            <ChooseDealer {...chooseDealer.props} />
            <StandardServices
                {...standardServices.props}
                setIsBookingRequest={setIsBookingRequest}
            />
            <ChooseTimeSlot
                {...chooseTimeSlot.props}
                isBookingRequest={isBookingRequest}
            />
            <TransportServices
                {...transportServices.props}
                isFastTrack={isFastrack}
            />
            <AdditionalServices
                {...additionalServices.props}
                isFastTrack={isFastrack}
            />
            {isBookingRequest && (
                <AlertBanner
                    type={'info'}
                    message={lc.chooseDealer.booking_request_info}
                />
            )}
            <ConfirmBooking
                {...confirmBooking.props}
                invalidateAppointmentsQuery={invalidateAppointmentsQuery}
            />
            {chooseVehicle.result.viewMode === 'done' &&
                chooseDealer.result.viewMode === 'done' && (
                    <SellVehicleCard
                        id="card_in_booking"
                        regNumber={chooseVehicle.result.data.registrationNumber}
                        dealerNumber={chooseDealer.result.data.dealerNumber}
                        phone={authContext.user.phone}
                        scroll={additionalServices.result.viewMode === 'done'}
                    />
                )}
            <WarnOnExitDialogue
                dealerNumber={
                    chooseDealer.result.viewMode === 'done'
                        ? chooseDealer.result.data.dealerNumber
                        : undefined
                }
                shouldShow={
                    chooseVehicle.result.viewMode === 'done' &&
                    confirmBooking.result.viewMode !== 'done'
                }
            />
        </>
    );
}
