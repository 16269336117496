import { useEffect } from 'react';
import {
    setAppReviewLoginOverride,
    setEnvironmentOverride,
} from './dev-tools/devToolsUtils';

// This page is only used to redirect to the app for app review.
// On web, we redirect to the app.
// In the app, we change environment and reload the app.
const AppReviewLogin = () => {
    useEffect(() => {
        setEnvironmentOverride('staging');
        setAppReviewLoginOverride();
        window.location.href = '/';
    }, []);

    return null;
};

export default AppReviewLogin;
