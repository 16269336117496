export interface AdobeDataLayer {
    push(fn: (dl: AdobeDataLayer) => void): number;
    push<T extends object>(
        ...args: T extends () => unknown ? Array<never> : Array<T>
    ): number;
    getState(reference?: string): object;
    addEventListener(
        type: string,
        listener: EventListener,
        options?: AdobeEventOptions
    ): never;
    removeEventListener(type: string, listener?: EventListener): never;
}

type InclusiveAdobeEventData = AdobeEventData<AdobeEvent>;

enum Brand {
    AUDI = 'AUDI',
    VW = 'VW',
    SKODA = 'SKODA',
    NYTTE = 'NYTTE',
    SEAT = 'SEAT',
    CUPRA = 'CUPRA',
    UNKNOWN = 'UNKNOWN',
    OTHER = 'OTHER',
}

interface AdobeEventOptions {
    path?: string;
    scope?: string;
}

export interface LoginEventDataType {
    userID: string;
    action: string;
}

export interface FormCompleteEventDataType {
    formName: string;
    product: { productInfo: { productSKU: string; productName: string }[] }[];
}

export interface NewUserEventDataType {
    formName?: string;
    formId?: string;
    formData?: unknown;
    brand?: Brand;
    dealerNumber?: string;
    dealerName?: string;
}

export interface FormStepEventDataType {
    formName: string;
    formId?: string;
    stepName: string;
    stepNumber: number;
}

export interface DamageNumberEventDataType {
    filledOut: string;
}

export interface ProductPurchaseEventDataType {
    productID: string;
    productName: string;
    price: number;
}

export interface PageLoadEventDataType {
    pageID: string;
    pageName: string;
    referringURL: string;
    language: string;
    category: string;
    platform: 'web' | 'app';
}

type AdobeEvent =
    | 'login'
    | 'formCompleted'
    | 'formStep'
    | 'damageNumber'
    | 'purchase'
    | 'clickProduct'
    | 'pageLoad'
    | 'newUserEvent';
type AdobeEventData<T extends AdobeEvent> = T extends 'login'
    ? LoginEventDataType
    : T extends 'formCompleted'
    ? FormCompleteEventDataType
    : T extends 'formStep'
    ? FormStepEventDataType
    : T extends 'damageNumber'
    ? DamageNumberEventDataType
    : T extends 'purchase'
    ? ProductPurchaseEventDataType
    : T extends 'clickProduct'
    ? ProductPurchaseEventDataType
    : T extends 'pageLoad'
    ? PageLoadEventDataType
    : T extends 'newUserEvent'
    ? NewUserEventDataType
    : '';

declare global {
    interface Window {
        dataLayer: AdobeDataLayer;
        digitalData: AdobeDataLayer;
    }
}

const eventDataForEventType = (
    event: AdobeEvent,
    eventData: InclusiveAdobeEventData
) => {
    switch (event) {
        case 'login':
            return loginEventData(eventData as LoginEventDataType);
        case 'formCompleted':
            return formCompletedEventData(
                eventData as FormCompleteEventDataType
            );
        case 'newUserEvent':
            return newUserEventData(eventData as NewUserEventDataType);
        case 'formStep':
            return formStepEventData(eventData as FormStepEventDataType);
        case 'damageNumber':
            return damageNumberEventData(
                eventData as DamageNumberEventDataType
            );
        case 'purchase':
            return productPurchaseEventData(
                eventData as ProductPurchaseEventDataType
            );
        case 'clickProduct':
            return productPurchaseEventData(
                eventData as ProductPurchaseEventDataType
            );
        case 'pageLoad':
            return pageLoadEventData(eventData as PageLoadEventDataType);
        default:
    }
    return '';
};

export const adobeEvent = {
    push: function <T extends AdobeEvent>(
        event: T,
        data: AdobeEventData<T>,
        platform: string
    ) {
        pushEvent<T>(event, data, platform);
    },
};

function pushEvent<T extends AdobeEvent>(
    event: T,
    data: AdobeEventData<T>,
    platform: string
) {
    // Create empty datalayer object if none exists
    const digitalData = window.digitalData || [];
    const subject = eventDataForEventType(event, data);

    digitalData.push({
        event,
        ...subject,
        platform: {
            platformValue: platform,
        },
    });
}

const loginEventData = (eventData: LoginEventDataType) => ({
    user: { userID: eventData.userID },
    interaction: { action: eventData.action },
});

const formCompletedEventData = (eventData: FormCompleteEventDataType) => ({
    form: {
        formName: eventData.formName,
        product: eventData.product,
    },
});

const newUserEventData = (eventData: NewUserEventDataType) => ({
    form: {
        formName: eventData.formName,
        time: Date.now(),
        formId: eventData.formId,
        formData: eventData.formData,
    },
    brand: eventData.brand,
    dealer: {
        dealerNumber: eventData.dealerNumber,
        dealerName: eventData.dealerName,
    },
});

const formStepEventData = (eventData: FormStepEventDataType) => ({
    form: {
        formName: eventData.formName,
        stepName: eventData.stepName,
        stepNumber: eventData.stepNumber,
        formId: eventData.formId,
        time: Date.now(),
    },
});

const damageNumberEventData = (eventData: DamageNumberEventDataType) => ({
    interaction: { filledOut: eventData.filledOut },
});

const productPurchaseEventData = (eventData: ProductPurchaseEventDataType) => ({
    product: {
        productInfo: [
            {
                productInfo: eventData.productID,
                productName: eventData.productName,
                price: eventData.price,
            },
        ],
    },
});

const pageLoadEventData = (eventData: PageLoadEventDataType) => ({
    page: {
        pageID: eventData.pageID,
        pageName: eventData.pageName,
        referringURL: eventData.referringURL,
        language: eventData.language,
        category: eventData.category,
        platform: eventData.platform,
    },
});
