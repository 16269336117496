import {
    CheckboxCard,
    Description,
    FormProvider,
    InputHelperText,
    useInputHelperText,
} from '@moller/design-system';
import { QueryObserverSuccessResult } from '@tanstack/react-query';
import { ServiceViewModel } from 'external-apis/src/types/port';
import { useFlag } from 'feature-toggle';
import { pipe } from 'fp-ts/lib/function';
import { Services, useGetRecommendedServices } from '../../_api/http/services';
import { BilholdNextButton } from '../../components/bilhold/BilholdNextButton';
import { BilholdInnerViewLayout } from '../../components/bilhold/BilholdView';
import { useLanguageContext } from '../../lib/languages/languageContext';
import { SetIsBookingRequest } from '../../useBookingState';
import { SectionState } from '../sectionUtils';
import { Price, parseServicePrices } from '../shared/prices/Price';
import { ServiceDescription } from '../shared/services/ServiceDescription';
import { toSelectedService } from '../shared/toSelectedService';
import { AdditionalServiceInfo } from './AdditionalServiceInfo';
import {
    GlassDamage,
    removeGlassDamageServices,
} from './features/glassDamage/GlassDamage';
import {
    RecommendedServicesContainer,
    removeRecommendedServices,
} from './features/recommendedServices/RecommendedServicesContainer';
import {
    TireChange,
    removeTireServices,
} from './features/tireChange/TireChange';
import { useStandardServicesForm } from './useSectionForm';
import { standardServicesHelperTextId } from './useStandardServiceListValidity';
import { StandardServicesData } from './useStandardServices';

type StandardServicesEditProps = {
    servicesForm: ReturnType<typeof useStandardServicesForm>;
    services: ServiceViewModel[];
    setSectionState: (x: SectionState<StandardServicesData>) => void;
    recommendedServices: ReturnType<typeof useGetRecommendedServices>;
    vin: string;
    dealerNumber: string;
    servicesResponse: QueryObserverSuccessResult<Services>;
    setIsBookingRequest: (x: SetIsBookingRequest) => void;
};

export function StandardServicesEdit({
    servicesForm,
    services,
    recommendedServices,
    setIsBookingRequest,
    setSectionState,
    dealerNumber,
    vin,
}: StandardServicesEditProps) {
    const selectedIds = servicesForm.state.raw.selectedIds;
    const mileage = servicesForm.state.raw.recommendedService.mileage;
    const setSelectedIds = servicesForm.setValue('selectedIds');
    const isEmailRequest = useFlag('use-booking-request-email');
    const [lc] = useLanguageContext();
    const nonFeatureServices = pipe(
        services,
        removeTireServices,
        removeRecommendedServices,
        removeGlassDamageServices
    ).sort(diagnosisLast);
    const validity = servicesForm.inputProps('selectedIds').validity;
    const { helperTextProps, inputAriaProps } = useInputHelperText<{
        errorMsgRequired: true;
    }>({
        id: standardServicesHelperTextId,
        validity,
        reserveSpaceForMessage: false,
    });

    return (
        <FormProvider
            id={'standardserviceslist-formprovider'}
            form={servicesForm}
            hideNecessityText={true}
            onSubmit={(e) => {
                const allServices = [
                    ...removeRecommendedServices(services),
                    ...(recommendedServices.data || []),
                ];
                const selectedServices = allServices
                    .filter((x) => e.selectedIds.includes(x.id))
                    .filter((x) =>
                        x.dealerSpecificInformation.some(
                            (x) => x.dealerNumber === dealerNumber
                        )
                    )
                    .map((x) => toSelectedService(x, e.additionalInfo));
                setIsBookingRequest({
                    services: selectedServices,
                    isEmailRequest,
                });
                setSectionState({
                    viewMode: 'done',
                    data: {
                        selectedServices,
                        mileage,
                        fetchedData: {
                            availableRecommendedServices:
                                recommendedServices.data ?? [],
                        },
                    },
                });
            }}
        >
            <BilholdInnerViewLayout id={'standardserviceslist-selectedIds'}>
                <RecommendedServicesContainer
                    serviceForm={servicesForm}
                    services={services}
                    vin={vin}
                    recommendedServices={recommendedServices}
                    dealerNumber={dealerNumber}
                />
                <TireChange
                    serviceForm={servicesForm}
                    services={services}
                    dealerNumber={dealerNumber}
                />
                <GlassDamage
                    serviceForm={servicesForm}
                    services={services}
                    dealerNumber={dealerNumber}
                />
                {nonFeatureServices.map((x) => (
                    <CheckboxCard
                        key={x.id}
                        density={'compact'}
                        validity={validity?.type && { type: validity.type }}
                        checkboxProps={inputAriaProps}
                        onChange={() =>
                            selectServiceId({
                                id: x.id,
                                selectedIds,
                                setSelectedIds,
                            })
                        }
                        description={
                            <ServiceDescription
                                description={x.description}
                                selected={selectedIds.includes(x.id)}
                            >
                                <Price
                                    values={parseServicePrices(x, dealerNumber)}
                                />
                                <AdditionalServiceInfo
                                    serviceForm={servicesForm}
                                    service={x}
                                />
                            </ServiceDescription>
                        }
                        value={selectedIds.includes(x.id)}
                        label={x.name}
                    />
                ))}
                <InputHelperText {...helperTextProps} />
                <Description>{lc.prices.disclaimer_price}</Description>
                <BilholdNextButton />
            </BilholdInnerViewLayout>
        </FormProvider>
    );
}

function diagnosisLast(a: ServiceViewModel, b: ServiceViewModel) {
    if (a.category === 'Diagnosis' && b.category === 'Diagnosis') return 0;
    if (a.category === 'Diagnosis') return 1;
    return -1;
}

type SelectServiceId = {
    id: string;
    selectedIds: string[];
    setSelectedIds: (x: string[]) => void;
};

function selectServiceId({ id, selectedIds, setSelectedIds }: SelectServiceId) {
    if (selectedIds.includes(id)) {
        setSelectedIds(selectedIds.filter((x) => x !== id));
    } else {
        setSelectedIds([...selectedIds, id]);
    }
}

if (import.meta.vitest) {
    const { it, expect } = import.meta.vitest;
    let selectedIds = ['1'];
    const fakeSelectServiceIds = (x: string[]) => {
        selectedIds = x;
    };
    it('selectServiceId - select service with given id', () => {
        selectServiceId({
            id: '2',
            selectedIds,
            setSelectedIds: fakeSelectServiceIds,
        });
        expect(selectedIds).toEqual(['1', '2']);
    });
    it('selectServiceId - unselect service with given id', () => {
        selectServiceId({
            id: '1',
            selectedIds,
            setSelectedIds: fakeSelectServiceIds,
        });
        expect(selectedIds).toEqual(['2']);
    });
}
