import {
    ActionText,
    Column,
    Icon,
    Link,
    PrimaryButton,
    Row,
    Subtitle,
} from '@moller/design-system';
import { isMobileApp } from 'src/utils/mobile';
import { styled } from 'styled-components';

interface Props {
    maxWeightWithoutBrakes: number;
    maxWeightWithBrakes: number;
}

const TrailerContainer = styled(Row)`
    align-items: center;
    font-weight: 500;
    gap: var(--moller-spacing-xxs);
    margin-left: calc(var(--moller-spacing-xxs) * -1);
`;

const StyledRow = styled(Row)`
    width: 100%;
    justify-content: space-between;
`;

const StyledPrimaryButton = styled(PrimaryButton)`
    @media only screen and (max-width: 600px) {
        width: 100%;
    }
`;
const StyledLink = styled(Link)`
    text-decoration: none;
`;
export const TrailerInfo = ({
    maxWeightWithoutBrakes,
    maxWeightWithBrakes,
}: Props) => {
    const carCanHaveTrailer =
        maxWeightWithoutBrakes > 0 || maxWeightWithBrakes > 0;

    return (
        <Column gap={'s'}>
            <ActionText>Tilhenger</ActionText>
            {carCanHaveTrailer ? (
                <Column gap={'xl'}>
                    <Column gap={'xs'}>
                        <TrailerContainer>
                            <Icon
                                size={isMobileApp() ? 'm' : 'xl'}
                                icon="success"
                            />
                            <Subtitle>Din bil kan trekke tilhenger</Subtitle>
                        </TrailerContainer>
                        <Column gap={'m'}>
                            <StyledRow gap={'s'}>
                                <p>Max totalvekt henger u/brems</p>
                                <p>{maxWeightWithoutBrakes} kg</p>
                            </StyledRow>
                            <StyledRow gap={'s'}>
                                <p>Max totalvekt henger m/brems</p>
                                <p>{maxWeightWithBrakes} kg</p>
                            </StyledRow>
                        </Column>
                    </Column>
                    <Column gap={'xs'}>
                        <ActionText>
                            Hvor mye kan du laste på hengeren?
                        </ActionText>
                        <p>Prøv Statens vegvesens tilhengerkalkulator</p>
                        <StyledLink
                            target="_blank"
                            href={
                                'https://www.vegvesen.no/kjoretoy/Eie+og+vedlikeholde/tilhengerkalkulator'
                            }
                        >
                            <StyledPrimaryButton>
                                Tilhengerkalkulator <Icon icon="external" />
                            </StyledPrimaryButton>
                        </StyledLink>
                    </Column>
                </Column>
            ) : (
                <TrailerContainer>
                    <Icon size={isMobileApp() ? 'm' : 'xl'} icon="error" />
                    <Subtitle>Din bil kan ikke trekke tilhenger</Subtitle>
                </TrailerContainer>
            )}
        </Column>
    );
};
