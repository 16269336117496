import {
    breakpointMobile,
    Column,
    DisplayText,
    GhostButton,
    LeadText,
    PrimaryButton,
    Row,
    TextContainer,
} from '@moller/design-system';
import { useNavigate } from 'react-router-dom';
import { PATH_APP } from 'src/routing/routes';
import loginRedirect from 'src/utils/auth/loginRedirect';
import lang from 'src/utils/lang';
import { styled } from 'styled-components';
import heroimage from '../images/hero.jpg';
import qrcode from '../images/qrcode-frontpage.svg';

const DownloadContainer = styled.section`
    display: flex;
    width: 100%;
    height: 100%;
    flex-wrap: wrap-reverse;
    max-width: 1700px;

    @media screen and (max-width: 640px) {
        flex-direction: column-reverse;
        flex-wrap: nowrap;
    }
`;

const TextBox = styled.div`
    display: flex;
    width: 50%;
    height: 100%;
    align-self: center;
    box-sizing: border-box;
    justify-content: center;
    @media screen and (max-width: 640px) {
        width: 100%;
    }
    @media screen and (min-width: 1700px) {
        padding: 150px;
        justify-content: flex-start;
    }
`;
const InformationContainer = styled(Row)`
    flex-wrap: wrap;
    word-break: break-word;
    height: 100%;
    max-width: 500px;
    align-items: center;
    gap: var(--moller-spacing-xl);
    padding: var(--moller-spacing-m) var(--moller-spacing-m) 0;
    @media screen and (min-width: 1700px) {
        padding: 0px;
    }
`;

const ButtonGroup = styled.div`
    width: 100%;
    padding-bottom: var(--moller-spacing-l);
`;
const DownloadButton = styled.div`
    padding-top: var(--moller-spacing-s);
    @media screen and (min-width: ${breakpointMobile}) {
        display: none;
        padding: 0;
    }
`;

const QrContainer = styled(Column)`
    @media screen and (max-width: ${breakpointMobile}) {
        display: none;
    }
`;

const QrCodeImage = styled.img`
    width: 100%;
    max-width: 200px;
    border-radius: var(--moller-border-radius-large);
    border: var(--moller-stroke-x-small) solid var(--moller-palette-black);
    margin-top: var(--moller-spacing-m);
`;

const ImageContainer = styled.div`
    display: flex;
    align-self: flex-end;
    width: 50%;
    height: calc(100vh - 87px);
    @media screen and (max-width: 640px) {
        width: 100%;
        height: 250px;
        padding-bottom: var(--moller-spacing-m);
    }
`;

const HeroImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 20% 50%;
`;

export const Hero = () => {
    const navigate = useNavigate();
    return (
        <DownloadContainer>
            <TextBox>
                <InformationContainer>
                    <DisplayText>{lang.beginTitle}</DisplayText>
                    <LeadText>{lang.beginDescription}</LeadText>
                    <ButtonGroup>
                        <PrimaryButton onClick={() => loginRedirect()}>
                            {lang.book_service_appointment}
                        </PrimaryButton>
                        <DownloadButton>
                            <GhostButton onClick={() => navigate(PATH_APP)}>
                                {lang.downloadApp}
                            </GhostButton>
                        </DownloadButton>
                    </ButtonGroup>
                    <QrContainer>
                        <TextContainer>
                            <p>Få appen direkte på telefonen din:</p>
                        </TextContainer>
                        <QrCodeImage src={qrcode} alt={lang.appStore} />
                    </QrContainer>
                </InformationContainer>
            </TextBox>
            <ImageContainer>
                <HeroImage src={heroimage} alt="" />
            </ImageContainer>
        </DownloadContainer>
    );
};
