import {
    Column,
    Headline,
    Icon,
    Row,
    breakpointDesktop,
    breakpointTablet,
} from '@moller/design-system';
import { Link } from 'react-router-dom';
import { PATH_ADD_CAR } from 'src/routing/routes';
import lang from 'src/utils/lang';
import { styled } from 'styled-components';
import { CarLinkCard, Container } from '../HomeStyledComponents';

const StyledColumn = styled(Column)`
    padding: var(--moller-spacing-s);
    @media screen and (min-width: ${breakpointDesktop}) {
        gap: var(--moller-spacing-xl);
    }
`;

const Desc = styled.div`
    text-align: start;
    margin-top: var(--moller-spacing-base);
    font-size: var(--moller-type-large-description-font-size);
    line-height: var(--moller-type-large-description-line-height);
    @media screen and (min-width: ${breakpointTablet}) {
        font-size: var(--moller-type-large-description-font-size);
        margin-bottom: var(--moller-spacing-s);
    }
`;

export const AddCar = () => {
    return (
        <Container>
            <CarLinkCard forwardedAs={Link} to={PATH_ADD_CAR}>
                <StyledColumn>
                    <Row gap="xxs" verticalAlign="center">
                        <Headline>{lang.addCar}</Headline>
                        <Icon icon="arrow_forward" />
                    </Row>
                    <Desc>{lang.addCarDescription}</Desc>
                </StyledColumn>
            </CarLinkCard>
        </Container>
    );
};
