import {
    AlertBanner,
    Column,
    Modal,
    SecondaryButton,
    TextContainer,
} from '@moller/design-system';
import { useContext, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { AuthContext } from 'src/features/competition/providers/AuthProvider';
import {
    TDFTextMedium,
    TDSHeadline,
    TDSSubtitle,
} from 'src/features/competition/textComponents/TDFText';
import { useDeauthorizeStrava } from 'src/features/competition/utils/useDeauthorizeStrava';
import { useUnsubscribeEmail } from 'src/features/competition/utils/useUnsubscribeEmail';
import {
    PATH_COMPETITION_ABOUT,
    PATH_COMPETITION_PRIVACY_STATEMENT,
    PATH_COMPETITION_TERMS,
    PATH_PRIVACY,
} from 'src/routing/routes';
import BackButton from 'src/shared/BackButton';
import FeedbackPrompt from 'src/shared/feedback-card/FeedbackPrompt';
import { styled } from 'styled-components';
import { CompetitionPageWrapper } from '../components/CompetitionPageWrapper';
import { ReconnectToStravaModal } from '../components/ReconnectToStravaModal';

const InlineLink = styled(RouterLink)`
    color: white;
`;

const ListTDT = styled.ul`
    list-style-type: '>';
    padding-left: var(--moller-spacing-xxs);
    font-size: 1rem;
    font-weight: 400;
    margin: 0;
`;
const ListItemTDT = styled.li`
    padding-inline-start: 1rem;
`;
const TDTMediumBold = styled(TDFTextMedium)`
    font-weight: 700;
`;

function Page() {
    const {
        hasStravaAuthenticated,
        hasCompetitionUser,
        isStravaAuthLoading,
        isHasCompetitionUserLoading,
    } = useContext(AuthContext);
    const isAuthLoading = isStravaAuthLoading && isHasCompetitionUserLoading;

    const [isDeauthorizModalOpen, setIsDeauthorizeModalOpen] = useState(false);
    const [isReconnectToStravaModalOpen, setIsReconnectToStravaModalOpen] =
        useState(false);

    const navigate = useNavigate();
    const deauthorizeStravaSuccess = () => {
        setIsDeauthorizeModalOpen(false);
        navigate(PATH_COMPETITION_ABOUT);
    };
    const {
        mutate: deauthorizeStrava,
        isLoading: isLoadingDeauthorize,
        isError: isErrorDeauthorize,
    } = useDeauthorizeStrava(deauthorizeStravaSuccess);

    const onDeauthorize = () => {
        deauthorizeStrava();
    };

    const [isUpdateEmailOpen, setIsUpdateEmailOpen] = useState(false);

    const unsubscribeEmailSuccess = () => {
        setIsUpdateEmailOpen(false);
    };
    const {
        mutate: updateEmail,
        isLoading: isUpdatingEmail,
        isError: isErrorUpdatingEmail,
    } = useUnsubscribeEmail(unsubscribeEmailSuccess);

    const onUnsubscribe = () => {
        updateEmail();
    };
    return (
        <Column gap={'xl'}>
            <BackButton />
            <TextContainer>
                <TDSHeadline>
                    Betingelser for å delta i konkurransen
                </TDSHeadline>
                <ListTDT>
                    <ListItemTDT>
                        Aldersgrensen for konkurransen er 13 år.
                    </ListItemTDT>
                    <ListItemTDT>
                        Deltakere med relasjon til ansatte i Harald A Møller AS,
                        Škoda-forhandlere kan delta i konkurransen, men kan ikke
                        vinne hovedpremie.
                    </ListItemTDT>
                    <ListItemTDT>
                        Kontaktinformasjonen fra Bilhold benyttes til å
                        identifisere og kommunisere med deltakere. Deltakeren er
                        selv ansvarlig for at kontaktinformasjonen i Bilhold er
                        korrekt.
                    </ListItemTDT>
                    <ListItemTDT>
                        Deltakeren må samtykke til at arrangøren kan bruke
                        fornavn og alder på innsender i videre markedsføring.
                    </ListItemTDT>
                    <ListItemTDT>
                        Deltakere må godta konkurransens{' '}
                        <InlineLink to={PATH_COMPETITION_PRIVACY_STATEMENT}>
                            personvernerklæring
                        </InlineLink>
                    </ListItemTDT>
                    <ListItemTDT>
                        Deltakelse i konkurransen er gratis.
                    </ListItemTDT>
                </ListTDT>
            </TextContainer>
            <TextContainer>
                <TDSSubtitle>Konkurranseregler</TDSSubtitle>
                <TDFTextMedium>
                    Deltakerne kobler sin Strava til Bilhold-appen, hvor
                    konkurransen foregår. Man må være innlogget med sin egen
                    bruker i begge appene. I Bilhold-appen står kriteriene for å
                    fullføre alle etappene, klare de ulike trøyene og få
                    oppstartspremien. Man kan sykle hvor man vil så lenge man
                    tracker syklingen sin i Strava og har denne koblet opp til
                    konkurransen i Bilhold-appen.
                </TDFTextMedium>
                <TDFTextMedium>
                    Vi oppfordrer alle til å sykle på vanlig sykkel, men vi
                    godtar også sykling på elsykkel. Godkjente typer
                    sykkelregistrering i Strava er “ride”, “mountain bike ride”
                    og “gravel ride”. Det er ikke lov å legge inn “manual
                    activity”. Syklingen må være tracket med Strava. Spinning,
                    “virtual ride” og Swift er ikke lov.
                </TDFTextMedium>
                <TDFTextMedium>
                    Det er ikke nødvendig med offentlig Strava-profil, så lenge
                    man samtykker til at data fra private sykkelturer hentes
                    inn. Da teller også sykkeløkter i Strava som man har gjort
                    private.
                </TDFTextMedium>
                <TDFTextMedium>
                    Melder man seg på etter at konkurransen har startet hentes
                    all registrert sykling fra Strava fra konkurranseoppstart,
                    slik at man får uttelling for også disse sykkeløktene i
                    konkurransen, fortrinnsvis i lodd. Avhengig av hvor sent man
                    melder seg på, vil sjansen for å vinne oppstartspremier og
                    premier for trøyer reduseres da det er et gitt antall
                    førstemann til mølla som blir premiert.
                </TDFTextMedium>
                <TDFTextMedium>
                    Melder man seg ut av konkurransen eller kobler Strava fra
                    Bilhold før vinneren av hovedpremien er trukket mister man
                    vinnermulighetene sine.
                </TDFTextMedium>
            </TextContainer>
            <TextContainer>
                <TDSSubtitle>Premier</TDSSubtitle>
                <TDFTextMedium>
                    Vinneren av hovedpremien blir kontaktet på e-post. Vinner
                    man en av de andre premiene får man beskjed om dette i
                    Bilhold-appen. Under “Premier” nesten øverst på
                    konkurransesiden velger man hvilken bilforhandler man vil
                    hente premien sin hos. Hvis premiene ikke er hentet innen
                    oppgitte tidsfrister mister man muligheten til å få sin
                    premie.
                </TDFTextMedium>
                <TDFTextMedium>
                    Vinner du <b>hovedpremien</b> kan du velge mellom to
                    premier:
                </TDFTextMedium>
                <ListTDT>
                    <ListItemTDT>
                        Låne en Škoda i to valgfrie uker (må benyttes i løpet av
                        2024)
                    </ListItemTDT>
                    <ListItemTDT>Et taktelt til bil fra Thule</ListItemTDT>
                </ListTDT>
                <TDTMediumBold>Oppstartspremie</TDTMediumBold>
                <TDFTextMedium>
                    De første 1000 deltakerne som fullfører første etappe før
                    24. juni vinner oppstartspremie. Man kan da velge mellom en
                    Škoda sykkelflaske eller en Škoda sykkelcaps. Hvis en
                    deltaker ikke ønsker premien sin ber vi om at man velger
                    “ønsker ikke premie” i nedtrekksmenyen for valg av
                    forhandler på “Premier”-siden i appen. Oppstartspremien kan
                    hentes hos valgt Škoda-forhandler i perioden 01.07.2024 -
                    21.07.2024.
                </TDFTextMedium>
                <TDTMediumBold>Klatretrøye</TDTMediumBold>
                <TDFTextMedium>
                    De første 250 deltakerne som sykler 2469 høydemetre i løpet
                    av konkurranseperioden vinner et Škoda piknikteppe.
                </TDFTextMedium>
                <TDTMediumBold>Grønn trøye</TDTMediumBold>
                <TDFTextMedium>
                    De første 250 deltakerne som registrerer sykling 7 dager på
                    rad vinner en Škoda sportssekk.
                </TDFTextMedium>
                <TDTMediumBold>Gul trøye</TDTMediumBold>
                <TDFTextMedium>
                    De første 250 deltakerne som fullfører alle etappene i
                    touren vinner en Škoda sovepose.
                </TDFTextMedium>
                <TDFTextMedium>
                    En deltaker kan maksimalt vinne én oppstartspremie, én
                    premie for hver oppnådde trøye og hovedpremien.
                    Trøyepremiene kan hentes hos valgt Škoda-forhandler i
                    perioden 26.08.2024 - 15.09.2024. Du må ha valgt hvor du vil
                    hente trøyepremien innen 11.08.2024.
                </TDFTextMedium>
            </TextContainer>
            <TextContainer>
                <TDSSubtitle>Kåring av vinnere</TDSSubtitle>
                <ListTDT>
                    <ListItemTDT>
                        Representanter fra Harald A. Møller AS vil trekke én
                        vinner av hovedpremien ved loddtrekning.
                    </ListItemTDT>
                    <ListItemTDT>
                        Trekningen skjer 09.08.2024 og all sykling må være
                        synkronisert til Strava innen denne datoen for at den
                        skal telle i konkurransen. Vinneren blir kontaktet på
                        mail.
                    </ListItemTDT>
                    <ListItemTDT>
                        Vinner man trøyepremier eller oppstartspremie vil du
                        kunne se dette i Bilhold-appen. Se også under “Premier”
                        i appen om du har vunnet.
                    </ListItemTDT>
                </ListTDT>
            </TextContainer>
            <TextContainer>
                <TDSSubtitle>Konkurranseperiode</TDSSubtitle>
                <TDFTextMedium>
                    Konkurranseperioden er fra 10.06.2024 og varer til og med
                    21.07.2024. Dette gir en konkurranseperiode på 6 hele uker,
                    eller 42 dager. I snitt har man da to dager på å fullføre
                    hver av de 21 etappene i touren.
                </TDFTextMedium>
            </TextContainer>
            <TextContainer>
                <TDSSubtitle>Publisering av vinner</TDSSubtitle>
                <TDFTextMedium>
                    Vinnere blir publisert på skoda.no og i Bilhold.
                </TDFTextMedium>
            </TextContainer>
            <TextContainer>
                <TDSSubtitle>Juks</TDSSubtitle>
                <TDFTextMedium>
                    Dersom arrangøren mistenker at en deltaker jukser, eller som
                    følge av juks har vunnet en premie vil vedkommende bli
                    ekskludert fra konkurransen og miste eventuelt tildelt
                    premie. For å validere at det ikke har blitt jukset vil vi
                    forbeholde oss retten til å se Stravaprofilen til enkelte
                    premievinnere.
                </TDFTextMedium>
            </TextContainer>
            <TextContainer>
                <TDSSubtitle>Avmelding</TDSSubtitle>
                <TDFTextMedium>
                    Deltakeren kan når som helst melde seg av konkurransen ved å
                    frakoble Strava fra Bilhold. All personlig data i
                    forbindelse med konkurransen vil da bli slettet. Deltakeren
                    vil miste muligheten til å få premier.
                </TDFTextMedium>

                {!isAuthLoading && hasCompetitionUser && (
                    <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                        {isErrorDeauthorize && (
                            <AlertBanner
                                type="error"
                                message={
                                    'Det oppstod en feil da vi prøvde å lagre valget ditt. Prøv igjen senere.'
                                }
                            />
                        )}
                        <SecondaryButton
                            style={{ marginTop: '1rem' }}
                            onClick={() => {
                                hasStravaAuthenticated
                                    ? setIsDeauthorizeModalOpen(true)
                                    : setIsReconnectToStravaModalOpen(true);
                            }}
                            disabled={isLoadingDeauthorize}
                        >
                            Meld av konkurransen
                        </SecondaryButton>
                    </div>
                )}
            </TextContainer>
            <ReconnectToStravaModal
                isOpen={isReconnectToStravaModalOpen}
                handleClose={() => setIsReconnectToStravaModalOpen(false)}
                returnPath={PATH_COMPETITION_TERMS}
                descriptionText={`Koblingen mellom Bilhold og Strava har blitt brutt. 
                
                For å melde deg av konkurransen må Strava først være koblet til Bilhold. Koble til på nytt med knappen under.`}
            />
            <Modal
                isOpen={isDeauthorizModalOpen}
                handleClose={() => {
                    setIsDeauthorizeModalOpen(false);
                }}
                title={'Meld av konkurransen'}
                primaryAction={{
                    label: 'Avbryt',
                    onClick: () => {
                        setIsDeauthorizeModalOpen(false);
                    },
                }}
                secondaryAction={{
                    label: 'Bekreft avmelding',
                    disabled: isUpdatingEmail,
                    onClick: () => {
                        onDeauthorize();
                    },
                }}
            >
                Er du sikker på at du vil melde deg av konkurransen?
            </Modal>
            <TextContainer>
                <TDSSubtitle>Personopplysninger</TDSSubtitle>
                <TDFTextMedium>
                    For å administrere konkurransen benyttes personopplysninger
                    lagret i Bilhold.
                </TDFTextMedium>
                <TDFTextMedium>
                    Opplysningene blir også brukt til markedsføring overfor
                    deltakeren i oppfølgingen av konkurransen. For ytterligere
                    opplysninger, se gjeldende{' '}
                    <InlineLink to={PATH_PRIVACY}>
                        personvernerklæring
                    </InlineLink>
                    .
                </TDFTextMedium>
                <TDFTextMedium>
                    Data knyttet til koblingen til Strava vil bli slettet i
                    etterkant av konkurransen.
                </TDFTextMedium>
            </TextContainer>
            <TextContainer>
                <TDSSubtitle>Ansvarsfraskrivelse</TDSSubtitle>
                <TDFTextMedium>
                    Arrangøren er ikke ansvarlig for eventuelle tekniske feil
                    underveis i konkurransen, som svikt i mobilnettet eller om
                    det skjer andre uforutsette hendelser.
                </TDFTextMedium>
                <TDFTextMedium>
                    Ved feil på arrangørens servere vil konkurransen kunne
                    stoppes. Når feilene er rettet vil konkurransen fortsette.
                </TDFTextMedium>
                <TDFTextMedium>
                    Deltakerne kan ikke fremme erstatningskrav mot arrangøren
                    med mindre arrangøren har opptrådt grovt uaktsomt.
                </TDFTextMedium>
            </TextContainer>
            <TextContainer>
                <TDSSubtitle>Lovvalg og verneting</TDSSubtitle>
                <TDFTextMedium>
                    Konkurransen reguleres av norsk lov. Arrangøren og
                    deltakeren vedtar Oslo tingrett som eksklusivt verneting.
                </TDFTextMedium>
            </TextContainer>
            <TextContainer>
                <TDSSubtitle>Begreper</TDSSubtitle>
                <ListTDT>
                    <ListItemTDT>
                        Deltakere: Alle som kobler Strava til konkurransen i
                        Bilhold.
                    </ListItemTDT>
                    <ListItemTDT>
                        Arrangør: Harald A Møller AS v/ Škoda avd. , PB 6671
                        Etterstad, N-0609 OSLO (org.¬nr. 943 733 988)
                    </ListItemTDT>
                </ListTDT>
            </TextContainer>
            <TextContainer>
                <TDSSubtitle>Samtykker og kontaktinfo</TDSSubtitle>
                <TDFTextMedium>
                    Ved å melde deg på konkurransen godtar du at Møller sender
                    ut oppdateringer og informasjon i forbindelse med
                    konkurransen på epost. Melder du deg av e-post vil du
                    likevel bli kontaktet på e-post dersom du vinner
                    hovedpremien.
                </TDFTextMedium>
                {!!hasStravaAuthenticated && (
                    <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                        {isErrorUpdatingEmail && (
                            <AlertBanner
                                type="error"
                                message={
                                    'Det oppstod en feil da vi prøvde å lagre valget ditt. Prøv igjen senere.'
                                }
                            />
                        )}

                        <SecondaryButton
                            style={{ marginTop: '1rem' }}
                            onClick={() => {
                                setIsUpdateEmailOpen(true);
                            }}
                        >
                            Meld deg av epost
                        </SecondaryButton>
                    </div>
                )}
            </TextContainer>
            <Modal
                isOpen={isUpdateEmailOpen}
                handleClose={() => {
                    setIsUpdateEmailOpen(false);
                }}
                title={'Meld av epost om konkurransen'}
                primaryAction={{
                    label: 'Avbryt',
                    onClick: () => {
                        setIsUpdateEmailOpen(false);
                    },
                }}
                secondaryAction={{
                    label: 'Melde av epost',
                    disabled: isUpdatingEmail,
                    onClick: () => {
                        onUnsubscribe();
                    },
                }}
            >
                Er du sikker på at du vil melde deg av epost om konkurransen?
            </Modal>
            <FeedbackPrompt />
        </Column>
    );
}
export const TermsPage = () => {
    return (
        <CompetitionPageWrapper>
            <Page />
        </CompetitionPageWrapper>
    );
};
