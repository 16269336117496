import { Router } from 'src/routing/Router';
import { cookiePolyfill, initDatadog } from 'src/utils/datadog';
import { isMobileApp } from 'utils/mobile';
import { ApplicationErrorBoundary } from '../utils/ApplicationErrorBoundary';

import { createRoot } from 'react-dom/client';
const container = document.getElementById('app');
const root = createRoot(container!);

const renderApplication = () => {
    root.render(
        <ApplicationErrorBoundary>
            <Router />
        </ApplicationErrorBoundary>
    );
};

export function renderReactApp() {
    safeInitDatadog();
    renderApplication();
}

const safeInitDatadog = () => {
    try {
        if (isMobileApp()) {
            cookiePolyfill(); // Required (in app) to be able to use datadog-rum
        }
        initDatadog();
    } catch (e) {
        console.error(e);
    }
};
