import { datadogRum } from '@datadog/browser-rum';
import {
    AlertBanner,
    Column,
    Subtitle,
    useLocalFormState,
} from '@moller/design-system';
import { noValidation } from '@moller/design-system/utilities/validation';
import { useGetDealers } from 'booking/_api/http/dealers';
import { DealerPicker } from 'booking/sections/choose-dealer/dealer-picker';
import { RefObject, useEffect, useRef } from 'react';
import Car from 'src/types/Car';
import { useNamedQueryParams } from 'src/utils/browser/useNamedQueryParams';
import { ddRumPrivacySetting } from 'src/utils/datadog';
import lang from 'src/utils/lang';
import { NextStepButton } from '../Damage';
import { DamageAppraisal } from 'external-apis/src/types/damageappraisal';
import { hasValidPaymentInfo } from '../utils/damageValidation';
import { useUpdateDamageAppraisal } from 'src/features/damage/utils/useDamageAppraisals';
import { getFilteredDealers } from '../utils/useDealers';

interface DamageDealerProps {
    car?: Car;
    damage: DamageAppraisal;
    paymentStepDone: boolean;
    dealerStepDone: boolean;
    setDealerStepDone: (value: boolean) => void;
    headingRef: RefObject<HTMLHeadingElement>;
}

const DamageDealer = ({
    car,
    damage,
    paymentStepDone,
    dealerStepDone,
    setDealerStepDone,
    headingRef,
}: DamageDealerProps) => {
    const { mutate: updateDamage, isError } = useUpdateDamageAppraisal(
        damage.id
    );
    const datadogSent = useRef(false);

    const dealerForm = useLocalFormState<{
        selectedDealer: string | undefined;
    }>(
        {
            selectedDealer: damage.dealerId,
        },
        {
            selectedDealer: noValidation(),
        }
    );
    useEffect(() => {
        const dealerId = dealerForm.state.raw.selectedDealer;
        if (dealerId && dealerId !== damage.dealerId)
            updateDamage({ dealerId });
    }, [damage.dealerId, dealerForm.state.raw.selectedDealer, updateDamage]);

    if (paymentStepDone && !datadogSent.current) {
        datadogRum.addAction('Damage dealer');
        datadogSent.current = true;
    }

    const dealerGroup = useNamedQueryParams('dealerGroup')[0] || '';
    const { data: dealers } = useGetDealers(
        dealerGroup === 'Møller Bil' ? 'MollerBil' : undefined,
        'Damage'
    );

    const filteredDealers = getFilteredDealers(dealers, car);

    const showDealer =
        damage.description && hasValidPaymentInfo(damage) && paymentStepDone;

    const inputProps = dealerForm.inputProps('selectedDealer');

    return (
        <Column {...ddRumPrivacySetting('allow')}>
            <Subtitle ref={headingRef}>{lang.damageDealerTitle}</Subtitle>
            {showDealer ? (
                <Column gap="xs">
                    <p>{lang.damageDealerDescription}</p>
                    <DealerPicker
                        dealers={filteredDealers}
                        onChange={(dealer) => inputProps.setValue(dealer.id)}
                        selectedDealerId={inputProps.value}
                        validity={inputProps.validity}
                    />
                    {!dealerStepDone && (
                        <NextStepButton
                            disabled={!damage.dealerId}
                            setStepDone={setDealerStepDone}
                        />
                    )}
                </Column>
            ) : (
                <p>Du må velge betaling først</p>
            )}
            {isError && (
                <AlertBanner type="error" message={lang.damageGeneralError} />
            )}
        </Column>
    );
};

export default DamageDealer;
