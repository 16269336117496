import { Column } from '@moller/design-system';
import { TDFShirt } from 'src/features/competition/assets/TDFShirt';
import { ShirtCard } from 'src/features/competition/components/shirtCards/ShirtCard';
import { ShirtProgressBar } from 'src/features/competition/components/shirtCards/ShirtProgressBar';
import { TDSTextXSmall } from 'src/features/competition/textComponents/TDFText';
import { styled } from 'styled-components';

const ShirtWrapper = styled(Column)`
    width: fit-content;
    align-self: end;
`;

const MAX_STREAK = 7;

interface Props {
    streak: number;
    isDisabled: boolean;
}

export const GreenStreakShirt = ({ streak, isDisabled }: Props) => {
    const shownStreak = Math.min(streak, MAX_STREAK);
    const isCompleted = streak >= MAX_STREAK;
    return (
        <ShirtCard
            isCompleted={isCompleted}
            isDisabled={isDisabled}
            headline={'Den grønne trøya'}
            modalText={
                'For å oppnå den grønne trøya må du registrere en sykkeløkt hver dag i 7 dager.'
            }
            prizesRemaining={250}
            shirtColor={'var(--moller-palette-primary-70)'}
        >
            <>
                <ShirtProgressBar
                    currentValue={shownStreak}
                    maxValue={MAX_STREAK}
                    progressHeader={
                        <Column>
                            <p>7 dager</p>
                            <p>streak</p>
                        </Column>
                    }
                    shirtColor={'var(--moller-palette-primary-70)'}
                    textUnderProgress={'dager'}
                />
                <ShirtWrapper
                    horizontalAlign={'center'}
                    style={{ marginTop: '1rem' }}
                >
                    <TDFShirt
                        fill={'var(--moller-palette-primary-70)'}
                        width={'48'}
                        height={'40'}
                        opacity={isCompleted ? '1' : '0.2'}
                    />
                    {!isCompleted && (
                        <TDSTextXSmall>
                            {MAX_STREAK - streak} dager igjen
                        </TDSTextXSmall>
                    )}
                </ShirtWrapper>
            </>
        </ShirtCard>
    );
};
