import { Description } from '@moller/design-system';
import { ServiceViewModel } from 'external-apis/src/types/port';
import { useLanguageContext } from '../../../lib/languages/languageContext';
import {
    freeService,
    serviceWithoutPrices,
    tireHotelService,
} from '../../testData';
import { toSelectedService } from '../toSelectedService';
import { StandardServicesData } from '../../standard-services/useStandardServices';

type PriceProps = {
    values: number[];
    isCalculated?: boolean;
};

export function Price({ values, isCalculated = false }: PriceProps) {
    const price = determinePriceFrom(values);
    if (isCalculated) {
        return (
            <Description>
                <CalculatedPrice />
            </Description>
        );
    }
    return (
        <Description>
            {price.type === 'unknown' && <UnknownPrice />}
            {price.type === 'fixed' && <FixedPrice value={price.value} />}
            {price.type === 'range' && <FromPrice min={price.min} />}
        </Description>
    );
}

function CalculatedPrice() {
    const [lc] = useLanguageContext();
    return <span>{lc.prices.price_calculated}</span>;
}

function UnknownPrice() {
    const [lc] = useLanguageContext();
    return <span>{lc.prices.price_calculated_at_dealer}</span>;
}

type FixedPriceProps = {
    value: number;
};

function FixedPrice({ value }: FixedPriceProps) {
    const [lc] = useLanguageContext();
    if (value === 0) {
        return <span>{lc.prices.free}</span>;
    }
    return <span>{`${Math.ceil(value)} ${lc.prices.currency}`}</span>;
}

type FromPriceProps = {
    min: number;
};

function FromPrice({ min }: FromPriceProps) {
    const [lc] = useLanguageContext();
    return (
        <>
            <span>{lc.prices.from}</span>
            <span>{`${Math.round(min)} ${lc.prices.currency}`}</span>
        </>
    );
}

type DeterminedPrice =
    | {
          type: 'unknown';
      }
    | {
          type: 'fixed';
          value: number;
      }
    | {
          type: 'range';
          min: number;
          max: number;
      };

function determinePriceFrom(values: number[]): DeterminedPrice {
    if (values.length === 0) return { type: 'unknown' };
    const min = values.reduce((x, y) => (x < y ? x : y));
    const max = values.reduce((x, y) => (x > y ? x : y));
    if (min !== max) return { type: 'range', min, max };
    return { type: 'fixed', value: min };
}

if (import.meta.vitest) {
    const { it, expect } = import.meta.vitest;
    it.concurrent('determineDisplayedPriceFrom - when price range', () => {
        const prices = [500, 200, 1300];
        const actual = determinePriceFrom(prices);
        expect(actual).toEqual({ min: 200, max: 1300, type: 'range' });
    });
    it.concurrent('determineDisplayedPriceFrom - when fixed pricing', () => {
        const prices = [500, 500, 500];
        const actual = determinePriceFrom(prices);
        expect(actual).toEqual({ value: 500, type: 'fixed' });
    });
    it.concurrent('determineDisplayedPriceFrom - when unknown price', () => {
        const actual = determinePriceFrom([]);
        expect(actual).toEqual({ type: 'unknown' });
    });
}

export function parseServicesPrices(
    services: (
        | StandardServicesData['selectedServices'][number]
        | ServiceViewModel
    )[],
    dealerNumber?: string
) {
    const servicePrices = services
        .map((x) => parseServicePrices(x, dealerNumber))
        .flat(1);
    return servicePrices;
}

export function parseServicePrices(
    service:
        | StandardServicesData['selectedServices'][number]
        | ServiceViewModel,
    dealerNumber?: string
) {
    return service.dealerSpecificInformation
        .filter((x) => (dealerNumber ? x.dealerNumber === dealerNumber : true))
        .map((x) => x.price?.fixedPrice)
        .filter((x): x is number => x !== undefined && x !== null);
}

if (import.meta.vitest) {
    const { it, expect } = import.meta.vitest;
    it.concurrent('parseServicePrices - when prices are undefined', () => {
        const actual = parseServicePrices(
            toSelectedService(serviceWithoutPrices)
        );
        expect(actual).toEqual([]);
    });
    it.concurrent('parseServicePrices - when prices are defined', () => {
        const actual = parseServicePrices(toSelectedService(tireHotelService));
        expect(actual).toEqual([299, 700]);
    });
    it.concurrent('parseServicePrices - when fixed price 0', () => {
        const dealerNumber = '1550';
        const actual = parseServicePrices(
            toSelectedService(freeService),
            dealerNumber
        );
        expect(actual).toEqual([0]);
    });
    it.concurrent('parseServicePrices - when selected dealer', () => {
        const dealerNumber = '1550';
        const actual = parseServicePrices(
            toSelectedService(tireHotelService),
            dealerNumber
        );
        expect(actual).toEqual([700]);
    });
}
