import { Browser } from '@capacitor/browser';
import { WHITE_SPACE } from 'src/utils/string/regex';
import { isMobileApp } from 'utils/mobile';

const inAppBrowserOpen = async (url: string) => {
    try {
        await Browser.open({ url });
    } catch (e) {
        const rethrown = new Error(`Failed to open URL ${url}`);
        if (e instanceof Error) {
            rethrown.message += `\n${e.message}`;
            rethrown.stack = e.stack ?? rethrown.stack;
        } else if (typeof e === 'string') {
            rethrown.message += `\n${e}`;
        }
        throw rethrown;
    }
};

/**
 * Opens default system call handler. To be used by CallToAction-buttons with the purpose of calling a number.
 * @param phoneNumber
 */
export const openPhoneNumberHref = (phoneNumber: string): void => {
    const phoneHref = `tel:${phoneNumber?.replace(WHITE_SPACE, '')}`;
    window.location.href = phoneHref;
};

/**
 * Opens default system handler for given link. NB! Use instead of window.open
 */
export const openWindowHref = (link?: string, target?: string): void => {
    if (!link) {
        return;
    }
    const href = link.replace(WHITE_SPACE, '');
    if (
        isMobileApp() &&
        !(href.startsWith('mailto:') || href.startsWith('tel:'))
    ) {
        void inAppBrowserOpen(href);
    } else {
        window.open(href, target ?? '_blank');
    }
};
