import type { ComponentProps } from 'react';

type Props = ComponentProps<'svg'>;
export function TDFCup({ ...props }: Props): JSX.Element {
    return (
        <svg
            width={props.width || 'auto'}
            height={props.height || '22'}
            viewBox="0 0 19 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M8 14C3.2 14 1.33333 5.33333 1 1H8H11H18C17.6667 5.33333 15.8 14 11 14V18H15.5V21H11H8H3.5V18H8V14Z"
                fill="#ECB425"
            />
            <path
                d="M11 21H3.5V18H8V14C3.2 14 1.33333 5.33333 1 1H11M8 21H15.5V18H11V14C15.8 14 17.6667 5.33333 18 1H8"
                stroke="white"
            />
        </svg>
    );
}
