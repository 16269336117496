import Status from 'http-status';
import { ApiError, Middleware } from 'openapi-typescript-fetch';
import forceUpdateApp from '../../native/forceUpdateApp';

export function forceAppUpdateMiddleware(): Middleware {
    return async (url, init, next) => {
        try {
            const apiResponse = await next(url, init);
            if (apiResponse.status === Status.IM_A_TEAPOT) {
                forceUpdateApp();
            }
            return apiResponse;
        } catch (e) {
            if (e instanceof ApiError) {
                if (e.status === Status.IM_A_TEAPOT) {
                    forceUpdateApp();
                }
            }

            throw e;
        }
    };
}
