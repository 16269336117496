export type FastTrackType = 'glass-damage' | 'tire-change' | 'campaign';

interface Params {
    vehicleIdentificationNumber?: string;
    registrationNumber?: string;
}

export default (path: string, params: Params, fastTrackType: FastTrackType) => {
    const noParams = !(
        params.vehicleIdentificationNumber || params.registrationNumber
    );

    if (noParams) {
        return `${path}?fastTrack=${fastTrackType}`;
    }
    return `${path}&fastTrack=${fastTrackType}`;
};
