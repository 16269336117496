import { SimpleHeader } from 'src/features/header/SimpleHeader';
import { getIsLoggedIn } from 'src/utils/auth/authenticationUtils';
import Page from 'src/utils/pageType';
import HeaderNew from './Header';
import { LoginHeader } from './LoginHeader';
import HeaderType from './utils/HeaderType';

const index = ({ currentPage }: { currentPage: Page }) => {
    const isLoggedIn = getIsLoggedIn();
    if (currentPage.headerType === HeaderType.NONE) {
        return null;
    }
    if (currentPage.headerType === HeaderType.SIMPLE) {
        return <>{isLoggedIn ? <HeaderNew /> : <SimpleHeader />}</>;
    }
    return <>{isLoggedIn ? <HeaderNew /> : <LoginHeader />}</>;
};

export default index;
