import { useEffect, useState } from 'react';

export function useEffectOnce(callback?: () => void) {
    const [hasRun, setHasRun] = useState(false);

    useEffect(() => {
        if (!callback || hasRun) return;
        callback();
        setHasRun(true);
    }, [hasRun, callback]);
}
