import { CountryCode } from 'external-apis/src/types/port';
import { useEffect } from 'react';
import { useGetRecommendedServices } from '../../../../_api/http/services';
import { shouldRefetchRecommendedServices } from './shouldRefetchRecommendedServices';

export function useRefetchRecommendedService(
    vin: string,
    mileage: string,
    countryCode: CountryCode,
    dealerNumber: string,
    preselectRecommendedServices: (ids: string[]) => void,
    { mutate, data }: ReturnType<typeof useGetRecommendedServices>,
    enable?: boolean
) {
    const shouldRefetch = shouldRefetchRecommendedServices(dealerNumber, data);

    useEffect(() => {
        const body = {
            vin,
            mileage: parseInt(mileage),
            countryCode,
            dealerFilter: {
                selectedDealerNumbers: [dealerNumber],
            },
        };
        if (shouldRefetch && enable) {
            mutate({
                body,
                preselectRecommendedServices: preselectRecommendedServices,
            });
        }
    }, [
        vin,
        mileage,
        countryCode,
        dealerNumber,
        shouldRefetch,
        preselectRecommendedServices,
        mutate,
        enable,
    ]);
}
