import {
    breakpointMobile,
    breakpointTablet,
    Column,
} from '@moller/design-system';
import { Fragment } from 'react';
import { ddRumPrivacySetting } from 'src/utils/datadog';
import { isMobileApp } from 'src/utils/mobile';
import { styled } from 'styled-components';
import { ChildThemeProvider } from 'theming';
import {
    AppReviews,
    ChangeTire,
    DamageAppraiser,
    DownloadBlock,
    Hero,
    HeroMobile,
    Info,
    NewCar,
    RoadAssistance,
} from './Sections';

const WebContainer = styled(Column)`
    box-sizing: border-box;
    padding-bottom: 5rem;
    padding-top: 87px;
    align-items: center;

    @media screen and (max-width: ${breakpointTablet}) {
        gap: var(--moller-spacing-xxl);
    }

    @media screen and (max-width: ${breakpointMobile}) {
        padding-top: 73px;
    }
`;

const MobileContainer = styled(Column)`
    padding-top: 4rem;
    box-sizing: border-box;
`;

const Begin = () => {
    return (
        <ChildThemeProvider theme="bilhold">
            <Fragment {...ddRumPrivacySetting('allow')}>
                {!isMobileApp() ? (
                    <WebContainer>
                        <Hero />
                        <Info />
                        <NewCar />
                        <ChangeTire />
                        <DamageAppraiser />
                        <RoadAssistance />
                        <DownloadBlock />
                        <AppReviews />
                    </WebContainer>
                ) : (
                    <MobileContainer>
                        <HeroMobile />
                    </MobileContainer>
                )}
            </Fragment>
        </ChildThemeProvider>
    );
};

export default Begin;
