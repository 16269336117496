import coupeRS from './coupe-rs-iv.webp';
import kodiaqLk from './kodiaq-lk.webp';
import kodiaqRs from './kodiaq-rs.webp';
import kodiaqSportline from './kodiaq-sportline.webp';
import kodiaqStyle from './kodiaq-style.webp';
import octaviaRs from './octavia-rs.webp';
import octaviaStyle from './octavia-style.webp';
import superbSportline from './superb-sportline.webp';
import superbStyle from './superb-style.webp';

const coupeRsImage = {
    src: coupeRS,
    name: ['enyaq', 'coupé', 'rs', 'iv'],
};

const kodiaqLkImage = {
    src: kodiaqLk,
    name: ['kodiaq', 'lk'],
};

const kodiaqRsImage = {
    src: kodiaqRs,
    name: ['kodiaq', 'rs'],
};

const kodiaqSportlineImage = {
    src: kodiaqSportline,
    name: ['kodiaq', 'sportline'],
};

const kodiaqStyleImage = {
    src: kodiaqStyle,
    name: ['kodiaq', 'style'],
};

const octaviaRsImage = {
    src: octaviaRs,
    name: ['octavia', 'rs'],
};

const octaviaStyleImage = {
    src: octaviaStyle,
    name: ['octavia', 'style'],
};

const superbSportlineImage = {
    src: superbSportline,
    name: ['superb', 'sportline'],
};

const superbStyleImage = {
    src: superbStyle,
    name: ['superb', 'style'],
};

export const skodaImages = [
    coupeRsImage,
    kodiaqLkImage,
    kodiaqRsImage,
    kodiaqSportlineImage,
    kodiaqStyleImage,
    octaviaRsImage,
    octaviaStyleImage,
    superbSportlineImage,
    superbStyleImage,
];
