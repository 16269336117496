import { Product } from 'src/features/home/utils/productType';
import Brand from 'src/types/Brand';
import AudiHjemmeladerImage from '../product-images/audi/audi-defa-lader.webp';
import Innstegslys from '../product-images/audi/audi-innstegslys.webp';
import Ladekabelsett from '../product-images/audi/audi-ladekabelsett.webp';
import Oppbevaringshylle from '../product-images/audi/audi-oppbevaringshylle.webp';
import Powerlader from '../product-images/audi/audi-powerlader.webp';
import Skiholder from '../product-images/audi/audi-skiholder.webp';
import Sykkelstativ from '../product-images/audi/audi-sykkelstativ.webp';
import Takstativ from '../product-images/audi/audi-takstativ.webp';
import Transportboks from '../product-images/audi/audi-transportboks.webp';

export const AudiProducts: Product[] = [
    {
        articleNumber: '4G0052133K',
        packageNumber: 'J520',
        image: Innstegslys,
        title: 'Innstignings-LED',
        descriptionShort: 'LED ved døråpningen med Audi-logo.',
        descriptionLong:
            'Døråpnings-LED-en projiserer logoen til Audi-ringene med gekko på bakken når bildørene åpnes. Dette gir ikke bare bedre lys i døråpningen, men skaper også et blikkfang.',
        text: '',
        price: 2000,
        carBrand: Brand.AUDI,
        carModel: ['Q4', 'Q8'],
    },

    {
        articleNumber: 'Audi DEFAPOWER + DEFABALANCER',
        packageNumber: 'A030-04',
        image: AudiHjemmeladerImage,
        title: 'Hjemmelader',
        descriptionShort: 'DEFA Power med Balancer.',
        descriptionLong:
            'Den smarteste måten å kontrollere strømforbruket ditt, slik at du kan lade til lavest mulig kostnad med maksimal effekt.',
        text: 'DEFA Power har et intuitivt display og 6 meter integrert Type 2-ladekabel som forenkler din daglige bruk og gir deg full kontroll over ladingen. DEFA Power optimaliserer tilgjengelig strøm, og med PowerSmart kan du unngå strømtopper og dermed unngå å lade når strømmen er dyrest.',
        specList: [
            'Maksimal effekt - opp til 22kW',
            'DEFA Power leverer lovet effekt, uansett om du kan motta 7,4kW, 11kW eller 22kW.',
            'Gjennom en intelligent konstruksjon og avanserte simuleringer er den designet for å sikre raskest mulig lading.',
            'PowerSmart™ med Power Appen',
            'Forhåndsinnstill at du vil lade når strømprisene er lave, basert på daglige priser fra Nord Pool.',
            'Montering er inkludert',
        ],
        price: 17990,
        carBrand: Brand.AUDI,
        carModel: ['Q4', 'Q8'],
    },

    {
        articleNumber: '8S0051435S',
        packageNumber: 'A016',
        image: Ladekabelsett,
        title: '2-PACK USB-C OG LIGHTNING',
        descriptionShort:
            'Komfortabel bruk og lading av enheter under kjøring.',
        descriptionLong:
            'Komfortabel bruk og lading av mobiltelefoner, nettbrett eller andre mobile enheter under kjøring.',
        text: 'USB Type-C®*-ladekabel med kraftig kabel for bruk med Audi Music Interface, Audi Smartphone Interface eller USB-ladefunksjonen.',
        price: 490,
        carBrand: Brand.AUDI,
        carModel: ['Q4', 'Q8'],
    },

    {
        articleNumber: '89A071151',
        packageNumber: 'G200',
        image: Takstativ,
        title: 'Takstativ original',
        descriptionShort: 'Audi Original lastbærer.',
        descriptionLong:
            'Audi Original lastbærer er spesielt utviklet for den aktuelle bilen og gjør inntrykk med sportslig, slank design med Audi-logoer.',
        text: 'Takstativet kan utstyres med mange forskjellige løsninger for transport. Takmontert lasteutstyr som sykkelstativ, ski- og snowboard-holder, kajakkholder og takboks monteres raskt og enkelt.',
        price: 3600,
        carBrand: Brand.AUDI,
        carModel: ['Q4'],
    },

    {
        articleNumber: '4M8071129',
        packageNumber: 'G300',
        image: Skiholder,
        title: 'Skiholder m.uttrekksfunksjon',
        descriptionShort: 'Inntil 4 par ski eller inntil 2 snøbrett.',
        descriptionLong:
            'Den enkle og hurtige monteringen på lastholderen muliggjør praktisk transport av inntil 4 par ski eller inntil 2 snøbrett.',
        text: 'Ski- og snøbrettholderen av eloksert aluminiumsprofil er laget med plast- og gummideler og kan låses. Som alt annet utstyr for takmontering fra Audi originalt tilbehør oppfyller takstativet de strengeste sikkerhetsstandarder.',
        price: 3599,
        carBrand: Brand.AUDI,
        carModel: ['Q4', 'Q8'],
    },

    {
        articleNumber: '4K4071207',
        packageNumber: 'G313',
        image: Transportboks,
        title: 'Transportboks for sykkelholder',
        descriptionShort: 'Utvid bagasjeplassen for lengre turer.',
        descriptionLong:
            'Fremfor alt før lengre reiser er det mye bagasje som skal plasseres i bilen. Bagasjeboksen fra Audi originalt tilbehør utvider bagasjeplassen med ca. 300 l og er en praktisk løsning for langturer.',
        text: 'Med Audi-design av høy kvalitet passer bagasjeboksen perfekt til Audis progressive bildesign. Den festes på den hengerfestemonterte sykkelstativet fra Audi originalt tilbehør, slik at lastehøyden blir komfortabel. De to hjulene på sidene på boksen gjør den lettere å transportere til og fra bilen. Den kompakte bagasjeboksen kan låses. Den maksimalt tillatte lasten er 40 kg. City crash-testet i samsvar med Audis testspesifikasjoner. Kan bare brukes i kombinasjon med sykkelstativ for tilhengerfeste fra Audi originalt tilbehør.',
        price: 10990,
        carBrand: Brand.AUDI,
        carModel: ['Q4', 'Q8'],
    },

    {
        articleNumber: '89A061140',
        packageNumber: 'E390',
        image: Oppbevaringshylle,
        title: 'Oppbevaringshylle Q4',
        descriptionShort: 'Fleksible og ryddige rom i midtkonsollen.',
        descriptionLong:
            'Fleksible og ryddige rom i midtkonsollen. Oppbevaringen i midtkonsollen foran er delt inn i rom i forskjellige størrelser med en hyllelignende, sort plastkomponent.',
        text: 'Det middels store rommet egner seg godt til oppbevaring av briller og etuier. Det er en skålformet fordypning under for nøkler og små gjenstander. Under denne fordypningen er det f.eks. plass til en pakke papirlommetørklær. I de to siderommene kan du f.eks. oppbevare smarttelefoner eller lommebøker.',
        price: 699,
        carBrand: Brand.AUDI,
        carModel: ['Q4'],
    },

    {
        articleNumber: '4K0071105',
        packageNumber: 'G311',
        image: Sykkelstativ,
        title: 'Sykkelstativ',
        descriptionShort: 'Stabil, praktisk og låsbart sykkelstativ fra Audi.',
        descriptionLong:
            'Det stabile, praktiske og låsbare sykkelstativet fra Audi for montering på tilhengerfestet gir plass til sikker transport av 2 vanlige sykler eller elsykler.',
        text: 'Maksimal last: 60 kg. Sykkelstativet er enkelt å montere og demontere. Med en egenvekt på bare 14 kg er det svært lett. Sykkelstativet kan enkelt svinges ut i 90° når bagasjerommet skal åpnes. Om ønskelig kan et utvidelsessett for en 3. Sykkel med ekstra muligheter.',
        price: 6990,
        carBrand: Brand.AUDI,
        carModel: ['Q4', 'Q8'],
    },

    {
        articleNumber: '4K0 051 443',
        packageNumber: 'A017',
        image: Powerlader,
        title: 'USB Power lader 100w',
        descriptionShort:
            'Hurtig lading av smarttelefoner, nettbrett og laptop.',
        descriptionLong:
            'USB Power-laderen gjør det mulig med hurtig og komfortabel lading av smarttelefoner, nettbrett, laptop med opp til 100 Watt ladeeffekt (Power Delivery) i bilen.',
        text: 'USB Power-laderen har en ladekontakt type-C. Tilkoblingen til bilen skjer via 12 V stikkontakten.',
        price: 2219,
        carBrand: Brand.AUDI,
        carModel: ['Q8'],
    },

    {
        articleNumber: '4KE071151',
        packageNumber: 'G200',
        image: Takstativ,
        title: 'Takstativ original',
        descriptionShort: 'Audi Original lastbærer.',
        descriptionLong:
            'Audi Original lastbærer er spesielt utviklet for den aktuelle bilen og gjør inntrykk med sportslig, slank design med Audi-logoer.',
        text: 'Takstativet kan utstyres med mange forskjellige løsninger for transport. Takmontert lasteutstyr som sykkelstativ, ski- og snowboard-holder, kajakkholder og takboks monteres raskt og enkelt.',
        price: 4500,
        carBrand: Brand.AUDI,
        carModel: ['Q8'],
    },
];
