import React from 'react';
import { styled } from 'styled-components';
import { ErrorBoundary } from './ErrorBoundary';

const Container = styled.div`
    display: flex;
    justify-content: center;
    height: 90vh;
    align-items: center;
`;

const ErrorMessage = styled.div`
    max-width: 700px;
    padding: 20px;
    font-family: sans-serif;
    line-height: 1.5;
`;

/* eslint-disable jsx-a11y/anchor-is-valid */
const TryAgain = (props: { retry: () => void; children: React.ReactNode }) => (
    <a
        href="#"
        role="button"
        onClick={(e) => {
            e.preventDefault();
            props.retry();
        }}
    >
        {props.children}
    </a>
);
/* eslint-enable jsx-a11y/anchor-is-valid */

export const ApplicationErrorBoundary: React.FC<{
    children?: React.ReactNode;
}> = ({ children }) => (
    <ErrorBoundary
        logMessage="React application crashed"
        fallback={(retry) => (
            <Container>
                <ErrorMessage>
                    <h1>En ukjent feil har oppstått</h1>
                    <p>
                        Vi beklager at en ukjent feil har oppstått. Hvis
                        problemet vedvarer, vennligst kontakt oss for hjelp.
                        Takk for din forståelse.
                    </p>
                    <a href="/">Gå til forsiden</a> eller{' '}
                    <TryAgain retry={retry}>prøv igjen</TryAgain>
                </ErrorMessage>
            </Container>
        )}
    >
        {children}
    </ErrorBoundary>
);
