import { useCallback, useEffect, useState } from 'react';
import { FirstPrizeCard } from 'src/features/competition/components/prizeSwiper/FirstPrizeCard';
import { StandardPrizeCard } from 'src/features/competition/components/prizeSwiper/StandardPrizeCard';
import { isMobileApp } from 'src/utils/mobile';
import { styled } from 'styled-components';
import { Swiper as SwiperType } from 'swiper';
import 'swiper/css/navigation';
import { Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/scss';
import 'swiper/scss/pagination';
import backpack from '../../images/Backpack.png';
import bottleAndCap from '../../images/BottleAndCap.png';
import picnicBlanket from '../../images/SkodaBlanket.jpg';
import sleepingBag from '../../images/SkodaSleepingBag.jpg';

const StyledSwiper = styled(Swiper)`
    z-index: 0;
    --swiper-navigation-size: 12px;
    padding-bottom: var(--moller-spacing-xs);
    margin-bottom: var(--moller-spacing-s);
    width: inherit;
    .swiper-pagination {
        bottom: -4px;
    }

    .swiper-slide {
        height: auto;
    }

    .swiper-button-next,
    .swiper-button-prev {
        border-radius: 50%;
        width: 35px;
        height: 35px;
        padding: 5px;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        margin: -30px 0;
        display: none;
        background-color: var(--moller-color-primary-container);
        color: var(--moller-color-on-primary-container);

        :hover {
            background-color: var(--moller-color-primary);
            color: var(--moller-color-on-primary);
        }
    }

    .swiper-pagination-bullet {
        background-color: var(--moller-color-on-background);
    }

    :hover {
        .swiper-button-next,
        .swiper-button-prev {
            display: flex;
        }
    }

    .swiper-button-disabled {
        display: none !important;
    }
`;

const SwiperContainer = styled.div`
    width: inherit;
    max-width: 800px;
    min-width: 260px;
    margin: 0 auto;
    position: relative;
`;

export const TDFSwiperView = () => {
    const [swiperRef, setSwiperRef] = useState<SwiperType | null>(null);
    const [swiperIndex, setIndex] = useState(0);

    const slideTo = useCallback(
        (index: number) => {
            swiperRef?.slideTo(index, 0);
        },
        [swiperRef]
    );

    useEffect(() => {
        slideTo(swiperIndex);
    }, [swiperIndex, slideTo]);

    return (
        <SwiperContainer>
            <StyledSwiper
                onSwiper={setSwiperRef}
                onSlideChange={(swiper) => setIndex(swiper.realIndex)}
                pagination={true}
                navigation={!isMobileApp()}
                modules={[Pagination, Navigation]}
                spaceBetween={0}
            >
                <SwiperSlide key={'1'}>
                    <FirstPrizeCard />
                </SwiperSlide>
                <SwiperSlide key={'2'}>
                    <StandardPrizeCard
                        heading={'Oppstartspremie'}
                        text={
                            '1000 vinnere kan velge mellom sykkelcaps eller drikkeflaske'
                        }
                        picture={bottleAndCap}
                    />
                </SwiperSlide>
                <SwiperSlide key={'3'}>
                    <StandardPrizeCard
                        heading={'Klatretrøye'}
                        text={'250 vinnere av et piknikteppe fra Škoda'}
                        picture={picnicBlanket}
                    />
                </SwiperSlide>
                <SwiperSlide key={'4'}>
                    <StandardPrizeCard
                        heading={'Grønn trøye'}
                        text={'250 vinnere av en ryggsekk fra Škoda'}
                        picture={backpack}
                    />
                </SwiperSlide>
                <SwiperSlide key={'5'}>
                    <StandardPrizeCard
                        heading={'Gul trøye'}
                        text={'250 vinnere av en sovepose fra Škoda'}
                        picture={sleepingBag}
                    />
                </SwiperSlide>
            </StyledSwiper>
        </SwiperContainer>
    );
};
