// Meant for phone number formatting, 22334455 -> 22 33 44 55. Usage: string.replace(SPACE_EVERY_TWO_CHARS, '$1 ')
export const SPACE_EVERY_TWO_CHARS = /([^+]{2})/g;

// create mew user validations
export const VALID_EMAIL =
    /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/i;
export const VALID_NAME =
    /^(?<firstchar>(?=\p{Script=Latin}))((?<alphachars>\p{Script=Latin})|(?<specialchars>\p{Script=Latin}['-](?=\p{Script=Latin}))|(?<spaces> (?=\p{Script=Latin})))*\s*$/u;
export const VALID_ADDRESS = /^[^%$()?;,:+&|¤#£"@!*¨^~`]*$/;
export const VALID_ZIP_INPUT = /^[0-9]*$/;

// OTP form validations
export const VALID_PHONE_NUMBER = /^[0-9]{8}$/;
export const VALID_DEV_PHONE_NUMBER = /^[0-9]{8}#[1-2]$/;
export const ONLY_NUMBERS = /^[0-9]*$/;

export const WHITE_SPACE = /\s/g;

// Add car validation
export const REG_NO_PATTERN = /^[A-Z]{0,2}[0-9]{0,5}$/;
export const REG_NO_FULL_LENGTH = 7;
export const VALID_ORGANISATION_NUMBER = /^([0-9]{9})$/;
