import { Column, Icon, LeadText, TextButton } from '@moller/design-system';
import { useNavigate } from 'react-router-dom';
import appStoreQrCodeUrl from 'src/features/begin/images/qrcode-frontpage.svg';
import { AppStoreButtons } from 'src/shared/appstore-buttons/AppStoreButtons';
import lang from 'src/utils/lang';
import { isMobileApp } from 'src/utils/mobile';
import { styled } from 'styled-components';

const Container = styled(Column)`
    flex-wrap: wrap;
    padding-top: 5rem;
    margin: 0 10%;
    gap: 8px;
`;

const QrCodeContainer = styled.div`
    align-self: center;
    align-items: center;
    max-width: 600px;
`;

const AppStoreQrLinkImage = styled.img`
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
`;
const iosAppStoreUrl = 'https://apps.apple.com/no/app/bilhold/id1006579487';
const androidAppStoreUrl =
    'https://play.google.com/store/apps/details?id=no.moller.carcare';

const AppRedirect = () => {
    const navigate = useNavigate();
    const isAndroid = /Android/i.test(navigator.userAgent);
    const isiOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);
    if (isMobileApp()) {
        window.location.href = '/';
    } else if (isAndroid) {
        window.location.href = androidAppStoreUrl;
    } else if (isiOS) {
        window.location.href = iosAppStoreUrl;
    }
    return (
        <Container>
            <TextButton underline onClick={() => navigate(-1)}>
                <Icon icon="arrow_backward" size="s" /> Tilbake
            </TextButton>
            <QrCodeContainer>
                <LeadText>Last ned Bilhold-appen her</LeadText>
                <AppStoreQrLinkImage
                    alt={lang.appStore}
                    src={appStoreQrCodeUrl}
                />
                <AppStoreButtons />
            </QrCodeContainer>
            <div />
        </Container>
    );
};
export default AppRedirect;
