import { Capacitor } from '@capacitor/core';
import { InAppReview } from '@capacitor-community/in-app-review';
import { useEffectOnce } from 'src/utils/hooks/useEffectOnce';
import { isMobileApp } from 'src/utils/mobile';

interface RateAppDialogProps {
    hideNpsFor30Days: () => void;
}

const RateAppDialog = ({ hideNpsFor30Days }: RateAppDialogProps) => {
    useEffectOnce(() => {
        if (!isMobileApp() || !Capacitor.isPluginAvailable('RateApp')) {
            return;
        }

        const timer = setTimeout(() => {
            void InAppReview.requestReview();

            hideNpsFor30Days();
        }, 1000);

        return () => clearTimeout(timer);
    });

    return null;
};

export default RateAppDialog;
