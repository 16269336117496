const includedDealersList = ['dahles auto fredrikstad'];

export const dealerIsUpdatingSystems = (dealer: string | undefined) => {
    for (const includedDealer of includedDealersList) {
        if (dealer?.toLowerCase().includes(includedDealer)) {
            return true;
        }
    }

    return false;
};
