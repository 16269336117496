import { Capacitor } from '@capacitor/core';
import { Device } from '@capacitor/device';
import { LocalNotifications } from '@capacitor/local-notifications';
import {
    PushNotifications,
    PushNotificationSchema,
    Token,
} from '@capacitor/push-notifications';
import { datadogLogs } from '@datadog/browser-logs';
import { NavigateFunction } from 'react-router-dom';
import getCurrentEnvironment from 'src/utils/environment/getCurrentEnvironment';
import post from 'src/utils/http/post';

interface UserIds {
    partyId: string;
    entryUuid: string;
}
interface RegisterDeviceRequest {
    partyId: string;
    entryUuid: string;
    registrationToken: string;
    clientType: 'ios' | 'android';
}

async function notificationRegistrationListener(
    userIds: UserIds,
    token: Token
) {
    console.log('Push registration success, token: ' + token.value);
    const clientType = (await Device.getInfo()).platform;
    if (clientType === 'web') {
        throw new Error('This code path should only run in the mobile apps');
    }

    if (userIds.partyId === '') {
        return;
    }

    const body: RegisterDeviceRequest = {
        clientType: clientType,
        registrationToken: token.value,
        entryUuid: userIds.entryUuid,
        partyId: userIds.partyId,
    };
    const { notificationUrl } = getCurrentEnvironment();
    console.log('Registering with notification backend', notificationUrl);
    await post(`${notificationUrl}/notification/register-device`, body);
}

async function notificationReceivedListener(
    notification: PushNotificationSchema
) {
    console.log('Push received: ' + JSON.stringify(notification));
    datadogLogs.logger.info('Push received', {
        notificationMessage: notification.title,
    });
    // Ensure a notification is shown even when the app is open.
    // In the future, determine if this should happen based on data in the notification.
    await LocalNotifications.schedule({
        notifications: [
            {
                ...notification,
                id: Date.now(),
                title: notification.title ?? '',
                body: notification.body ?? '',
                extra: notification.data as unknown,
            },
        ],
    });
}

async function initializePushNotifications(
    userIds: UserIds,
    navigate: NavigateFunction
) {
    try {
        // Ensure we don't end up with multiple listeners per event after app resume
        await PushNotifications.removeAllListeners();

        // Request permission to use push notifications
        // iOS will prompt user and return if they granted permission or not
        // Android will just grant without prompting
        await PushNotifications.requestPermissions().then(async (result) => {
            if (result.receive === 'granted') {
                // Register with Apple / Google to receive push via APNS/FCM
                await PushNotifications.register();
            } else {
                // Show some error
            }
        });

        // On success, we should be able to receive notifications
        await PushNotifications.addListener('registration', (token) => {
            void notificationRegistrationListener(userIds, token);
        });

        // Some issue with our setup and push will not work
        await PushNotifications.addListener('registrationError', (error) => {
            console.log('Push registration error: ' + JSON.stringify(error));
            datadogLogs.logger.error('Push registration error', {
                pushRegistrationError: error.error,
            });
        });

        // Show us the notification payload if the app is open on our device
        await PushNotifications.addListener(
            'pushNotificationReceived',
            (notification) => void notificationReceivedListener(notification)
        );

        // Methods called when tapping on a notification
        await PushNotifications.addListener(
            'pushNotificationActionPerformed',
            (action) => {
                console.log('Push action performed: ' + JSON.stringify(action));
                datadogLogs.logger.info('Push action performed', {
                    pushNotificationAction: action.actionId,
                });
                handleNotificationAction(action.notification.data, navigate);
            }
        );

        await LocalNotifications.addListener(
            'localNotificationActionPerformed',
            (action) => {
                console.log(
                    'Local notification action performed: ' +
                        JSON.stringify(action)
                );
                datadogLogs.logger.info('Local notification action performed', {
                    localNotificationAction: action.actionId,
                });
                handleNotificationAction(action.notification.extra, navigate);
            }
        );
    } catch (e) {
        datadogLogs.logger.error(
            'Could not initialize push notifications',
            undefined,
            e instanceof Error ? e : undefined
        );
    }
}

interface ExtraData {
    navigateTo?: string;
}

function isNotificationExtraData(data: unknown): data is ExtraData {
    const keys: Array<keyof ExtraData> = ['navigateTo'];
    return (
        !!data &&
        Object.getOwnPropertyNames(data).some(
            (name) => keys.findIndex((key) => key === name) !== -1
        )
    );
}

function handleNotificationAction(data: unknown, navigate: NavigateFunction) {
    if (!isNotificationExtraData(data)) {
        return;
    }

    if (data.navigateTo) {
        console.log('Navigate to:', data.navigateTo);
        // HACK to make old url format work in Capacitor app
        const navigateTo = data.navigateTo.replace('carcare://', '/');
        const [path, search] = navigateTo.split('?');

        navigate({ pathname: path, search: search });
    }
}

export const initPushNotificationDevice = (
    userIds: UserIds,
    navigate: NavigateFunction
) => {
    if (Capacitor.getPlatform() !== 'web') {
        void initializePushNotifications(userIds, navigate);
    }
};
