import { useQuery } from '@tanstack/react-query';
import { useFetchersContext } from 'external-apis';
import { DealerAffiliation } from 'external-apis/src/types/bilhold';
import {
    CountryCode,
    DealerGroup,
    DealerType,
    DealerViewModel,
} from 'external-apis/src/types/port';
import {
    FriendlyQueryError,
    FriendlyQueryWarning,
} from '../../lib/errors/PortError';
import {
    LanguageContextType,
    useLanguageContext,
} from '../../lib/languages/languageContext';
import { STALE_TIME } from '../../lib/query-client/config';
import { useGetApiLocaleConfig } from '../apiLocale';

const useFindDealers = () => {
    const [fetchers] = useFetchersContext();
    return fetchers.port.fetcher.path('/dealers').method('get').create();
};

const useFindDealerAffiliations = () => {
    const [fetchers] = useFetchersContext();
    return fetchers.bilhold.fetcher
        .path('/v1/preferred-dealers/affiliation')
        .method('get')
        .create();
};

export const useGetDealers = (
    dealerGroup?: DealerGroup,
    dealerType?: DealerType
) => {
    const findDealers = useFindDealers();
    const [lc] = useLanguageContext();
    const { countryCode } = useGetApiLocaleConfig();

    const query = {
        countryCode,
        dealerType,
        dealerGroup,
    };

    return useQuery<DealerViewModel[], Error>({
        queryKey: ['chooseDealer', query],
        staleTime: STALE_TIME.hour,
        queryFn: () => getDealers(query, lc, findDealers),
    });
};

async function getDealers(
    query: {
        countryCode: CountryCode;
        dealerType?: DealerType;
        dealerGroup?: DealerGroup;
    },
    lc: LanguageContextType,
    findDealers: ReturnType<typeof useFindDealers>
) {
    try {
        const result = await findDealers(query);
        return result.data;
    } catch (e) {
        if (e instanceof findDealers.Error) {
            throw new FriendlyQueryError(
                lc.errors.port_dealers_default,
                e,
                e.status
            );
        }
        throw e;
    }
}

export const useGetDealerAffiliation = (vin: string) => {
    const [lc] = useLanguageContext();
    const findDealerAffiliations = useFindDealerAffiliations();

    return useQuery<DealerAffiliation | null, Error>({
        queryKey: ['affiliatedDealers', vin],
        queryFn: () => getDealerAffiliations(lc, vin, findDealerAffiliations),
        refetchOnWindowFocus: false,
    });
};

async function getDealerAffiliations(
    language: LanguageContextType,
    vin: string,
    findDealerAffiliations: ReturnType<typeof useFindDealerAffiliations>
) {
    try {
        const result = await findDealerAffiliations({});
        return result.data.find((x) => x.vin === vin) ?? null;
    } catch (e) {
        if (e instanceof findDealerAffiliations.Error) {
            throw new FriendlyQueryWarning(
                language.errors.preferred_dealer_failed,
                e as Error,
                e.status
            );
        }
        throw e;
    }
}
