import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useFetchersContext } from 'external-apis';

const useDeleteDamageAppraisal = () => {
    const [fetchers] = useFetchersContext();
    return fetchers.damageappraisal.fetcher
        .path('/v3/damageAppraiser/id/{damageId}')
        .method('delete')
        .create();
};

export const useDeleteDamageAppraiser = (
    setModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>
) => {
    const queryClient = useQueryClient();
    const deleteDamageAppraiser = useDeleteDamageAppraisal();
    return useMutation({
        mutationFn: (id: number) =>
            deleteDamageAppraiser({ damageId: id.toString() }),
        onSuccess: () => {
            void queryClient.invalidateQueries({
                queryKey: ['damageAppraiser'],
            });
            setModalIsOpen(false);
        },
    });
};
