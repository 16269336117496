import { Middleware } from 'openapi-typescript-fetch';

/**
 * Middleware which allows us to enhance a thrown error with the URL of the request,
 * since this information is otherwise not always available (e.g. on network errors)
 */
export const errorUrlEnhancerMiddleware: Middleware = async (
    url,
    init,
    next
) => {
    try {
        return await next(url, init);
    } catch (err) {
        (err as ErrorWithUrl).fetcher_url = url;
        throw err;
    }
};

export type ErrorWithUrl = Error & { fetcher_url?: string };
