import { InformationArticle } from '@bilhold/sanity';
import {
    AlertBanner,
    Headline,
    LoadingSpinner,
    TextContainer,
} from '@moller/design-system';
import { useNavigate } from 'react-router-dom';
import RichText from 'src/sanity/components/RichText';
import { useSanityData } from 'src/sanity/useSanityData';
import { CenteredPageContent } from 'src/shared/CenteredPageContent';

interface Props {
    articleSlug: string;
}
export const InformationArticleSanity = (props: Props) => {
    const query = `*[_type == "informationArticle" && slug.current == $articleSlug][0]`;
    const { data, isLoading, error } = useSanityData<InformationArticle>(
        query,
        { articleSlug: props.articleSlug }
    );

    const navigate = useNavigate();

    const reload = () => {
        navigate('/' + props.articleSlug);
    };

    if (isLoading) return <LoadingSpinner isFullScreen />;
    else if (error)
        return (
            <CenteredPageContent showBackButton>
                <AlertBanner
                    type="error"
                    message={
                        'Det oppstod en feil da vi prøvde å hente informasjon om personvernerklæringen.'
                    }
                    action={{
                        label: 'Prøv igjen',
                        onClick: reload,
                    }}
                />
            </CenteredPageContent>
        );

    return (
        <CenteredPageContent showBackButton>
            <TextContainer>
                <Headline>{data && data.name}</Headline>
                {data && data.bodytext && <RichText value={data.bodytext} />}
            </TextContainer>
        </CenteredPageContent>
    );
};
