import { MockData } from '../data';

const getTireHotelDeal = (
    vin: string,
    mockData: MockData
): { isTireHotelCar: boolean } | null => {
    const car = mockData.cars.find((car) => car.vin === vin);

    if (!car) {
        return null;
    }

    return {
        isTireHotelCar: car.hasTireHotel,
    };
};

export default getTireHotelDeal;
