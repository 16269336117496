import { Logo } from '@moller/design-system';
import { LogoBrand } from '@moller/design-system/foundation/logos/Logo.js';
import { styled } from 'styled-components';
import { CarTransition } from './CarTransition';

export const AnimatedLogo = styled.div.attrs<{ brand: LogoBrand }>(
    ({ brand }) => ({
        children: (
            <CarTransition brand={brand}>
                <Logo brand={brand} type="symbol" />
            </CarTransition>
        ),
    })
)<{ brand: string }>`
    * svg {
        animation: 0.75s fadeIn 0.5s ease-in-out;
        animation-fill-mode: forwards;
        height: ${({ brand }) =>
            brand === 'audi' ? '70px' : '135px'} !important;
        opacity: 0;
        color: ${({ brand }) =>
            brand === 'vw' && 'var(--moller-color-primary)'};
    }

    @keyframes fadeIn {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }
`;
