import { InfoCard, OverlineText } from '@moller/design-system';
import { styled } from 'styled-components';
import {
    PreferredAffiliationDealerResponse,
    usePreferredAffiliationDealers,
} from '../../home/queries/usePreferredDealer';
import { ContactInfoCard } from './ContactInfoCard';

const StyledOverlineText = styled(OverlineText)`
    margin-top: var(--moller-spacing-l);
    margin-bottom: var(--moller-spacing-xs);
    font-weight: bold;
`;

const DealerContactInfo = () => {
    const { data: preferredDealerResponse, isLoading } =
        usePreferredAffiliationDealers();

    const allPreferredDealers: PreferredAffiliationDealerResponse[] =
        preferredDealerResponse || [];

    // remove duplicates from the list of preferred dealers
    const uniqueDealers = Array.from(
        new Set(allPreferredDealers.map((dealer) => dealer.preferredDealerId))
    ).map((id) =>
        allPreferredDealers.find((dealer) => dealer.preferredDealerId === id)
    );

    if (!uniqueDealers || uniqueDealers.length === 0) {
        return null;
    }

    return (
        <div>
            <StyledOverlineText>Dine forhandlere</StyledOverlineText>
            {uniqueDealers.map((preferredDealer) => {
                preferredDealer =
                    preferredDealer as PreferredAffiliationDealerResponse;

                return isLoading ? (
                    <InfoCard
                        key={preferredDealer.preferredDealerId}
                        isLoading
                    />
                ) : (
                    <ContactInfoCard
                        key={preferredDealer?.preferredDealerId}
                        title={preferredDealer.preferredDealerName}
                        description={
                            'Ved spørsmål om verkstedstime, bil i bestilling, bilmodeller, tilbehør, ekstrautstyr eller pristilbud'
                        }
                        phoneLabel={'Ring forhandler'}
                        phoneNumber={preferredDealer.dealerPhone}
                        email={preferredDealer.dealerEmail}
                    />
                );
            })}
        </div>
    );
};

export default DealerContactInfo;
