import {
    Column,
    Description,
    LeadText,
    Row,
    breakpointMobile,
} from '@moller/design-system';
import { styled } from 'styled-components';
import { TDFCup } from '../../assets/TDFCup';
import { TDFShirt } from '../../assets/TDFShirt';
import { Ticket } from './Ticket';

const CompetitionFinishedLeadText = styled(LeadText)`
    font-size: 1.6rem;
    font-weight: bold;
    text-align: center;
    @media screen and (max-width: ${breakpointMobile}) {
        font-size: 1.2rem;
    }
`;

const CompetitionFinishedDescriptionText = styled(Description)`
    font-size: 1.4rem;
    text-align: center;
    @media screen and (max-width: ${breakpointMobile}) {
        font-size: 0.9rem;
    }
`;

const LeftTicketContainer = styled.div`
    --translate-x: -7rem;
    --translate-y: 3.5rem;
    --rotate: -60deg;
    --scale: 1;

    transform: translate(var(--translate-x), var(--translate-y))
        rotate(var(--rotate)) scale(var(--scale));
    @media screen and (max-width: ${breakpointMobile}) {
        --translate-x: -3.2rem;
        --translate-y: 3.2rem;
        --scale: 0.7;
    }
`;

const RightTicketContainer = styled.div`
    --translate-x: 8.5rem;
    --translate-y: 0rem;
    --rotate: 5deg;
    --scale: 1;

    transform: translate(var(--translate-x), var(--translate-y))
        rotate(var(--rotate)) scale(var(--scale));
    @media screen and (max-width: ${breakpointMobile}) {
        --translate-x: 3.3rem;
        --translate-y: -0.6rem;
        --scale: 0.7;
    }
`;

const ShirtIconContainer = styled.div`
    --translate-x: -6rem;
    --translate-y: 1.2rem;
    --rotate: -18deg;
    --scale: 1.8;

    transform: translate(var(--translate-x), var(--translate-y))
        rotate(var(--rotate)) scale(var(--scale));
    @media screen and (max-width: ${breakpointMobile}) {
        --translate-x: -3.5rem;
        --translate-y: 1.2rem;
        --scale: 1.2;
    }
`;

const CupIconContainer = styled.div`
    --translate-x: 5.5rem;
    --translate-y: 3.5rem;
    --rotate: 25deg;
    --scale: 1.6;

    transform: translate(var(--translate-x), var(--translate-y))
        rotate(var(--rotate)) scale(var(--scale));
    @media screen and (max-width: ${breakpointMobile}) {
        --translate-x: 3.8rem;
        --translate-y: 3.2rem;
        --scale: 1.2;
    }
`;

export const CompetitionFinishedContent = () => {
    return (
        <Column
            gap={'xs'}
            horizontalAlign="center"
            style={{ padding: '0px 0px 30px 0px' }}
        >
            <Row style={{ position: 'relative' }}>
                <LeftTicketContainer>
                    <Ticket />
                </LeftTicketContainer>
                <ShirtIconContainer>
                    <TDFShirt fill="#FAEB67" width={'30'} height={'30'} />
                </ShirtIconContainer>
                <CupIconContainer>
                    <TDFCup width={'25'} height={'25'} />
                </CupIconContainer>
                <RightTicketContainer>
                    <Ticket />
                </RightTicketContainer>
            </Row>
            <Row>
                <CompetitionFinishedLeadText>
                    Gratulerer!
                </CompetitionFinishedLeadText>
            </Row>
            <Row>
                <CompetitionFinishedDescriptionText>
                    Du har rundet hele touren og er i mål!
                </CompetitionFinishedDescriptionText>
            </Row>
        </Column>
    );
};
