import { formatDealerName } from 'src/utils/formatDealerName';

const googleMapsApiKey = 'AIzaSyCPZ4nT3HpKRzeD2OgGpPU3j9iKQaoNhK0';

export const buildMapUrl = (
    dealer: string,
    address: string,
    postalArea: string
) => {
    const dealerName = formatDealerName(dealer);
    const addressString = encodeURI(`${dealerName}, ${address}, ${postalArea}`);
    return {
        image: `https://maps.googleapis.com/maps/api/staticmap?zoom=14&scale=2&size=410x200&key=${googleMapsApiKey}&markers=label:Se%7Csize:mid%7C${addressString}`,
        url: `https://www.google.com/maps/search/?api=1&query=${addressString}`,
    };
};
