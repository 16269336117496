import { useEffect } from 'react';

export const useStickyScroll = (
    isScrollActive: boolean,
    setIsScrollActive: (value: boolean) => void
) => {
    useEffect(() => {
        const handleStichyHeader = () => {
            if (window.scrollY < 0 || window.scrollY === 0) {
                setIsScrollActive(false);
            }
            if (window.scrollY > 0) {
                setIsScrollActive(true);
            }
        };
        window.addEventListener('scroll', handleStichyHeader);
        return () => window.removeEventListener('scroll', handleStichyHeader);
    }, [isScrollActive, setIsScrollActive]);
    return isScrollActive;
};
