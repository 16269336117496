import { STORAGE_KEY_OVERRIDE_ENVIRONMENT } from 'src/utils/auth/constants';
import LocalStorage from 'src/utils/browser/LocalStorage';
import Environment from 'src/utils/environment/Environment';
import {
    EnvironmentName,
    environmentNameFromLocation,
    getEnvironment,
} from 'src/utils/environment/environments';

function getCurrentEnvironment(): Environment {
    const overrideEnvironment = LocalStorage.get(
        STORAGE_KEY_OVERRIDE_ENVIRONMENT
    ) as EnvironmentName;
    if (overrideEnvironment) {
        return getEnvironment(overrideEnvironment);
    }

    const environmentName = environmentNameFromLocation(window.location);
    return getEnvironment(environmentName);
}

export default getCurrentEnvironment;

export function getCurrentEnvironmentName(): EnvironmentName | undefined {
    return getCurrentEnvironment().name;
}
