import { CapacitorUpdater } from '@capgo/capacitor-updater';
import 'react-loading-skeleton/dist/skeleton.css';
import './bilhold.scss';
import { initializeMobileApp } from './initialization/mobile-app';
import './initialization/moment-config';
import { renderReactApp } from './initialization/renderReactApp';
import {
    setAccessToken,
    setRefreshToken,
} from './utils/auth/authenticationUtils';
import {
    STORAGE_KEY_ACCESS_TOKEN,
    STORAGE_KEY_REFRESH_TOKEN,
} from './utils/auth/constants';
import activateMockData from './utils/data-mocks/activateMockData';
import { setupAppDeepLinkHandling } from './utils/setupAppDeepLinkHandling';
import { getFromStorage } from './utils/storage';

async function initTokens() {
    const refreshToken = await getFromStorage(STORAGE_KEY_REFRESH_TOKEN);

    if (refreshToken != null) {
        await setRefreshToken(refreshToken);

        const accessToken = await getFromStorage(STORAGE_KEY_ACCESS_TOKEN);

        if (accessToken != null) {
            await setAccessToken(accessToken);
        }

        return;
    }
}

setupAppDeepLinkHandling();

void initTokens()
    .then(activateMockData)
    .then(initializeMobileApp)
    .then(renderReactApp)
    .then(() => CapacitorUpdater.notifyAppReady());
