import { createContext, useContext } from 'react';
import { AuthCallbacks } from '../../_api/middlewares/authMiddleware';

export interface UserProfile {
    partyId: string;
    email: string;
    phone: string;
}

export type AuthScheme = {
    callbacks: AuthCallbacks;
    user: UserProfile;
};

const AuthContext = createContext<AuthScheme | null>(null);

type AuthProviderProps = {
    authScheme: AuthScheme;
    children: JSX.Element;
};

export function AuthProvider({ authScheme, children }: AuthProviderProps) {
    return (
        <AuthContext.Provider value={authScheme}>
            {children}
        </AuthContext.Provider>
    );
}

export function useAuthContext() {
    const authContext = useContext(AuthContext);
    if (!authContext) {
        throw new Error('Authentication level was not provided');
    }
    return authContext;
}
