import { addMonths, isAfter } from 'date-fns';

export function vehicleOlderThanXMonths(
    regDate: string | undefined,
    months: number
) {
    if (!regDate) {
        return undefined;
    }

    const registrationDatePlusMonths = addMonths(new Date(regDate), months);

    const todayDate = new Date();
    return isAfter(todayDate, registrationDatePlusMonths);
}
