import { CountryCode } from 'external-apis/src/types/port';
import { useAppConfig } from '../lib/config/configContext';

export const apiLocaleIdentifiers = ['no', 'lv'] as const;
export type ApiLocaleIdentifier = (typeof apiLocaleIdentifiers)[number];

type ApiLocaleConfig = {
    countryCode: CountryCode;
    timeZone: string;
};

const apiLocaleConfig: Record<ApiLocaleIdentifier, ApiLocaleConfig> = {
    no: {
        countryCode: 'NOR',
        timeZone: 'Europe/Oslo',
    },
    lv: {
        countryCode: 'LVA',
        timeZone: 'Europe/Riga',
    },
};

export function useGetApiLocaleConfig() {
    const { apiLocaleId } = useAppConfig();
    return apiLocaleConfig[apiLocaleId];
}
