import { styled } from 'styled-components';

const StyledTicket = styled.div`
    border: 1px solid var(--moller-color-primary);
    color: var(--moller-color-primary);
    padding: 0.08rem 0.2rem;
    display: inline-flex;
    transform: rotate(0.11turn);
    font-size: 0.5rem;
    height: fit-content;
`;

export const TicketSmall = () => <StyledTicket>Lodd</StyledTicket>;
