import { LogoBrand } from '@moller/design-system/foundation/logos/Logo.js';
import { Route, Routes } from 'react-router-dom';
import NoContent from 'src/routing/NoContent';
import Brand from 'src/types/Brand';
import { themeOverride } from 'src/utils/dev-tools/devToolsUtils';
import { ChildThemeProvider } from 'theming';
import WarrantyAudi from './WarrantyAudi';
import WarrantyCupra from './WarrantyCupra';
import WarrantySeat from './WarrantySeat';
import WarrantySkoda from './WarrantySkoda';
import WarrantyVw from './WarrantyVw';

const checkBrandFromPath = (brandFromUrl: string) => {
    if (brandFromUrl.includes(Brand.VW)) {
        return 'vw';
    } else if (brandFromUrl.includes(Brand.AUDI)) {
        return 'audi';
    } else if (brandFromUrl.includes(Brand.SKODA)) {
        return 'skoda';
    } else if (brandFromUrl.includes(Brand.SEAT)) {
        return 'seat';
    } else if (brandFromUrl.includes(Brand.CUPRA)) {
        return 'cupra';
    } else if (brandFromUrl.includes(Brand.NYTTE)) {
        return 'nytte';
    } else {
        return 'bilhold';
    }
};

const WarrantyContainer = () => {
    const brandName = (brandType: Brand) => Brand[brandType];
    const brandFromUrl = window.location.href?.toUpperCase();
    const brand = checkBrandFromPath(brandFromUrl);

    return (
        <ChildThemeProvider
            theme={brand as LogoBrand}
            overrideTheme={themeOverride}
        >
            <Routes>
                <Route
                    path={`${brandName(Brand.AUDI)}`}
                    element={<WarrantyAudi />}
                />
                <Route
                    path={`${brandName(Brand.SKODA)}`}
                    element={<WarrantySkoda />}
                />
                <Route
                    path={`${brandName(Brand.SEAT)}`}
                    element={<WarrantySeat />}
                />
                <Route
                    path={`${brandName(Brand.VW)}`}
                    element={<WarrantyVw />}
                />
                <Route
                    path={`${brandName(Brand.CUPRA)}`}
                    element={<WarrantyCupra />}
                />
                <Route
                    path={`${brandName(Brand.NYTTE)}`}
                    element={<WarrantyVw />}
                />
                <Route path="*" element={<NoContent />} />
            </Routes>
        </ChildThemeProvider>
    );
};

export default WarrantyContainer;
