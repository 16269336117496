import { Product } from 'src/features/home/utils/productType';
import Brand from 'src/types/Brand';
import SkodaUnderlagBarnestol from '../product-images/skoda/skoda-beskytter-barnesete.webp';
import SkodaHjemmeladerImage from '../product-images/skoda/skoda-defa-lader.webp';
import SkodaSykkelholder from '../product-images/skoda/skoda-enyaq_sykkelstativ.webp';
import SkodaDekseltrakt from '../product-images/skoda/skoda-fylletrakt.webp';
import SkodaSkipose from '../product-images/skoda/skoda-skipose.webp';
import SkodaLastekantlist from '../product-images/skoda/skoda-sort-lastekantlist.webp';
import SkodaTakstativ from '../product-images/skoda/skoda-takstativ.webp';

const ENYAQ = 'ENYAQ';
const ENYAQ_IV = `${ENYAQ} IV`; // iV 50, 60, 80, 80x,
const ENYAQ_RS_IV = `${ENYAQ} RS IV`;
const ENYAQ_COUPE = `${ENYAQ} COUPE`; // Coupe iV 60, 80, 80X

export const SkodaProducts: Product[] = [
    {
        articleNumber: '000071105P',
        packageNumber: 'G311 - 03',
        image: SkodaSykkelholder,
        title: 'Sykkelholder',
        descriptionShort:
            'Original sykkelholder for tilhengerfeste - 3 sykler.',
        descriptionLong:
            'Solid originalt sykkelstativ for kjøretøy med tilhengerfeste. Stativet er enkelt å montere og bruke, og har plass til opptil tre sykler.',
        text: 'Maksimal belastning avhenger av tilhenger-festets kapasitet, som du finner i kjøretøyets registreringsdokument. Stativet kan også låses for å beskytte dine sykler mot tyveri.',
        price: 7900,
        carBrand: Brand.SKODA,
        carModel: [ENYAQ_IV, ENYAQ_RS_IV, ENYAQ_COUPE],
    },
    {
        articleNumber: 'Skoda Beskyttelsespakke sort',
        packageNumber: 'A010/A011',
        image: SkodaLastekantlist,
        title: 'Beskyttelsespakke',
        descriptionShort: 'Sort lastekant inkl. montering.',
        descriptionLong:
            'Denne pakken inneholder beskyttelseslist for lastekant, dørterskel foran og elektronisk parkeringsur. Prisen ikluderer også montering',
        text: '',
        price: 5250,
        carBrand: Brand.SKODA,
        carModel: [ENYAQ_IV, ENYAQ_RS_IV],
    },
    {
        articleNumber: 'Skoda DEFAPOWER + DEFABALANCER',
        packageNumber: 'A030-04',
        image: SkodaHjemmeladerImage,
        title: 'Hjemmelader',
        descriptionShort: 'DEFA Power med Balancer.',
        descriptionLong:
            'Den smarteste måten å kontrollere strømforbruket ditt, slik at du kan lade til lavest mulig kostnad med maksimal effekt.',
        text: 'DEFA Power har et intuitivt display og 6 meter integrert Type 2-ladekabel som forenkler din daglige bruk og gir deg full kontroll over ladingen. DEFA Power optimaliserer tilgjengelig strøm, og med PowerSmart kan du unngå strømtopper og dermed unngå å lade når strømmen er dyrest.',
        specList: [
            'Maksimal effekt - opp til 22kW',
            'DEFA Power leverer lovet effekt, uansett om du kan motta 7,4kW, 11kW eller 22kW.',
            'Gjennom en intelligent konstruksjon og avanserte simuleringer er den designet for å sikre raskest mulig lading.',
            'PowerSmart™ med Power Appen',
            'Forhåndsinnstill at du vil lade når strømprisene er lave, basert på daglige priser fra Nord Pool.',
            'Montering er inkludert',
        ],
        price: 17990,
        carBrand: Brand.SKODA,
        carModel: [ENYAQ_IV, ENYAQ_RS_IV, ENYAQ_COUPE],
    },
    {
        articleNumber: '5LG071151',
        packageNumber: 'G200',
        image: SkodaTakstativ,
        title: 'Škoda takstativ',
        descriptionShort: 'Originalt takstativ tilpasset ENYAQ iV.',
        descriptionLong:
            'Skoda originalt takstativ er et praktisk tilbehør som lar deg enkelt transportere større gjenstander som ikke får plass inne i bilen. Den aero- dynamiske formen på takstativet bidrar til å redusere luftmotstanden og støyen inne i bilen. Den stilige, flate designen passer perfekt til ENYAQs estetikk.',
        text: 'Takstativet er enkelt å montere - for å feste det til takrailsene, trenger du bare å vippe en spak til nedre posisjon. Det er også låsbart, slik at du ikke trenger å bekymre deg for tyveri. Ved hjelp av T-spor kan ulike transportsystemer som sykkelstativer, takbokser og takbokser monteres på dette stativet.',
        price: 3590,
        carBrand: Brand.SKODA,
        carModel: [ENYAQ_IV, ENYAQ_RS_IV, ENYAQ_COUPE],
    },
    {
        articleNumber: '000050515D',
        packageNumber: 'G301',
        image: SkodaSkipose,
        title: 'Skipose',
        descriptionShort: 'Original skibag kan romme fire par ski og staver.',
        descriptionLong:
            'Denne Skiposen er en praktisk følgesvenn for vinterlige sportsutflukter, og den byr på flere nyttige egenskaper.',
        text: '',
        specList: [
            'Beskytter skiene dine mot riper og skader. Vanntett: Laget av vannavstøtende materiale med vanntette sømmer.',
            'Laget av vannavstøtende materiale med vanntette sømmer.',
            'Kan romme opptil 4 par ski med en maksimal vekt på 24 kg. Enkel montering: Enkel å feste til bilsetet og sikre på plass. Lomme: Inneholder en praktisk lomme for små gjenstander.',
            'Leveres med to slitesterke stropper for enkel transport.',
        ],
        price: 1790,
        carBrand: Brand.SKODA,
        carModel: [ENYAQ_IV, ENYAQ_RS_IV, ENYAQ_COUPE],
    },
    {
        articleNumber: '000096706',
        packageNumber: 'A042',
        image: SkodaDekseltrakt,
        title: 'Dekseltrakt',
        descriptionShort: 'Trakt til spyleveskebeholderen.',
        descriptionLong:
            'Smart lokk for spylervæskebeholderen som blir til en trakt når du åpner lokket. Gjør det enklere å fylle spylervæske samtidig som det blir mindre søl ved påfyll.',
        text: '',
        price: 89,
        carBrand: Brand.SKODA,
        carModel: [ENYAQ_IV, ENYAQ_RS_IV, ENYAQ_COUPE],
    },
    {
        articleNumber: '000019819A',
        packageNumber: 'C205',
        image: SkodaUnderlagBarnestol,
        title: 'Underlag barnestol',
        descriptionShort: 'Skoda originalt underlag for barnestol.',
        descriptionLong:
            'Et underlag under barnesetet gir nødvendig beskyttelse mot avtrykk i setet. Underlaget beskytter også setet mot slitasje og flekker, og forlenger levetiden til interiøret. ',
        text: 'Takket være antisklimønstrene forblir underlaget på plass, noe som sikrer stabilitet. Med praktiske nettlommer kan barna enkelt oppbevare leker og små gjenstander innen rekkevidde. Underlaget er kompatibel med alle typer barneseter.',
        price: 499,
        carBrand: Brand.SKODA,
        carModel: [ENYAQ_IV, ENYAQ_RS_IV, ENYAQ_COUPE],
    },
];
