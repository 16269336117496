import {
    AllowedIcons,
    Column,
    Icon,
    Row,
    Subtitle,
    breakpointTablet,
} from '@moller/design-system';
import { useEffect, useState } from 'react';
import { styled } from 'styled-components';

import { removeUnit } from '@moller/design-system/utilities/tokens';

const StyledImg = styled.img`
    border-radius: var(--moller-spacing-base);
    object-fit: cover;
    width: 80px;
    height: auto;
    max-height: 100%;
    @media screen and (min-width: ${breakpointTablet}) {
        height: 118px;
        width: 118px;
    }
`;

const StyledColumn = styled(Column)`
    flex-grow: 1;
`;

const WebRow = styled(Row)`
    gap: var(--moller-spacing-s);
    width: 100%;
    flex-direction: row-reverse;
    align-items: stretch;

    @media screen and (min-width: ${breakpointTablet}) {
        flex-direction: row;
    }

    * {
        text-align: start;
    }
`;

const Desc = styled.div`
    text-align: start;
    margin-top: var(--moller-spacing-base);
    font-size: var(--moller-type-large-description-font-size);
    line-height: var(--moller-type-large-description-line-height);
    @media screen and (min-width: ${breakpointTablet}) {
        font-size: var(--moller-type-large-description-font-size);
        margin-bottom: var(--moller-spacing-s);
    }
`;

const Highlighted = styled.div`
    margin-bottom: var(--moller-spacing-xxs);
    position: absolute;
    bottom: 0;
    right: var(--moller-spacing-xs);
    height: var(--moller-spacing-s);
    font-size: var(--moller-spacing-xs);
    line-height: 150%;
    border: none;
    padding: var(--moller-spacing-base);
    border-radius: var(--moller-spacing-base);
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: var(--moller-spacing-base);

    @media screen and (min-width: ${breakpointTablet}) {
        position: static;
        font-size: var(--moller-typeface-size-s);
    }
`;

const Description = styled(Desc)<{ $withMargin: boolean }>`
    margin-bottom: ${(props) => (props.$withMargin ? '24px' : '0px')};

    @media screen and (min-width: ${breakpointTablet}) {
        margin-bottom: 0;
    }
`;

interface CardLayoutProps {
    title: string;
    body: string;
    ImageSrc: string;
    overlineText?: string;
    iconColor?: string;
    icon?: AllowedIcons;
    iconTextBackground?: string;
    iconTextColor?: string;
}

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height,
    };
}

export const CardLayout = ({
    overlineText,
    title,
    body,
    iconColor,
    iconTextBackground,
    iconTextColor,
    icon,
    ImageSrc,
}: CardLayoutProps) => {
    const [windowDimensions, setWindowDimensions] = useState(
        getWindowDimensions()
    );

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <WebRow>
            <StyledColumn>
                {icon && (
                    <Highlighted
                        style={{
                            backgroundColor: iconTextBackground,
                            color: iconTextColor,
                        }}
                    >
                        <Icon
                            icon={icon}
                            color={iconColor}
                            size={
                                windowDimensions.width >
                                removeUnit(breakpointTablet)
                                    ? 'm'
                                    : 's'
                            }
                        />
                        <span>{overlineText}</span>
                    </Highlighted>
                )}
                <Subtitle $as="h3">{title}</Subtitle>
                <Description $withMargin={!!icon}>{body}</Description>
            </StyledColumn>
            <StyledImg
                className="cardImage"
                src={ImageSrc}
                alt="Team standing together"
            />
        </WebRow>
    );
};
