import { ReasonInfos } from './types';

const reasons = {
    RelationAlreadyExisting: {
        title: 'leaserRelationAlreadyExistingTitle',
        description: 'leaserRelationAlreadyExistingDescription',
        buttonText: 'iUnderstand',
    },
    ProprietorNotExisting: {
        title: 'leaserProprietorNotExistingTitle',
        description: 'leaserProprietorNotExistingDescription',
        buttonText: 'iUnderstand',
    },
    ProprietorNotValid: {
        title: 'leaserProprietorNotValidTitle',
        description: 'leaserProprietorNotValidDescription',
        buttonText: 'iUnderstand',
    },
} as const;

const leaserReasons: ReasonInfos<typeof reasons> = reasons;

export default leaserReasons;
