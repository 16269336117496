import { useMutation, useQueryClient } from '@tanstack/react-query';
import getCurrentEnvironment from 'src/utils/environment/getCurrentEnvironment';
import patch from 'src/utils/http/patch';
import { prizeKey } from 'src/features/competition/utils/useCompetitionPrizeData';

export type UpdatePrizeDataPayload = Partial<{
    dealer: string | undefined;
}>;

export const useUpdateStreakPrizeData = () => {
    const queryClient = useQueryClient();
    const { bilholdApiUrl } = getCurrentEnvironment();
    const url = `${bilholdApiUrl}/v1/competition/update-streak-prize`;
    return useMutation({
        mutationFn: (payload: UpdatePrizeDataPayload) => patch(url, payload),
        onSuccess: async () => {
            return await queryClient.invalidateQueries({
                queryKey: [prizeKey],
            });
        },
    });
};
export const useUpdateDistancePrizeData = () => {
    const queryClient = useQueryClient();
    const { bilholdApiUrl } = getCurrentEnvironment();
    const url = `${bilholdApiUrl}/v1/competition/update-distance-prize`;
    return useMutation({
        mutationFn: (payload: UpdatePrizeDataPayload) => patch(url, payload),
        onSuccess: async () => {
            return await queryClient.invalidateQueries({
                queryKey: [prizeKey],
            });
        },
    });
};
export const useUpdateElevationPrizeData = () => {
    const queryClient = useQueryClient();
    const { bilholdApiUrl } = getCurrentEnvironment();
    const url = `${bilholdApiUrl}/v1/competition/update-elevation-prize`;
    return useMutation({
        mutationFn: (payload: UpdatePrizeDataPayload) => patch(url, payload),
        onSuccess: async () => {
            return await queryClient.invalidateQueries({
                queryKey: [prizeKey],
            });
        },
    });
};
