import { datadogRum } from '@datadog/browser-rum';
import {
    Column,
    GhostButton,
    Icon,
    PrimaryButton,
    Row,
    Subtitle,
} from '@moller/design-system';
import { LogoBrand } from '@moller/design-system/foundation/logos/Logo.js';
import { BookingViewModel } from 'external-apis/src/types/port';
import { useToggle } from 'feature-toggle';
import { useNavigate } from 'react-router-dom';
import {
    getMessageWithAppointmentId,
    partialAppointmentGuidToSafeNumberId,
} from 'src/features/contact/utils/messagesUtils';
import { PATH_DETAILED_APPOINTMENT } from 'src/routing/routes';
import { useMessageThreads } from 'src/utils/hooks/useMessageThreads';
import lang from 'src/utils/lang';
import { isMobileApp } from 'src/utils/mobile';
import { styled } from 'styled-components';
import { useThemeContext } from 'theming';
import { DateLayout } from './DateLayout';

interface CardLayoutProps {
    date: string;
    time: string;
    dealer: string;
    services?: string[];
    setCampaignModalIsOpen: (isOpen: boolean) => void;
    appointment: BookingViewModel;
}

function formatServices(services?: string[] | null) {
    if (
        !services ||
        services?.length > 1 ||
        services?.length === 0 ||
        !services[0]
    ) {
        return lang.service_appointment;
    }
    const cleanService = services?.[0]?.replace(
        /[^a-zA-ZÆØÅæøå0-9+.,/:\-() ]/g,
        ''
    );
    return cleanService;
}

const FlexContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: var(--moller-spacing-xs);
`;

const StyledGhostButton = styled(GhostButton)<{ brand: LogoBrand }>`
    ${({ brand }) =>
        /* 
            Overriding dahles, as tokens in design system are not accessible.
        */
        brand === 'dahles' &&
        `
        border-color: var(--moller-color-on-primary);
        color: var(--moller-color-on-primary);
    `}
`;

export const AppointmentCardLayout = ({
    date,
    time,
    dealer,
    services,
    setCampaignModalIsOpen,
    appointment,
}: CardLayoutProps) => {
    const service = formatServices(services);
    const { theme } = useThemeContext();

    const navigate = useNavigate();

    const messageFunctionality = useToggle<string[]>(
        'DealersWithMessageFunctionality'
    );
    const dealersWithMessageFunctionality =
        messageFunctionality?.variation.value ?? ([] as string[]);

    const { data: messages } = useMessageThreads();

    const appointmentIdAsNumber = partialAppointmentGuidToSafeNumberId(
        appointment.id
    );

    const earlierSentMessage = getMessageWithAppointmentId(
        messages,
        appointmentIdAsNumber
    );

    /*
        Even though we have turned off message in unleash for certain dealers, 
        we want to allow users who have started messages to easily see shortcut to started threads
    */
    const shouldShowSendMessage =
        !!earlierSentMessage ||
        dealersWithMessageFunctionality.includes(appointment.dealer.id);

    function handleNavigateToMessage() {
        datadogRum.addAction('bilhold-send-message-home-page');

        if (earlierSentMessage) {
            navigate(
                `/messages/messages-contact/thread/${earlierSentMessage.threadId}`
            );
        } else {
            navigate(`/messages/messages-contact/new/${appointment.id}`);
        }
    }

    return (
        <FlexContainer>
            <Row gap="s">
                <DateLayout date={date} />
                <Column gap="base">
                    <Subtitle>{service}</Subtitle>
                    <p>{`${time} | ${dealer?.replace(' AS', '')}`}</p>
                </Column>
            </Row>
            <Row gap={'s'}>
                <PrimaryButton
                    density="compact"
                    onClick={() =>
                        isMobileApp()
                            ? setCampaignModalIsOpen(true)
                            : navigate(
                                  `${PATH_DETAILED_APPOINTMENT}/${appointment.id}`
                              )
                    }
                >
                    <Icon icon="assignment" /> Se detaljer
                </PrimaryButton>
                {shouldShowSendMessage && (
                    <>
                        <StyledGhostButton
                            onClick={handleNavigateToMessage}
                            density="compact"
                            brand={theme}
                        >
                            <Icon icon="chat" />{' '}
                            {earlierSentMessage
                                ? 'Fortsett samtale'
                                : 'Send melding'}
                        </StyledGhostButton>
                    </>
                )}
            </Row>
        </FlexContainer>
    );
};
