import { VideosByTypeNumberAndYearResult } from '@bilhold/sanity';
import {
    breakpointTablet,
    Headline,
    TextContainer,
} from '@moller/design-system';
import { LogoBrand } from '@moller/design-system/foundation/logos/Logo.js';
import { PageLayout } from 'src/features/my-car/components/PageLayout';
import CarVideos from 'src/types/CarVideos';
import { themeOverride } from 'src/utils/dev-tools/devToolsUtils';
import lang from 'src/utils/lang';
import { Themes } from 'src/utils/themes/selectTheme';
import { styled } from 'styled-components';
import { ChildThemeProvider } from 'theming';
import { CarVideo } from './CarVideo';

const VideoGrid = styled.div`
    margin-top: var(--moller-spacing-xxl);
    display: grid;
    grid-template-columns: 1fr;
    gap: var(--moller-spacing-3xl);

    @media screen and (min-width: ${breakpointTablet}) {
        grid-template-columns: 1fr 1fr;
    }
`;

interface CarVideoContainerProps {
    videos?: CarVideos[] | VideosByTypeNumberAndYearResult;
    theme: Themes;
}
interface CarVideoContainerProps {
    videos?: CarVideos[] | VideosByTypeNumberAndYearResult;
    theme: Themes;
}

export const CarVideoContainer = ({
    videos,
    theme,
}: CarVideoContainerProps) => {
    const mapToCarVideo = (
        video: VideosByTypeNumberAndYearResult[number]
    ): CarVideos => ({
        title: video.name || '',
        text: video.description || '',
        url: video.url || '',
        type: video._type || 'video',
    });

    /*
        Videos may come from old backend or from Sanity.
        Infering video is from Sanity if name property exists, and therefore mapping
    
        After feature toggle shouldUseSanityVideosForExistingCars is removed,
        we can skip the mapping and only use Sanity format.
    */
    const transformedVideos = Array.isArray(videos)
        ? videos.map((video) =>
              'name' in video ? mapToCarVideo(video) : (video as CarVideos)
          )
        : [];

    return (
        <ChildThemeProvider
            theme={theme.toLocaleLowerCase() as LogoBrand}
            overrideTheme={themeOverride}
        >
            <PageLayout showBackButton>
                <Headline>{lang.videos}</Headline>
                {transformedVideos && transformedVideos.length > 0 ? (
                    <VideoGrid>
                        {transformedVideos.map((video) => (
                            <CarVideo key={video.title} video={video} />
                        ))}
                    </VideoGrid>
                ) : (
                    <TextContainer>
                        <p>{lang.noVideos}</p>
                    </TextContainer>
                )}
            </PageLayout>
        </ChildThemeProvider>
    );
};
