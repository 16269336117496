import { useQuery } from '@tanstack/react-query';
import getCurrentEnvironment from 'src/utils/environment/getCurrentEnvironment';
import get from 'src/utils/http/get';

export const competitionStatistics = 'statistics';

interface CompetitionStatistics {
    totalKilometers: number;
    totalElevation: number;
    totalStartPrizesWon: number;
    totalDistancePrizesWon: number;
    totalStreakPrizesWon: number;
    totalElevationPrizesWon: number;
}

export const useGetCompetitionStatistics = () => {
    const { bilholdApiUrl } = getCurrentEnvironment();
    const url = `${bilholdApiUrl}/v1/competition/statistics`;
    return useQuery({
        queryKey: [competitionStatistics],
        queryFn: () =>
            get<CompetitionStatistics>(url).then((response) => response.data),
    });
};
