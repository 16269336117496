import { Coordinates } from 'external-apis/src/types/bilhold';
import { SimpleCoordinates } from 'src/types/SimpleCoordinates';

const toRadians = (degrees: number) => degrees * (Math.PI / 180);

const haversineDistance = (
    customerPosition: SimpleCoordinates,
    dealerPosition: Coordinates
) => {
    const earthRadius = 6371; // Radius of the earth in kilometers
    const { latitude: lat1, longitude: lon1 } = customerPosition;
    const { latitude: lat2, longitude: lon2 } = dealerPosition;

    const dLat = toRadians(lat2 - lat1);
    const dLon = toRadians(lon2 - lon1);
    const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(toRadians(lat1)) *
            Math.cos(toRadians(lat2)) *
            Math.sin(dLon / 2) *
            Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return Math.ceil(earthRadius * c); // Distance in kilometers
};

export const getKilometersBetweenDealerAndCustomer = (
    customerPosition?: SimpleCoordinates,
    dealerPosition?: Coordinates
) => {
    if (customerPosition && dealerPosition) {
        return haversineDistance(customerPosition, dealerPosition);
    }

    return undefined;
};
