import { Link } from 'react-router-dom';
import { showDevTools } from 'src/utils/dev-tools/devToolsUtils';
import { styled } from 'styled-components';
import packageJson from '../../../package.json';

const DevToolsLink = styled(Link)`
    text-decoration: none;
    font-size: 13px;
    line-height: 1;
    background: var(--moller-color-info-container);
    border-radius: 99px;
    padding: 4px 10px;
    margin-left: 10px;
    color: black;
    pointer-events: auto;
    z-index: 8;
    margin: 40px auto;
    width: fit-content;
    opacity: 0.7;
`;

export const DevToolsLinkBadge = () => {
    if (!showDevTools) {
        return null;
    }

    return <DevToolsLink to="/dev-tools">{packageJson.version}</DevToolsLink>;
};
