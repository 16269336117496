import {
    AlertBanner,
    DangerButton,
    Headline,
    TextContainer,
    TextField,
} from '@moller/design-system';
import { useState } from 'react';
import { CenteredPageContent } from 'src/shared/CenteredPageContent';
import capitalize from 'src/utils/string/capitalize';
import { styled } from 'styled-components';
import lang, { TextKey } from 'utils/lang';
import deleteAccountErrorTypes from './deleteAccountErrorTypes';
import { DeleteUser } from './deleteUser';

const DeleteField = styled(TextField)`
    width: 100%;
    max-width: 400px;
`;

const DeleteAccount = () => {
    const [confirmText, setConfirmText] = useState('');
    const deletionEnabled =
        confirmText && capitalize(confirmText)?.includes(lang.deleteMyAccount);

    const {
        mutate: deleteAccount,
        isLoading,
        isError,
        error,
        reset,
    } = DeleteUser();
    const deleteError = error?.response?.data;

    return (
        <>
            <CenteredPageContent showBackButton>
                <TextContainer>
                    <Headline>{lang.deleteMyAccount}</Headline>
                    {isError ? (
                        deleteError?.httpCode === 409 &&
                        deleteError?.errors?.[0].message ? (
                            <AlertBanner
                                type="error"
                                heading={
                                    lang[
                                        deleteAccountErrorTypes[
                                            deleteError?.errors?.[0].message
                                        ].title as TextKey
                                    ]
                                }
                                message={
                                    lang[
                                        deleteAccountErrorTypes[
                                            deleteError?.errors?.[0].message
                                        ].description as TextKey
                                    ]
                                }
                            />
                        ) : (
                            <AlertBanner
                                type="error"
                                heading={lang.deleteGeneralErrorTitle}
                                message={lang.deleteGeneralErrorDescription}
                                action={{
                                    label: lang.tryAgain,
                                    onClick: () => {
                                        reset();
                                        setConfirmText('');
                                    },
                                }}
                            />
                        )
                    ) : (
                        <>
                            <p>{lang.deleteAccountInfo}</p>
                            <p>
                                <b>{lang.confirmDeletion}</b>
                            </p>
                            <p>{lang.confirmDeletionInfo}</p>
                            <DeleteField
                                label={lang.confirmDeletionLabel}
                                onChange={(e) => {
                                    setConfirmText(e.target.value);
                                }}
                                value={confirmText}
                            />
                            <DangerButton
                                disabled={!deletionEnabled}
                                onClick={() => deleteAccount()}
                                loading={{
                                    isLoading: isLoading,
                                    loadingText: 'Sletter bruker',
                                }}
                            >
                                {lang.deleteMyAccount}
                            </DangerButton>
                            <p>
                                Hvis du ønsker å slette ytterligere
                                kundeinformasjon om deg hos forhandler, importør
                                eller fabrikk, må du kontakte kundeservice på{' '}
                                <a href="mailto:kundeservice@moller.no">
                                    kundeservice@moller.no
                                </a>
                                .
                            </p>
                        </>
                    )}
                </TextContainer>
            </CenteredPageContent>
        </>
    );
};

export default DeleteAccount;
