import { Subtitle } from '@moller/design-system';
import { Skeleton } from '@mui/material';
import Vimeo from '@u-wave/react-vimeo';
import { useState } from 'react';
import CarVideos from 'src/types/CarVideos';
import { styled } from 'styled-components';

const VideoContainer = styled.div`
    h3 {
        margin-top: var(--moller-type-medium-body-paragraph-spacing);
    }
    p {
        margin-top: var(--moller-type-medium-body-paragraph-spacing);
    }
`;

const SkeletonContainer = styled.div`
    .MuiSkeleton-root {
        padding-top: 56.25%;
    }
`;

const IframeContainer = styled.div`
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;

    iframe {
        border: none;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }
`;

export const CarVideo = ({ video }: { video: CarVideos }) => {
    const [renderVimeoPlayer, setRenderVimeoPlayer] = useState(false);
    return (
        <VideoContainer>
            <IframeContainer>
                <Vimeo
                    video={video.url}
                    showByline={false}
                    showTitle={false}
                    showPortrait={false}
                    onReady={() => setRenderVimeoPlayer(true)}
                />
                {!renderVimeoPlayer && (
                    <SkeletonContainer>
                        <Skeleton
                            variant="rounded"
                            height={'0'}
                            width={'100%'}
                        />
                    </SkeletonContainer>
                )}
            </IframeContainer>
            <Subtitle>{video.title}</Subtitle>
            <p>{video.text}</p>
        </VideoContainer>
    );
};
