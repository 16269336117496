import React, { ReactElement } from 'react';
import StickyFooterContainer from 'src/features/home/components/StickyFooterContainer';
import { SwipeableDrawer } from 'src/shared/SwipeableDrawer';
import { styled } from 'styled-components';

const Container = styled.div`
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
        width: 100%;
        padding-top: var(--moller-spacing-s);
    }
`;

interface CampaignDrawerProps {
    drawerIsOpen: boolean;
    setDrawerIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    title: string;
    buttonText?: string;
    buttonLink?: string;
    imageUrl?: string;
    children?: ReactElement;
    buttonClick?: () => void;
}
export const CampaignDrawer = ({
    drawerIsOpen,
    setDrawerIsOpen,
    title,
    buttonText,
    buttonLink,
    imageUrl,
    children,
    buttonClick,
}: CampaignDrawerProps) => {
    return (
        <>
            <SwipeableDrawer
                isOpen={drawerIsOpen}
                setIsOpen={setDrawerIsOpen}
                title={title}
                scrollHeader={title}
                imageUrl={imageUrl}
                footer={
                    buttonText && buttonLink ? (
                        <StickyFooterContainer
                            buttonText={buttonText}
                            buttonLink={buttonLink}
                            buttonClick={buttonClick}
                        />
                    ) : null
                }
            >
                <Container>{children}</Container>
            </SwipeableDrawer>
        </>
    );
};
