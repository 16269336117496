import { ReasonInfos } from './types';

const reasons = {
    RelationAlreadyExistingPrivate: {
        title: 'ownerRelationAlreadyExistingPrivateTitle',
        description: 'ownerRelationAlreadyExistingPrivateDescription',
        buttonText: 'continue',
        continue: true,
    },
    RelationAlreadyExistingOrganization: {
        title: 'ownerRelationAlreadyExistingOrganizationTitle',
        description: 'ownerRelationAlreadyExistingOrganizationDescription',
        buttonText: 'continue',
        continue: true,
    },
    RelationAlreadyExistingOrganizationExt: {
        title: 'ownerRelationAlreadyExistingOrganizationTitle',
        description:
            'ownerRelationAlreadyExistingOrganizationCarLeasAbleDescription',
        buttonText: 'continue',
        continue: true,
    },
    OwnerNotExistingInMvr: {
        title: 'ownerReasonOwnerNotExistingTitle',
        description: 'ownerReasonOwnerNotExistingDescription',
        buttonText: 'iUnderstand',
    },
} as const;

const ownerReasons: ReasonInfos<typeof reasons> = reasons;

export default ownerReasons;
