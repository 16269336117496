import { Checkbox, RadioButton } from '@moller/design-system';
import { useLanguageContext } from '../../../../lib/languages/languageContext';
import { Extent, setAdditionalGlassDamageInfo } from './glassDamageFormState';
import { styled } from 'styled-components';
import { ServiceViewModel } from 'external-apis/src/types/port';
import { useStandardServicesForm } from '../../useSectionForm';

const IndentedCheckbox = styled(Checkbox)`
    padding-left: 28px;
`;

type GlassDamageOptionsProps = {
    service: ServiceViewModel;
    serviceForm: ReturnType<typeof useStandardServicesForm>;
};

export function GlassDamageOptions({
    service,
    serviceForm,
}: GlassDamageOptionsProps) {
    const [lc] = useLanguageContext();
    const damageExtent = serviceForm.state.raw.glassDamageExtent;
    const damageNearEdge = serviceForm.state.raw.glassDamageNearEdge;
    const setDamageExtent = serviceForm.setValue('glassDamageExtent');
    const setDamageNearEdge = serviceForm.setValue('glassDamageNearEdge');
    const options: { key: Extent; label: string }[] = [
        {
            key: 'large',
            label: lc.standardServices.glass_damage_large,
        },
        {
            key: 'small',
            label: lc.standardServices.glass_damage_small,
        },
    ];
    return (
        <>
            {options.map((x) => (
                <RadioButton
                    key={x.key}
                    onChange={() => setDamageExtent(x.key)}
                    label={x.label}
                    value={x.key === damageExtent}
                    onBlur={() => {
                        setAdditionalGlassDamageInfo({
                            serviceForm,
                            service,
                            lc,
                        });
                    }}
                />
            ))}
            {damageExtent === 'small' && (
                <IndentedCheckbox
                    onChange={() => setDamageNearEdge(!damageNearEdge)}
                    value={damageNearEdge}
                    label={lc.standardServices.glass_damage_edge}
                    onBlur={() => {
                        setAdditionalGlassDamageInfo({
                            serviceForm,
                            service,
                            lc,
                        });
                    }}
                />
            )}
        </>
    );
}
