import { useQuery } from '@tanstack/react-query';
import { useFetchersContext } from 'external-apis';
import { ServiceBookletItemViewModel } from 'external-apis/src/types/port';
import lang from 'src/utils/lang';

function useGetServiceBooklet() {
    const [fetchers] = useFetchersContext();
    return fetchers.port.fetcher
        .path('/vehicles/{vin}/service-booklet')
        .method('get')
        .create();
}

export function useServiceBooklet(vin: string) {
    const getServiceBooklet = useGetServiceBooklet();

    return useQuery<ServiceBookletItemViewModel[], Error>({
        queryKey: ['serviceBooklet', vin],
        queryFn: async () => {
            try {
                const res = await getServiceBooklet({ vin: vin });
                return res.data;
            } catch (e) {
                if (e instanceof getServiceBooklet.Error) {
                    throw new Error(lang.errorServiceBooklet, e);
                }
                throw e;
            }
        },
    });
}
