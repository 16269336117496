import { styled } from 'styled-components';

const BadgeContainer = styled.div`
    background-color: var(--moller-color-error);
    color: var(--moller-color-on-error);
    height: var(--moller-size-s);
    width: var(--moller-size-s);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -5px;
    right: -4px;
    pointer-events: none;
    padding: 2px;
    font-size: 12px;
`;

export const UnreadMessagesBadge = ({ unread }: { unread?: number }) => {
    if (!unread) {
        return null;
    }
    return (
        <BadgeContainer aria-label={`${unread} uleste meldinger`}>
            <p>{unread}</p>
        </BadgeContainer>
    );
};
