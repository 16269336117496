import getCurrentEnvironment from '../../environment/getCurrentEnvironment';

/**
 * Resolve the given path relative to current environment's serverUrl. Explicitly, given 'https://<domain>/bilhold' as serverUrl:
 *   - relative paths are resolved from '<domain>/bilhold/'. E.g path 'public/api' --> '<domain>/bilhold/public/api
 *   - root-relative paths are resolved from '<domain>/'. E.g. path '/bilhold/public/api' --> '<domain>/bilhold/public/api
 *   - absolute paths are unchanged. E.g path 'https://www.example.com/public/api' is unchanged.
 * @param path A relative, root-relative or absolute path
 * @returns an absolute URL
 */
export function toDamageAppraisalUrl(path: string) {
    const env = getCurrentEnvironment();
    // Use URL constructor to join path onto base server url.
    const baseUrl = new URL(env.damageAppraisalApiUrl).href.endsWith('/')
        ? env.damageAppraisalApiUrl
        : `${env.damageAppraisalApiUrl}/`;
    return new URL(path, baseUrl).href;
}
