import CountryCode from 'src/types/CountryCode';
import personSearch from 'src/utils/auth/personSearch';
import trimUserName from 'src/utils/auth/trimUserName';
import getCurrentEnvironment from 'src/utils/environment/getCurrentEnvironment';
import { USER_INFO } from '../http/endpoints';
import post from '../http/post';
import createOtpCredentials from './createOtpCredentials';

const checkUserForRegistration = async (data: {
    phoneNumber: string;
    otpCode: string;
}) => {
    const { phoneNumber, otpCode } = data;
    const { loginServerUrl } = getCurrentEnvironment();
    const url = `${loginServerUrl}/${USER_INFO}`;
    const payload = createOtpCredentials(
        CountryCode.NO,
        trimUserName(phoneNumber),
        otpCode
    );
    const {
        data: { otp: userInfoOtpToken, partyId },
    } = await post<{ otp: string; partyId: number }>(url, payload, {
        axiosConfig: { withCredentials: true },
        isAnonymous: true,
        onAuthError: () => {
            return;
        },
    });

    const { result, otpToken } = await personSearch(
        phoneNumber,
        userInfoOtpToken
    );
    return { partyId, otpToken, result };
};

export default checkUserForRegistration;
