import { CenteredPageContent } from 'src/shared/CenteredPageContent';
import { AddCar } from './AddCar';

export const AddCarIndex = () => {
    return (
        <CenteredPageContent showBackButton>
            <AddCar />
        </CenteredPageContent>
    );
};
