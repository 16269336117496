import { IconButton } from '@moller/design-system';
import { AllowedIcons } from '@moller/design-system/foundation/iconography';
import { buildMapUrl } from 'src/features/appointment/utililties/buildMapUrl';
import {
    openPhoneNumberHref,
    openWindowHref,
} from 'src/utils/navigation/openSystemHref';
import { SPACE_EVERY_TWO_CHARS } from 'src/utils/string/regex';
import { styled } from 'styled-components';

interface DealerContactProps {
    dealerName?: string | null;
    address?: string | null;
    postalArea?: string | null;
    readonly email?: string | null;
    readonly phoneNumber?: string | null;
}

const IconStyle = styled.div`
    justify-content: flex-start;

    p {
        word-break: break-all;
    }
`;

const DealerContact = ({
    dealerName,
    address,
    postalArea,
    email,
    phoneNumber,
}: DealerContactProps) => {
    const dealerContactInfo: {
        readonly valid: boolean;
        readonly textValue: string;
        readonly icon: AllowedIcons;
        readonly onClick: () => void;
    }[] = [
        {
            valid: !!address && !!postalArea && !!dealerName,
            icon: 'location',
            textValue: address ?? '',
            onClick: () =>
                openWindowHref(
                    buildMapUrl(
                        dealerName ?? '',
                        address ?? '',
                        postalArea ?? ''
                    )?.url,
                    '_system'
                ),
        },
        {
            valid: !!phoneNumber,
            icon: 'call',
            textValue: phoneNumber?.replace(SPACE_EVERY_TWO_CHARS, '$1 ') ?? '',
            onClick: () => openPhoneNumberHref(phoneNumber ?? ''),
        },
        {
            valid: !!email,
            icon: 'mail',
            textValue: email?.toLowerCase() ?? '',
            onClick: () => openWindowHref(`mailto:${email}`),
        },
    ];

    return (
        <div>
            {dealerContactInfo
                .filter((i) => i.valid)
                .map((i) => (
                    <IconStyle key={i.textValue}>
                        <IconButton
                            $as={'a'}
                            showLabel={'right'}
                            icon={i.icon}
                            label={i.textValue}
                            onClick={i.onClick}
                        />
                    </IconStyle>
                ))}
        </div>
    );
};

export default DealerContact;
