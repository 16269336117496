import { Headline, IconButton, TextContainer } from '@moller/design-system';
import { LogoBrand } from '@moller/design-system/foundation/logos/Logo.js';
import { useNavigate, useParams } from 'react-router-dom';
import { useCarInformation } from 'src/features/home/queries/useCarInformation';
import FeedbackPrompt from 'src/shared/feedback-card/FeedbackPrompt';
import { themeOverride } from 'src/utils/dev-tools/devToolsUtils';
import lang from 'src/utils/lang';
import { selectTheme } from 'src/utils/themes/selectTheme';
import { styled } from 'styled-components';
import { ChildThemeProvider } from 'theming';
import { PageLayout } from '../components/PageLayout';
import { ServiceBooklet } from './ServiceBooklet';

const StyledHeadline = styled(Headline)`
    margin-top: var(--moller-spacing-xl);
`;

const StyledTextContainer = styled(TextContainer)`
    margin-top: var(--moller-spacing-m);
`;
export function ServiceHistory({ path }: { path: string }) {
    const { vin } = useParams();
    const { data: technicalDetails } = useCarInformation(vin);
    const carTheme = selectTheme(technicalDetails?.brand);
    const navigate = useNavigate();

    return (
        <ChildThemeProvider
            theme={carTheme.toLocaleLowerCase() as LogoBrand}
            overrideTheme={themeOverride}
        >
            <PageLayout>
                <IconButton
                    icon={'chevron_left'}
                    label={technicalDetails?.model ?? ''}
                    showLabel="right"
                    onClick={() => navigate(-1)}
                />
                <StyledHeadline>{lang.digitalServiceBooklet}</StyledHeadline>
                <StyledTextContainer>
                    {lang.digitalServiceBookletDescription}
                </StyledTextContainer>
                {vin && <ServiceBooklet vin={vin} />}
                <FeedbackPrompt path={path} />
            </PageLayout>
        </ChildThemeProvider>
    );
}
