const sections = [
    'chooseVehicle',
    'standardServices',
    'chooseDealer',
    'chooseTimeSlot',
    'transportServices',
    'additionalServices',
    'customerInfo',
    'confirmBooking',
] as const;

export type Section = (typeof sections)[number];

export type Done<T> = {
    [key in keyof T]: Extract<T[key], { viewMode: 'done' }>;
};

export type SectionState<TData> =
    | {
          viewMode: 'edit';
      }
    | {
          viewMode: 'hidden';
      }
    | {
          viewMode: 'done';
          data: TData;
      };

export type ViewMode = 'edit' | 'hidden' | 'done';

export type Dependencies = Record<symbol, SectionState<object>>;

export function checkDoneDependencies<T extends Dependencies>(
    dependencies: T
): dependencies is Done<T> {
    const depsAreDone = !Object.values<SectionState<object>>(dependencies).some(
        (e) => e.viewMode !== 'done'
    );

    return depsAreDone;
}
