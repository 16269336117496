import { ActionChip, IconButton } from '@moller/design-system';
import { WeightItem } from 'src/features/electric-car-playground/payload/payload-calculator/types/WeightItems';
import { styled } from 'styled-components';

const ChipContainer = styled('div')`
    display: flex;
    gap: var(--moller-spacing-xxs);
    flex-direction: row;
    align-items: center;
`;

const StyledActionChip = styled(ActionChip)`
    display: flex;
    align-items: center;
    gap: var(--moller-spacing-xxs);
    font-size: var(--moller-font-size-s);
    pointer-events: none;
`;

interface WeightItemsContainerProps {
    item: WeightItem;
    clickedAmount: number;
    addItem: (item: WeightItem) => void;
    removeItem: (item: WeightItem) => void;
}

export const WeightItemChip = ({
    item,
    addItem,
    clickedAmount,
    removeItem,
}: WeightItemsContainerProps) => {
    return (
        <ChipContainer>
            <StyledActionChip
                onClick={() => {
                    addItem(item);
                }}
                label={`${clickedAmount} ${item.name} ${item.kg}`}
            />
            <IconButton
                disabled={item.amount === 0}
                label={'remove-button'}
                onClick={() => removeItem(item)}
                icon={'remove'}
            />
            <IconButton
                label={'add-button'}
                onClick={() => addItem(item)}
                icon={'add'}
            />
        </ChipContainer>
    );
};
