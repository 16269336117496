import amarok from './Amarok.webp';
import buzz from './Buzz.webp';
import caddyCargo from './CaddyCargo.webp';
import crafter from './Crafter.webp';
import golf from './Golf.webp';
import id3 from './ID.3.webp';
import id4 from './ID.4.webp';
import id5 from './ID.5.webp';
import id7 from './ID.7.webp';
import tRoc from './t-roc.webp';

const amarokImage = {
    src: amarok,
    name: ['amarok'],
};
const buzzImage = {
    src: buzz,
    name: ['buzz'],
};
const caddyCargoImage = {
    src: caddyCargo,
    name: ['caddy', 'cargo'],
};
const crafterImage = {
    src: crafter,
    name: ['crafter'],
};
const golfImage = {
    src: golf,
    name: ['golf'],
};
const id3Image = {
    src: id3,
    name: ['id.3'],
};
const id4Image = {
    src: id4,
    name: ['id.4'],
};
const id5Image = {
    src: id5,
    name: ['id.5'],
};
const id7Image = {
    src: id7,
    name: ['id.7'],
};
const tRocImage = {
    src: tRoc,
    name: ['t-roc'],
};

export const vwImages = [
    amarokImage,
    buzzImage,
    caddyCargoImage,
    crafterImage,
    golfImage,
    id3Image,
    id4Image,
    id5Image,
    id7Image,
    tRocImage,
];
