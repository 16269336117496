import { App } from '@capacitor/app';
import { ActionCard, Icon, Logo, Row } from '@moller/design-system';
import { LogoBrand } from '@moller/design-system/foundation/logos/Logo.js';
import Brand from 'src/types/Brand';
import { isIOS, isMobileApp } from 'src/utils/mobile';
import { styled } from 'styled-components';
import AudiAppLogo from '../car-brands/audi/audiAppLogo.svg';
import CupraAppLogo from '../car-brands/cupra/cupraAppLogo.svg';

const openAppOrAppStore = async (brand: LogoBrand, carName?: string) => {
    let iosAppLink = '';
    let androidAppLink = '';
    let appStoreLink = '';
    let googlePlayLink = '';
    switch (brand) {
        case 'vw':
            iosAppLink = 'weconnect://home';
            androidAppLink =
                'https://weconnect.volkswagen-we.com/mobile-api/redirect/idnow';
            appStoreLink =
                'https://apps.apple.com/no/app/volkswagen/id1517566572';
            googlePlayLink =
                'https://play.google.com/store/apps/details?id=com.volkswagen.weconnect&hl=no&gl=US';
            break;
        case 'audi':
            iosAppLink = 'myaudi://home';
            androidAppLink = 'myaudi://home';
            appStoreLink = 'https://apps.apple.com/no/app/myaudi/id440464115';
            googlePlayLink =
                'https://play.google.com/store/apps/details?id=de.myaudi.mobile.assistant';
            break;
        case 'skoda':
            if (carName?.toLowerCase().includes('enyaq')) {
                iosAppLink = 'myskoda://home';
                androidAppLink = 'myskoda://app/home';
                appStoreLink =
                    'https://apps.apple.com/no/app/my%C5%A1koda/id1632202810?l=nb';
                googlePlayLink =
                    'https://play.google.com/store/apps/details?id=cz.skodaauto.myskoda';
            } else {
                iosAppLink = 'skodaconnect://home';
                appStoreLink =
                    'https://apps.apple.com/no/app/%C5%A1koda-connect/id1161648654';
                googlePlayLink =
                    'https://play.google.com/store/apps/details?id=cz.skodaauto.connect&hl=no&gl=US';
            }
            break;
        case 'cupra':
            iosAppLink = 'cupra://home';
            androidAppLink = 'cupra://home';
            appStoreLink =
                'https://apps.apple.com/gb/app/my-cupra-app/id1576693756';
            googlePlayLink =
                'https://play.google.com/store/apps/details?id=com.cupra.mycupra&hl=en_US';
            break;
        default:
            break;
    }

    if (isIOS()) {
        window.location.href = iosAppLink;
        setTimeout(() => {
            window.location.href = appStoreLink;
        }, 300);
    } else {
        window.open(androidAppLink, '_blank');
        await App.addListener('pause', () => {
            googlePlayLink = '';
        });

        setTimeout(() => {
            if (googlePlayLink) window.location.href = googlePlayLink;
        }, 300);
    }
};

const StyledActionCard = styled(ActionCard)<{ brand: LogoBrand }>`
    display: flex;
    justify-content: space-between;
    align-items: center;

    ${({ brand }) =>
        brand === 'skoda' &&
        `
        ${Logo} {
        color: var(--moller-color-tertiary);
        }`}
    ${({ brand }) =>
        brand === 'vw' &&
        `
        background-color: var(--moller-color-primary);
        color: var(--moller-color-on-primary);
        `}
`;

const renderAppCardLogo = (brand: LogoBrand) => {
    switch (brand) {
        case 'vw':
            return <Logo brand={brand} type={'symbol'} size={'xxl'} />;
        case 'audi':
            return <img src={AudiAppLogo} alt="Audi app logo" />;
        case 'skoda':
            return <Logo brand={brand} type={'symbol'} size={'xxl'} />;
        case 'cupra':
            return <img src={CupraAppLogo} alt="Cupra app logo" />;
        default:
            return null;
    }
};

interface CarAppStoreCardProps {
    brand: Brand;
    carName?: string;
}

export const CarAppStoreCard = ({ brand, carName }: CarAppStoreCardProps) => {
    if (!isMobileApp()) return <></>;

    let cardTitle = '';
    const logoBrand = brand?.toLocaleLowerCase() as LogoBrand;

    switch (brand) {
        case Brand.SKODA:
            cardTitle = 'MyŠkoda app';
            break;
        case Brand.VW:
            cardTitle = 'Volkswagen app';
            break;
        case Brand.AUDI:
            cardTitle = 'myAudi app';
            break;
        case Brand.CUPRA:
            cardTitle = 'my CUPRA app';
            break;
        default:
            return <></>;
    }

    return (
        <StyledActionCard
            onClick={() => {
                void openAppOrAppStore(logoBrand, carName);
            }}
            brand={logoBrand}
        >
            <Row gap={'xs'}>
                <p>{cardTitle}</p>
                <Icon icon={'external'} />
            </Row>
            {renderAppCardLogo(logoBrand)}
        </StyledActionCard>
    );
};
