import { App } from '@capacitor/app';

export function setupAppDeepLinkHandling() {
    void App.addListener('appUrlOpen', (event) => {
        if (event.url.includes('carcare://auth/')) {
            // Handled in loginRedirect.ts
            return;
        }
        console.log('appUrlOpen - regular', event.url);
        const path = event.url.split('mittbilhold.no').pop();
        if (path) {
            window.location.assign(`${path}`);
        }
    });
}
