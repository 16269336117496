import CountryCode from 'src/types/CountryCode';
import trimUserName from 'src/utils/auth/trimUserName';
import getCurrentEnvironment from 'src/utils/environment/getCurrentEnvironment';
import { PERSON_SEARCH } from '../http/endpoints';
import post from '../http/post';
import createOtpCredentials from './createOtpCredentials';
import mapMdmUserInfo, { UserInfo, UserInfoResponse } from './mapMdmUserInfo';

const personSearch = async (
    phoneNumber: string,
    otp: string
): Promise<{ readonly otpToken: string; readonly result: UserInfo }> => {
    const { loginServerUrl } = getCurrentEnvironment();
    const url = `${loginServerUrl}/${PERSON_SEARCH}`;
    const payload = createOtpCredentials(
        CountryCode.NO,
        trimUserName(phoneNumber),
        otp
    );
    const { data } = await post<{ otp: string; result: UserInfoResponse }>(
        url,
        payload,
        {
            axiosConfig: { withCredentials: true },
            isAnonymous: true,
        }
    );
    if (!data) return { otpToken: '', result: {} };
    return { otpToken: data.otp, result: mapMdmUserInfo(data.result) };
};

export default personSearch;
