import DeleteAccountErrorType from 'src/utils/enums/DeleteAccountErrorType';

interface DeleteAccountErrorTypes {
    [key: string]: {
        title: string;
        description: string;
    };
}

const deleteAccountErrorTypes: DeleteAccountErrorTypes = {
    [DeleteAccountErrorType.HAS_ACTIVE_DAMAGE_APPRAISER_VALUATION]: {
        title: 'activeValuationErrorTitle',
        description: 'activeValuationErrorDescription',
    },
    [DeleteAccountErrorType.ECOM_DEPOSIT_NOT_REFUNDED]: {
        title: 'reservationDepositErrorTitle',
        description: 'reservationDepositErrorDescription',
    },
    [DeleteAccountErrorType.ECOM_ACTIVE_RESERVATION]: {
        title: 'reservationActiveErrorTitle',
        description: 'reservationActiveErrorDescription',
    },
    [DeleteAccountErrorType.GENERAL_ERROR]: {
        title: 'deleteGeneralErrorTitle',
        description: 'deleteGeneralErrorDescription',
    },
};

export default deleteAccountErrorTypes;
