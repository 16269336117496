import { Capacitor } from '@capacitor/core';
import { Preferences } from '@capacitor/preferences';

export const removeFromStorage = async (key: string) => {
    if (Capacitor.isPluginAvailable('Preferences')) {
        await Preferences.remove({ key });
    } else {
        localStorage.removeItem('CapacitorStorage.' + key);
    }
};

export const getFromStorage = async (key: string) => {
    if (Capacitor.isPluginAvailable('Preferences')) {
        return (await Preferences.get({ key })).value;
    } else {
        return localStorage.getItem('CapacitorStorage.' + key);
    }
};

export const setInStorage = async (key: string, value: string) => {
    if (Capacitor.isPluginAvailable('Preferences')) {
        await Preferences.set({ key, value });
    } else {
        localStorage.setItem('CapacitorStorage.' + key, value);
    }
};
