export async function generateHashedKey(
    s: string,
    encode: (x: string) => Uint8Array,
    decode: (x: ArrayBuffer) => string,
    digest: (x: Uint8Array) => Promise<ArrayBuffer>
) {
    return decode(await digest(encode(s)));
}

if (import.meta.vitest) {
    const { it, expect } = import.meta.vitest;
    const material =
        'Buffalobob@marley.com0000BobMarleyHeart of America12345678';
    const a = new TextEncoder().encode('transformed - ');
    const encode = (x: string) => new TextEncoder().encode(x);
    const decode = (x: ArrayBufferLike) => new TextDecoder().decode(x);
    const digest = (x: Uint8Array) =>
        new Promise<ArrayBufferLike>((res) => {
            res(new Uint8Array([...a, ...x]).buffer);
        });
    it('generate hash key', async () => {
        const key = await generateHashedKey(material, encode, decode, digest);
        const expected =
            'transformed - Buffalobob@marley.com0000BobMarleyHeart of America12345678';
        expect(key).toBe(expected);
    });
}

export function buf2hex(buffer: ArrayBuffer) {
    return [...new Uint8Array(buffer)]
        .map((x) => x.toString(16).padStart(2, '0'))
        .join('');
}

if (import.meta.vitest) {
    const { it, expect } = import.meta.vitest;
    const material =
        'Buffalobob@marley.com0000BobMarleyHeart of America12345678';
    const buffer = new TextEncoder().encode(material);
    it('buf2hex', () => {
        const hex = buf2hex(buffer);
        const expected =
            '42756666616c6f626f62406d61726c65792e636f6d30303030426f624d61726c65794865617274206f6620416d65726963613132333435363738';
        expect(hex).toBe(expected);
    });
}
