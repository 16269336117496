import { Calendar } from '@awesome-cordova-plugins/calendar';
import ical from 'ical-generator';
import moment from 'moment';
import { isMobileApp } from 'utils/mobile';

type CommonEventArgs = [
    subject: string,
    startDateTime: moment.MomentInput,
    endDateTime: moment.MomentInput,
    location: string,
    body: string
];

type EventArgs = [...CommonEventArgs, ...[htmlBody: string]];

const BLOB_TYPE = 'text/calendar';

const createBlob = (calendarFileContent: string) =>
    new Blob([calendarFileContent], { type: BLOB_TYPE });

const sendFile = (fileName: string, blob: Blob) => {
    const reader = new FileReader();

    type MsNavigator = Navigator & {
        msSaveBlob: (blob: Blob, fileName: string) => boolean;
    };
    if ((navigator as MsNavigator).msSaveBlob) {
        (navigator as MsNavigator).msSaveBlob(blob, fileName);
        return;
    }

    reader.onload = (e) => {
        const save: HTMLAnchorElement = document.createElement('a');

        save.href = e.target?.result as string;
        save.download = fileName;

        document.body.appendChild(save);

        setTimeout(() => {
            save.click();
        }, 100);

        document.body.removeChild(save);
        window.URL.revokeObjectURL(save.href);
    };

    reader.readAsDataURL(blob);
};

const createAppEvent: (...args: CommonEventArgs) => Promise<unknown> = (
    subject,
    startDateTime,
    endDateTime,
    location,
    body
) =>
    Calendar.createEventInteractively(
        subject,
        location,
        body,
        moment(startDateTime).toDate(),
        moment(endDateTime).toDate()
    );

const createWebEvent: (...args: EventArgs) => Blob = (
    subject,
    startDateTime,
    endDateTime,
    location,
    body,
    htmlBody
) => {
    const calendar = ical();
    calendar.createEvent({
        summary: subject,
        description: { plain: body, html: htmlBody },
        location,
        start: moment(startDateTime),
        end: moment(endDateTime),
    });

    return createBlob(calendar.toString());
};

const createEvent: (...args: EventArgs) => Promise<unknown> = async (
    subject,
    startDateTime,
    endDateTime,
    location,
    body,
    htmlBody
) => {
    if (isMobileApp()) {
        return createAppEvent(
            subject,
            startDateTime,
            endDateTime,
            location,
            body
        );
    } else {
        const fileName = `event-${+new Date()}.ics`;
        const event = createWebEvent(
            subject,
            startDateTime,
            endDateTime,
            location,
            body,
            htmlBody
        );
        sendFile(fileName, event);

        return event;
    }
};

export default createEvent;
