import { Headline, TextContainer } from '@moller/design-system';
import { CenteredPageContent } from 'src/shared/CenteredPageContent';

const Maintenance = () => {
    return (
        <CenteredPageContent>
            <TextContainer>
                <Headline>
                    Bilhold er nede for vedlikehold og vil straks være tilbake
                </Headline>
            </TextContainer>
        </CenteredPageContent>
    );
};

export default Maintenance;
