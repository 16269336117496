import { useQuery } from '@tanstack/react-query';
import { useFetchersContext } from 'external-apis/src/fetchers/fetchersProvider';

const useGetDamageAppraiser = () => {
    const [fetchers] = useFetchersContext();
    return fetchers.damageappraisal.fetcher
        .path('/v3/damageAppraiser/id/{damageId}')
        .method('get')
        .create();
};

export const useDamageAppraiser = (damageId?: number) => {
    const getDamageAppraiser = useGetDamageAppraiser();
    return useQuery({
        queryKey: ['damageAppraiser', damageId],
        queryFn: () =>
            getDamageAppraiser({ damageId: damageId?.toString() }).then(
                (res) => res.data
            ),
        enabled: !!damageId,
    });
};
