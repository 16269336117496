import moment from 'moment';
import { BookingViewModel } from 'src/features/appointment/api/useAppointments';
import lang, { stringFormat } from 'utils/lang';
import formatDealerObject from '../../features/appointment/utililties/formatDealerObject';
import dealerToAddressString from '../string/dealerToAddressString';
import downloadCalendarEvent from './downloadCalendarEvent';

const SERVICES = lang.calendarEventServices;
const EDIT = lang.calendarEventChange;
const DURATION = lang.calendarEventDuration;
const REGARDS = lang.regards;

export const getBody = (services: string[], dealerName: string) => {
    const servicesList = services.map((service) => `\n- ${service}`).join(' ');
    const intro = stringFormat(lang.calendarEventIntroduction, dealerName);

    return `${intro}\n\n${SERVICES}${servicesList}\n\n${EDIT}\n\n${DURATION}\n\n${REGARDS}\n${dealerName}`;
};

export const getHtmlBody = (services: string[], dealerName: string) => {
    const servicesList = services
        .map((service) => `<li>${service}</li>`)
        .join('');
    const intro = stringFormat(lang.calendarEventIntroduction, dealerName);

    return `<p>${intro}</p><p>${SERVICES}<ul>${servicesList}</ul></p><p>${EDIT}</p><p>${DURATION}</p><p>${REGARDS}<br />${dealerName}</p>`;
};

const addToCalendar = async (appointment: BookingViewModel) => {
    const { bookedServices, startTime, estimatedFinished, dealer } =
        appointment;
    if (!dealer) {
        return;
    }
    const subject = stringFormat(lang.icalSubject, dealer.name);
    const startDateTime = moment(startTime);
    const endDateTime = estimatedFinished
        ? moment(estimatedFinished)
        : moment(startDateTime).add(1, 'hour');

    const serviceNames = bookedServices?.map((service) => service.name) ?? [];

    return void (await downloadCalendarEvent(
        subject,
        startDateTime,
        endDateTime,
        dealerToAddressString(formatDealerObject(dealer)),
        getBody(serviceNames, dealer.name),
        getHtmlBody(serviceNames, dealer.name)
    ));
};

export default addToCalendar;
