import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import loadImage from 'blueimp-load-image';
import { ImageType } from 'react-images-uploading';
import { loadImageUrls } from './loadImageUrl';
import { getDamageQueryKey } from './useDamageAppraisals';
import { useFetchersContext } from 'external-apis';

const useGetDamageMedia = () => {
    const [fetchers] = useFetchersContext();
    return fetchers.damageappraisal.fetcher
        .path('/v3/media')
        .method('get')
        .create();
};

const getMediaQueryKey = (damageId?: null | number) =>
    ['damageMedia', damageId] as const;

export const useGetMedia = (damageId?: null | number) => {
    const getDamageMedia = useGetDamageMedia();
    return useQuery({
        queryKey: getMediaQueryKey(damageId),
        queryFn: async () => {
            const res = await getDamageMedia({
                relatedId: damageId?.toString(),
            });
            if (res.data) {
                return await loadImageUrls(res.data);
            }
            return [];
        },
        enabled: !!damageId,
    });
};
export type ImageUploadType = {
    relatedId: number;
    tag: string;
    dataURL: string;
    file: File;
};

async function resizeImage(image: File): Promise<string> {
    const options = {
        maxWidth: 1280,
        maxHeight: 1280,
        canvas: true,
        orientation: 1,
    };
    const res = await loadImage(image, options);
    return (res.image as HTMLCanvasElement).toDataURL('image/jpeg');
}

const usePostDamageMedia = () => {
    const [fetchers] = useFetchersContext();
    return fetchers.damageappraisal.fetcher
        .path('/v3/media')
        .method('post')
        .create();
};

export const useUploadMedia = (relatedId: number) => {
    const qc = useQueryClient();
    const postMediaDamage = usePostDamageMedia();
    return useMutation({
        onSuccess: async (result, uploadImgs) => {
            const queryKey = getMediaQueryKey(relatedId);
            await qc.cancelQueries(queryKey);
            const resizedDataUrls = await Promise.all(
                uploadImgs.map((img) => resizeImage(img.file))
            );

            qc.setQueryData<ImageType[]>(queryKey, (existingImages) => {
                return [
                    ...(existingImages ?? []),
                    ...uploadImgs.map((img, idx) => ({
                        id: result[idx].data.id,
                        tag: img.tag,
                        dataURL: resizedDataUrls[idx],
                    })),
                ];
            });
            await qc.invalidateQueries(getDamageQueryKey(relatedId));
        },
        mutationFn: (images: ImageUploadType[]) =>
            Promise.all(
                images.map(async ({ relatedId, tag, file }) => {
                    const resizedDataUrl = await resizeImage(file);
                    return postMediaDamage({
                        relatedId: relatedId,
                        tag: tag,
                        data: resizedDataUrl.replace(
                            /data:image\/(.+);base64,/,
                            ''
                        ),
                    });
                })
            ),
    });
};

const useDeleteDamageMedia = () => {
    const [fetchers] = useFetchersContext();
    return fetchers.damageappraisal.fetcher
        .path('/v3/deleteMedia/{id}')
        .method('delete')
        .create();
};

export const useDeleteMedia = (relatedId: number) => {
    const qc = useQueryClient();
    const deleteDamageMedia = useDeleteDamageMedia();
    return useMutation({
        onSuccess: async (_, deleteImg) => {
            const queryKey = getMediaQueryKey(relatedId);
            await qc.cancelQueries(queryKey);
            qc.setQueryData<ImageType[]>(queryKey, (existingImages) => {
                return existingImages?.filter((img) => deleteImg.id !== img.id);
            });
        },
        mutationFn: ({ id }: { id: number }) =>
            deleteDamageMedia({ id: id.toString() }),
    });
};
