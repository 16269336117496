import {
    AccordionGroup,
    InformationVariable,
    ModuleAccordion,
} from '@bilhold/sanity';
import {
    Accordion,
    Description,
    DisplayText,
    Headline,
    LabelText,
    LeadText,
    OverlineText,
    Subtitle,
    TextContainer,
} from '@moller/design-system';
import {
    PortableText as OriginalPortableText,
    PortableTextComponents,
    PortableTextProps,
} from '@portabletext/react';
import React from 'react';
import { PreferredAffiliationDealerResponse } from 'src/features/home/queries/usePreferredDealer';
import Car from 'src/types/Car';
import { styled } from 'styled-components';
import { getVariableText } from '../utils/getVariableText';

export interface Variables {
    car?: Car;
    nextEuControlDate?: string;
    recallCode?: string;
    preferredDealer?: PreferredAffiliationDealerResponse;
}

const StyledTextContainer = styled(TextContainer)`
    padding-top: 0 !important;
    > *:first-child {
        margin-top: 0 !important;
    }
`;

const getComponents = (variables?: Variables): PortableTextComponents => {
    return {
        block: {
            h1: ({ children }) => <DisplayText>{children}</DisplayText>,
            h2: ({ children }) => <Headline>{children}</Headline>,
            h3: ({ children }) => <Subtitle>{children}</Subtitle>,
            body: ({ children }) => <p>{children}</p>,
            overline: ({ children }) => <OverlineText>{children}</OverlineText>,
            label: ({ children }) => <LabelText>{children}</LabelText>,
            lead: ({ children }) => <LeadText>{children}</LeadText>,
            description: ({ children }) => (
                <Description>{children}</Description>
            ),
        },

        marks: {
            strong: ({ children }) => <b>{children}</b>,
            em: ({ children }) => <em>{children}</em>,
            link: ({
                children,
                value,
            }: {
                value?: { link?: string };
                children?: React.ReactNode;
            }) => {
                return <a href={value?.link}>{children}</a>;
            },

            emailLink: ({
                value,
                children,
            }: {
                value?: { emailLink?: string };
                children?: React.ReactNode;
            }) => {
                return <a href={`mailto:${value?.emailLink}`}>{children}</a>;
            },

            phoneLink: ({
                value,
                children,
            }: {
                value?: { phoneLink?: string };
                children?: React.ReactNode;
            }) => {
                return <a href={`tel:${value?.phoneLink}`}>{children}</a>;
            },
        },

        list: {
            bullet: ({ children }) => <ul>{children}</ul>,
            number: ({ children }) => {
                return <ol>{children}</ol>;
            },
        },
        types: {
            'module.accordion': ({ value }: { value: ModuleAccordion }) => {
                if (!value.groups) {
                    console.warn(
                        `Could not find any groups in the accordion module`
                    );
                    return null;
                }

                return value.groups.map(
                    (group: AccordionGroup, index: number) => (
                        <Accordion
                            head={<b>{group.title}</b>}
                            withDivider
                            key={index}
                        >
                            <StyledTextContainer>
                                {group.body && (
                                    <OriginalPortableText
                                        value={group.body}
                                        components={getComponents(variables)}
                                    />
                                )}
                            </StyledTextContainer>
                        </Accordion>
                    )
                );
            },
            variable: ({ value }: { value: InformationVariable }) => {
                if (!variables) {
                    console.warn(`Could not find any provided data`);
                    return null;
                }

                if (!value.title) {
                    console.warn('No key provided for variable');
                    return null;
                }

                return <span>{getVariableText(value.title, variables)}</span>;
            },
        },
    };
};

interface RichTextProps extends PortableTextProps {
    variables?: Variables;
}

function RichText({ value, variables }: RichTextProps) {
    return (
        <OriginalPortableText
            value={value}
            components={getComponents(variables)}
        />
    );
}

export default RichText;
