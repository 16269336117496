import { App } from '@capacitor/app';
import { Device, DeviceInfo } from '@capacitor/device';
import { datadogRum } from '@datadog/browser-rum';
import {
    getAccessToken,
    getRefreshToken,
    hasValidAccessToken,
    hasValidRefreshToken,
    setAccessToken,
    setRefreshToken,
} from 'src/utils/auth/authenticationUtils';
import refreshAccessToken from 'src/utils/auth/refreshAccessToken';
import getCurrentEnvironment from 'src/utils/environment/getCurrentEnvironment';
import { isMobileApp } from 'src/utils/mobile';
import { getFromStorage } from 'src/utils/storage';
import {
    STORAGE_KEY_ACCESS_TOKEN,
    STORAGE_KEY_REFRESH_TOKEN,
    USERSELFSERVICE_API_CLIENT_ID,
} from '../../auth/constants';
import lockify from './lockify';

const _refreshAccessTokenIfNeeded = async () => {
    if (!hasValidAccessToken() && hasValidRefreshToken()) {
        const accessTokenFromStorage = await getFromStorage(
            STORAGE_KEY_ACCESS_TOKEN
        );
        const refreshTokenFromStorage = await getFromStorage(
            STORAGE_KEY_REFRESH_TOKEN
        );

        // Handles refresh of tokens if user has website open in multiple tabs.
        // Reason: tab1 might refresh the token, so we need to check if storage has a different token than the current.
        if (
            accessTokenFromStorage != null &&
            refreshTokenFromStorage != null &&
            refreshTokenFromStorage != getRefreshToken()
        ) {
            await setAccessToken(accessTokenFromStorage);
            await setRefreshToken(refreshTokenFromStorage);

            if (hasValidAccessToken()) {
                return;
            }
        }

        datadogRum.addAction('refreshAccessToken');

        try {
            await refreshAccessToken();
        } catch (e) {
            datadogRum.addError(e);
        }
    } else if (
        !hasValidAccessToken() &&
        !hasValidRefreshToken() &&
        getRefreshToken() != null
    ) {
        datadogRum.addError(
            new Error('No valid access token or refresh token')
        );
    }
};

export const refreshAccessTokenIfNeeded = lockify(_refreshAccessTokenIfNeeded);

let appVersion = '';
let deviceType = '';

function formatPlatform(platform: DeviceInfo['platform']) {
    // Bilhold backend is apparently case sensitive on the allowed X-MBH-Device-Type header
    // TODO: should be changed on the backend
    switch (platform) {
        case 'android':
            return 'Android';
        case 'ios':
            return 'iOS';
        default:
            return platform;
    }
}

async function getDeviceHeaders() {
    if (!isMobileApp()) {
        return;
    }
    if (!appVersion) {
        appVersion = (await App.getInfo()).version;
    }
    if (!deviceType) {
        deviceType = formatPlatform((await Device.getInfo()).platform);
    }
    return {
        'X-MBH-Device-Type': deviceType,
        'X-MBH-Application-Version': appVersion,
    };
}

const defaultHeaders = {
    'Content-Type': 'application/json',
};

function getUserSelfServiceHeaders(url: string) {
    const { loginServerUrl } = getCurrentEnvironment();

    if (loginServerUrl && url && url.startsWith(loginServerUrl)) {
        return {
            'X-Moller-UserSelfService-Api-Client-Id':
                USERSELFSERVICE_API_CLIENT_ID,
        };
    }
    return undefined;
}

export async function getAnonymousHeaders(
    url: string,
    isBilholdUrl: boolean,
    idempotencyKey?: string
) {
    const headers = {
        ...defaultHeaders,
        ...(isBilholdUrl ? await getDeviceHeaders() : undefined),
        ...getUserSelfServiceHeaders(url),
    };
    if (idempotencyKey) {
        const withIdempotencyKey = {
            ...headers,
            'Idempotency-Key': idempotencyKey,
        };
        return withIdempotencyKey;
    }
    return headers;
}

async function getHeaders(
    url: string,
    isBilholdUrl: boolean,
    idempotencyKey?: string
) {
    await refreshAccessTokenIfNeeded();
    const anonymousHeaders = await getAnonymousHeaders(
        url,
        isBilholdUrl,
        idempotencyKey
    );
    const accessToken = getAccessToken();

    if (accessToken) {
        const headers = {
            ...anonymousHeaders,
            Authorization: `Bearer ${accessToken}`,
        };
        return headers;
    }
    return anonymousHeaders;
}

export default getHeaders;
