import { useEffect } from 'react';

export function useSetStepDone(
    isSuccess: boolean,
    setStepDone: (value: boolean) => void
) {
    useEffect(() => {
        if (isSuccess) {
            setStepDone(true);
        }
        return () => {
            setStepDone(false);
        };
    }, [isSuccess, setStepDone]);
}
