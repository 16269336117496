export const formatDealerName = (dealerName?: string) => {
    if (!dealerName) {
        return '';
    }
    let newDealername = dealerName;
    if (newDealername.includes('MNET') && newDealername.includes('-')) {
        newDealername = newDealername.split('-')[1];
    }
    if (newDealername.includes('AS')) {
        newDealername = newDealername.replace('AS', '');
    }
    return newDealername;
};
