import { Clipboard } from '@capacitor/clipboard';
import { Column, IconButton } from '@moller/design-system';
import Popover from '@mui/material/Popover';
import { useRef, useState } from 'react';
import { isMobileApp } from 'src/utils/mobile';
import { openPhoneNumberHref } from 'src/utils/navigation/openSystemHref';
import { SPACE_EVERY_TWO_CHARS } from 'src/utils/string/regex';

interface ContactItemProps {
    phoneLabel?: string;
    phoneNumber?: string;
    email?: string;
}

export const ContactItem = ({
    phoneNumber,
    phoneLabel,
    email,
}: ContactItemProps) => {
    const isEmail = !!email; // Either email or phonenumber
    const [isCopied, setIsCopied] = useState(false);
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const containerRef = useRef(null);

    const handleOpenPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleCopy = async () => {
        const textToCopy = isEmail ? email : phoneNumber;

        if (!textToCopy) {
            return;
        }

        setIsCopied(true);
        setTimeout(() => {
            setIsCopied(false);
        }, 1500);

        if (isMobileApp()) {
            await Clipboard.write({
                string: textToCopy,
            });
            return;
        } else {
            await navigator.clipboard.writeText(textToCopy);
        }
    };

    const handleEmailOrPhone = () => {
        if (isEmail) {
            window.open(`mailto:${email}`);
        }

        if (phoneNumber) {
            openPhoneNumberHref(phoneNumber);
        }
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const formattedNumber = phoneNumber?.replace(SPACE_EVERY_TWO_CHARS, '$1 ');

    return (
        <div ref={containerRef}>
            <IconButton
                aria-describedby={id}
                variant="contained"
                onClick={handleOpenPopover}
                showLabel={'right'}
                icon={isEmail ? 'mail' : 'call'}
                label={isEmail ? 'Send e-post' : phoneLabel}
            />
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                container={containerRef.current}
            >
                <Column
                    style={{ padding: 'var(--moller-spacing-xs)' }}
                    gap="xs"
                >
                    <p>{isEmail ? email : formattedNumber}</p>
                    <IconButton
                        showLabel={'right'}
                        icon={isEmail ? 'mail' : 'call'}
                        label={isEmail ? 'Send e-post' : 'Ring'}
                        onClick={handleEmailOrPhone}
                    />
                    <IconButton
                        showLabel={'right'}
                        icon={isCopied ? 'check' : 'contract'}
                        label={isEmail ? 'Kopier e-post' : 'Kopier nummer'}
                        onClick={handleCopy}
                    />
                </Column>
            </Popover>
        </div>
    );
};
