import Brand from 'src/types/Brand';
import formatBrandNames from 'src/utils/string/formatBrandNames';
import toLowerCaseWithCapitalize from 'src/utils/string/toLowerCaseWithCapitalize';
import lang from 'utils/lang';

const formatCarName = (brand: Brand, name: string) =>
    `${formatBrandNames(brand)} ${
        toLowerCaseWithCapitalize(name) || ''
    }`.trim() ?? lang.unknown;

export default formatCarName;
