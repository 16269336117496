import { Capacitor } from '@capacitor/core';
import {
    Geolocation,
    Position,
    PermissionStatus,
} from '@capacitor/geolocation';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
    LanguageContextType,
    useLanguageContext,
} from './languages/languageContext';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { datadogRum } from '@datadog/browser-rum';

type PermissionState = PermissionStatus['location'];

export type LocationPermission = PermissionState | 'disabled' | 'failed';

const locationPermissionErrorStates = ['denied', 'disabled', 'failed'] as const;
type LocationPermissionErrorStates =
    (typeof locationPermissionErrorStates)[number];

function isLocationPermissionError(
    permission: LocationPermission | undefined
): permission is LocationPermissionErrorStates {
    return locationPermissionErrorStates.some((x) => x === permission);
}

export const datadogLocationActionPrefix = 'share-location: ';

export const useUserLocation = (requestLocationImmediately = false) => {
    const [locationPermission, setLocationPermission] =
        useState<LocationPermission>();
    const [requestLocation, setRequestLocation] = useState(false);
    const qc = useQueryClient();
    const enableLocation = useCallback(() => {
        void qc.invalidateQueries({ queryKey: ['location'] });
        setRequestLocation(true);
    }, [qc]);

    useEffect(() => {
        /*
        This is a workaround so that we don't immediately request location if a component
        using "useUserLocation" is temporarily rendered for some reason. E.g. the DealerPicker
        being rendered because WorkshopBooking's dealer step temporarily is in 'edit' mode
        for a few seconds.

        Instead of using "requestLocationImmediately" as the initial state of requestLocation,
        we delay the request for LOCATION_DELAY_MS ms, and cancel the request if the hook is
        unmounted before that time.
        */
        const LOCATION_DELAY_MS = 100;
        if (requestLocationImmediately) {
            const requestLocationCallback = window.setTimeout(
                () => enableLocation(),
                LOCATION_DELAY_MS
            );
            return () => window.clearTimeout(requestLocationCallback);
        }
    }, [enableLocation, requestLocationImmediately]);

    useEffect(() => {
        if (locationPermission) {
            datadogRum.addAction(
                datadogLocationActionPrefix + locationPermission
            );
        }
    }, [locationPermission]);

    const getUserLocationWeb = useCallback(async (): Promise<Position> => {
        try {
            const position = await Geolocation.getCurrentPosition({
                enableHighAccuracy: false,
            });
            setLocationPermission('granted');
            return position;
        } catch (e) {
            setLocationPermission('denied');
            throw e;
        }
    }, []);

    const getUserLocationMobile = useCallback(async (): Promise<Position> => {
        let status: PermissionState;
        try {
            status = (
                await Geolocation.requestPermissions({
                    permissions: ['coarseLocation'],
                })
            ).coarseLocation;
        } catch (e) {
            setLocationPermission('disabled');
            throw e;
        }

        if (status === 'granted') {
            try {
                const position = await Geolocation.getCurrentPosition({
                    enableHighAccuracy: false,
                });
                setLocationPermission('granted');
                return position;
            } catch (e) {
                setLocationPermission('failed');
                throw e;
            }
        } else {
            setLocationPermission(status);
            throw new Error('Location permission not granted');
        }
    }, []);

    const response = useQuery<Position, Error>({
        queryFn: Capacitor.isNativePlatform()
            ? getUserLocationMobile
            : getUserLocationWeb,
        queryKey: ['location'],
        enabled: requestLocation,
        staleTime: Infinity,
        retry: false,
    });

    const [lc] = useLanguageContext();
    const errorMessage = useMemo(() => {
        if (requestLocation && isLocationPermissionError(locationPermission)) {
            return getErrorMessage(lc, locationPermission);
        }
    }, [lc, locationPermission, requestLocation]);

    const isLoading = useDelayIsLoading(response.isFetching, 500);

    return {
        location: requestLocation ? response.data : undefined,
        isLoading,
        permission: locationPermission,
        enableLocation,
        errorMessage,
    };
};

const getErrorMessage = (
    lc: LanguageContextType,
    error: LocationPermissionErrorStates
) => {
    // TODO: Consider using capacitor-native-settings to link the user to the appropriate device settings on native apps
    if (error === 'denied') {
        return Capacitor.isNativePlatform()
            ? lc.errors.location_denied_app
            : lc.errors.location_denied;
    }
    if (error === 'disabled' && Capacitor.isNativePlatform()) {
        return Capacitor.getPlatform() === 'ios'
            ? lc.errors.location_services_disabled_ios
            : lc.errors.location_services_disabled_android;
    }
    return lc.errors.location_unavailable;
};

/**
 * Delay changes to isLoading by the given delayInMs, to avoid flashing a loader on the screen
 * @param isLoading
 * @param delayInMs
 * @returns The delayed isLoading
 */
function useDelayIsLoading(isLoading: boolean, delayInMs: number) {
    const [result, setResult] = useState(false);
    useEffect(() => {
        let timeoutId: number;
        if (isLoading) {
            timeoutId = window.setTimeout(() => setResult(true), delayInMs);
        } else {
            setResult(false);
        }
        return () => window.clearTimeout(timeoutId);
    }, [delayInMs, isLoading]);

    return result;
}
