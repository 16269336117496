import { Column, InfoCard, Row } from '@moller/design-system';
import { breakpointTablet } from '@moller/design-system/utilities/layout/breakpoints.js';
import BackButton from 'src/shared/BackButton';
import { styled } from 'styled-components';
import logo from '../images/TDSLogoGreen.png';

const WrapperCol = styled(Column)`
    position: relative;
    top: -60px;
    width: 100%;
    margin-bottom: -70px;
`;

const InfoCardUser = styled(InfoCard)`
    width: 70%;
    padding: var(--moller-spacing-m);
    border: 0;
    align-self: center;
    background-color: var(--moller-color-background);
`;

const ImageTBF = styled.img`
    width: 80%;
`;

const TextUserName = styled.p`
    font-size: 1.25rem;
    font-weight: 300;
    @media screen and (min-width: ${breakpointTablet}) {
        font-size: 2rem;
    }
`;

interface Props {
    firstName?: string;
}

export const UserCard = ({ firstName }: Props) => {
    return (
        <WrapperCol gap={'xxs'} horizontalAlign={'start'} verticalAlign={'end'}>
            <InfoCardUser density="default" transparent={false}>
                <Row horizontalAlign={'spaceAround'} verticalAlign={'center'}>
                    <ImageTBF src={logo} alt={'Tour de Škoda Logo'} />
                </Row>
            </InfoCardUser>
            <BackButton />
            {firstName && <TextUserName>{firstName}</TextUserName>}
        </WrapperCol>
    );
};
