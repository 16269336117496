import {
    AlertBanner,
    RadioCard,
    Column,
    InputFieldValidity,
    InputHelperText,
} from '@moller/design-system';
import { useLanguageContext } from '../../../lib/languages/languageContext';
import { capitalize } from '@moller/design-system/utilities/tokens';
import { useInputHelperText } from '@moller/design-system/building-blocks/inputs';
import { DealerAddressLink } from '../../../components/DealerAddressLink';
import { DealerListSortOrder } from './DealerPicker';
import { DealerViewModel } from 'external-apis/src/types/port';

export function formatDealerAddress(dealer: DealerViewModel) {
    const streetAddress = dealer.streetAddress
        .split(' ')
        .map((x, i) => {
            const lowercased = x.toLocaleLowerCase();
            // Some words should not be capitalized
            if (i > 0 && ['vei', 'veg', 'gate', 'gt.'].includes(lowercased)) {
                return lowercased;
            }
            // Don't touch street numbers like 3B
            if (/^[0-9]/.test(x)) {
                return x;
            }

            return capitalize(lowercased);
        })
        .join(' ');
    const zipCode = dealer.zipCode;
    const city = capitalize(dealer.city.toLocaleLowerCase());
    return `${streetAddress}, ${zipCode} ${city}`;
}
interface SingleChoiceDealerListProps {
    firstXDealers: DealerViewModel[];
    totalNumberOfDealers: number;
    onChange: (dealer: DealerViewModel) => void;
    selectedDealerId?: string;
    validity?: InputFieldValidity;
    sortOrder: DealerListSortOrder;
}

const SingleChoiceDealerListContent: React.FC<SingleChoiceDealerListProps> = ({
    firstXDealers,
    onChange,
    selectedDealerId,
    validity,
    sortOrder,
}) => {
    const inputHelperText = useInputHelperText<{ errorMsgRequired: true }>({
        id: 'dealerPicker',
        reserveSpaceForMessage: false,
        validity,
    });
    const validityWithoutMsg = validity?.type && { type: validity.type };

    return (
        <Column gap="xxs">
            {firstXDealers.map((dealer) => (
                <RadioCard
                    key={dealer.id}
                    name="selectedDealer"
                    label={dealer.name}
                    description={
                        <DealerAddressLink
                            dealer={dealer}
                            sortOrder={sortOrder}
                        ></DealerAddressLink>
                    }
                    value={selectedDealerId === dealer.id}
                    onChange={() => {
                        onChange(dealer);
                    }}
                    // discards the validation message here, since it is common for all the cards
                    validity={validityWithoutMsg}
                    {...inputHelperText.inputAriaProps}
                />
            ))}
            <InputHelperText {...inputHelperText.helperTextProps} />
        </Column>
    );
};

export const SingleChoiceDealerList: React.FC<SingleChoiceDealerListProps> = ({
    ...props
}) => {
    const [lc] = useLanguageContext();
    if (props.totalNumberOfDealers === 0) {
        return (
            <AlertBanner
                type={'info'}
                message={lc.chooseDealer.no_dealers_to_show}
            />
        );
    }
    return <SingleChoiceDealerListContent {...props} />;
};
