import { Middleware } from 'openapi-typescript-fetch';
import { isMobileApp } from 'src/utils/mobile';
import { App } from '@capacitor/app';
import { Device } from '@capacitor/device';

export function appVersionMiddleware(): Middleware {
    return async (url, init, next) => {
        const device = await getDevice();

        if (device) {
            init.headers.append('X-MBH-Device-Type', device.type);
            init.headers.append('X-MBH-Application-Version', device.version);
        }

        return await next(url, init);
    };
}

let appVersion = '';
let deviceType = '';
async function getDevice() {
    if (!isMobileApp()) {
        return;
    }
    if (!appVersion) {
        appVersion = (await App.getInfo()).version;
    }
    if (!deviceType) {
        deviceType = (await Device.getInfo()).platform;
    }
    return {
        type: deviceType,
        version: appVersion,
    };
}
