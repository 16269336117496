export default {
    get: (key: string) => {
        try {
            return JSON.parse(window.localStorage.getItem(key) ?? '') as string;
        } catch (e) {
            return undefined;
        }
    },
    set: (key: string, value: unknown) => {
        window.localStorage.setItem(key, JSON.stringify(value));
    },
    remove: (...keys: string[]) => {
        keys.forEach((key) => window.localStorage.removeItem(key));
    },
};
