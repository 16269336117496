import {
    AlertBanner,
    breakpointMobile,
    Column,
    DangerButton,
    GhostButton,
    Logo,
    Modal,
    Row,
} from '@moller/design-system';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DELETED_BOOKING } from 'src/constants';
import { BookingViewModel } from 'src/features/appointment/api/useAppointments';
import { PATH_HOME } from 'src/routing/routes';
import lang from 'src/utils/lang';
import { isMobileApp } from 'src/utils/mobile';
import { styled } from 'styled-components';
import { useCancelAppointment } from '../../api/useCancelAppointment';

const EditContainer = styled(Column)`
    width: 30%;
    gap: var(--moller-spacing-s);
    align-items: flex-start;
    @media screen and (max-width: ${breakpointMobile}) {
        width: 100%;
        ${Logo} {
            align-self: center;
        }
    }
`;

const ButtonGroup = styled(Column)`
    width: 100%;
    gap: var(--moller-spacing-s);
    margin: var(--moller-spacing-s) 0;
    button {
        width: 100%;
    }
`;

const DeleteButtonGroup = styled(Row)`
    width: 100%;
    gap: var(--moller-spacing-s);
    justify-content: center;
    button {
        width: 40%;
    }
`;

type EditProps = {
    appointment: BookingViewModel;
    setDrawerIsOpen?: React.Dispatch<React.SetStateAction<boolean>>;
};

export const Edit = ({ appointment, setDrawerIsOpen }: EditProps) => {
    const navigate = useNavigate();
    const [showDeleteOptions, setShowDeleteOptions] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const { mutate, isLoading, error } = useCancelAppointment(
        appointment.id,
        () => {
            const deletedAppointment = {
                startTime: appointment.startTime,
                dealer: appointment.dealer.id,
                vin: appointment.vin,
            };
            localStorage.setItem(
                DELETED_BOOKING,
                JSON.stringify(deletedAppointment)
            );
            setDrawerIsOpen?.(false);
            navigate(PATH_HOME);
            setShowModal(false);
            setShowDeleteOptions(false);
        }
    );
    return (
        <EditContainer>
            {appointment.dealer?.name.includes('Møller') && (
                <Logo brand="mollerbil" type="wordmark" size="3xl" />
            )}
            <ButtonGroup>
                {appointment.isCancellable && !error && (
                    <>
                        {showDeleteOptions ? (
                            <>
                                {lang.cancelAppointmentConfirmation}
                                <DeleteButtonGroup>
                                    <GhostButton
                                        onClick={() =>
                                            setShowDeleteOptions(false)
                                        }
                                    >
                                        {lang.cancel}
                                    </GhostButton>
                                    <DangerButton
                                        onClick={() => mutate()}
                                        loading={{
                                            isLoading,
                                            loadingText: 'Loading',
                                        }}
                                    >
                                        {lang.confirm}
                                    </DangerButton>
                                </DeleteButtonGroup>
                            </>
                        ) : (
                            <DangerButton
                                onClick={() => {
                                    if (isMobileApp())
                                        setShowDeleteOptions(true);
                                    else setShowModal(true);
                                }}
                            >
                                {lang.cancelAppointment}
                            </DangerButton>
                        )}
                    </>
                )}
            </ButtonGroup>
            {
                <AlertBanner
                    type="warning"
                    message={lang.appointmentNotEditableBecauseNotImplemented}
                />
            }
            {!error ? (
                <Modal
                    isOpen={showModal}
                    title={lang.cancelAppointment}
                    handleClose={() => setShowModal(false)}
                    primaryAction={{
                        onClick: () => mutate(),
                        label: lang.confirm,
                        loading: {
                            isLoading: isLoading,
                            loadingText: 'Loading',
                        },
                    }}
                    secondaryAction={{
                        label: lang.cancel,
                        onClick: () => setShowModal(false),
                    }}
                >
                    {lang.cancelAppointmentConfirmation}
                </Modal>
            ) : (
                <Modal
                    isOpen={showModal}
                    title={lang.somethingWentWrong}
                    handleClose={() => setShowModal(false)}
                >
                    {lang.tryAgainLaterContactWorkshop}
                </Modal>
            )}
        </EditContainer>
    );
};
