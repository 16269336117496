import {
    validation,
    ValidationStep,
} from '@moller/design-system/utilities/validation';
import { StdServicesForm } from '../../useSectionForm';
import { useLanguageContext } from '../../../../lib/languages/languageContext';
import { Services } from '../../../../_api/http/services';
import { ServiceViewModel } from 'external-apis/src/types/port';

const VALID_DATE = /^(\d{2}\.){2}\d{4}/;

/*
 * Will cause serviceId validation to fail when validGlassDamage is false.
 * */
export function useGlassDamageIdValidationStep(
    services: Services | undefined
): ValidationStep<string[], StdServicesForm> {
    const [lc] = useLanguageContext();
    const service = services?.Standard.find(
        (x) => x.category === 'GlassDamage'
    );
    if (!service) {
        return (_s, _f) => ({ isValid: true, errorMessage: '' });
    }
    return (selectedIds, form) => ({
        isValid: validGlassDamage(service, selectedIds, form),
        errorMessage: lc.errors.glass_service_validation,
    });
}

function validGlassDamage(
    service: ServiceViewModel,
    selectedIds: string[],
    form: StdServicesForm
) {
    const glassDamageIsSelected = selectedIds.includes(service.id);
    if (!glassDamageIsSelected) return true;
    return (
        validIncidentLocation(form.glassDamageIncidentLocation) &&
        validIncidentDate(form.glassDamageIncidentDate)
    );
}

function validIncidentLocation(location: string) {
    const maxLength = 150;
    if (!location) {
        return false;
    }
    return location.length > 0 && location.length < maxLength;
}

if (import.meta.vitest) {
    const { expect, it } = import.meta.vitest;
    it.concurrent(
        'validIncidentLocation - empty input should be invalid',
        () => {
            const location = validIncidentLocation('');
            expect(location).toBeFalsy();
        }
    );
    it.concurrent('validIncidentLocation - undefined should be invalid', () => {
        const location = validIncidentLocation('');
        expect(location).toBeFalsy();
    });
    it.concurrent(
        'validIncidentLocation - arbitrary string should be valid',
        () => {
            const location = validIncidentLocation('Bob');
            expect(location).toBeTruthy();
        }
    );
    it.concurrent(
        'validIncidentlocation - long string should be invalid',
        () => {
            const location = validIncidentLocation(
                'ssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssss'
            );
            expect(location).toBeFalsy();
        }
    );
}

function validIncidentDate(date: string) {
    if (!date) {
        return false;
    }
    return VALID_DATE.test(date);
}

if (import.meta.vitest) {
    const { expect, it } = import.meta.vitest;
    it.concurrent('validIncidentDate - should be valid', () => {
        const date = validIncidentDate('08.01.2022');
        expect(date).toBeTruthy();
    });
    it.concurrent('validIncidentDate - undefined should be invalid', () => {
        const date = validIncidentDate('');
        expect(date).toBeFalsy();
    });
    it.concurrent('validIncidentDate - should be invalid', () => {
        const date = validIncidentDate('Bob');
        expect(date).toBeFalsy();
    });
}

export function useValidIncidentDate(availableServices: Services | undefined) {
    const [lc] = useLanguageContext();
    return validation<string, StdServicesForm>({
        steps: [
            (date) => ({
                isValid: validIncidentDate(date),
                errorMessage: lc.errors.glass_service_date_validation,
            }),
        ],
        necessity: (form) => ({
            isRequired: form.selectedIds.some(
                (id) =>
                    availableServices?.Standard.find((x) => x.id === id)
                        ?.category === 'GlassDamage'
            ),
            missingMessage: lc.errors.glass_service_date_validation,
        }),
    });
}

export function useValidIncidentLocation(
    availableServices: Services | undefined
) {
    const [lc] = useLanguageContext();
    return validation<string, StdServicesForm>({
        steps: [
            (location) => ({
                isValid: validIncidentLocation(location),
                errorMessage: lc.errors.glass_service_location_validation,
            }),
        ],
        necessity: (form) => ({
            isRequired: form.selectedIds.some(
                (id) =>
                    availableServices?.Standard.find((x) => x.id === id)
                        ?.category === 'GlassDamage'
            ),
            missingMessage: lc.errors.glass_service_location_validation,
        }),
    });
}
