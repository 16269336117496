import {
    onlyNumbers,
    validDevPhoneNumber,
    validPhoneNumber,
} from 'src/utils/auth/otpFormValidation';
import { isMobileApp } from 'utils/mobile';

export const invalidPhoneNumber = (phoneNumber: string) => {
    return (
        (!!phoneNumber &&
            phoneNumber.length > 8 &&
            (isMobileApp()
                ? !validDevPhoneNumber(phoneNumber)
                : !validPhoneNumber(phoneNumber))) ||
        (!!phoneNumber && phoneNumber.length <= 8 && !onlyNumbers(phoneNumber))
    );
};
