import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import getCurrentEnvironment from 'src/utils/environment/getCurrentEnvironment';
import get from 'src/utils/http/get';
import { hasStravaAuthKey } from './useHasAuthenticatedToStrava';
import { hasCompetitionUserKey } from './useHasCompetitionUser';

interface ErrorResponseData {
    message?: string;
}

export const useUpdateAccessToken = () => {
    const queryClient = useQueryClient();
    const { bilholdApiUrl } = getCurrentEnvironment();
    const url = `${bilholdApiUrl}/v1/competition/strava-auth`;

    return useMutation<unknown, AxiosError<ErrorResponseData>, string>({
        mutationFn: async (code: string) => {
            await get(`${url}?Code=${code}`);
        },
        onSuccess: async () => {
            return await Promise.all([
                queryClient.invalidateQueries({ queryKey: [hasStravaAuthKey] }),
                queryClient.invalidateQueries({
                    queryKey: [hasCompetitionUserKey],
                }),
            ]);
        },
        onError: (error) => {
            console.error(error);
        },
    });
};
