import { VideosByTypeAndYearResult } from '@bilhold/sanity';
import { datadogRum } from '@datadog/browser-rum';
import { ActionCard, LinkCard, Modal, Subtitle } from '@moller/design-system';
import groq from 'groq';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import {
    openContractFileMobileApp,
    openContractFileWeb,
} from 'src/features/home/current-actions/waiting-program/utils/openContractFile';
import {
    ChecklistCategory,
    ChecklistCheckpoint,
    FileCheckpoint,
} from 'src/features/home/current-actions/waiting-program/utils/types';
import { useGetChecklist } from 'src/features/home/queries/useGetChecklist';
import { CarAppStoreCard } from 'src/features/my-car/components/CarAppStoreCard';
import { CardLayout } from 'src/features/my-car/components/CardLayout';
import { useSanityData } from 'src/sanity/useSanityData';
import { SwipeableDrawer } from 'src/shared/SwipeableDrawer';
import Brand from 'src/types/Brand';
import { OrderedCar } from 'src/types/OrderedCar';
import CheckpointTypes from 'src/utils/enums/CheckpointTypes';
import { isMobileApp } from 'src/utils/mobile';
import { styled } from 'styled-components';
import { CardLayout as CardLayoutWithImage } from '../../components/CardLayout';
import { DeliveryProcessContent } from './DeliveryProcessContent';
import checkingDocumentImage from './check-document.jpg';

const Container = styled.div`
    margin-top: var(--moller-spacing-s);
    margin-bottom: var(--moller-spacing-s);
`;

const StyledSubtitle = styled(Subtitle)`
    margin-bottom: var(--moller-spacing-m);
`;

const StyledCardsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: var(--moller-spacing-s);
`;

const StyledSwipeableDrawer = styled(SwipeableDrawer)`
    background-color: var(--moller-background);
    color: var(--moller-color-on-background);
`;

interface WaitingProgramTasksProps {
    car: OrderedCar;
}

const WaitingProgramTasks = ({ car }: WaitingProgramTasksProps) => {
    const { data: checklist } = useGetChecklist(car.contractId.toString());
    const [showDeliveryModal, setShowDeliveryModal] = useState(false);

    const userIsOnMobile = isMobileApp();

    const videos: ChecklistCategory[] =
        checklist?.categories?.filter((category) =>
            category.checkpoints.some(
                (checkpoint) => checkpoint.type === CheckpointTypes.VIDEO
            )
        ) || [];

    const videosByTypeAndYear = groq`*[_type == "video" && references(*[_type == "carType" && $modelType in modelType[] && carBrand == $carBrand && modelYear == $modelYear]._id)] | order(priority asc)`;

    const { data: sanityVideos } = useSanityData<VideosByTypeAndYearResult>(
        videosByTypeAndYear,
        {
            modelType: car.model.type,
            carBrand: car.brand,
            modelYear: car.model.year,
        }
    );

    const getFilesData = () => {
        const files: ChecklistCheckpoint[][] = [];
        checklist?.categories.forEach((category) => {
            category.checkpoints.forEach((checkpoint) => {
                if (checkpoint.type === CheckpointTypes.FILE) {
                    files.push(category.checkpoints);
                }
            });
        });
        return files;
    };

    const files = getFilesData().flat();

    const generateFileName = (checkpoint: ChecklistCheckpoint) => {
        return `${checkpoint.title.replaceAll(
            /[^A-ZÆØÅa-zæøå0-9-_ ]/g,
            ''
        )}.pdf`;
    };

    const handleOpenFile = async (
        checkpoint: FileCheckpoint
    ): Promise<void> => {
        const fileName = generateFileName(checkpoint);
        if (userIsOnMobile) {
            try {
                await openContractFileMobileApp(checkpoint.url, fileName);
            } catch (error) {
                datadogRum.addError(
                    `Error opening contract file on mobile: ${error}`
                );
            }
        } else {
            try {
                await openContractFileWeb(checkpoint.url, fileName);
            } catch (error) {
                datadogRum.addError(
                    `Error opening contract file on web: ${error}`
                );
            }
        }
    };

    const renderDeliveryProcessModal = () => {
        return isMobileApp() ? (
            <StyledSwipeableDrawer
                isOpen={showDeliveryModal}
                setIsOpen={() => setShowDeliveryModal(false)}
                imageUrl={checkingDocumentImage}
                scrollHeader="Leveringsprosess"
            >
                <DeliveryProcessContent carBrand={car.brand} />
            </StyledSwipeableDrawer>
        ) : (
            <Modal
                isOpen={showDeliveryModal}
                handleClose={() => setShowDeliveryModal(false)}
                size="large"
                primaryAction={{
                    label: 'Lukk',
                    onClick: () => setShowDeliveryModal(false),
                }}
            >
                <DeliveryProcessContent carBrand={car.brand} />
            </Modal>
        );
    };

    return (
        <Container>
            <StyledSubtitle>Aktuelle oppgaver</StyledSubtitle>
            <StyledCardsContainer>
                <CarAppStoreCard brand={car.brand} carName={car.model.text} />
                {car.brand !== Brand.NYTTE && (
                    <ActionCard onClick={() => setShowDeliveryModal(true)}>
                        <CardLayoutWithImage
                            title="Få innsikt i leveringsprosessen"
                            body="Her kan du lese om hva som skjer fremover."
                            overlineText="Leveringsprosessen"
                            ImageSrc={checkingDocumentImage}
                        />
                    </ActionCard>
                )}
                {(videos.length !== 0 ||
                    (sanityVideos && sanityVideos?.length > 0)) && (
                    <LinkCard
                        $as={Link}
                        to={`/my-ordered-car/contract/${car.contractId}/videos`}
                    >
                        <CardLayout
                            title="Bli kjent med bilen din"
                            description="Se videoer"
                        />
                    </LinkCard>
                )}
                {files.map((file) => {
                    return (
                        file.type === CheckpointTypes.FILE && (
                            <ActionCard
                                onClick={() => {
                                    void handleOpenFile(file);
                                }}
                                key={file.key}
                            >
                                <CardLayout
                                    title={file.title}
                                    description="Åpne fil"
                                />
                            </ActionCard>
                        )
                    );
                })}
            </StyledCardsContainer>
            {renderDeliveryProcessModal()}
        </Container>
    );
};

export default WaitingProgramTasks;
