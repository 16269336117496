import { useEffect } from 'react';
import lang from 'src/utils/lang';
import { SuggestedActionCard } from '../../components/HomeStyledComponents';
import { CardLayout } from '../../components/CardLayout';
import { datadogRum } from '@datadog/browser-rum';
import Image from './keys.webp';

interface UsedCarValuationCardProps {
    toggleDrawer: () => void;
}

export const UsedCarValuationCard = ({
    toggleDrawer,
}: UsedCarValuationCardProps) => {
    useEffect(() => {
        datadogRum.addAction('User sees used car valuation card');
    }, []);
    return (
        <SuggestedActionCard
            clickName="Used car valuation card"
            toggleDrawer={toggleDrawer}
        >
            <CardLayout
                title={lang.valuationCardTitle}
                body={lang.valuationCardBody}
                ImageSrc={Image}
            />
        </SuggestedActionCard>
    );
};
