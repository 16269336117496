import { ServiceViewModel } from 'external-apis/src/types/port';
import { otherRecommendedService, recommendedService } from '../../../testData';

export function shouldRefetchRecommendedServices(
    selectedDealerNumber: string,
    recommendedServices?: ServiceViewModel[]
) {
    if (!recommendedServices) {
        return false;
    }
    const hasRecommendedServices = recommendedServices.length > 0;
    const noServicesOnSelectedDealer = recommendedServices?.every((s) =>
        s.dealerSpecificInformation.every(
            (d) => d.dealerNumber !== selectedDealerNumber
        )
    );
    return hasRecommendedServices && noServicesOnSelectedDealer;
}

if (import.meta.vitest) {
    const { it, expect } = import.meta.vitest;
    const recommendedServicesAt1290: ServiceViewModel = {
        ...recommendedService,
        dealerSpecificInformation: [{ dealerNumber: '1290' }],
    };
    const otheRecommendedServicesAt1290: ServiceViewModel = {
        ...otherRecommendedService,
        dealerSpecificInformation: [{ dealerNumber: '1290' }],
    };
    const recommendedServicesAt1550: ServiceViewModel = {
        ...recommendedService,
        dealerSpecificInformation: [{ dealerNumber: '1550' }],
    };
    it.concurrent(
        'should refetch when all reccomended services are at different dealer',
        () => {
            const actual = shouldRefetchRecommendedServices('1550', [
                recommendedServicesAt1290,
                otheRecommendedServicesAt1290,
            ]);
            expect(actual).toBe(true);
        }
    );
    it.concurrent(
        'should not refetch when some of the reccomended services are at selected dealer',
        () => {
            const actual = shouldRefetchRecommendedServices('1550', [
                recommendedServicesAt1290,
                recommendedServicesAt1550,
            ]);
            expect(actual).toBe(false);
        }
    );
    it.concurrent('should not refetch when no recommended services', () => {
        const actual = shouldRefetchRecommendedServices('1550', []);
        expect(actual).toBe(false);
    });

    it.concurrent(
        'should not refetch when all recommended services are at the recommended dealer',
        () => {
            const actual = shouldRefetchRecommendedServices('1550', [
                recommendedServicesAt1550,
            ]);
            expect(actual).toBe(false);
        }
    );
}
