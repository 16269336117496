import { MutableRefObject, useEffect, useRef, useState } from 'react';

interface UseInViewOptions extends IntersectionObserverInit {}

export const useInView = <T extends HTMLElement>(
    options?: UseInViewOptions
): [MutableRefObject<T | null>, boolean] => {
    const [isInView, setIsInView] = useState(false);
    const elementRef = useRef<T | null>(null);

    useEffect(() => {
        const currentElementRef = elementRef.current;
        const observer = new IntersectionObserver(([entry]) => {
            setIsInView(entry.isIntersecting);
        }, options);

        if (currentElementRef) {
            observer.observe(currentElementRef);
        }

        return () => {
            if (currentElementRef) {
                observer.unobserve(currentElementRef);
            }
        };
    }, [elementRef, options]);

    return [elementRef, isInView];
};

export const useUserHasSeenElement = <T extends HTMLElement>(
    callbackWhenSeen?: () => void,
    options?: UseInViewOptions
): MutableRefObject<T | null> => {
    const [ref, isInView] = useInView<T>(
        options ?? {
            threshold: 0.8, // Trigger when 80% of the element is in view
        }
    );

    const [userHasSeenElement, setUserHasSeenElement] = useState(false);

    useEffect(() => {
        if (isInView) {
            setUserHasSeenElement(true);
        }

        if (isInView && !userHasSeenElement) {
            callbackWhenSeen && callbackWhenSeen();
        }
    }, [callbackWhenSeen, isInView, userHasSeenElement]);

    return ref;
};
