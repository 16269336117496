import { AlertBanner } from '@moller/design-system';
import { QueryObserverSuccessResult } from '@tanstack/react-query';
import { DealerViewModel } from 'external-apis/src/types/port';
import { useFlag } from 'feature-toggle';
import { useRef } from 'react';
import { isAnnataAdapter } from '../..';
import { useGetDealers } from '../../_api/http/dealers';
import { Services, useGetRecommendedServices } from '../../_api/http/services';
import { DDRumPrivacySetting } from '../../components/DDRumPrivacySetting';
import { FlexRow } from '../../components/FlexRow';
import { QueryError } from '../../components/QueryError';
import {
    BilholdViewHidden,
    BilholdViewLayout,
} from '../../components/bilhold/BilholdView';
import { BilholdViewTitle } from '../../components/bilhold/BilholdViewTitle';
import { useAppConfig } from '../../lib/config/configContext';
import { useLanguageContext } from '../../lib/languages/languageContext';
import { getFastTrackServices, useFastTrack } from '../../lib/useFastTrack';
import { useScrollRefIntoView } from '../../lib/util/scroll';
import { SetIsBookingRequest } from '../../useBookingState';
import { mapLegacyServiceIds } from './AnnataMnetServiceMap';
import { StandardServicesDone } from './StandardServicesDone';
import { StandardServicesEdit } from './StandardServicesEdit';
import { StandardServicesLoader } from './StandardServicesLoader';
import { useFetchedData } from './useFetchedData';
import { useRecall } from './useRecall';
import { useStandardServicesForm } from './useSectionForm';
import { useSectionValidators } from './useSectionValidators';
import { useStandardServices } from './useStandardServices';

type StandardServicesProps = ReturnType<typeof useStandardServices>['props'] & {
    setIsBookingRequest: (x: SetIsBookingRequest) => void;
};

export type StandardServicesResult = ReturnType<
    typeof useStandardServices
>['result'];

export function StandardServices(props: StandardServicesProps) {
    const [lc] = useLanguageContext();

    const sectionRef = useRef(null);
    useScrollRefIntoView(props.viewMode === 'edit', sectionRef);

    return (
        <DDRumPrivacySetting privacyLevel="allow">
            <BilholdViewLayout>
                <FlexRow ref={sectionRef}>
                    <BilholdViewTitle
                        title={lc.standardServices.title}
                        isEditable={props.viewMode === 'done'}
                        onClick={() =>
                            props.setSectionState({
                                viewMode: 'edit',
                            })
                        }
                    />
                </FlexRow>
                <StandardServicesSection {...props} />
            </BilholdViewLayout>
        </DDRumPrivacySetting>
    );
}

function StandardServicesSection(props: StandardServicesProps) {
    const [lc] = useLanguageContext();

    const idsFromUrl = mapLegacyServiceIds(
        useAppConfig().bookingParameters.serviceIds
    );

    const dependencies =
        props.viewMode === 'done' || props.viewMode === 'edit'
            ? props.dependencies
            : undefined;
    const {
        vehicleResponse,
        dealerResponse,
        serviceResponse,
        customerResponse,
        recommendedServices,
    } = useFetchedData(dependencies);

    const sectionValidators = useSectionValidators(serviceResponse?.data);

    const form = useStandardServicesForm(
        sectionValidators,
        idsFromUrl,
        serviceResponse?.data?.Standard
    );

    const useBookingRequestEnabled = useFlag('use-booking-request-annata');

    if (props.viewMode === 'hidden') {
        return <StandardServicesHidden />;
    }

    if (
        vehicleResponse.isError ||
        serviceResponse.isError ||
        dealerResponse.isError ||
        customerResponse.isError
    ) {
        return (
            <QueryError
                isError={
                    serviceResponse.isError ||
                    dealerResponse.isError ||
                    customerResponse.isError
                }
                error={
                    serviceResponse.error ||
                    dealerResponse.error ||
                    customerResponse.error
                }
            />
        );
    }

    if (
        vehicleResponse.isLoading ||
        serviceResponse.isLoading ||
        customerResponse.isLoading ||
        dealerResponse.isLoading
    ) {
        return <StandardServicesLoader />;
    }

    const selectedDealer = dealerResponse.data.find(
        (x) => x.id == props.dependencies.chooseDealer.data.dealerNumber
    );
    const isAnnataDealer = isAnnataAdapter(selectedDealer?.adapterId);
    const isSyncedAnnata = customerResponse.data.some(
        (x) => isAnnataAdapter(x.adapterId) && x.id !== null
    );

    if (isAnnataDealer && !isSyncedAnnata && !useBookingRequestEnabled) {
        return (
            <AlertBanner
                message={lc.standardServices.notSyncedAnnataDescription}
                type="warning"
                heading={lc.standardServices.notSyncedAnnataHeading.replace(
                    '{0}',
                    selectedDealer?.name ?? ''
                )}
            />
        );
    }

    const vin = vehicleResponse.data?.vehicleIdentificationNumber;
    const dealerNumbers = dependencies && [
        dependencies.chooseDealer.data.dealerNumber,
    ];
    if (!vin || !dealerNumbers) {
        return (
            <AlertBanner
                type={'error'}
                message={lc.errors.port_services_default}
                density={'compact'}
            />
        );
    }

    return (
        <StandardServicesWithFormState
            serviceResponse={serviceResponse}
            recommendedServices={recommendedServices}
            vin={vin}
            dealerNumbers={dealerNumbers}
            servicesForm={form}
            {...props}
        />
    );
}

function StandardServicesWithFormState({
    ...props
}: {
    serviceResponse: QueryObserverSuccessResult<Services>;
    recommendedServices: ReturnType<typeof useGetRecommendedServices>;
    vin: string;
    dealerNumbers: string[];
    servicesForm: ReturnType<typeof useStandardServicesForm>;
} & StandardServicesProps) {
    const services = [
        ...props.serviceResponse.data.Standard,
        ...props.serviceResponse.data.Recommended,
    ];
    /* TODO
     * This logic should be done in backend. Fix it when the endpoint is moved
     * to new backend.
     * */
    const {
        isLoading,
        services: servicesFilteredByRecall,
        showInfoBox,
    } = useRecall(props.vin, services);

    const fastTrack = useFastTrack();
    const fastTrackServices = getFastTrackServices(
        services,
        fastTrack.fastTrack
    );

    // used to report datadog actions per dealer
    const dealerResponse = useGetDealers();

    if (isLoading) {
        return <StandardServicesLoader />;
    }

    if (dealerResponse.isLoading) {
        return <StandardServicesLoader />;
    }

    if (props.viewMode === 'done') {
        return (
            <StandardServicesDone
                vin={props.vin}
                dealerNumbers={props.dealerNumbers}
                selectedServices={props.data.selectedServices}
                dealers={dealerResponse.data}
                services={services}
            />
        );
    }

    return (
        <>
            {showInfoBox && (
                <RecallInfoBox
                    dealers={dealerResponse.data}
                    dealerNumbers={props.dealerNumbers}
                />
            )}
            <StandardServicesEdit
                vin={props.vin}
                dealerNumber={props.dealerNumbers[0]}
                servicesForm={props.servicesForm}
                services={
                    fastTrackServices
                        ? fastTrackServices.services
                        : servicesFilteredByRecall
                }
                setSectionState={props.setSectionState}
                servicesResponse={props.serviceResponse}
                recommendedServices={props.recommendedServices}
                setIsBookingRequest={props.setIsBookingRequest}
            />
        </>
    );
}

function RecallInfoBox(props: {
    dealers: DealerViewModel[] | undefined;
    dealerNumbers: string[];
}) {
    const [lc] = useLanguageContext();

    const dealer = props.dealers?.find((x) => x.id === props.dealerNumbers[0]);
    if (!dealer) {
        return null;
    }

    const phoneNumber = dealer.phoneNumber ?? '';
    const email = dealer.email ?? '';

    return (
        <AlertBanner
            type="info"
            heading={lc.standardServices.recall.title}
            message={lc.standardServices.recall.description
                .replace('{0}', phoneNumber)
                .replace('{1}', email)}
        />
    );
}

const StandardServicesHidden: React.FC = () => {
    const [lc] = useLanguageContext();

    return <BilholdViewHidden desc={lc.standardServices.description} />;
};
