import { InputFieldValidity } from '@moller/design-system';
import lang from 'src/utils/lang';

export const PhoneNumberFormatValidity: InputFieldValidity = {
    type: 'error',
    message: lang.phoneNumberWrongFormat,
};

export const WrongOtpValidity: InputFieldValidity = {
    type: 'error',
    message: lang.wrong_one_time_code,
};

export const ValidInput: InputFieldValidity = {
    type: 'none',
};
