import { Route, Routes } from 'react-router-dom';
import { PATH_SA_DEAL, PATH_SV_DEAL } from 'src/routing/routes';
import SaServiceDealArticle from './SaServiceDealArticle';
import SvServiceDealArticle from './SvServiceDealArticle';

const SvaServiceDeals = () => {
    return (
        <Routes>
            <Route path={PATH_SA_DEAL} element={<SaServiceDealArticle />} />
            <Route path={PATH_SV_DEAL} element={<SvServiceDealArticle />} />
        </Routes>
    );
};

export default SvaServiceDeals;
