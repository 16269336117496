import { create } from 'zustand';

interface SwiperIndex {
    carSwiperIndex: number;
    setCarSwiperIndex: (nextNumber: number) => void;
}

export const swiperIndexLocalStorageKey = 'swiperIndex';

export const useCarSwiperIndex = create<SwiperIndex>((set) => {
    const initialIndex = parseInt(
        localStorage.getItem(swiperIndexLocalStorageKey) || '0'
    );

    return {
        carSwiperIndex: initialIndex,
        setCarSwiperIndex: (nextIndex: number) => {
            localStorage.setItem(
                swiperIndexLocalStorageKey,
                nextIndex.toString()
            );
            set({ carSwiperIndex: nextIndex });
        },
    };
});
