import { Services } from '../../_api/http/services';
import {
    useValidServiceAdditionalInfo,
    useValidStandardServices,
} from '../../lib/form/validators';
import {
    useGlassDamageIdValidationStep,
    useValidIncidentDate,
    useValidIncidentLocation,
} from './features/glassDamage/glassDamageValidators';
import { useValidRecommendedServices } from './features/recommendedServices/recommendedServicesValidators';

export function useSectionValidators(availableServices: Services | undefined) {
    const additionalSteps = useAdditionalValidationSteps(availableServices);
    const validServiceIds = useValidStandardServices(
        availableServices,
        additionalSteps
    );
    const validIncidentDate = useValidIncidentDate(availableServices);
    const validIncidentLocation = useValidIncidentLocation(availableServices);
    const validServiceAdditionalInfo =
        useValidServiceAdditionalInfo(availableServices);
    const validRecommendedServices = useValidRecommendedServices();

    return {
        glassDamage: {
            glassDamageIncidentDate: validIncidentDate,
            glassDamageIncidentLocation: validIncidentLocation,
        },
        validServiceIds,
        validServiceAdditionalInfo,
        validRecommendedServices,
    };
}

function useAdditionalValidationSteps(availableServices: Services | undefined) {
    const glassDamage = useGlassDamageIdValidationStep(availableServices);
    const additionalSteps = [glassDamage];
    return additionalSteps;
}
