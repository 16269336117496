import {
    ActionCard,
    ActionChip,
    Column,
    Modal,
    OverlineText,
    Row,
    Subtitle,
    TextContainer,
} from '@moller/design-system';
import { useState } from 'react';
import { PreferredDealer } from 'src/features/home/queries/usePreferredDealer';
import { SwipeableDrawer } from 'src/shared/SwipeableDrawer';
import Brand from 'src/types/Brand';
import lang from 'src/utils/lang';
import { isMobileApp } from 'src/utils/mobile';
import formatBrandNames from 'src/utils/string/formatBrandNames';
import { SPACE_EVERY_TWO_CHARS } from 'src/utils/string/regex';
import { styled } from 'styled-components';
import SelectPreferredDealer from './SelectPreferredDealer';

const StyledActionCard = styled(ActionCard)`
    margin-bottom: var(--moller-spacing-xs);
`;

const StyledRow = styled(Row)`
    justify-content: space-between;

    h2 {
        padding-top: var(--moller-spacing-base);
    }
`;

const PreferredDealerCard = ({
    preferredDealer,
    brand,
}: {
    preferredDealer?: PreferredDealer;
    brand: Brand;
}) => {
    const [isDealerSelectOpen, setIsDealerSelectOpen] =
        useState<boolean>(false);

    const renderPreferredDealerContent = () => {
        if (preferredDealer) {
            return (
                <>
                    <Subtitle>{preferredDealer.dealerName}</Subtitle>
                    <TextContainer>
                        <p>
                            {preferredDealer.address}
                            <br />
                            {preferredDealer.zipCode}, {preferredDealer.city}
                            <br />
                            {preferredDealer.phoneNumber?.replace(
                                SPACE_EVERY_TWO_CHARS,
                                '$1 '
                            )}
                        </p>
                    </TextContainer>
                </>
            );
        } else {
            return (
                <p>
                    Du har ikke valgt en preferert forhandler for{' '}
                    {formatBrandNames(brand)}.
                </p>
            );
        }
    };

    return (
        <>
            <StyledActionCard
                density="compact"
                onClick={() => setIsDealerSelectOpen(true)}
            >
                <Column>
                    <StyledRow>
                        <OverlineText $as="h2">
                            {formatBrandNames(brand)}
                        </OverlineText>
                        <ActionChip label={lang.change} />
                    </StyledRow>
                    {renderPreferredDealerContent()}
                </Column>
            </StyledActionCard>
            {isMobileApp() ? (
                <SwipeableDrawer
                    isOpen={isDealerSelectOpen}
                    setIsOpen={setIsDealerSelectOpen}
                    title={lang.preferredDealer}
                    scrollHeader={lang.preferredDealer}
                >
                    <SelectPreferredDealer
                        brand={brand}
                        handleClose={() => setIsDealerSelectOpen(false)}
                        preferredDealer={preferredDealer}
                    />
                </SwipeableDrawer>
            ) : (
                <Modal
                    isOpen={isDealerSelectOpen}
                    handleClose={() => setIsDealerSelectOpen(false)}
                    size="fullscreen"
                >
                    <SelectPreferredDealer
                        brand={brand}
                        handleClose={() => setIsDealerSelectOpen(false)}
                        preferredDealer={preferredDealer}
                    />
                </Modal>
            )}
        </>
    );
};

export default PreferredDealerCard;
