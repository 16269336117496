import appStoreLogoUrl from 'src/features/begin/images/app-store-ios.svg';
import googlePlayeLogoUrl from 'src/features/begin/images/google-play-android.png';
import lang from 'src/utils/lang';
import { styled } from 'styled-components';

const StoreLinkContainer = styled.div`
    display: flex;
    gap: var(--moller-spacing-m);
    min-height: 40px;
    justify-content: center;
    align-items: center;
    img {
        object-fit: contain;
        height: 40px;
    }
`;

const iosAppStoreUrl =
    'https://itunes.apple.com/no/app/mitt-bilhold/id1006579487';
const androidAppStoreUrl =
    'https://play.google.com/store/apps/details?id=no.moller.carcare&pli=1&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1';

export const AppStoreButtons = () => {
    return (
        <StoreLinkContainer>
            <a
                href={iosAppStoreUrl}
                target="_blank"
                rel="noopener noreferrer"
                aria-label={lang.appStore}
            >
                <img alt={lang.appStore} src={appStoreLogoUrl} />
            </a>
            <a
                href={androidAppStoreUrl}
                target="_blank"
                rel="noopener noreferrer"
                aria-label={lang.googlePlay}
            >
                <img
                    alt="Tilgjengelig på Google Play"
                    src={googlePlayeLogoUrl}
                />
            </a>
        </StoreLinkContainer>
    );
};
