import { SplashScreen } from '@capacitor/splash-screen';
import { isMobileApp } from 'src/utils/mobile';

export async function initializeMobileApp() {
    if (!isMobileApp()) {
        return;
    }

    await SplashScreen.hide();
}
