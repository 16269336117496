import { ServiceViewModel } from 'external-apis/src/types/port';
import { useFlag } from 'feature-toggle';
import { styled } from 'styled-components';
import { BoldText } from '../../components/BoldText';
import { useLanguageContext } from '../../lib/languages/languageContext';
import { WaitingServiceIcons } from './WaitingServiceIcons';

export const Facilities = styled.div`
    font-size: 16px;
    padding-bottom: var(--moller-spacing-s);
`;

type DealerFacilitiesProps = {
    waitingServices: ServiceViewModel[];
};

export function DealerFacilities({ waitingServices }: DealerFacilitiesProps) {
    const [lc] = useLanguageContext();

    const showWaitingServicesInSummary = useFlag(
        'show-waiting-services-in-summry'
    );

    return (
        <Facilities>
            {showWaitingServicesInSummary ? (
                <BoldText>
                    {lc.transportServices.waiting_services_at_dealer}
                </BoldText>
            ) : (
                <p>{lc.transportServices.waiting_services_at_dealer}</p>
            )}
            <WaitingServiceIcons waitingServices={waitingServices} />
        </Facilities>
    );
}
