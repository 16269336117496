import { checkDoneDependencies, SectionState } from '../../sectionUtils';
import { ConfirmBookingDependencies } from '../ConfirmBookingSection';
import { ConfirmBookingWithDoneDependencies } from './ConfirmBookingWithDoneDependencies';
import { ConfirmBookingWithoutDoneDependencies } from './ConfirmBookingWithoutDoneDependencies';

export type ConfirmBookingEditProps = {
    setSectionState: (x: SectionState<null>) => void;
    dependencies: ConfirmBookingDependencies;
    invalidateAppointmentsQuery: () => void;
};

export function ConfirmBookingEdit({
    dependencies,
    invalidateAppointmentsQuery,
    ...props
}: ConfirmBookingEditProps) {
    if (checkDoneDependencies(dependencies)) {
        return (
            <ConfirmBookingWithDoneDependencies
                dependencies={dependencies}
                invalidateAppointmentsQuery={invalidateAppointmentsQuery}
                {...props}
            />
        );
    }

    return (
        <ConfirmBookingWithoutDoneDependencies dependencies={dependencies} />
    );
}
