import { AlertBanner } from '@moller/design-system';
import {
    LanguageContextType,
    useLanguageContext,
} from '../../../../lib/languages/languageContext';

type GlassDamageInfoText = {
    lc: LanguageContextType;
    changeScreen: boolean;
};

function glassDamageInfoText({ lc, changeScreen }: GlassDamageInfoText) {
    const change = {
        heading: lc.standardServices.glass_damage_info_title_large,
        message: lc.standardServices.glass_damage_info_description_large,
    };
    const repair = {
        heading: lc.standardServices.glass_damage_info_title_small,
        message: lc.standardServices.glass_damage_info_description_small,
    };
    const content = changeScreen ? change : repair;
    return content;
}

type GlassDamageInfoBoxProps = Pick<GlassDamageInfoText, 'changeScreen'>;

export function GlassDamageInfoBox({ changeScreen }: GlassDamageInfoBoxProps) {
    const [lc] = useLanguageContext();
    const content = glassDamageInfoText({ lc, changeScreen });
    return <AlertBanner type="info" {...content} />;
}
