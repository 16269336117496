import { OverlineText } from '@moller/design-system';

import { styled } from 'styled-components';
import BrandContactInfo from './components/BrandContactInfo';
import { ContactInfoCard } from './components/ContactInfoCard';
import DealerContactInfo from './components/DealerContactInfo';

const StyledOverlineText = styled(OverlineText)`
    margin-top: var(--moller-spacing-l);
    margin-bottom: var(--moller-spacing-xs);
    font-weight: bold;
`;

const ContactInfoList = () => {
    return (
        <>
            <ContactInfoCard
                title={'Veihjelp'}
                description={
                    'Ved behov for bilberging eller umiddelbar assistanse.'
                }
                phoneLabel={'Ring veihjelp'}
                phoneNumber={'+4791504444'}
                openHoursText="Åpent 24 timer"
            />

            {/* Dynamically rendered cards based on preferred dealers */}
            <DealerContactInfo />

            <StyledOverlineText>Annet</StyledOverlineText>
            <ContactInfoCard
                title={'Sentralt kundesenter'}
                description={
                    'Ved spørsmål om Bilhold-appen eller behov for veiledning i ditt bilhold.'
                }
                phoneLabel={'Ring kundesenter'}
                phoneNumber={'+4724033570'}
                email="kundeservice@moller.no"
                openHoursText="Åpent mandag - fredag 08:00 - 16:00"
            />
            <BrandContactInfo />
        </>
    );
};

export default ContactInfoList;
