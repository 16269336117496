import { useQuery } from '@tanstack/react-query';
import { useFetchersContext } from 'external-apis';
import { RecallAppointment } from 'external-apis/src/types/bilhold';
import { FriendlyQueryError } from '../../lib/errors/PortError';
import {
    LanguageContextType,
    useLanguageContext,
} from '../../lib/languages/languageContext';
import { STALE_TIME } from '../../lib/query-client/config';

const useGetRecallsFetcher = () => {
    const [fetchers] = useFetchersContext();
    return fetchers.bilhold.fetcher
        .path('/v1/cars/{Vin}/recalls')
        .method('get')
        .create();
};

export function useGetRecalls(vin: string | undefined) {
    const findRecalls = useGetRecallsFetcher();
    const [lc] = useLanguageContext();
    return useQuery<RecallAppointment[], Error>({
        queryKey: ['getRecalls', vin ?? ''],
        queryFn: () => getRecalls(vin ?? '', lc, findRecalls),
        enabled: !!vin,
        staleTime: STALE_TIME.hour,
        refetchOnWindowFocus: false, // This call will often fail in Test/Stage and does not change often
    });
}

async function getRecalls(
    vin: string,
    lc: LanguageContextType,
    findRecalls: ReturnType<typeof useGetRecallsFetcher>
) {
    try {
        const result = await findRecalls({ Vin: vin });
        return result.data;
    } catch (e) {
        if (e instanceof findRecalls.Error) {
            throw new FriendlyQueryError(
                lc.errors.port_dealers_default,
                e,
                e.status
            );
        }
        throw e;
    }
}
