import {
    breakpointDesktop,
    breakpointTablet,
} from '@moller/design-system/utilities/layout/breakpoints.js';
import { useMemo, useState, useEffect } from 'react';

function useMediaQuery(query: string) {
    const mediaQuery = useMemo(() => window.matchMedia(query), [query]);
    const [match, setMatch] = useState(mediaQuery.matches);

    useEffect(() => {
        const onChange = () => {
            return setMatch(mediaQuery.matches);
        };
        mediaQuery.addEventListener('change', onChange);

        return () => {
            return mediaQuery.removeEventListener('change', onChange);
        };
    }, [mediaQuery]);

    return match;
}

export function useMediaQueries() {
    const tabletOrSmaller = useMediaQuery(`(min-width: ${breakpointTablet})`);
    const desktop = useMediaQuery(`(min-width: ${breakpointDesktop})`);

    return { desktop, tabletOrSmaller };
}
