import { ImageType } from 'react-images-uploading';
import { DamageAppraisal } from 'external-apis/src/types/damageappraisal';

const hasMinimumOneImage = (tag: string, images: ImageType[]) => {
    return images.some((image) => image.tag === tag);
};

export const hasValidImages = (images?: ImageType[]) => {
    if (!images) return false;
    return (
        hasMinimumOneImage('car-closeup', images) &&
        hasMinimumOneImage('car-overview', images) &&
        hasMinimumOneImage('car-mileage', images)
    );
};

// Is insurance number needed?
export const hasValidPaymentInfo = (damage: DamageAppraisal) => {
    return damage.customerPaying || !!damage.insuranceCompanyName;
};
