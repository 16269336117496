import { Headline, InfoCard, PrimaryButton } from '@moller/design-system';
import { useCarProfile } from 'src/features/home/queries/useCarProfile';
import { usePreferredDealer } from 'src/features/home/queries/usePreferredDealer';
import { PATH_ADD_CAR } from 'src/routing/routes';
import { CenteredPageContent } from 'src/shared/CenteredPageContent';
import lang from 'src/utils/lang';
import { styled } from 'styled-components';
import PreferredDealerCard from './PreferredDealerCard';
import { mapBrandForPreferredDealer } from './SelectPreferredDealer';

const Page = styled.div`
    background-color: var(--moller-color-background);
`;

const StyledPage = styled(CenteredPageContent)`
    display: flex;
    flex-direction: column;
    gap: var(--moller-spacing-m);
`;

const StyledHeadline = styled(Headline)`
    margin-bottom: var(--moller-spacing-s);
`;

const NoCarsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: var(--moller-spacing-l);
`;

const MyPreferredDealer = () => {
    const cars = useCarProfile();
    const existingCarBrands = cars.data?.existingCars?.map((car) => car.brand);
    const uniqueBrands = [...new Set(existingCarBrands)];
    const { data: preferredDealers, isLoading } = usePreferredDealer();

    const noCars = () => {
        return (
            <NoCarsContainer>
                <p>{lang.addCarBeforePreferredDealer}</p>
                <PrimaryButton $as="a" href={PATH_ADD_CAR}>
                    {lang.addCar}
                </PrimaryButton>
            </NoCarsContainer>
        );
    };

    return (
        <Page>
            <StyledPage showBackButton>
                <StyledHeadline>{lang.myDealer}</StyledHeadline>
                {uniqueBrands.length === 0 && !isLoading
                    ? noCars()
                    : uniqueBrands.map((brand) =>
                          isLoading ? (
                              <InfoCard key={brand} isLoading />
                          ) : (
                              <PreferredDealerCard
                                  key={brand.toLowerCase()}
                                  preferredDealer={preferredDealers?.find(
                                      (pd) =>
                                          mapBrandForPreferredDealer(brand) ===
                                          pd.brand
                                  )}
                                  brand={brand}
                              />
                          )
                      )}
            </StyledPage>
        </Page>
    );
};

export default MyPreferredDealer;
