import Brand from 'src/types/Brand';

const energyConsumptionForModel = (carModel: string, brand: Brand) => {
    const carName = carModel.toLowerCase().trim();

    //Default values from manually searching on different models in car-information
    // kWh/km
    if (brand === Brand.VW) {
        if (carName.includes('id.3')) {
            return 0.145;
        }
        if (carName.includes('id.4')) {
            return 0.176;
        }
        if (carName.includes('id.5')) {
            return 0.176;
        }
    } else if (brand === Brand.AUDI) {
        if (carName.includes('q4-etron')) {
            return 0.18;
        }
        if (carName.includes('q8 e-tron')) {
            return 0.23;
        }
        if (carName.includes('e-tron')) {
            return 0.245;
        }
    } else if (brand === Brand.SKODA) {
        if (carName.includes('enyaq')) {
            return 0.175;
        }
    } else if (brand === Brand.CUPRA) {
        if (carName.includes('born')) {
            return 0.16;
        }
    }

    return null;
};

export default energyConsumptionForModel;
