import { useQuery } from '@tanstack/react-query';
import { ChecklistContent } from 'src/features/home/current-actions/waiting-program/utils/types';
import get from 'src/utils/http/get';

export const useGetChecklist = (id: string | undefined) => {
    return useQuery(['checklist', id], () =>
        get<ChecklistContent>(
            `api/contracts/${id}/checklist?language=NOR`
        ).then((res) => res.data)
    );
};
