import { AlertBanner } from '@moller/design-system';
import { CarContract } from 'external-apis/src/types/bilhold';

interface ManualProductRequestProps {
    orderedCarDealerEmail: string;
    customProductRequest: string;
    contract: CarContract;
    userFirstName: string;
}

export const ManualProductRequest = ({
    orderedCarDealerEmail,
    customProductRequest,
    contract,
    userFirstName,
}: ManualProductRequestProps) => {
    const sendOrderEmailManually = () => {
        const email = encodeURIComponent(orderedCarDealerEmail);
        const subject = encodeURIComponent(`Forespørsel om kjøp av produkt`);
        const body = encodeURIComponent(
            `${customProductRequest}
    
            Bil og kontraktsinformasjon:
            Merke: ${contract?.carContractCar?.brand}
            Modell: ${contract?.carContractCar?.model}
            Kontraktsnummer: ${`${contract.contractNumber}/${contract.version}`}
    
            Hilsen
            ${userFirstName}
            `.replace(/  +/g, ' ')
        );

        window.open(`mailto:${email}?subject=${subject}&body=${body}`);
    };

    return (
        <AlertBanner
            type={'error'}
            message={`Det har dessverre oppstått en feil. Trykk på knappen under for å åpne e-postklienten din med ferdigutfylt informasjon for bestillingen.`}
            action={{
                label: 'Send e-post',
                onClick: sendOrderEmailManually,
            }}
        />
    );
};
