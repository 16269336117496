import { useFlag } from 'feature-toggle';
import { isFeatureToggleOverrides, showDevTools } from './devToolsUtils';

const useFeatureToggle = (key: string): boolean => {
    const flag = useFlag(key);

    if (showDevTools && isFeatureToggleOverrides(key)) {
        return true;
    }

    return flag;
};

export default useFeatureToggle;
