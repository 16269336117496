import {
    Column,
    PrimaryButton,
    TextButton,
    TextContainer,
} from '@moller/design-system';

import { Clipboard } from '@capacitor/clipboard';
import IosShareIcon from '@mui/icons-material/IosShare';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { IconButton } from '@mui/material';
import { useEffect, useState } from 'react';
import { styled } from 'styled-components';
import useModal from '../hooks/useModal';
import { TDFTextLarge, TDFTextMedium } from '../textComponents/TDFText';

const ModalWrapper = styled(Column)`
    inset: 0;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    z-index: 1000;
    isolation: isolate;
    position: fixed;
`;
const GrayBackground = styled.div`
    z-index: -1;
    position: fixed;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-tap-highlight-color: transparent;
`;
const DivWrapper = styled.div`
    position: relative;
    width: 75%;
    color: black;
    height: fit-content;
    border-radius: 1rem;
    max-height: 656px;
    max-width: 600px;
    box-sizing: border-box;
    overflow: auto;
    display: flex;
    gap: var(--moller-spacing-m);
    position: fixed;
    flex-direction: column;
    -webkit-box-pack: justify;
    justify-content: space-between;
    padding: var(--moller-spacing-l) var(--moller-spacing-xl);
    z-index: 1100;
    background-color: #a6a6a6;
`;

const CopyModal = styled.div`
    position: absolute;
    width: 200px;
    display: flex;
    justify-content: center;
    border-radius: 0.5rem;
    background-color: #303132;
    padding: var(--moller-spacing-m) var(--moller-spacing-l);
    z-index: 1101;
`;

const ModalDiv = styled.div`
    display: block;
    unicode-bidi: isolate;
`;
export const ShareWithFriendsButton = () => {
    const [isCopiedModalVisible, setIsCopiedModalVisible] = useState(false);
    const { isOpen, toggle } = useModal();

    const handleCopyClick = async () => {
        try {
            await Clipboard.write({
                string: 'Bli med å sykle din egen Tour de France med Škoda!\nTrykk på lenken under og følg instruksene:\n\nhttps://www.skoda-auto.no/mer-skoda/tour-de-skoda',
            });
            setIsCopiedModalVisible(true);
        } catch (err) {
            console.error('Failed to copy: ', err);
            setIsCopiedModalVisible(false);
        }
    };

    const handleClose = () => {
        setIsCopiedModalVisible(false);
        toggle();
    };

    useEffect(() => {
        if (isCopiedModalVisible) {
            const timeout = setTimeout(() => {
                setIsCopiedModalVisible(false);
            }, 2000);
            return () => clearTimeout(timeout);
        }
    }, [isCopiedModalVisible]);

    return (
        <>
            <IconButton
                size="large"
                style={{ color: 'white' }}
                onClick={toggle}
            >
                <IosShareIcon />
            </IconButton>
            {isOpen && (
                <ModalWrapper onClick={() => null}>
                    {isCopiedModalVisible && (
                        <CopyModal>Lenke kopiert</CopyModal>
                    )}
                    <GrayBackground />
                    <DivWrapper>
                        <TextContainer>
                            <Column horizontalAlign="center" gap="s">
                                <TDFTextLarge style={{ textAlign: 'center' }}>
                                    Inviter en venn
                                </TDFTextLarge>
                                <PersonAddIcon />
                            </Column>
                            <TDFTextMedium style={{ textAlign: 'center' }}>
                                Trykk på knappen under for å kopiere lenken til
                                konkurransen. Lim lenken inn i en melding du
                                sender til en du vil invitere!
                            </TDFTextMedium>
                        </TextContainer>
                        <PrimaryButton
                            onClick={handleCopyClick}
                            style={{
                                alignSelf: 'center',
                                backgroundColor:
                                    'var(--moller-palette-primary-70)',
                            }}
                        >
                            <TDFTextMedium>Kopier lenke</TDFTextMedium>
                        </PrimaryButton>
                        <TextButton
                            type="button"
                            style={{
                                alignSelf: 'center',
                                textDecoration: 'underline',
                            }}
                            onClick={handleClose}
                        >
                            <TDFTextMedium>Lukk</TDFTextMedium>
                        </TextButton>
                    </DivWrapper>
                    <ModalDiv />
                </ModalWrapper>
            )}
        </>
    );
};
