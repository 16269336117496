import { VideosByTypeNumberAndYearResult } from '@bilhold/sanity';
import groq from 'groq';
import { useSanityData } from 'src/sanity/useSanityData';
import Car from 'src/types/Car';

export const useGetSanityVideosByTypeNumber = (
    typeNumber?: string | null,
    isEnabled: boolean = true
) => {
    const videosByTypeNumber = groq`*[_type == "video" && references(*[_type == "carType" && $typeNumber == typeNumber]._id)] | order(priority asc)`;

    return useSanityData<VideosByTypeNumberAndYearResult>(
        videosByTypeNumber,
        { typeNumber },
        isEnabled
    );
};

export function getTypeNumberForVin(
    existingCars: Car[] | undefined,
    vin: string | undefined
) {
    const currentCar = existingCars?.find((c) => c.vin === vin);
    const typeNumber = currentCar?.typeNumber;

    return typeNumber;
}
