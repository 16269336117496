import { Capacitor } from '@capacitor/core';
import { adobeEvent } from 'adobe-utils';
import { RouteProps } from 'react-router-dom';
import { getIsLoggedIn } from 'src/utils/auth/authenticationUtils';
import { isMobileApp } from 'src/utils/mobile';
import LoginRedirecter from '../utils/auth/LoginRedirecterComponent';

const AuthRoute = ({ element }: RouteProps) => {
    if (location.pathname) {
        adobeEvent.push(
            'pageLoad',
            {
                pageID: location.pathname,
                pageName: location.host,
                referringURL: document.referrer,
                language: 'nb-NO',
                category: location.pathname,
                platform: isMobileApp() ? 'app' : 'web',
            },
            Capacitor.getPlatform()
        );
    }
    return <>{getIsLoggedIn() ? element : <LoginRedirecter />}</>;
};

export default AuthRoute;
