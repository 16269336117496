import { datadogRum } from '@datadog/browser-rum';
import { IconButton, Row } from '@moller/design-system';
import { styled } from 'styled-components';
import { Sentiment } from './FeedbackPrompt';

const StyledIconButton = styled(IconButton)`
    margin-bottom: var(--moller-spacing-s);
    color: var(--moller-color-on-background);
    background-color: transparent;

    svg {
        width: 28px;
        height: 28px;
    }

    &:hover {
        color: var(--moller-color-interactive);
    }
`;

interface SentimentIconsProps {
    setSentiment: (sentiment: Sentiment) => void;
    setShowRatingIcons: (showRatingIcons: boolean) => void;
}

export const SentimentIcons = ({
    setSentiment,
    setShowRatingIcons,
}: SentimentIconsProps) => {
    const handleFeedbackIconClick = (sentiment: Sentiment) => {
        setSentiment(sentiment);

        datadogRum.addAction('bilhold-feedback-sentiment', {
            feedbackSentimentValue: sentiment,
            feedbackSentimentPage: window.location.pathname,
        });

        setShowRatingIcons(false);
    };

    return (
        <Row horizontalAlign="center" gap="xl">
            <StyledIconButton
                icon="sentiment_dissatisfied"
                label="sentiment_dissatisfied"
                onClick={() =>
                    handleFeedbackIconClick('sentiment_dissatisfied')
                }
            />
            <StyledIconButton
                icon="sentiment_neutral"
                label="sentiment_neutral"
                onClick={() => handleFeedbackIconClick('sentiment_neutral')}
            />
            <StyledIconButton
                icon="sentiment_very_satisfied"
                label="sentiment_satisfied"
                onClick={() => handleFeedbackIconClick('sentiment_satisfied')}
            />
        </Row>
    );
};
export default SentimentIcons;
