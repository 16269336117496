import { InfoCard } from '@moller/design-system';
import { DelayedRender } from '../../lib/DelayedRender';

export function TransportServicesLoader() {
    const card = <InfoCard isLoading density="compact" />;
    return (
        <DelayedRender>
            {card}
            {card}
            {card}
            {card}
            {card}
        </DelayedRender>
    );
}

export function TransportServicesSingleCardLoader() {
    const card = <InfoCard isLoading density="compact" />;
    return <DelayedRender>{card}</DelayedRender>;
}
