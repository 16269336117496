import CountryCode from 'src/types/CountryCode';

export interface UserInfoResponse {
    readonly name: {
        readonly firstName: string;
        readonly lastName: string;
    };
    readonly address: {
        readonly addressLine: string;
        readonly city: string;
        readonly zipCode: string;
    };
}

export interface UserInfo {
    readonly addressLine?: string;
    readonly addressCountry?: string;
    readonly city?: string;
    readonly zipCode?: string;
    readonly firstName?: string;
    readonly lastName?: string;
}

const mapMdmUserInfo = (personSearchResponse: UserInfoResponse): UserInfo => {
    if (!personSearchResponse) return {};
    const {
        name: { firstName, lastName },
        address: { addressLine, city, zipCode },
    } = personSearchResponse;

    return {
        addressLine,
        addressCountry: CountryCode.NO,
        city,
        zipCode,
        firstName,
        lastName,
    };
};

export default mapMdmUserInfo;
