import { Capacitor } from '@capacitor/core';
import { adobeEvent } from 'adobe-utils';
import { Navigate, RouteProps } from 'react-router-dom';
import { hasValidRefreshToken } from 'src/utils/auth/authenticationUtils';
import { isMobileApp } from 'src/utils/mobile';
import { PATH_HOME } from './routes';

const NoAuthRoute = ({ element }: RouteProps) => {
    if (location.pathname) {
        adobeEvent.push(
            'pageLoad',
            {
                pageID: location.pathname,
                pageName: location.host,
                referringURL: document.referrer,
                language: 'nb-NO',
                category: location.pathname,
                platform: isMobileApp() ? 'app' : 'web',
            },
            Capacitor.getPlatform()
        );
    }
    return (
        <>
            {hasValidRefreshToken() ? (
                <Navigate
                    replace
                    to={`${PATH_HOME}${window.location.search}`}
                />
            ) : (
                element
            )}
        </>
    );
};

export default NoAuthRoute;
