import { Capacitor } from '@capacitor/core';
import { isMobileApp } from 'src/utils/mobile';
import RateAppDialog from './RateAppDialog';
import NpsInput from './net-promoter-score/NpsInput';

const randomChoice = Math.random() < 0.5;

interface RateAppOrNpsInputProps {
    trigger: string;
    showUnlessDismissed?: boolean;
}

const RateAppOrNpsInput = ({
    trigger,
    showUnlessDismissed,
}: RateAppOrNpsInputProps) => {
    const showRatingPrompt = (): boolean => {
        const storageItem = localStorage.getItem('showRatingPrompt');

        if (storageItem === null) {
            return !!showUnlessDismissed;
        }

        const parsedItem = JSON.parse(storageItem) as {
            showRatingPrompt: string;
            expiry?: string;
        };

        if (
            parsedItem.showRatingPrompt === 'true' ||
            parsedItem.expiry === undefined
        ) {
            return true;
        }

        const expiry = new Date(parsedItem.expiry);
        if (expiry < new Date()) {
            localStorage.removeItem('showRatingPrompt');
        }

        return false;
    };

    const hideNpsFor30Days = () => {
        const date = new Date();
        date.setDate(date.getDate() + 30);

        const storageItem = {
            showRatingPrompt: 'false',
            expiry: date.toISOString(),
        };

        localStorage.setItem('showRatingPrompt', JSON.stringify(storageItem));
    };

    if (!showRatingPrompt()) {
        return null;
    }

    if (!isMobileApp() || !Capacitor.isPluginAvailable('RateApp')) {
        return (
            <NpsInput hideNpsFor30Days={hideNpsFor30Days} trigger={trigger} />
        );
    }

    return randomChoice ? (
        <RateAppDialog hideNpsFor30Days={hideNpsFor30Days} />
    ) : (
        <NpsInput hideNpsFor30Days={hideNpsFor30Days} trigger={trigger} />
    );
};

export default RateAppOrNpsInput;
