import { EnvironmentName } from 'src/utils/environment/environments';
import { getCurrentEnvironmentName } from 'src/utils/environment/getCurrentEnvironment';
import { isMobileApp } from 'utils/mobile';

const setLaunchScriptForEnv = (env: EnvironmentName) => {
    if (isMobileApp()) {
        switch (env) {
            case 'test':
            case 'staging':
                return 'https://assets.adobedtm.com/9ec761efc3e5/ed9d67db8bd8/launch-881081f0ebfc-staging.min.js';
            case 'prod':
                return 'https://assets.adobedtm.com/9ec761efc3e5/ed9d67db8bd8/launch-dd86f6912469.min.js';
            default:
                return '';
        }
    }
    switch (env) {
        case 'test':
            return 'https://assets.adobedtm.com/9ec761efc3e5/8ed804a5ce8d/launch-19d7a1810e6f-development.min.js';
        case 'staging':
            return 'https://assets.adobedtm.com/9ec761efc3e5/8ed804a5ce8d/launch-37371f45c473-staging.min.js';
        case 'prod':
            return 'https://assets.adobedtm.com/9ec761efc3e5/8ed804a5ce8d/launch-527e238d310e.min.js';
        default:
            return '';
    }
};

export default () => {
    let isInitialized = false;
    document.addEventListener('DOMContentLoaded', function () {
        initGTM;
    });
    document.addEventListener('scroll', initGTMOnEvent);
    document.addEventListener('mousemove', initGTMOnEvent);
    document.addEventListener('touchstart', initGTMOnEvent);

    function initGTMOnEvent(event: Event) {
        initGTM();
        event.currentTarget?.removeEventListener(event.type, initGTMOnEvent);
    }

    function initGTM() {
        if (isInitialized) {
            return;
        }

        const nonce =
            document.head.querySelector<HTMLMetaElement>('meta[name="nonce"]')
                ?.content ?? '';
        isInitialized = true;
        const environmentName = getCurrentEnvironmentName();
        document.cookie = 'sat_track=true';
        const scriptTag = document.createElement('script');
        scriptTag.type = 'text/javascript';
        scriptTag.src = setLaunchScriptForEnv(environmentName ?? 'dev');
        scriptTag.async = true;
        scriptTag.setAttribute('nonce', nonce);
        document.head.insertBefore(scriptTag, document.head.firstElementChild);
    }
};
