import { Icon } from '@moller/design-system';
import { styled } from 'styled-components';
import { services } from './services';

const VisuallyHidden = styled.div`
    position: absolute;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(0 0 0 0); /* Legacy property for Internet Explorer */
    clip-path: inset(50%);
    white-space: nowrap;

    &:not(:focus):not(:active) {
        position: absolute;
        width: 1px;
        height: 1px;
        overflow: hidden;
        clip: rect(0 0 0 0); /* Legacy property for Internet Explorer */
        clip-path: inset(50%);
        white-space: nowrap;
    }
`;

const Table = styled.table`
    margin-top: var(--moller-spacing-xs);
    margin-bottom: var(--moller-spacing-xs);
    width: 100%;
    border-collapse: collapse;
`;

const TableHead = styled.th`
    background-color: var(--moller-color-background);
    color: var(--moller-color-on-surface-variant);
    padding: var(--moller-spacing-xs);
    text-align: left;
`;

const TableCell = styled.td`
    padding: var(--moller-spacing-xs);
`;

const IconTableCell = styled(TableCell)`
    text-align: center;
`;

const TableRow = styled.tr`
    background-color: var(--moller-color-surface);

    &:nth-child(even) {
        background-color: var(--moller-color-surface-variant);
    }
`;

interface Props {
    isElectric: boolean;
}

export function ServiceTable({ isElectric }: Props) {
    const filteredServices = services.filter(
        (s) => !s.onlyForFossilCars || !isElectric
    );
    return (
        <Table>
            <thead>
                <tr>
                    <TableHead>Tjeneste</TableHead>
                    <TableHead>Service- avtale</TableHead>
                    <TableHead>SVA</TableHead>
                </tr>
            </thead>
            <tbody>
                {filteredServices.map((s) => (
                    <TableRow key={s.text}>
                        <TableCell>{s.text}</TableCell>
                        <IconTableCell>
                            {s.isIncludedInOrdinaryService ? (
                                <Icon icon="check" aria-label="Er inkludert" />
                            ) : (
                                <VisuallyHidden>
                                    <span>er ikke inkludert</span>
                                </VisuallyHidden>
                            )}
                        </IconTableCell>
                        <IconTableCell>
                            <Icon icon="check" aria-label="Er inkludert" />
                        </IconTableCell>
                    </TableRow>
                ))}
            </tbody>
        </Table>
    );
}
