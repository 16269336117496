export const isDateLessThanXMonthsAgo = (
    date: string | undefined | null,
    months: number
): boolean => {
    if (!date) {
        return false;
    }
    // Get the date x months ago
    const pastDate = new Date();
    pastDate.setMonth(pastDate.getMonth() - months);

    // Check if the date of the object is newer than x months
    return new Date(date) > pastDate;
};
