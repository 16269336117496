import {
    AlertBanner,
    Column,
    Description,
    IconButton,
    InfoCard,
    Link,
    Modal,
    OverlineText,
    Row,
    Subtitle,
    TextContainer,
} from '@moller/design-system';
import moment from 'moment';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDamageAppraisers } from 'src/features/my-car/query/useDamageAppraisers';
import { useDeleteDamageAppraiser } from 'src/features/my-car/query/useDeleteDamageAppraiser';
import DamageStatus from 'src/utils/enums/DamageStatus';
import { DAY_MONTH_YEAR_FORMAT } from 'src/utils/string/formatTime';
import { styled } from 'styled-components';
import lang from 'utils/lang';

const SectionWrapper = styled.div`
    margin: var(--moller-spacing-xl) 0;
`;

const AppraiserCardsContainer = styled(Column)`
    gap: var(--moller-spacing-xs);
    margin-top: var(--moller-spacing-xxs);
`;

const EllipsisText = styled.p`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: var(--moller-spacing-xs);
    margin-bottom: var(--moller-spacing-m);
`;

const StyledLink = styled(Link)`
    max-width: max-content;
`;

interface DamageAppraisalSectionProps {
    vin: string;
}

export const DamageAppraisalSection = ({
    vin,
}: DamageAppraisalSectionProps) => {
    const { data } = useDamageAppraisers();
    const navigate = useNavigate();

    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [selectedDa, setSelectedDa] = useState<number>(0);
    const {
        mutate: deleteReport,
        isLoading,
        isError,
        reset,
    } = useDeleteDamageAppraiser(setModalIsOpen);

    const carDamageAppraisers = data?.filter((p) => p.vin === vin) ?? [];

    const startedAppraisals = carDamageAppraisers.filter(
        (p) => p.status === DamageStatus.STARTED
    );

    const closeModal = () => {
        reset();
        setModalIsOpen(false);
    };

    if (!startedAppraisals || startedAppraisals?.length === 0) {
        return null;
    }

    return (
        <SectionWrapper>
            <TextContainer>
                <OverlineText>{lang.draftedAppraisers}</OverlineText>
                <p>
                    Skadesakene under er kladder du har begynt på, men ikke
                    sendt inn. Du kan trygt slette sakene som har blitt
                    behandlet eller som ikke lenger er aktuelle.
                </p>
            </TextContainer>
            <AppraiserCardsContainer>
                {startedAppraisals.slice(0, 2).map((da) => (
                    <InfoCard key={da.id}>
                        <Subtitle>Skadesak: {da.id}</Subtitle>
                        <Description>
                            {`${lang.lastChanged} `}
                            {moment(da.updatedAt)
                                .format(DAY_MONTH_YEAR_FORMAT)
                                .trim()}
                        </Description>
                        <TextContainer>
                            <EllipsisText>{da.description}</EllipsisText>
                        </TextContainer>
                        <Row gap="s" horizontalAlign="start">
                            <IconButton
                                onClick={() => {
                                    navigate(`/damage/${da.id}`);
                                }}
                                icon="edit"
                                label="Fortsett"
                                showLabel="right"
                            ></IconButton>
                            <IconButton
                                icon="trash"
                                label={lang.delete_draft}
                                showLabel="left"
                                onClick={() => {
                                    setSelectedDa(da.id);
                                    setModalIsOpen(true);
                                }}
                            />
                        </Row>
                    </InfoCard>
                ))}
                {startedAppraisals.length > 2 && (
                    <StyledLink href={`/my-car/vin/${vin}/damageAppraiser`}>
                        Se flere skadesaker
                    </StyledLink>
                )}
            </AppraiserCardsContainer>

            <Modal
                isOpen={modalIsOpen}
                handleClose={() => closeModal()}
                title={lang.delete_draft}
                primaryAction={{
                    label: lang.delete_draft,
                    onClick: () => deleteReport(selectedDa),
                    loading: {
                        isLoading: isLoading,
                        loadingText: 'Loading',
                    },
                }}
                secondaryAction={{
                    label: lang.cancel,
                    onClick: () => closeModal(),
                }}
            >
                {lang.confirm_delete_report}
                {isError && (
                    <AlertBanner
                        type="error"
                        message={lang.deleteDamageAppraiserError}
                    />
                )}
            </Modal>
        </SectionWrapper>
    );
};
