enum Brand {
    AUDI = 'AUDI',
    VW = 'VW',
    SKODA = 'SKODA',
    NYTTE = 'NYTTE',
    SEAT = 'SEAT',
    CUPRA = 'CUPRA',
    UNKNOWN = 'UNKNOWN',
    OTHER = 'OTHER',
}
export default Brand;

//TODO brandMapper methods can be removed when no more brand specific api calls goes to CarCare(Old bilhold backend)
//Brand Enum values should also be updated to reflect values from new backend
export const brandMapper = (brand?: string | null): Brand => {
    const lowerCaseBrand = brand?.toLowerCase();

    switch (lowerCaseBrand) {
        case 'vw':
        case 'volkswagen':
            return Brand.VW;
        case 'audi':
            return Brand.AUDI;
        case 'skoda':
            return Brand.SKODA;
        case 'nytte':
        case 'volkswagencommercialvehicles':
            return Brand.NYTTE;
        case 'seat':
            return Brand.SEAT;
        case 'cupra':
            return Brand.CUPRA;
        case 'other':
            return Brand.OTHER;
        default:
            return Brand.UNKNOWN;
    }
};

import { Brand as BrandType } from 'external-apis/src/types/bilhold';
export const oldToNewBrandMapper = (brand?: Brand): BrandType => {
    switch (brand) {
        case Brand.VW:
            return 'Volkswagen';
        case Brand.AUDI:
            return 'Audi';
        case Brand.SKODA:
            return 'Skoda';
        case Brand.NYTTE:
            return 'VolkswagenCommercialVehicles';
        case Brand.SEAT:
            return 'Seat';
        case Brand.CUPRA:
            return 'Cupra';
        case Brand.OTHER:
            return 'Other';
        default:
            return 'Other';
    }
};
