import { getBookingPath } from 'src/features/booking/usePathToBooking';
import Car from 'src/types/Car';
import lang, { stringFormat } from 'src/utils/lang';
import { FormatDayAndMonth } from 'src/utils/time/timeUtils';
import { ProactiveArticleSanity } from '../SanityProactiveArticle';

interface EuControlProps {
    nextDate: string;
    car: Car;
}
export const EuControl = ({ nextDate, car }: EuControlProps) => {
    const bookingPath = getBookingPath({
        vehicleIdentificationNumber: car.vin,
        registrationNumber: car.licensePlate,
        serviceIds: ['3'],
    });

    return (
        <ProactiveArticleSanity
            variables={{ car, nextEuControlDate: nextDate }}
            name="EU-Kontroll"
            buttonLink={bookingPath}
            overlineText={stringFormat(
                lang.DlEuControl,
                FormatDayAndMonth(nextDate)
            )}
        />
    );
};
