import { useRef, useState } from 'react';
import { FlexRow } from '../../components/FlexRow';
import { BilholdViewTitle } from '../../components/bilhold/BilholdViewTitle';
import { useLanguageContext } from '../../lib/languages/languageContext';
import { ChooseVehicleDone } from './ChooseVehicleDone';
import { ChooseVehicleEdit } from './ChooseVehicleEdit';
import { datadogRum } from '@datadog/browser-rum';
import { ddRumPrivacySetting } from '../../components/DDRumPrivacySetting';
import { SectionState } from '../sectionUtils';
import { BilholdViewLayout } from '../../components/bilhold/BilholdView';
import { useScrollRefIntoView } from '../../lib/util/scroll';

export interface ChooseVehicleData {
    registrationNumber: string;
}

export type VehicleSectionState = Exclude<
    SectionState<ChooseVehicleData>,
    { viewMode: 'hidden' }
>;

export function useChooseVehicle({
    initialState,
}: {
    initialState: VehicleSectionState;
}) {
    const [state, setState] = useState(initialState);

    if (state.viewMode === 'done') {
        const done = {
            result: {
                viewMode: state.viewMode,
                data: state.data,
            },
            props: {
                viewMode: state.viewMode,
                setSectionState: setState,
                data: state.data,
            },
        };
        return done;
    }

    const edit = {
        result: {
            viewMode: state.viewMode,
        },
        props: {
            viewMode: state.viewMode,
            setSectionState: setState,
        },
    };
    return edit;
}

type ChooseVehicleProps = ReturnType<typeof useChooseVehicle>['props'];

export type ChooseVehicleResult = ReturnType<typeof useChooseVehicle>['result'];

export const ChooseVehicle: React.FC<ChooseVehicleProps> = (props) => {
    const { viewMode, setSectionState } = props;
    const [lc] = useLanguageContext();

    const sectionRef = useRef(null);
    useScrollRefIntoView(props.viewMode === 'edit', sectionRef);

    return (
        <BilholdViewLayout>
            <FlexRow ref={sectionRef} {...ddRumPrivacySetting('allow')}>
                <BilholdViewTitle
                    title={lc.chooseVehicle.title}
                    isEditable={viewMode === 'done'}
                    onClick={() => {
                        datadogRum.addAction('Edit vehicle');
                        setSectionState({ viewMode: 'edit' });
                    }}
                />
            </FlexRow>
            {viewMode === 'edit' && (
                <ChooseVehicleEdit {...{ setSectionState }} />
            )}
            {viewMode === 'done' && (
                <ChooseVehicleDone
                    registrationNumber={props.data.registrationNumber}
                />
            )}
        </BilholdViewLayout>
    );
};
