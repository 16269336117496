import { HttpStatusCode } from 'axios';
import CountryCode from 'src/types/CountryCode';
import trimUserName from 'src/utils/auth/trimUserName';
import getCurrentEnvironment from 'src/utils/environment/getCurrentEnvironment';
import getCountryCode from 'src/utils/region/getCountryCode';
import post from '../http/post';

export interface EnrollmentError {
    code: string;
    errors: { item: string | null; message: string }[];
    httpCode: HttpStatusCode;
    httpDescription: string;
}

const enrollUser = (endpoint: string, phoneNumber: string) => {
    const { loginServerUrl } = getCurrentEnvironment();
    const url = `${loginServerUrl}/${endpoint}`;
    return post(
        url,
        {
            phoneNumber: trimUserName(phoneNumber),
            countryCode: getCountryCode(),
            phoneCountryCode: CountryCode.NO,
        },
        { isAnonymous: true }
    );
};

export default enrollUser;
