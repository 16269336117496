import {
    AlertBanner,
    LoadingSpinner,
    TextContainer,
    breakpointTablet,
} from '@moller/design-system';
import { useEffect } from 'react';
import { VideoCheckpoint } from 'src/features/home/current-actions/waiting-program/utils/types';
import { useGetChecklist } from 'src/features/home/queries/useGetChecklist';
import { OrderedCar } from 'src/types/OrderedCar';
import CheckpointTypes from 'src/utils/enums/CheckpointTypes';
import lang from 'src/utils/lang';
import { selectTheme } from 'src/utils/themes/selectTheme';
import { styled } from 'styled-components';
import { CarVideoContainer } from './CarVideoContainer';

const Container = styled(TextContainer)`
    width: 90vw;
    padding-top: var(--moller-spacing-xs);
    padding-bottom: var(--moller-spacing-xxl);
    margin: auto;
    max-width: 1200px;
    @media screen and (min-width: ${breakpointTablet}) {
        width: 80vw;
    }
`;

interface OrderedCarVideosProps {
    car: OrderedCar;
}
export const OrderedCarVideos = ({ car }: OrderedCarVideosProps) => {
    const {
        data: checklist,
        isLoading,
        isError,
    } = useGetChecklist(`${car.contractId}`);

    useEffect(() => {
        if (localStorage.getItem('showRatingPrompt') === null) {
            const storageItem = {
                showRatingPrompt: 'true',
                expiry: null,
                trigger: 'Tilbake fra videoer i venteprogram',
            };

            localStorage.setItem(
                'showRatingPrompt',
                JSON.stringify(storageItem)
            );
        }
    }, []);

    if (isLoading) {
        return (
            <Container>
                <LoadingSpinner isFullScreen />
            </Container>
        );
    }

    if (isError) {
        return (
            <AlertBanner
                type={'error'}
                message={lang.general_error_contact_support}
            />
        );
    }

    const videos: VideoCheckpoint[] = checklist.categories
        ?.flatMap((category) => category.checkpoints)
        ?.filter((checkpoint) => checkpoint.type === CheckpointTypes.VIDEO);

    return (
        <CarVideoContainer
            theme={selectTheme(car.brand ?? checklist?.brand)}
            videos={videos}
        />
    );
};
