import { Icon, LinkCard, Row, Subtitle } from '@moller/design-system';

interface Props {
    url: string;
    name: string;
    description: string;
}
export function ExternalBrandLink({ url, name, description }: Props) {
    return (
        <li>
            <LinkCard as={'a'} href={url} target="__blank">
                <Row horizontalAlign="spaceBetween" gap="base">
                    <Subtitle>{name}</Subtitle>
                    <Icon icon="external" />
                </Row>
                <p>{description}</p>
            </LinkCard>
        </li>
    );
}
