import Dealer from 'src/types/Dealer';

export default (dealer: { location: Dealer['location'] }) => {
    const {
        location: { address, zipCode, postalArea },
    } = dealer;
    if (!address) return '';
    const zipString = zipCode ? `, ${zipCode}` : '';
    const postalString = postalArea ?? '';
    return `${address}${zipString} ${postalString}`;
};
