import { datadogRum } from '@datadog/browser-rum';
import {
    FlagProvider,
    IConfig,
    IToggle as unleashToggle,
    useUnleashClient,
    useFlag as useUnleashFlag,
} from '@unleash/proxy-client-react';
import {
    Ref,
    createContext,
    forwardRef,
    useContext,
    useEffect,
    useState,
} from 'react';

/**
 * Retrieves a boolean flag value from local storage based on the specified key.
 *
 * @param {string} key - The unique key used to store the flag in local storage.
 *
 * @returns {boolean} - The boolean flag value retrieved from local storage.
 * Returns `true` if the flag is present and truthy in local storage,
 * or `false` if it's not found or is falsy.
 */
export function useFlag(key: string): boolean {
    const toggle = useToggle(key);
    return !!toggle?.isEnabled;
}

/**
 * Interface representing a feature toggle.
 *
 * @interface IToggle
 * @property {string} name - The name of the feature toggle.
 * @property {'variation' | 'boolean'} type - The type of the feature toggle ('variation' or 'boolean').
 * @property {boolean} isEnabled - Indicates whether the feature toggle is enabled.
 */
interface IToggle {
    name: string;
    type: 'variation' | 'boolean';
    isEnabled: boolean;
}

/**
 * Interface representing a variation toggle, a specific type of feature toggle.
 *
 * @interface IVariationToggle
 * @extends {IToggle} - Extends the IToggle interface.
 * @property {object} variation - The variation details associated with this toggle.
 * @property {string} variation.key - The key identifying the variation.
 * @property {D | undefined} variation.value - The value associated with the variation, or undefined if not set.
 *
 * @template D - The type of data associated with the variation (optional).
 */
interface IVariationToggle<D> extends IToggle {
    type: 'variation';
    variation: {
        key: string;
        value: D;
    };
}

/**
 * Retrieves a feature toggle based on the specified key.
 *
 * @param {string} key - The unique key used to identify the feature toggle.
 *
 * @template D - The type of data associated with the toggle (optional).
 * @template T - The type of toggle to return, defaults to IToggle or IVariationToggle<D>.
 *
 * @returns {T | undefined} - The retrieved feature toggle or undefined if not found.
 * If the toggle is found and is of type 'boolean', an object of type IToggle is returned.
 * If the toggle is found and is of type 'variation', an object of type IVariationToggle is returned.
 * If the toggle is not found, undefined is returned.
 */
export function useToggle<
    D = undefined,
    T = D extends undefined ? IToggle : IVariationToggle<D>
>(key: string): T | undefined {
    const unleashClient = useUnleashClient();
    const unleashToggles = unleashClient.getAllToggles();

    const unleashToggle = unleashToggles.find((x) => x.name === key);

    // use this to enable rerending
    const unleashFlag = useUnleashFlag(key);

    if (!unleashToggle) return;

    const toggle = mapToggle<D>(unleashToggle);
    toggle.isEnabled = unleashFlag;

    onAccessToggle(toggle);
    return toggle as T;
}

function mapToggle<D>(toggle: unleashToggle): IToggle | IVariationToggle<D> {
    const isVariation = !!toggle.variant.payload;
    const returnToggle: IToggle = {
        name: toggle.name,
        type: isVariation ? 'variation' : 'boolean',
        isEnabled: toggle.enabled,
    };

    if (returnToggle.type === 'variation') {
        let value: D;
        switch (toggle.variant.payload?.type) {
            case 'json':
                value = JSON.parse(toggle.variant.payload?.value) as D;
                break;
            default:
                value = toggle.variant.payload?.value as D;
        }

        return {
            ...returnToggle,
            type: 'variation',
            variation: {
                key: toggle.variant.name,
                value,
            },
        };
    }

    return returnToggle;
}

const frontendEdgeUrl = {
    prod: 'https://unleash-edge.prod.mollercloud.no/api/frontend',
    stage: 'https://unleash-edge.stage.mollercloud.no/api/frontend',
    test: 'https://unleash-edge.test.mollercloud.no/api/frontend',
    dev: 'https://unleash-edge.dev.mollercloud.no/api/frontend',
    local: 'https://unleash-edge.dev.mollercloud.no/api/frontend',
};

const bookingFrontendKeys = {
    prod: 'workshopbooking:prod.36457e30bd537a573614f229fcf7224223d29b79c0e0893bd6294068',
    stage: 'workshopbooking:stage.eee2f5197b6febb6e996f31a5c2934ecbf175328a2503ec694985266',
    test: 'workshopbooking:test.c9e9ffcfa5755682332b0cdd7c9b902362792ffb1c20612d27e279d8',
    dev: 'workshopbooking:dev.bd1fc74c9332b2ff1e522183af171760a3c3b07bea1a65c98f9adc3c',
    local: 'workshopbooking:dev.bd1fc74c9332b2ff1e522183af171760a3c3b07bea1a65c98f9adc3c',
};

const bilholdFrontendKeys = {
    prod: 'Bilhold:prod.e6a89b1b9b30e0f71989a6644b086e4659544f295ab88b53124a1342',
    stage: 'Bilhold:stage.e017f7f4b32844fe592fe32fa6b3d5c955669ae777979b1dae524ab5',
    test: 'Bilhold:test.3f5b2c52f118d9d75dfce35f08c03abffc14f2630d129f3795d3a533',
    dev: 'Bilhold:dev.b5bb4ddc8860fb4e49744d3cb715ceba191d2a486674ed8dd6b87e6b',
    local: 'Bilhold:dev.b5bb4ddc8860fb4e49744d3cb715ceba191d2a486674ed8dd6b87e6b',
};

const applications = {
    booking: {
        url: frontendEdgeUrl,
        keys: bookingFrontendKeys,
        appName: 'package-booking',
    },

    bilhold: {
        url: frontendEdgeUrl,
        keys: bilholdFrontendKeys,
        appName: 'Bilhold',
    },
};

/**
 * Props for the FeatureToggleProvider component, which provides feature toggle configuration to its children.
 *
 * @typedef {Object} FeatureToggleProviderProps
 * @property {'prod' | 'stage' | 'staging' | 'test' | 'dev' | 'local'} env - The environment for which feature toggles are configured.
 * @property {keyof typeof applications} app - The application for which feature toggles are configured.
 * @property {FeatureToggleContext} [context] - An optional context for additional feature toggle configuration.
 * @property {JSX.Element | JSX.Element[]} children - React elements that are wrapped by FeatureToggleProvider to provide feature toggle context.
 */
export type FeatureToggleProviderProps = {
    env: 'prod' | 'stage' | 'staging' | 'test' | 'dev' | 'local';
    app: keyof typeof applications;
    context?: FeatureToggleContext;
    children: JSX.Element | JSX.Element[];
};

interface FeatureToggleContext {
    userId?: string;
}

const namespace = '#feature-toggle';
const event_ready = `${namespace}-ready`;

const FeatureToggleContext = createContext<Ref<HTMLDivElement>>(null);

function useAttachedToRef() {
    return useContext(FeatureToggleContext);
}

/**
 * Provides feature toggle configuration to its children by creating a context.
 *
 * @param {FeatureToggleProviderProps} props - The props for the FeatureToggleProvider component.
 * @param {React.Ref} ref - A ref to the component (automatically passed when using forwardRef).
 * @returns {React.ReactNode} - The wrapped components with feature toggle context.
 */
export const FeatureToggleProvider = forwardRef(
    (
        { env, app, context, children }: FeatureToggleProviderProps,
        ref: Ref<HTMLDivElement>
    ) => {
        if (!applications[app]) {
            throw new Error('App not found in feature toggle provider');
        }

        if (env === 'staging') {
            env = 'stage';
        }

        const config: IConfig = {
            url: applications[app].url[env],
            clientKey: applications[app].keys[env],
            refreshInterval: 300,
            appName: applications[app].appName,
            context: {
                userId: context?.userId ?? 'anonymous',
            },
        };

        return (
            <FeatureToggleContext.Provider value={ref}>
                <FlagProvider config={config}>
                    <FeatureToggleStartup>{children}</FeatureToggleStartup>
                </FlagProvider>
            </FeatureToggleContext.Provider>
        );
    }
);

FeatureToggleProvider.displayName = 'FeatureToggleProvider';

function FeatureToggleStartup({
    children,
}: {
    children: JSX.Element | JSX.Element[];
}) {
    const client = useUnleashClient();
    const [ready, setReady] = useState(false);
    const ref = useAttachedToRef();

    client.on('ready', () => {
        setReady(true);
    });

    useEffect(() => {
        if (ready) {
            const e = new Event(event_ready, {
                bubbles: false,
            });
            if (ready && typeof ref === 'object') {
                ref?.current?.dispatchEvent(e);
            }
        }
    }, [ready, ref]);

    return <>{children}</>;
}

/**
 * An interface representing the Feature Toggle client, providing methods for working with feature toggles.
 *
 * @interface IClient
 */
interface IClient {
    /**
     * Retrieves a list of feature toggles.
     *
     * @returns {IToggle[]} An array of feature toggles.
     */
    getToggles: () => IToggle[];

    /**
     * Registers a callback function to be invoked when the client is ready.
     *
     * @param {() => void} callback - The callback function to be executed when the client is ready.
     */
    useReady: (callback: () => void) => void;

    /**
     * Checks if the Feature Toggle client is ready.
     *
     * @returns {boolean} Returns `true` if the client is ready; otherwise, returns `false`.
     */
    isReady: () => boolean;

    /**
     * Checks if a given feature toggle is of type IVariationToggle.
     *
     * @param {IToggle} toggle - The feature toggle to check.
     * @returns {toggle is IVariationToggle} Returns `true` if the toggle is of type IVariationToggle; otherwise, returns `false`.
     */
    isVariation: (toggle: IToggle) => toggle is IVariationToggle<unknown>;
}

function onAccessToggle(toggle: IToggle) {
    datadogRum.addFeatureFlagEvaluation(toggle.name, {
        value: toggle.isEnabled,
        variant: isVariation(toggle) ? toggle.variation.key : undefined,
    });
}

const isVariation = (toggle: IToggle): toggle is IVariationToggle<unknown> =>
    toggle.type === 'variation';

/**
 * Returns an instance of the Feature Toggle client, which provides methods to interact with feature toggles.
 *
 * @returns {IClient} An instance of the Feature Toggle client.
 */
export function useFeatureToggleClient(): IClient {
    const ref = useAttachedToRef();

    const client = useUnleashClient();

    const useReady = (callback: () => void) => {
        useEffect(() => {
            if (typeof ref === 'object') {
                ref?.current?.addEventListener(event_ready, callback);
                return () => {
                    ref?.current?.removeEventListener(event_ready, callback);
                };
            }
        });
    };

    const getToggles = () => {
        const toggles = client
            .getAllToggles()

            .map((x) => mapToggle(x))
            .filter((x): x is IToggle => !!x);
        toggles.forEach((toggle) => onAccessToggle(toggle));
        return toggles;
    };

    const isReady = () => !!client.getContext().sessionId;

    return {
        useReady,
        isReady,
        getToggles,
        isVariation,
    };
}
