import { useQuery } from '@tanstack/react-query';
import { useFetchersContext } from 'external-apis';

const useFindTireHotelDeal = () => {
    const [fetchers] = useFetchersContext();
    return fetchers.bilhold.fetcher
        .path('/v1/tire-hotel/vin/{Vin}')
        .method('get')
        .create();
};

export function useGetTireHotelDeal(vin: string) {
    const getTireHotelDeal = useFindTireHotelDeal();
    return useQuery({
        queryKey: ['getTireHotelDeal', vin],
        queryFn: () => getTireHotelDeal({ Vin: vin }).then((x) => x.data),
    });
}
