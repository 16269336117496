import { FileOpener } from '@capacitor-community/file-opener';
import { App } from '@capacitor/app';
import { Directory, Filesystem, WriteFileResult } from '@capacitor/filesystem';
import { fromByteArray } from 'base64-js';
import { fetchBlob } from 'src/utils/auth/authUtils';
import { toServerUrl } from 'src/utils/http/utils/toServerUrl';

async function deleteFileAfterResume(file: WriteFileResult) {
    console.debug(`Scheduling deletion of ${file.uri} after resume`);
    const handle = await App.addListener('appStateChange', (appState) => {
        if (appState.isActive) {
            try {
                void Filesystem.deleteFile({ path: file.uri });
            } finally {
                void handle.remove();
            }
        }
    });
}

export async function openFileForMobileApp(
    url: string,
    mimeType: string,
    fileName?: string,
    adapterId?: string,
    useAnnataHistory?: boolean
) {
    const cacheFilename = fileName ?? `Bilhold-${new Date().getTime()}.pdf`;
    const blob = await fetchBlob(toServerUrl(url, adapterId, useAnnataHistory));
    const file = await Filesystem.writeFile({
        directory: Directory.Cache,
        path: cacheFilename,
        data: fromByteArray(new Uint8Array(await blob.arrayBuffer())),
    });
    try {
        await FileOpener.open({ filePath: file.uri, contentType: mimeType });
        await deleteFileAfterResume(file);
    } catch (error) {
        console.error(`Failed to open ${file.uri}`, error);
        await Filesystem.deleteFile({ path: file.uri });
    }
}
