import { useToggle } from 'feature-toggle';
import { useUserIds } from 'src/utils/hooks/useUserIds';

type CompetitionVariant = {
    userIds: string[];
};

export function useShowCompetitionForBetaUsers() {
    const showCompetitionPageForBetaUsers = useToggle<CompetitionVariant>(
        'ShowCompetitionForUsers'
    );

    const { entryUuid } = useUserIds();

    const showForBetaUsers =
        showCompetitionPageForBetaUsers?.variation?.value?.userIds.includes(
            entryUuid
        ) ?? false;

    return showForBetaUsers;
}
