import { useQuery } from '@tanstack/react-query';
import { useFetchersContext } from 'external-apis';

const useGetDamageAppraisers = () => {
    const [fetchers] = useFetchersContext();
    return fetchers.damageappraisal.fetcher
        .path('/v3/damageAppraisers')
        .method('get')
        .create();
};

export const useDamageAppraisers = () => {
    const getDamageAppraisers = useGetDamageAppraisers();
    return useQuery({
        queryKey: ['damageAppraiser'],
        queryFn: () => getDamageAppraisers({}).then((x) => x.data),
    });
};
