import { useQuery } from '@tanstack/react-query';
import { useFetchersContext } from 'external-apis';

const useGetRecallsFetcher = () => {
    const [fetchers] = useFetchersContext();
    return fetchers.bilhold.fetcher
        .path('/v1/cars/{Vin}/recalls')
        .method('get')
        .create();
};

export function useRecalls(vin: string | undefined) {
    const getRecalls = useGetRecallsFetcher();
    return useQuery({
        queryKey: ['getRecalls', vin ?? ''],
        queryFn: () =>
            getRecalls({ Vin: vin ?? '' }).then((x) => {
                return x.data;
            }),
        enabled: !!vin,
        staleTime: 1000 * 60 * 60,
    });
}
