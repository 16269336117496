import { TextButton } from '@moller/design-system';
import { useNavigate } from 'react-router-dom';
import { PATH_HOME } from '../../lib/constants/routes';
import { useLanguageContext } from '../../lib/languages/languageContext';
import { ddRumPrivacySetting } from '../DDRumPrivacySetting';

export function CancelButton() {
    const [lc] = useLanguageContext();
    const navigate = useNavigate();

    return (
        <TextButton
            underline={true}
            onClick={() => navigate(PATH_HOME)}
            {...ddRumPrivacySetting('allow')}
        >
            {lc.components.button_cancel}
        </TextButton>
    );
}
