import { PreferredAffiliationDealerResponse } from 'src/features/home/queries/usePreferredDealer';
import useFeatureToggle from 'src/utils/dev-tools/useFeatureToggle';
import { dealersWithDekkhotell } from './TireHotelDealers';

export const useTireCardContent = (
    vin: string,
    preferredDealer?: PreferredAffiliationDealerResponse
): ContentType | null => {
    const showTireChange = useFeatureToggle('ShowTireChange');

    if (!showTireChange || !preferredDealer) return null;

    // Render card content based on dealer affiliation
    if (dealersWithDekkhotell.includes(preferredDealer?.preferredDealerId)) {
        return DealerWithDekkhotellContent;
    }

    return StandardCarTireContent;
};

export type ContentType = {
    type: 'internal' | 'external' | 'standard';
    cardTitle: string;
    cardDescription: string;
    modalHeader: string;
    modalTitle: string;
    modalDescription: string[];
    modalDetail?: string;
    infoText?: string;
    modalButton: string;
    modalButtonLink?: string;
};

const StandardCarTireContent: ContentType = {
    type: 'standard',
    cardTitle: 'Vi fikser hjulskift for deg!',
    cardDescription:
        'Du slipper stresset med å bytte selv – og kan nyte en kaffe, eller gjør noe annet fornuftig, mens du venter. Bestill time i dag!',
    modalHeader: 'Hjulskift',
    modalTitle: 'Vi sørger for at bilen er klar for en ny sesong på veien.',
    modalDescription: [
        'Det er viktig at bilen din alltid er riktig skodd for føret du kjører på. Vi hjelper deg med hjulskift, så slipper du å bytte hjul selv.',
    ],
    modalButton: 'Bestill hjulskift',
};

const DealerWithDekkhotellContent: ContentType = {
    type: 'internal',
    cardTitle: 'Tar dekkene dine mye plass?',
    cardDescription: `Dekkene dine har det best på dekkhotell. Bestill dekkhotell hos oss! Hjulskift er inkludert!`,
    modalHeader: 'Dekkhotell',
    modalTitle: 'Vi sørger for at bilen er klar for en ny sesong på veien.',
    modalDescription: [
        'Dekkene dine har det best på et dekkhotell når de ikke er i bruk. Hos oss utfører vi hjulskift, vasker og kontrollerer dekkene grundig, før vi oppbevarer dem trygt innendørs',
        'Den største fordelen med å oppbevare dekkene på et dekkhotell, er at det forlenger levetiden. I tillegg er det praktisk og trygt.',
        'Hos Møller Bils dekkhotell inspiserer vi alle dekk og kommer med anbefalinger til hva slags dekk du bør velge til bilen din. Profesjonelle fagfolk sørger for at dekkene dine alltid er i gode hender.',
    ],
    modalButton: 'Bestill dekkhotell',
};
