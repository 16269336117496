import Car from 'src/types/Car';
import { OrderedCar } from 'src/types/OrderedCar';
import { PendingCar } from 'src/types/PendingCar';

export type CarWithStatus =
    | CarAndStatus<CarStatusType.PENDING_CAR, PendingCar>
    | CarAndStatus<CarStatusType.ORDERED_CAR, OrderedCar>
    | CarAndStatus<CarStatusType.EXISTING_CAR, Car>;

type CarAndStatus<TStatus extends CarStatusType, TCar> = {
    car: TCar;
    statusType: TStatus;
};

export enum CarStatusType {
    PENDING_CAR = 'PENDING_CAR',
    ORDERED_CAR = 'ORDERED_CAR',
    EXISTING_CAR = 'EXISTING_CAR',
}
