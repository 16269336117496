import { Description, InfoCard } from '@moller/design-system';
import { styled } from 'styled-components';
import { useGetVehicle } from '../../_api/http/vehicle';
import { BilholdViewLayout } from '../../components/bilhold/BilholdView';
import { BoldText } from '../../components/BoldText';
import { QueryError } from '../../components/QueryError';
import { useBookingStepAction } from '../../lib/datadog/hooks';
import { ChooseVehicleLoader } from './ChooseVehicleLoader';

const UpperCaseText = styled(Description)`
    text-transform: uppercase;
`;

interface ChooseVehicleDoneProps {
    registrationNumber: string;
}
export const ChooseVehicleDone: React.FC<ChooseVehicleDoneProps> = ({
    registrationNumber,
}) => {
    useBookingStepAction('Search car');
    const vehicle = useGetVehicle(registrationNumber);

    if (vehicle.isError) {
        return <QueryError error={vehicle.error} isError={true} />;
    }
    if (!vehicle.isSuccess) {
        return <ChooseVehicleLoader />;
    }

    return (
        <BilholdViewLayout>
            <InfoCard density="compact">
                <BoldText>
                    {vehicle.data.brandName + ' ' + vehicle.data.modelName}
                </BoldText>
                <UpperCaseText>{registrationNumber}</UpperCaseText>
            </InfoCard>
        </BilholdViewLayout>
    );
};
