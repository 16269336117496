import { Column, LeadText, Modal, Row } from '@moller/design-system';
import { useContext } from 'react';
import { styled } from 'styled-components';
import { AuthContext } from '../providers/AuthProvider';
import { ConnectToStravaButton } from './ConnectToStravaButton';

const StyledModal = styled(Modal)`
    /* Removes Header of modal which contains X exit button */
    [class^='modal_headerRowRecipe'] {
        display: none;
    }
    background-color: #a6a6a6;
    color: black;
    width: 90%;
    max-width: 24rem;
    text-align: center;
`;

const CloseButton = styled.button`
    background: none;
    border: none;
    color: inherit;
    font: inherit;
    cursor: pointer;
    text-decoration: underline;
    padding: 0;
    &:hover {
        opacity: 60%;
    }
`;

interface Props {
    isOpen: boolean;
    handleClose: () => void;
    descriptionText?: string;
    returnPath?: string;
}

export const ReconnectToStravaModal = ({
    isOpen,
    handleClose,
    descriptionText,
    returnPath,
}: Props) => {
    const { isStravaCodeLoading } = useContext(AuthContext);
    return (
        <StyledModal
            isOpen={isOpen}
            handleClose={handleClose}
            backgroundColor="#A6A6A6"
        >
            <Column
                horizontalAlign="center"
                gap="xs"
                style={{
                    margin: '2rem 0 2rem 0',
                }}
            >
                <LeadText style={{ marginBottom: '1rem' }}>Wops! </LeadText>
                <p style={{ whiteSpace: 'pre-line' }}>
                    {descriptionText ??
                        'Koblingen mellom Strava og Bilhold mislyktes. Prøv å koble til Strava på nytt.'}
                </p>
                <Row style={{ margin: '1rem 0 1rem 0' }}>
                    <ConnectToStravaButton
                        isDisabled={false}
                        isLoading={isStravaCodeLoading}
                        returnPath={returnPath}
                    />
                </Row>
                <CloseButton onClick={handleClose}>Lukk</CloseButton>
            </Column>
        </StyledModal>
    );
};
