/**
 * Note: This function supports Moment .day() numbers (0-6 starting on sunday)
 * and weekDayNumber from MinifiedDealerInfo (1-7 starting on monday)
 */
export default (weekDayNumber: number) => {
    switch (weekDayNumber) {
        case 1:
            return 'monday';
        case 2:
            return 'tuesday';
        case 3:
            return 'wednesday';
        case 4:
            return 'thursday';
        case 5:
            return 'friday';
        case 6:
            return 'saturday';
        case 7:
        case 0:
            return 'sunday';
        default:
            throw new Error('weekDayNumber must be from 0 to 7');
    }
};
