import { useQuery } from '@tanstack/react-query';
import { brandMapper } from 'src/types/Brand';
import Car from 'src/types/Car';
import { OrderedCar } from 'src/types/OrderedCar';
import { PendingCar } from 'src/types/PendingCar';
import proxyGet from 'src/utils/data-mocks/proxyGet';
import getCurrentEnvironment from 'src/utils/environment/getCurrentEnvironment';

export interface CarsResponse {
    existingCars: Car[];
    orderedCars: OrderedCar[];
    pendingCars: PendingCar[];
}

export const carProfileQueryKey = 'carProfile';

export async function fetchAndSortCars() {
    const { bilholdApiUrl } = getCurrentEnvironment();
    const apiUrl = `${bilholdApiUrl}/v1/cars`;

    const res = await proxyGet<CarsResponse>(apiUrl);
    const newCarsData: CarsResponse = {
        ...res.data,
        existingCars: res.data.existingCars.map((existingCar) => {
            const mappedExistingCarBrand = brandMapper(existingCar.brand);
            return {
                ...existingCar,
                brand: mappedExistingCarBrand,
            };
        }),
        orderedCars: res.data.orderedCars.map((orderedCar) => {
            const mappedOrderedCarBrand = brandMapper(orderedCar.brand);
            return {
                ...orderedCar,
                brand: mappedOrderedCarBrand,
            };
        }),
    };
    return newCarsData;
}

export const useCarProfile = () => {
    return useQuery({
        queryKey: [carProfileQueryKey],
        queryFn: () => fetchAndSortCars(),
    });
};
