import Brand from 'src/types/Brand';
import { CarModelMedia } from './CarModelMedia';

export interface OrderedCar {
    brand: Brand;
    model: CarModel;
    contractId: number;
    orderNumber: number;
    orderStep?: OrderStepNoReserved;
    carModelMedia: CarModelMedia;
    checklistAvailable: boolean;
    createdAt: string;
    status?: string;
}

export interface CarModel {
    year: number;
    type: number;
    number: number;
    text: string;
}

export enum OrderStep {
    DELIVERY = 'DELIVERY',
    SENT = 'SENT',
    PRODUCTION = 'PRODUCTION',
    CAR_ORDERED = 'CAR_ORDERED',
    RESERVED = 'RESERVED',
}

export enum OrderStatus {
    SIGNED = 'SIGNED',
    IN_PROGRESS = 'IN_PROGRESS',
    INVOICED = 'INVOICED',
}

export enum OrderStepNoReserved {
    DELIVERY = 'DELIVERY',
    SENT = 'SENT',
    PRODUCTION = 'PRODUCTION',
    CAR_ORDERED = 'CAR_ORDERED',
}
