import { PATH_BOOKING_ROOT } from 'src/routing/routes';

interface Params {
    vehicleIdentificationNumber?: string;
    registrationNumber?: string;
    serviceIds?: string[];
}

export function getBookingPath(params: Params, dealerNumber?: string) {
    const queryParams = new URLSearchParams();
    if (params.registrationNumber) {
        queryParams.set('registrationNumber', params.registrationNumber);
    } else if (params.vehicleIdentificationNumber) {
        queryParams.set('vin', params.vehicleIdentificationNumber);
    }
    if (dealerNumber) {
        queryParams.set('dealerNumber', dealerNumber);
    }
    params.serviceIds?.forEach((s) => {
        queryParams.append('serviceId', s);
    });
    const search = queryParams.toString() ? `?${queryParams}` : '';
    return `${PATH_BOOKING_ROOT}${search}`;
}
