import { AlertBanner, Column, Row } from '@moller/design-system';
import { Link } from 'react-router-dom';
import lang from 'src/utils/lang';
import { isMobileApp } from 'src/utils/mobile';
import { getFormatDateWithDay } from 'src/utils/time/timeUtils';
import { styled } from 'styled-components';

const Container = styled(Column)`
    width: 100%;
    max-width: 500px;
    margin-bottom: var(--moller-spacing-l);
`;

interface RequestInfoProps {
    damageDesc: string;
    createdAt: string;
    damageId: number;
    hasReply?: boolean;
}

const RequestInfo = ({
    damageDesc,
    createdAt,
    damageId,
    hasReply,
}: RequestInfoProps) => {
    return (
        <Container>
            {!hasReply && (
                <AlertBanner
                    type={'success'}
                    message={lang.requestReceivedInfo}
                />
            )}
            <Column gap={'m'}>
                <Row gap={'base'}>
                    <p>
                        <b>{lang.sentDate}:</b>
                    </p>
                    <p>{getFormatDateWithDay(createdAt)}</p>
                </Row>
                <Row gap={'base'}>
                    <p>
                        <b>{lang.aboutDamage}:</b>
                    </p>
                    <p>{damageDesc}</p>
                </Row>
                <Link
                    to={`/damage/${damageId}`}
                    target={isMobileApp() ? undefined : '_blank'}
                >
                    {lang.seeDamage}
                </Link>
            </Column>
        </Container>
    );
};

export default RequestInfo;
