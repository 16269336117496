import { CapacitorUpdater } from '@capgo/capacitor-updater';
import { Headline, LabelText, Modal } from '@moller/design-system';
import { useEffect, useState } from 'react';
import { DevToolsLinkBadge } from 'src/features/header/DevToolsLinkBadge';
import {
    PATH_COOKIES,
    PATH_MY_PREFERRED_DEALER,
    PATH_MY_PROFILE,
    PATH_PRIVACY,
    PATH_TERMS_OF_SERVICE,
} from 'src/routing/routes';
import { CenteredPageContent } from 'src/shared/CenteredPageContent';
import logout from 'src/utils/auth/logout';
import { ddRumPrivacySetting } from 'src/utils/datadog';
import { themeOverride } from 'src/utils/dev-tools/devToolsUtils';
import { useProfileConsent } from 'src/utils/hooks/useProfile';
import lang from 'src/utils/lang';
import { isMobileApp } from 'src/utils/mobile';
import { styled } from 'styled-components';
import { ChildThemeProvider } from 'theming';
import packageJson from '../../../../package.json';
import MenuButton from './components/MenuButton';

const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--moller-spacing-xs);
`;

const AppBundleText = styled.div`
    opacity: 0.3;
    text-align: center;
    width: 100%;
    margin-top: 50px !important;
    margin-bottom: -30px !important;
`;

const ProfileView = () => {
    const consent = useProfileConsent();
    const [isOpen, setIsOpen] = useState(false);
    const [currentAppVersion, setCurrentAppVersion] = useState<{
        native: string;
        bundle: string;
    }>({ native: ' ', bundle: ' ' });

    useEffect(() => {
        void CapacitorUpdater.current().then((current) =>
            setCurrentAppVersion({
                native: current.native,
                bundle: current.bundle.version,
            })
        );
    }, []);

    return (
        <ChildThemeProvider theme="bilhold" overrideTheme={themeOverride}>
            <CenteredPageContent>
                <PageContainer {...ddRumPrivacySetting('allow')}>
                    <Headline $as="h2">{lang.settings}</Headline>
                    <MenuButton
                        icon="chevron_right"
                        text={lang.personalInformation}
                        route={PATH_MY_PROFILE}
                    />
                    <MenuButton
                        icon="chevron_right"
                        text={lang.myDealer}
                        route={PATH_MY_PREFERRED_DEALER}
                    />
                    <MenuButton
                        icon="external"
                        text={lang.myConsents}
                        route={consent.data}
                    />
                    <MenuButton
                        icon="chevron_right"
                        text={lang.privacy}
                        route={PATH_PRIVACY}
                    />
                    <MenuButton
                        icon="chevron_right"
                        text={lang.cookies}
                        route={PATH_COOKIES}
                    />
                    <MenuButton
                        icon="chevron_right"
                        text={lang.termsOfService}
                        route={PATH_TERMS_OF_SERVICE}
                    />
                    <MenuButton
                        icon="logout"
                        text={lang.logout}
                        setIsOpen={setIsOpen}
                    />

                    {isMobileApp() && currentAppVersion && (
                        <AppBundleText>
                            <LabelText>
                                {currentAppVersion.native}/
                                {currentAppVersion.bundle}
                            </LabelText>
                        </AppBundleText>
                    )}
                    {!isMobileApp() && packageJson && (
                        <AppBundleText>
                            <LabelText> {packageJson.version}</LabelText>
                        </AppBundleText>
                    )}
                    <DevToolsLinkBadge />
                </PageContainer>
            </CenteredPageContent>

            <Modal
                isOpen={isOpen}
                title={lang.logout}
                size="medium"
                handleClose={() => setIsOpen(false)}
                primaryAction={{
                    onClick: () => void logout(),
                    label: lang.logout,
                }}
                secondaryAction={{
                    onClick: () => setIsOpen(false),
                    label: lang.cancel,
                }}
            >
                {lang.log_out_confirm}
            </Modal>
        </ChildThemeProvider>
    );
};

export default ProfileView;
