import { useQuery } from '@tanstack/react-query';
import UserProfile from 'src/types/UserProfile';
import getCurrentEnvironment from 'src/utils/environment/getCurrentEnvironment';
import get from 'src/utils/http/get';

export const useProfile = (enabled = true) => {
    const { bilholdApiUrl } = getCurrentEnvironment();
    return useQuery({
        enabled,
        queryKey: ['userProfile'],
        queryFn: () =>
            get<UserProfile>(`${bilholdApiUrl}/v1/user`).then(
                (res) => res.data
            ),
    });
};

interface Consent {
    consentUrl: string;
}

export const useProfileConsent = () => {
    const { bilholdApiUrl } = getCurrentEnvironment();
    return useQuery({
        queryKey: ['userProfileConsent'],
        queryFn: () =>
            get<Consent>(`${bilholdApiUrl}/v1/consent/generate-url`).then(
                (res) => res.data.consentUrl
            ),
        staleTime: 1000 * 60 * 60,
    });
};
