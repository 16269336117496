import { openPdfWithAuth } from 'src/utils/auth/authUtils';
import { openFileForMobileApp } from 'src/utils/native/pdf';

export const openContractFileMobileApp = (url: string, fileName: string) =>
    openFileForMobileApp(
        `api/v3/media/${url}/type/FILE`,
        'application/pdf',
        fileName
    );

export const openContractFileWeb = (url: string, fileName: string) =>
    openPdfWithAuth(`api/v3/media/${url}/type/FILE`, fileName);
