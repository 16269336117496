import {
    breakpointTablet,
    LoadingSpinner,
    TextContainer,
} from '@moller/design-system';

import { useParams } from 'react-router-dom';
import { useCarProfile } from 'src/features/home/queries/useCarProfile';
import { styled } from 'styled-components';
import { ExistingCarVideos } from './ExistingCarVideos';
import { OrderedCarVideoContainer } from './OrderedCarVideoContainer';

const Container = styled(TextContainer)`
    width: 90vw;
    padding-top: var(--moller-spacing-xs);
    padding-bottom: var(--moller-spacing-xxl);
    margin: auto;
    max-width: 1200px;
    @media screen and (min-width: ${breakpointTablet}) {
        width: 80vw;
    }
`;

export const Videos = () => {
    const contractId = useParams().contractId;

    const { data: cars, isLoading } = useCarProfile();
    const car = cars?.orderedCars.find((c) => `${c.contractId}` === contractId);

    if (isLoading) {
        return (
            <Container>
                <LoadingSpinner isFullScreen />
            </Container>
        );
    }
    if (car) {
        return <OrderedCarVideoContainer car={car} />;
    }
    return <ExistingCarVideos />;
};
