import { styled } from 'styled-components';

const StyledTicket = styled.div`
    border: 2px solid var(--moller-color-primary);
    color: var(--moller-color-primary);
    padding: 0.25rem 0.5rem;
    display: inline-flex;
    margin-right: 1rem;
    margin-bottom: 0.5rem;
    transform: rotate(0.1turn);
    font-size: 0.75rem;
    height: fit-content;
`;

export const Ticket = () => <StyledTicket>Lodd</StyledTicket>;
