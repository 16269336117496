import { PrizeComponentBase } from 'src/features/competition/components/myPrizes/PrizeComponentBase';
import { useUpdateDistancePrizeData } from 'src/features/competition/utils/useUpdatePrizeData';
import sleepingBag from '../../images/SleepingBag.png';
interface Props {
    hasWon: boolean | undefined;
    dealer: string | undefined;
}

export const YellowTourShirtPrize = ({ hasWon, dealer }: Props) => {
    const {
        mutate: updatePrize,
        isLoading: isUpdating,
        isError,
    } = useUpdateDistancePrizeData();

    return (
        <PrizeComponentBase
            dealer={dealer}
            hasWonText={
                'Du har fullført alle etappene i hele touren i løpet av konkurranseperioden! Du var også blant de 250 første som klarte dette. Derfor får du premie!'
            }
            hasNotWonText={
                'Fullfør alle etappene i hele touren i løpet av konkurranseperioden! De første 250 som klarer den gule trøya får premie.'
            }
            heading={'Den gule trøya'}
            hasWon={hasWon}
            color={'#FAEB67'}
            isUpdating={isUpdating}
            isError={isError}
            updatePrize={updatePrize}
            image={sleepingBag}
            prizeName={'Škoda Sovepose'}
        />
    );
};
