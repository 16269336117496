import { useQuery } from '@tanstack/react-query';
import { getIsLoggedIn } from 'src/utils/auth/authenticationUtils';
import getCurrentEnvironment from 'src/utils/environment/getCurrentEnvironment';
import get from 'src/utils/http/get';

export const hasCompetitionUserKey = 'has-competition-user';

export const useHasCompetitionUser = () => {
    const { bilholdApiUrl } = getCurrentEnvironment();
    const url = `${bilholdApiUrl}/v1/competition/has-competition-user`;
    return useQuery({
        queryKey: [hasCompetitionUserKey],
        enabled: !!getIsLoggedIn(),
        queryFn: () => get<boolean>(url).then((response) => response.data),
        staleTime: 0,
    });
};
