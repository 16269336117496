import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useFetchersContext } from 'external-apis';
import { useNavigate, useParams } from 'react-router-dom';
import { DamageAppraisal } from 'external-apis/src/types/damageappraisal';

export const getDamageQueryKey = (damageId?: number | null) =>
    damageId
        ? (['existingDamageAppraisal', damageId] as const)
        : (['newDamageAppraisal'] as const);

const useGetDamageAppraisal = () => {
    const [fetchers] = useFetchersContext();
    return fetchers.damageappraisal.fetcher
        .path('/v3/damageAppraiser/id/{damageId}')
        .method('get')
        .create();
};

export const useExistingDamage = (damageId?: number) => {
    const getDamageAppraiser = useGetDamageAppraisal();
    return useQuery({
        queryFn: () =>
            getDamageAppraiser({ damageId: damageId?.toString() }).then(
                (res) => res.data
            ),
        queryKey: getDamageQueryKey(damageId),
        enabled: !!damageId,
    });
};

const useGetCreateNewDamage = () => {
    const [fetchers] = useFetchersContext();
    return fetchers.damageappraisal.fetcher
        .path('/v3/damageAppraiser')
        .method('get')
        .create();
};

export const useNewDamage = () => {
    const getNewDamageAppraiser = useGetCreateNewDamage();
    return useQuery({
        queryFn: () => getNewDamageAppraiser({}).then((res) => res.data),
        queryKey: getDamageQueryKey(),
    });
};

const useDeleteDamageAppraiser = () => {
    const [fetchers] = useFetchersContext();
    return fetchers.damageappraisal.fetcher
        .path('/v3/damageAppraiser/id/{damageId}')
        .method('delete')
        .create();
};

export const useDeleteDamageAppraisal = () => {
    const deleteDamageAppraiser = useDeleteDamageAppraiser();
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (damageId: number | null) =>
            deleteDamageAppraiser({ damageId: damageId?.toString() }),
        onSuccess: (_, damageId) =>
            void queryClient.invalidateQueries({
                queryKey: getDamageQueryKey(damageId),
            }),
    });
};

const useUpdateDamageAppraiser = () => {
    const [fetchers] = useFetchersContext();
    return fetchers.damageappraisal.fetcher
        .path('/v3/damageAppraiser/id/{damageId}')
        .method('post')
        .create();
};

const usePostDamageAppraiser = () => {
    const [fetchers] = useFetchersContext();
    return fetchers.damageappraisal.fetcher
        .path('/v3/damageAppraiser')
        .method('post')
        .create();
};

export const useUpdateDamageAppraisal = (damageId: number | null) => {
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const isIdInParam = !!useParams().id;
    const updateDamageAppraiser = useUpdateDamageAppraiser();
    const postDamageAppraiser = usePostDamageAppraiser();
    // This mutation does optimistic updates by updating the cache, see
    // https://tanstack.com/query/v5/docs/react/guides/optimistic-updates
    return useMutation({
        mutationFn: async (damage: Partial<DamageAppraisal>) => {
            const existingDamage =
                await queryClient.ensureQueryData<DamageAppraisal>(
                    getDamageQueryKey(damageId)
                );
            return damage.id
                ? await updateDamageAppraiser({
                      damageId: damage.id.toString(),
                      ...existingDamage,
                      ...damage,
                  }).then((x) => x.data)
                : await postDamageAppraiser({
                      ...existingDamage,
                      ...damage,
                  }).then((x) => x.data);
        },
        onMutate: async (damageUpdates: Partial<DamageAppraisal>) => {
            const queryKey = getDamageQueryKey(damageId);
            // Cancel any outgoing refetches
            // (so they don't overwrite our optimistic update)
            await queryClient.cancelQueries({ queryKey: queryKey });

            // Snapshot the previous value
            const existingDamage =
                await queryClient.ensureQueryData<DamageAppraisal>(queryKey);

            // Optimistically update to the new value
            queryClient.setQueryData<DamageAppraisal>(queryKey, (old) => ({
                ...old,
                ...(damageUpdates as DamageAppraisal),
            }));

            // Return a context object with the snapshotted value
            return { existingDamage };
        },
        onSuccess: (result: DamageAppraisal) => {
            // When being assigned an id from the backend, initialise the query cache for that id
            // and navigate to the correct path
            if (result.id && !isIdInParam) {
                queryClient.setQueryData<DamageAppraisal>(
                    getDamageQueryKey(result.id),
                    result
                );
                navigate(result.id?.toString(), { replace: true });
            }
        },
        // If the mutation fails,
        // use the context returned from onMutate to roll back
        onError: (
            err,
            vars,
            context: { existingDamage: DamageAppraisal } | undefined
        ) => {
            queryClient.setQueryData<DamageAppraisal>(
                getDamageQueryKey(damageId),
                context?.existingDamage
            );
        },
        // Always refetch after error or success
        onSettled: () => {
            void queryClient.invalidateQueries({
                queryKey: getDamageQueryKey(damageId),
            });
        },
    });
};
