type ServiceIdMap = {
    [serviceId: string]: string | undefined;
};

const serviceIdMap: ServiceIdMap = {
    '01-010-001': '3', // EU test
    '05-010-001': 'GS-2', // Tire change
    '04-010-001': '5', // Glass damage
    '02-010-001': 'GS-1', // Recommended service
};

// TODO: Delete function when all dealers use Annata.
// We always add the old serviceIds to the form state to support Mnet dealers.
// Ids that are not supported by the dealer will be filtered out in the onSubmit of the form.
export function mapLegacyServiceIds(serviceIdsFromUrl: string[]) {
    return serviceIdsFromUrl
        .map((serviceId) =>
            serviceIdMap[serviceId]
                ? [serviceId, serviceIdMap[serviceId]]
                : [serviceId]
        )
        .flat()
        .filter((x): x is string => !!x);
}
