import { Logo } from '@moller/design-system';
import { styled } from 'styled-components';

export const AnimationContainer = styled.div`
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    background-color: var(--moller-color-background);

    position: absolute;
    z-index: 100;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const AnimatedBilholdLogo = styled.div.attrs(() => ({
    children: <Logo brand={'bilhold'} type="symbol" size={'s'} />,
}))`
    color: var(--moller-color-on-background);
    * svg {
        animation-duration: 1s;
        animation-name: fadeIn;
        animation-timing-function: ease-in-out;
        animation-fill-mode: forwards;
        height: 135px !important;
        opacity: 0;

        * {
            fill: currentColor;
        }
    }

    @keyframes fadeIn {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
`;

export const AnimatedGumpenLogo = styled.div.attrs(() => ({
    children: <Logo brand={'gumpen'} type="symbol" size={'s'} />,
}))`
    color: var(--moller-color-primary);
    * svg {
        animation-duration: 1s;
        animation-name: fadeIn;
        animation-timing-function: ease-in-out;
        animation-fill-mode: forwards;
        height: 135px !important;
        opacity: 0;

        * {
            fill: currentColor;
        }
    }

    @keyframes fadeIn {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }
`;

export const AnimatedDahlesLogo = styled.div.attrs(() => ({
    children: <Logo brand={'dahles'} type="symbol" size={'s'} />,
}))`
    color: var(--moller-color-primary);
    * svg {
        animation-duration: 1s;
        animation-name: fadeIn;
        animation-timing-function: ease-in-out;
        animation-fill-mode: forwards;
        height: 75px !important;
        opacity: 0;

        * {
            fill: var(--moller-color-on-background);
        }
    }

    @keyframes fadeIn {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }
`;

export const AnimatedAutoriaLogo = styled.div.attrs(() => ({
    children: <Logo brand={'autoria'} type="symbol" size={'s'} />,
}))`
    color: var(--moller-color-primary);
    * svg {
        animation-duration: 1s;
        animation-name: fadeIn;
        animation-timing-function: ease-in-out;
        animation-fill-mode: forwards;
        height: 75px !important;
        opacity: 0;

        * {
            fill: var(--moller-color-on-background);
        }
    }

    @keyframes fadeIn {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }
`;

export const AnimatedMollerLogo = styled.div.attrs(() => ({
    children: <Logo brand={'mollerbil'} type="symbol" size={'3xl'} />,
}))`
    color: var(--moller-color-secondary);
    * svg {
        animation: 1.5s rotate 0.5s ease-in-out;
        animation-fill-mode: forwards;
        animation-iteration-count: 10;
        height: 150px !important;
        * {
            fill: currentColor;
        }
    }

    @keyframes rotate {
        from {
            transform: rotate(0deg);
        }
        50% {
            transform: rotate(250deg);
        }
        to {
            transform: rotate(0deg);
        }
    }
`;
