import { IconButton } from '@moller/design-system';
import React, { useRef } from 'react';
import {
    BottomSheet,
    BottomSheetProps,
    BottomSheetRef,
} from 'react-spring-bottom-sheet';
import 'react-spring-bottom-sheet/dist/style.css';
import lang from 'src/utils/lang';
import { styled } from 'styled-components';

const StyledBottomSheet = styled(BottomSheet)`
    z-index: 10;
    position: relative;
    color: var(--moller-palette-black);
`;

const Container = styled.div`
    padding: var(--moller-spacing-s);
    align-items: center;
    display: flex;
    flex-direction: column;
`;

const StickyCloseButton = styled(IconButton)`
    display: flex;
    position: fixed;
    height: fit-content;
    padding: var(--moller-spacing-xxs);
    right: var(--moller-spacing-s);
    background-color: var(--moller-palette-white);
    z-index: 1;
    border-radius: 0%;
`;

interface SwipeableDrawerProps
    extends Omit<BottomSheetProps, 'open' | 'blocked' | 'snapPoints'> {
    children: React.ReactNode;
    scrollHeader?: string;
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    footer?: React.ReactNode;
    imageUrl?: string;
}

export const SwipeableDrawer = ({
    children,
    isOpen,
    setIsOpen,
    footer,
}: SwipeableDrawerProps) => {
    const sheetRef = useRef<BottomSheetRef>(null);
    const containerRef = useRef<HTMLDivElement>(null);

    return (
        <>
            <StyledBottomSheet
                footer={footer}
                ref={sheetRef}
                open={isOpen}
                onDismiss={() => setIsOpen(false)}
                defaultSnap={({ maxHeight }) => maxHeight}
                snapPoints={({ maxHeight }) => maxHeight}
                initialFocusRef={containerRef}
                expandOnContentDrag
            >
                <StickyCloseButton
                    label={lang.back}
                    icon={'close'}
                    onClick={() => setIsOpen(false)}
                />
                <Container ref={containerRef} id="content-container">
                    {children}
                </Container>
            </StyledBottomSheet>
        </>
    );
};
