export const formatDealerName = (dealerName: string) => {
    let name = dealerName;
    if (dealerName.startsWith('MNET') && dealerName.includes('-')) {
        name = dealerName.split('-')[1];
    }
    if (dealerName.endsWith('AS')) {
        name = name.replace('AS', '');
    }
    return name;
};
