import { Fetcher } from 'openapi-typescript-fetch';
import { ENVIRONMENTS, backendUrl } from '../environment';
import { paths } from '../schema/damage-appraisal';
import { FetcherConfiguration } from './fetchersProvider';
import { Environment } from '../../index';

const urls: { [x in (typeof ENVIRONMENTS)[number]]: string } = {
    prod: 'https://damage-appraisal-api.prod.mollercloud.no/bilhold/api',
    stage: 'https://damage-appraisal-api.stage.mollercloud.no/bilhold/api',
    test: 'https://damage-appraisal-api.test.mollercloud.no/bilhold/api',
    dev: 'https://damage-appraisal-api.dev.mollercloud.no/bilhold/api',
    local: 'https://damage-appraisal-api.test.mollercloud.no/bilhold/api',
};

export const fetcher: ReturnType<typeof Fetcher.for<paths>> =
    Fetcher.for<paths>();

export function configureDamageAppraisalFetcher({
    localEnvironmentOverride,
    environment,
    middlewares,
}: FetcherConfiguration) {
    const baseUrl = backendUrl({
        environment,
        urls,
        localEnvironmentOverride,
    });
    fetcher.configure({
        baseUrl,
        init: {
            headers: {
                'Content-Type': 'application/json',
            },
        },
        use: middlewares,
    });
    return {
        baseUrl,
        fetcher,
        environment,
    };
}

export function damageAppraisalBaseUrl(
    environment: Environment,
    localEnvironmentOverride?: string
) {
    if (localEnvironmentOverride) return localEnvironmentOverride;
    return urls[environment];
}
