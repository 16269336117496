import { LabelText, TextContainer } from '@moller/design-system';
import { useState } from 'react';
import lang from 'src/utils/lang';
import { styled } from 'styled-components';

const NpsScaleContainer = styled.div`
    width: 400px;
    max-width: 100%;
    margin: 8px auto;
`;

const NpsValueButton = styled.button<{ selected: boolean }>`
    background: ${({ selected }) =>
        selected
            ? 'var(--moller-keycolor-success)'
            : 'var(--moller-color-primary)'};
    color: var(--moller-color-on-primary);
    width: calc(10% - 6px);
    height: auto;
    aspect-ratio: 1;
    cursor: pointer;
    border: none;
    border-radius: 50%;
    transition: 0.15s ease all;
    line-height: 1;

    &:hover {
        div {
            transform: scale(1.25);
        }
    }
`;

const FlexContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 4px;
`;

const NpsScale = ({
    submitScore,
}: {
    submitScore: (value: number) => void;
}) => {
    const scoreValues = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    const [value, setValue] = useState<number | null>(null);

    return (
        <NpsScaleContainer>
            <FlexContainer>
                {scoreValues.map((i) => (
                    <NpsValueButton
                        selected={value !== null && value >= i}
                        key={i}
                        onMouseEnter={() => setValue(i)}
                        onMouseLeave={() => setValue(null)}
                        onClick={() => submitScore(i)}
                    >
                        <TextContainer>{i}</TextContainer>
                    </NpsValueButton>
                ))}
            </FlexContainer>
            <FlexContainer>
                <LabelText>{lang.npsNotLikely}</LabelText>
                <LabelText>{lang.npsVeryLikely}</LabelText>
            </FlexContainer>
        </NpsScaleContainer>
    );
};

export default NpsScale;
