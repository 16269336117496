import { Divider } from '@mui/material';
import { PropsWithChildren } from 'react';
import { ddRumPrivacySetting } from 'src/utils/datadog';
import lang, { TextKey } from 'src/utils/lang';
import { styled } from 'styled-components';

interface DetailsListProps {
    readonly items: { readonly key: string; readonly value: string | number }[];
}

const DetailsListContent = styled.div`
    padding-top: 1em;
`;

const DetailsListRow = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: var(--moller-spacing-base);
    margin-top: var(--moller-spacing-base);
    :last-child {
        text-align: right;
        font-weight: 600;
    }
`;

const StyledDivider = styled(Divider)`
    border-color: var(--moller-color-outline) !important;
`;

const DetailsList = ({
    items,
    children,
}: PropsWithChildren<DetailsListProps>) => (
    <DetailsListContent>
        {items
            .filter((item) => item.value)
            .map((item) => (
                <>
                    <DetailsListRow key={item.key}>
                        <p>{lang[item.key as TextKey]}</p>
                        <p {...ddRumPrivacySetting('mask')}>{item.value}</p>
                    </DetailsListRow>
                    <StyledDivider />
                </>
            ))}

        {children}
    </DetailsListContent>
);

export default DetailsList;
