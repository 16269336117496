import { useQuery } from '@tanstack/react-query';
import { useFetchersContext } from 'external-apis';
const useCarContract = () => {
    const [fetchers] = useFetchersContext();
    return fetchers.bilhold.fetcher
        .path('/v1/car-contracts/{contractId}')
        .method('get')
        .create();
};
export function useContract(contractId: number | undefined) {
    const getCarContract = useCarContract();
    return useQuery({
        queryKey: ['getCarContract', contractId ?? ''],
        queryFn: () =>
            getCarContract({ contractId: contractId ?? -1 }).then((x) => {
                return x.data;
            }),
        enabled: !!contractId,
        staleTime: 1000 * 60 * 60,
    });
}
