import { datadogRum } from '@datadog/browser-rum';
import styled from '@emotion/styled';
import {
    AlertBanner,
    Column,
    Description,
    Headline,
    InfoCard,
    Row,
    Subtitle,
} from '@moller/design-system';
import { useGetDealers } from 'booking/_api/http/dealers';
import { RefObject, useEffect, useRef } from 'react';
import { useCarProfile } from 'src/features/home/queries/useCarProfile';
import scrollToTopOfPage from 'src/utils/browser/scrollToTopOfPage';
import { ddRumPrivacySetting } from 'src/utils/datadog';
import lang from 'src/utils/lang';
import formatCarName from 'src/utils/string/formatCarName';
import { DamageAppraisal } from 'external-apis/src/types/damageappraisal';
import { useGetMedia } from '../utils/useMedia';
import { StyledImage } from './DamageDetails';

const BoldText = styled.p`
    font-weight: var(--moller-typeface-weight-bold);
`;

const UpperCaseText = styled(Description)`
    text-transform: uppercase;
`;

const PreInfoCard = styled(InfoCard)`
    white-space: pre-wrap;
`;

const DamageSummary = ({
    damage,
    headingRef,
}: {
    damage?: DamageAppraisal;
    headingRef?: RefObject<HTMLHeadingElement>;
}) => {
    const { data: dealers } = useGetDealers(undefined, 'Damage');
    const dealer = dealers?.find((x) => x.id === damage?.dealerId);
    const { data: images } = useGetMedia(damage?.id);
    const cars = useCarProfile();
    const datadogSent = useRef(false);
    const car = cars.data?.existingCars?.find((c) => c.vin === damage?.vin);

    useEffect(() => {
        if (damage?.status === 'SENT') {
            scrollToTopOfPage();
        }
    }, [damage?.status]);

    if (!datadogSent.current) {
        if (damage?.status === 'SENT') {
            datadogRum.addAction('Damage submitted');
        } else {
            datadogRum.addAction('Damage summary');
        }
        datadogSent.current = true;
    }

    return (
        <div {...ddRumPrivacySetting('allow')}>
            <Headline ref={headingRef}>{lang.summary} av skadetakst</Headline>
            {damage?.status === 'SENT' && (
                <AlertBanner
                    type={'success'}
                    message={'Din skadetakst er innsendt!'}
                />
            )}
            {damage && (
                <Column gap="xs">
                    <Subtitle>Bilen det gjelder</Subtitle>
                    {car?.brand && (
                        <InfoCard density="compact">
                            <BoldText>
                                {`${formatCarName(car?.brand, car?.name)}`}
                            </BoldText>
                            <UpperCaseText {...ddRumPrivacySetting('mask')}>
                                {car?.licensePlate}
                            </UpperCaseText>
                        </InfoCard>
                    )}
                    {!car?.brand && (
                        <InfoCard density="compact">
                            {damage.licensePlate}
                        </InfoCard>
                    )}
                    <Subtitle>Beskrivelse av skaden</Subtitle>
                    <PreInfoCard>{damage?.description}</PreInfoCard>
                    <Row flexWrap="wrap" gap="s">
                        {images?.map((img) => (
                            <StyledImage
                                src={img.dataURL}
                                alt={`damage ${img.tag}`}
                                key={img.id}
                            />
                        ))}
                    </Row>
                    {!damage?.customerPaying && (
                        <>
                            <Subtitle>Forsikringsselskap</Subtitle>
                            <InfoCard density="compact">
                                {damage.insuranceCompanyName}
                            </InfoCard>
                        </>
                    )}
                    <Subtitle>Leiebil</Subtitle>
                    <InfoCard density="compact">
                        {damage?.needsReplacementCar ? lang.yes : lang.no}
                    </InfoCard>
                    <Subtitle>Skadeverksted</Subtitle>
                    <InfoCard density="compact">{dealer?.name}</InfoCard>
                    {damage.status === 'SENT' ? (
                        <AlertBanner
                            type="info"
                            message={
                                'Informasjonen om din skade er sendt. Du vil få takst og forslag til time for reparasjon i Bilhold innen tre virkedager.'
                            }
                        />
                    ) : (
                        <AlertBanner
                            type="info"
                            message={
                                'Når du sender dette mottar skadeverkstedet informasjonen om skaden og sender deg takst i Bilhold innen tre virkedager. Du vil også få forslag til time for reparasjon.'
                            }
                        />
                    )}
                </Column>
            )}
        </div>
    );
};

export default DamageSummary;
