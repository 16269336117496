import {
    Accordion,
    AlertBanner,
    Row,
    Subtitle,
    TextContainer,
} from '@moller/design-system';
import { Skeleton } from '@mui/material';
import { ddRumPrivacySetting } from 'src/utils/datadog';
import { withErrorBoundary } from 'src/utils/ErrorBoundary';
import { styled } from 'styled-components';
import { useCarInformation } from '../../home/queries/useCarInformation';
import EngineContent from './EngineContent';

const Container = styled.div`
    margin: var(--moller-spacing-m) 0;
`;

const StyledListSubtitle = styled(Subtitle)`
    margin-bottom: var(--moller-spacing-s);
`;

const StyledRow = styled(Row)`
    :last-child {
        padding-bottom: var(--moller-spacing-xs);
    }
`;

const List = styled.ul`
    list-style: none;
    padding: 0;
`;

const ListElement = styled.li`
    margin-top: var(--moller-spacing-xs);
`;

const StyledTextContainer = styled(TextContainer)`
    text-align: right;
`;

interface LoadinSpanProp {
    content: string | undefined | null;
    isLoading: boolean;
    isError: boolean;
}

export const LoadingSpan = ({
    content,
    isLoading,
    isError,
}: LoadinSpanProp) => {
    if (isLoading) {
        return <Skeleton variant="rounded" height={'1.5rem'} width={'77px'} />;
    }
    if (isError) {
        return <span>--</span>;
    }
    return <span>{content}</span>;
};

interface TechnicalData {
    readonly label: string;
    readonly value: string | null | undefined;
}

interface TechnicalDetailsProps {
    vin: string;
}

export const TechicalDetailsContainer = ({ vin }: TechnicalDetailsProps) => {
    const {
        data: technicalDetails,
        isLoading: tdLoading,
        isError: tdError,
    } = useCarInformation(vin);

    const registrationData: TechnicalData[] = [
        { label: 'Chassisnr.', value: technicalDetails?.vin },
        {
            label: 'Registreringsår',
            value: technicalDetails?.registrationDate?.slice(0, 4),
        },
        { label: 'Drivstoff', value: technicalDetails?.fuelType },
        { label: 'Farge', value: technicalDetails?.color },
    ];

    const measurements: TechnicalData[] = [];

    if (technicalDetails?.minimumWeight && technicalDetails?.maxWeight) {
        const items = [
            {
                label: 'Egenvekt',
                value: `${technicalDetails.minimumWeight}kg`,
            },
            {
                label: 'Egenvekt med fører',
                value: `${technicalDetails.minimumWeight + 75}kg`,
            },
            {
                label: 'Tillat totalvekt',
                value: `${technicalDetails.maxWeight}kg`,
            },
            {
                label: 'Maks nyttelast',
                value: `${
                    technicalDetails.maxWeight -
                    (technicalDetails.minimumWeight + 75)
                }kg`,
            },
        ];

        measurements.push(...items);
    }

    if (technicalDetails?.maxWeightTrailerNoBrakes) {
        measurements.push({
            label: 'Maksvekt tilhenger u/brems',
            value: `${technicalDetails?.maxWeightTrailerNoBrakes}kg`,
        });

        measurements.push({
            label: 'Maksvekt tilhenger m/brems',
            value: `${technicalDetails?.maxWeightTrailerWithBrakes}kg`,
        });
    }

    const tireAndRim: TechnicalData[] = [];

    if (technicalDetails?.rimDimFront === technicalDetails?.rimDimRear) {
        tireAndRim.push({
            label: 'Felger',
            value: technicalDetails?.rimDimFront,
        });
    } else {
        tireAndRim.push({
            label: 'Felger foran',
            value: technicalDetails?.rimDimFront,
        });
        tireAndRim.push({
            label: 'Felger bak',
            value: technicalDetails?.rimDimRear,
        });
    }

    if (technicalDetails?.tireDimFront === technicalDetails?.tireDimRear) {
        tireAndRim.push({
            label: 'Dekk',
            value: technicalDetails?.tireDimFront,
        });
    } else {
        tireAndRim.push({
            label: 'Dekk foran',
            value: technicalDetails?.tireDimFront,
        });
        tireAndRim.push({
            label: 'Dekk bak',
            value: technicalDetails?.tireDimRear,
        });
    }

    return (
        <Container>
            <StyledListSubtitle>Kjøretøysopplysninger</StyledListSubtitle>
            <Accordion head="Mål og vekt" withDivider>
                <List>
                    {measurements.map(({ label, value }) => (
                        <ListElement key={label}>
                            <StyledRow horizontalAlign="spaceBetween">
                                <TextContainer>{label}</TextContainer>
                                <StyledTextContainer
                                    {...ddRumPrivacySetting('mask')}
                                >
                                    {value}
                                </StyledTextContainer>
                            </StyledRow>
                        </ListElement>
                    ))}
                </List>
            </Accordion>
            <Accordion head="Dekk og felg" withDivider>
                <List>
                    {tireAndRim.map(({ label, value }) => (
                        <ListElement key={label}>
                            <StyledRow horizontalAlign="spaceBetween">
                                <TextContainer>{label}</TextContainer>
                                <StyledTextContainer
                                    {...ddRumPrivacySetting('mask')}
                                >
                                    {value}
                                </StyledTextContainer>
                            </StyledRow>
                        </ListElement>
                    ))}
                </List>
            </Accordion>
            <Accordion head="Registreringsdata" withDivider>
                <List>
                    {registrationData.map(({ label, value }) => (
                        <ListElement key={label}>
                            <StyledRow horizontalAlign="spaceBetween">
                                <TextContainer>{label}</TextContainer>
                                <StyledTextContainer
                                    {...ddRumPrivacySetting('mask')}
                                >
                                    {value}
                                </StyledTextContainer>
                            </StyledRow>
                        </ListElement>
                    ))}
                </List>
            </Accordion>
            <Accordion head="Motor/kraftoverføring" withDivider>
                <EngineContent
                    technicalDetails={technicalDetails}
                    tdError={tdError}
                    tdLoading={tdLoading}
                />
            </Accordion>
        </Container>
    );
};

const fallback = (retry: () => void) => (
    <AlertBanner
        type="warning"
        message="Vi greide dessverre ikke å vise tekniske detaljer om din bil. Prøv igjen senere!"
        action={{ label: 'Prøv igjen', onClick: retry }}
    />
);

export const TechicalDetails = withErrorBoundary(
    'TechicalDetails',
    TechicalDetailsContainer,
    {
        fallback,
    }
);
