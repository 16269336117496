import { BaseData, initMockData } from './data';

const init = () => {
    try {
        const mockDataJson = localStorage.getItem('mockData');

        if (mockDataJson) {
            initMockData(JSON.parse(mockDataJson) as BaseData);
            localStorage.removeItem('REACT_QUERY_OFFLINE_CACHE');
        }
    } catch (e) {
        console.error(e);
    }
};

export default init;
