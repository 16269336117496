import { datadogRum } from '@datadog/browser-rum';
import { ActionChip, Column, Row } from '@moller/design-system';
import { useState } from 'react';
import { styled } from 'styled-components';

const StyledUserInput = styled(Column)`
    margin-top: var(--moller-spacing-s);
    gap: var(--moller-spacing-s);
`;

const ChipRow = styled(Row)`
    gap: var(--moller-spacing-m);
`;

interface UsefulContentInputProps {
    usefulText: string;
    notUsefulText: string;
}
export const UsefulContentInput = ({
    usefulText,
    notUsefulText,
}: UsefulContentInputProps) => {
    const [isUseful, setIsUseful] = useState<boolean | null>(null);

    const handleOnClick = (feedback: boolean) => {
        if (feedback) {
            datadogRum.addAction(usefulText);
        } else {
            datadogRum.addAction(notUsefulText);
        }
        setIsUseful(feedback);
    };

    return (
        <StyledUserInput>
            <p>Var denne siden nyttig?</p>
            <div>
                {isUseful === null ? (
                    <ChipRow>
                        <ActionChip
                            onClick={() => handleOnClick(true)}
                            icon="check"
                            label="Ja"
                        />

                        <ActionChip
                            onClick={() => handleOnClick(false)}
                            icon="close"
                            label="Nei"
                        />
                    </ChipRow>
                ) : (
                    <p> Takk for tilbakemeldingen!</p>
                )}
            </div>
        </StyledUserInput>
    );
};
