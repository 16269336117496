import { ProactiveArticle } from '@bilhold/sanity';
import { datadogRum } from '@datadog/browser-rum';
import {
    breakpointTablet,
    Headline,
    LoadingSpinner,
    TextContainer,
} from '@moller/design-system';
import { useState } from 'react';
import RichText, { Variables } from 'src/sanity/components/RichText';
import SanityImage from 'src/sanity/components/SanityImage';
import { useSanityData } from 'src/sanity/useSanityData';
import { isMobileApp } from 'src/utils/mobile';
import { styled } from 'styled-components';
import { CampaignDrawer } from '../components/CampaignDrawer';
import { CampaignModal } from '../components/CampaignModal';
import SanityCard from './SanityCard';

const FlexContainer = styled.div`
    display: flex;
    flex-direction: column;

    h2 {
        margin-top: var(--moller-spacing-s) !important;
    }

    img {
        width: 100%;
        object-fit: cover;
        height: 200px;

        @media screen and (min-width: ${breakpointTablet}) {
            width: 100%;
            height: 350px;
            object-fit: cover;
        }
    }
`;

interface Props {
    name: string;
    variables?: Variables;
    nextDate?: string;
    buttonLink?: string;
    useReadMore?: boolean;
    overlineText?: string;
}

export const ProactiveArticleSanity = (props: Props) => {
    const [campaignModalIsOpen, setCampaignModalIsOpen] = useState(false);

    const query = `*[_type == "proactiveArticle" && name == $name][0]`;

    const { data, isLoading, error } = useSanityData<ProactiveArticle>(query, {
        name: props.name,
    });

    if (isLoading) return <LoadingSpinner isFullScreen />;

    if (error || !data) {
        datadogRum.addError(
            `Error received when trying to fetch Sanity data from ${props.name}`
        );
        return null;
    }

    const SanityModalContent = () => {
        return (
            <TextContainer>
                <FlexContainer>
                    <SanityImage sanityImageData={data.image} />
                    <Headline>{data.name}</Headline>
                </FlexContainer>
                {data && data.bodytext && (
                    <RichText
                        variables={props.variables}
                        value={data.bodytext}
                    />
                )}
            </TextContainer>
        );
    };

    const logActionButtonClick = () => {
        datadogRum.addAction(
            `User clicks ${data.cardTitle} article with Sanity data's action button`
        );
    };

    return (
        <>
            <SanityCard
                cardTitle={data.cardTitle || ''}
                cardDescription={data.cardDescription}
                variables={props.variables}
                openDrawer={() => {
                    setCampaignModalIsOpen(true);
                }}
                image={data.image}
                buttonText={data.buttonText}
                buttonLink={props.buttonLink}
                overlineText={props.overlineText}
                useReadMore={props.useReadMore ?? true}
            />

            {isMobileApp() ? (
                <CampaignDrawer
                    drawerIsOpen={campaignModalIsOpen}
                    setDrawerIsOpen={setCampaignModalIsOpen}
                    title={data.name || ''}
                    buttonText={
                        props.useReadMore ?? true ? data.buttonText : ''
                    }
                    buttonLink={props.buttonLink}
                    buttonClick={logActionButtonClick}
                >
                    <SanityModalContent />
                </CampaignDrawer>
            ) : (
                <CampaignModal
                    campaignModalIsOpen={campaignModalIsOpen}
                    setCampaignModalIsOpen={setCampaignModalIsOpen}
                    buttonText={
                        props.useReadMore ?? true ? data.buttonText : ''
                    }
                    buttonLink={props.buttonLink}
                    buttonClick={logActionButtonClick}
                >
                    <SanityModalContent />
                </CampaignModal>
            )}
        </>
    );
};
