import HeaderType from 'src/features/header/utils/HeaderType';
import {
    DEV_TOOLS,
    PATH_ADD_CAR,
    PATH_BEGIN,
    PATH_BOOKING_CONFIRMATION_ROOT,
    PATH_BOOKING_FAST_TRACK,
    PATH_BOOKING_ROOT,
    PATH_COMPETITION,
    PATH_COMPETITION_ABOUT,
    PATH_COMPETITION_PRIVACY_STATEMENT,
    PATH_COMPETITION_TERMS,
    PATH_COOKIES,
    PATH_CREATE_NEW_USER,
    PATH_DAMAGE,
    PATH_DAMAGE_APPRAISER,
    PATH_DAMAGE_APPRAISER_STATS,
    PATH_DAMAGE_APPRAISER_VALUATION,
    PATH_DAMAGE_APPRAISER_VALUATIONS,
    PATH_DAMAGE_APPRAISERS,
    PATH_DELETE_ACCOUNT,
    PATH_EXPLORE,
    PATH_HOME,
    PATH_LOGIN_CALLBACK_OIDC,
    PATH_MESSAGE_THREAD,
    PATH_MESSAGES,
    PATH_MY_CAR,
    PATH_MY_ORDERED_CAR,
    PATH_MY_PROFILE,
    PATH_PRIVACY,
    PATH_PRODUCT_WITH_ID,
    PATH_PROFILE_OVERVIEW,
    PATH_SERVICE_DEAL,
    PATH_TERMS_OF_SERVICE,
    PATH_WARRANTY,
} from 'src/routing/routes';
import MenuCategory from 'src/types/MenuCategory';
import Page from './pageType';

export const defaultConfig = {
    url: '',
    navigation: {},
    appMenuActive: true,
    headerType: HeaderType.NORMAL,
    footer: { visible: true, visibleMobile: true },
};

const noAuthPage = {
    ...defaultConfig,
    appMenuActive: false,
    headerType: HeaderType.SIMPLE,
};

const damageAppraiserSubPage: Page = {
    ...defaultConfig,
};

const bookingMinimalPage: Page = {
    ...defaultConfig,
    headerType: HeaderType.FIXED,
};

export const pages: Page[] = [
    /** ------------------------
     User management
     ---------------------------*/
    // Landing page
    {
        ...noAuthPage,
        footer: { visible: false, visibleMobile: false },
        url: '',
    },
    // begin
    {
        ...noAuthPage,
        headerType: HeaderType.NORMAL,
        url: PATH_BEGIN,
    },
    // Login callback
    {
        ...defaultConfig,
        url: PATH_LOGIN_CALLBACK_OIDC,
        headerType: HeaderType.NONE,
    },
    //  Create user
    {
        ...noAuthPage,
        url: PATH_CREATE_NEW_USER,
        footer: { visible: true, visibleMobile: false },
    },
    // Privacy
    {
        ...noAuthPage,
        appMenuActive: true,
        url: PATH_PRIVACY,
    },
    // Cookies
    {
        ...noAuthPage,
        appMenuActive: true,
        url: PATH_COOKIES,
    },
    {
        ...noAuthPage,
        appMenuActive: true,
        url: PATH_TERMS_OF_SERVICE,
    },
    // Dev tools
    {
        ...noAuthPage,
        appMenuActive: true,
        url: DEV_TOOLS,
    },
    /** ------------------------
     MY PROFILE w/subpages
     ---------------------------*/
    // Profile overview
    {
        ...defaultConfig,
        url: PATH_PROFILE_OVERVIEW,
        navigation: {
            web: {
                title: 'my_profile',
                position: 5,
            },
            menuCategory: MenuCategory.PROFILE,
        },
    },
    // My profile
    {
        ...defaultConfig,
        title: 'personalInformation',
        url: PATH_MY_PROFILE,
        navigation: {
            menuCategory: MenuCategory.PROFILE,
        },
    },
    // Delete account
    {
        ...defaultConfig,
        url: PATH_DELETE_ACCOUNT,
        navigation: {
            menuCategory: MenuCategory.PROFILE,
        },
    },
    /** ------------------------
     DAMAGE APPRAISERS w/subpages
     ---------------------------*/
    // Damage appraiser
    {
        ...defaultConfig,
        title: 'digitalValuation',
        url: PATH_DAMAGE,
    },
    // Damage appraiser
    {
        ...damageAppraiserSubPage,
        title: 'dmgAppraiserTitle',
        url: PATH_DAMAGE_APPRAISER,
    },
    // Damage appraisers
    {
        ...damageAppraiserSubPage,
        title: 'dmgAppraiserTitle',
        url: PATH_DAMAGE_APPRAISERS,
    },

    // TDF Competition
    {
        ...defaultConfig,
        url: PATH_COMPETITION,
        headerType: HeaderType.NORMAL,
        footer: { visible: false, visibleMobile: false },
    },
    {
        ...defaultConfig,
        url: PATH_COMPETITION_ABOUT,
        headerType: HeaderType.NORMAL,
        footer: { visible: false, visibleMobile: false },
    },
    {
        ...defaultConfig,
        url: PATH_COMPETITION_TERMS,
        headerType: HeaderType.NORMAL,
        footer: { visible: false, visibleMobile: false },
    },
    {
        ...defaultConfig,
        url: PATH_COMPETITION_PRIVACY_STATEMENT,
        headerType: HeaderType.NORMAL,
        footer: { visible: false, visibleMobile: false },
    },

    // Damage appraisers for dealer
    {
        ...defaultConfig,
        url: PATH_DAMAGE_APPRAISER_VALUATIONS,
        headerType: HeaderType.NONE,
        footer: { visible: false, visibleMobile: false },
    },
    {
        ...defaultConfig,
        url: PATH_DAMAGE_APPRAISER_VALUATION,
        headerType: HeaderType.NONE,
        footer: { visible: false, visibleMobile: false },
    },
    // Damage appraiser stats
    {
        ...defaultConfig,
        url: PATH_DAMAGE_APPRAISER_STATS,
        headerType: HeaderType.NONE,
        footer: { visible: false, visibleMobile: false },
    },
    // Home
    {
        ...defaultConfig,
        url: PATH_HOME,
        navigation: {
            web: {
                title: 'homeTitle',
                position: 1,
            },
            menuCategory: MenuCategory.HOME,
        },
    },
    // My car
    {
        ...defaultConfig,
        url: PATH_MY_CAR,
        appMenuActive: true,
        headerType: HeaderType.NONE,
    },
    // Service deals
    {
        ...defaultConfig,
        url: PATH_SERVICE_DEAL,
    },
    // Add car
    {
        ...defaultConfig,
        url: PATH_ADD_CAR,
    },

    /** ------------------------
     MY ORDERED CAR w/subpages
     ---------------------------*/
    // My ordered car
    {
        ...defaultConfig,
        url: PATH_MY_ORDERED_CAR,
        appMenuActive: true,
        headerType: HeaderType.NONE,
    },
    // Product pages
    {
        ...defaultConfig,
        url: PATH_PRODUCT_WITH_ID,
        appMenuActive: true,
        headerType: HeaderType.NONE,
    },
    /** ------------------------
     Various
     ---------------------------*/
    // OLB
    {
        ...defaultConfig,
        title: 'book_service_appointment',
        url: PATH_BOOKING_ROOT,
    },
    // Booking minimal
    {
        ...bookingMinimalPage,
        url: PATH_BOOKING_FAST_TRACK,
    },
    { ...bookingMinimalPage, url: PATH_BOOKING_CONFIRMATION_ROOT },
    // Messages
    {
        ...defaultConfig,
        url: PATH_MESSAGES,
        navigation: {
            web: {
                title: 'myMessages',
                position: 4,
            },
            menuCategory: MenuCategory.MESSAGES,
        },
    },
    // Message thread
    {
        ...defaultConfig,
        url: PATH_MESSAGE_THREAD,
        navigation: {
            menuCategory: MenuCategory.MESSAGES,
        },
    },
    {
        ...noAuthPage,
        url: PATH_WARRANTY,
    },
    // Explore
    {
        ...defaultConfig,
        url: PATH_EXPLORE,
        navigation: {
            web: {
                title: 'explore',
                position: 6,
            },
            menuCategory: MenuCategory.EXPLORE,
        },
    },
];
