import {
    Headline,
    IconButton,
    Row,
    Subtitle,
    breakpointMobile,
    breakpointTablet,
} from '@moller/design-system';
import { LogoBrand } from '@moller/design-system/foundation/logos/Logo.js';
import { CarContract } from 'external-apis/src/types/bilhold';
import { useState } from 'react';
import { normalizeETron } from 'src/features/my-car/car-brands/audi/audiUtils';
import { CarImage } from 'src/features/my-car/components/CarImage';
import { PageLayout } from 'src/features/my-car/components/PageLayout';
import { Header } from 'src/features/my-car/sticky-header/StickyHeader';
import DealerSection from 'src/features/my-ordered-car/components/DealerSection';
import OrderSummary from 'src/features/my-ordered-car/components/OrderSummary';
import { useStickyScroll } from 'src/utils/browser/useStickyScroll';
import { ddRumPrivacySetting } from 'src/utils/datadog';
import { themeOverride } from 'src/utils/dev-tools/devToolsUtils';
import lang from 'src/utils/lang';
import { openPhoneNumberHref } from 'src/utils/navigation/openSystemHref';
import { getThemeForCarBrand } from 'src/utils/themes/selectTheme';
import { styled } from 'styled-components';
import { ChildThemeProvider } from 'theming';
import RSLozenge from '../my-car/car-brands/audi/rsLozenge.svg';

interface MyOrderedCarProps {
    readonly contractDetails: CarContract;
    readonly isLoading: boolean;
}

const TopContent = styled.div`
    margin: var(--moller-spacing-xl) 0 var(--moller-spacing-m);
    img {
        max-width: 100%;
        max-height: 140px;
        margin: var(--moller-spacing-xl);
    }
`;

const SpaceBetween = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 800px;
    margin: auto;
`;

const FullWidthOnDesktop = styled.div`
    @media screen and (min-width: ${breakpointMobile}) {
        grid-column: 1 / 2 span;
    }
`;

const StyledColumn = styled.div`
    display: flex;
    flex-direction: column;
    gap: var(--moller-spacing-xs);
    align-items: flex-start;
    margin-bottom: calc(var(--moller-spacing-xl) * 2);
    @media screen and (max-width: ${breakpointTablet}) {
        min-width: 40vw;
    }
`;

const StyledHeadline = styled(Headline)`
    width: max-content;
    max-width: 280px;
    margin: var(--moller-spacing-m) 0;

    @media screen and (min-width: ${breakpointTablet}) {
        max-width: 450px;
    }
`;

const AlignedLozenge = styled.div`
    display: flex;
    align-items: flex-start;
    margin-right: 4px;

    img {
        height: 1.1rem;
        margin-top: 7px;
    }

    @media screen and (min-width: ${breakpointTablet}) {
        img {
            height: 1.5rem;
            margin-top: 10px;
        }
    }
`;

const IconStyle = styled.div`
    justify-content: flex-start;

    p {
        word-break: break-all;
    }
`;

const MyOrderedCar = ({ contractDetails, isLoading }: MyOrderedCarProps) => {
    const [isScrollActive, setIsScrollActive] = useState(false);
    const { seller, carContractCar, carContractDealer } = contractDetails;

    const carTheme = getThemeForCarBrand(carContractCar?.brand);

    const isStickyScrollActive = useStickyScroll(
        isScrollActive,
        setIsScrollActive
    );

    const carName = carContractCar?.model;

    const renderRSLozenge = () => {
        if (carTheme === 'audi' && carName?.toLowerCase().includes('rs')) {
            return (
                <AlignedLozenge>
                    <img src={RSLozenge} alt="RS" />
                </AlignedLozenge>
            );
        }
    };

    const handleSendEmail = () => {
        const email = encodeURIComponent(seller?.email ?? '');
        const contractNumber = `${contractDetails?.contractNumber}/${contractDetails?.version}`;
        const subject = encodeURIComponent(
            `Spørsmål til bestilt bil på kontrakt ${contractNumber}`
        );
        const body = encodeURIComponent(
            `

------------
Bil og kontraktsinformasjon:
Merke: ${carContractCar?.brand}
Modell: ${carContractCar?.model}
Kontraktsnummer: ${contractNumber}
            `
        );

        window.open(`mailto:${email}?subject=${subject}&body=${body}`);
    };

    return (
        <ChildThemeProvider
            theme={carTheme?.toLowerCase() as LogoBrand}
            overrideTheme={themeOverride}
        >
            <Header
                brand={carContractCar?.brand}
                isLoading={isLoading}
                carName={carContractCar?.model}
                isScrollActive={isStickyScrollActive}
            />
            <PageLayout>
                <FullWidthOnDesktop {...ddRumPrivacySetting('allow')}>
                    <TopContent>
                        <SpaceBetween>
                            <StyledColumn>
                                <Row>
                                    {renderRSLozenge()}
                                    <StyledHeadline>
                                        {normalizeETron(carName)}
                                    </StyledHeadline>
                                </Row>
                                {carContractDealer.phoneNumber && (
                                    <IconStyle>
                                        <IconButton
                                            $as={'a'}
                                            showLabel={'right'}
                                            icon={'call'}
                                            label="Ring forhandler"
                                            onClick={() =>
                                                openPhoneNumberHref(
                                                    carContractDealer?.phoneNumber ??
                                                        ''
                                                )
                                            }
                                        />
                                    </IconStyle>
                                )}
                                {seller?.email && (
                                    <IconStyle>
                                        <IconButton
                                            $as={'a'}
                                            showLabel={'right'}
                                            icon={'mail'}
                                            label="Send e-post"
                                            onClick={handleSendEmail}
                                        />
                                    </IconStyle>
                                )}
                            </StyledColumn>
                            {carTheme !== 'bilhold' && (
                                <CarImage
                                    carName={carContractCar.model ?? ''}
                                    brand={carContractCar.brand}
                                />
                            )}
                        </SpaceBetween>
                    </TopContent>
                </FullWidthOnDesktop>
                <TopContent {...ddRumPrivacySetting('allow')}>
                    <div>
                        <Subtitle>
                            <b>{lang.orderSummary}</b>
                        </Subtitle>
                        <OrderSummary
                            dealer={carContractDealer}
                            number={contractDetails.contractNumber}
                            version={contractDetails.version}
                            car={carContractCar}
                        />
                    </div>
                    <DealerSection dealer={carContractDealer} seller={seller} />
                </TopContent>
            </PageLayout>
        </ChildThemeProvider>
    );
};

export default MyOrderedCar;
