import { Capacitor } from '@capacitor/core';
import { datadogRum } from '@datadog/browser-rum';
import { LoadingSpinner } from '@moller/design-system';
import {
    AuthorizationNotifier,
    AuthorizationRequest,
    AuthorizationResponse,
    AuthorizationServiceConfiguration,
    BaseTokenRequestHandler,
    DefaultCrypto,
    FetchRequestor,
    GRANT_TYPE_AUTHORIZATION_CODE,
    LocalStorageBackend,
    RedirectRequestHandler,
    StringMap,
    TokenRequest,
} from '@openid/appauth';
import { useQueryClient } from '@tanstack/react-query';
import { adobeEvent } from 'adobe-utils';
import { useEffect, useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { QUERY_PARAM_STORAGE } from 'src/constants';
import { PATH_HOME } from 'src/routing/routes';
import {
    getUsername,
    hasValidRefreshToken,
    setAccessToken,
    setRefreshToken,
} from 'src/utils/auth/authenticationUtils';
import NoHashQueryStringUtils from 'src/utils/auth/NoHashQueryStringUtils';
import LocalStorage from 'src/utils/browser/LocalStorage';
import getCurrentEnvironment from 'src/utils/environment/getCurrentEnvironment';
import { isMobileApp } from 'utils/mobile';

const getTokenRequest = (
    request: AuthorizationRequest,
    response: AuthorizationResponse
) => {
    const environment = getCurrentEnvironment();
    const audience = environment.auth0.audience;
    let extras: StringMap | undefined = undefined;
    if (request && request.internal) {
        extras = {
            code_verifier: request.internal.code_verifier,
            audience: audience,
        };
    }

    return new TokenRequest({
        client_id: environment.auth0.clientId,
        redirect_uri: isMobileApp()
            ? environment.appRedirectURL
            : environment.redirectURL,
        grant_type: GRANT_TYPE_AUTHORIZATION_CODE,
        code: response.code,
        refresh_token: undefined,
        extras,
    });
};

const handleLegacyParams = (callbackState: string) => {
    const queryParamsInState = callbackState.split('?')[1];
    if (queryParamsInState) {
        LocalStorage.set(QUERY_PARAM_STORAGE, queryParamsInState);
    }
};

const LoginCallback = () => {
    const [isError, setIsError] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const environment = getCurrentEnvironment();
    const oAuthServer = `https://${environment.auth0.domain}`;
    const queryClient = useQueryClient();

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const tokenHandler = new BaseTokenRequestHandler(new FetchRequestor());
        const authorizationHandler = new RedirectRequestHandler(
            new LocalStorageBackend(),
            new NoHashQueryStringUtils(),
            window.location,
            new DefaultCrypto()
        );
        const notifier = new AuthorizationNotifier();
        authorizationHandler.setAuthorizationNotifier(notifier);

        const authorizationCallback = async (
            request: AuthorizationRequest,
            response: AuthorizationResponse | null
        ) => {
            if (!response) {
                return;
            }

            try {
                const serviceConfiguration =
                    await AuthorizationServiceConfiguration.fetchFromIssuer(
                        oAuthServer,
                        new FetchRequestor()
                    );

                datadogRum.addAction('loginCallbackRequest');
                const tokenResponse = await tokenHandler.performTokenRequest(
                    serviceConfiguration,
                    getTokenRequest(request, response)
                );

                await setAccessToken(tokenResponse.accessToken);
                tokenResponse.refreshToken
                    ? await setRefreshToken(tokenResponse.refreshToken)
                    : null;
                // The state can be null, handle this with replacing it with empty string
                const state = params.get('state') ?? '';
                handleLegacyParams(state);
                adobeEvent.push(
                    'login',
                    {
                        userID: getUsername(),
                        action: 'Login successful',
                    },
                    Capacitor.getPlatform()
                );

                await queryClient.invalidateQueries();
                navigate(state, { replace: true });
                datadogRum.addAction('loginCallbackComplete');
            } catch (er) {
                datadogRum.addError(er);
                setIsError(true);
            }
        };

        notifier.setAuthorizationListener(
            (request, response) => void authorizationCallback(request, response)
        );

        const codeFromParams = params.get('code');
        if (!codeFromParams) {
            datadogRum.addError(new Error('No code in params'));
            setIsError(true);
            return;
        }

        void authorizationHandler.completeAuthorizationRequestIfPossible();
    }, [location.search, navigate, oAuthServer, queryClient]);

    // Handle users who clicks back after logging in, to prevent them from getting stuck on the callback-page
    if (hasValidRefreshToken()) {
        return <Navigate replace to={PATH_HOME} />;
    }

    if (isError) {
        window.location.href = PATH_HOME;
    }

    return <LoadingSpinner isFullScreen />;
};

export default LoginCallback;
