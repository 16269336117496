import { ErrorResponse } from 'external-apis/src/types/carcare';

export const CAR_NOT_IN_CAR_SYSTEM = 'CAR_NOT_IN_CAR_SYSTEM';

export function isErrorResponse(response: { status: number; data: object }) {
    const errorResponse = response as { status: number; data: ErrorResponse };
    if (errorResponse?.data?.errors?.length > 0) {
        return errorResponse;
    }
    return undefined;
}
