import {
    Column,
    DisplayText,
    Headline,
    LeadText,
    PrimaryButton,
    TextButton,
    TextContainer,
} from '@moller/design-system';
import { useState } from 'react';
import { SwipeableDrawer } from 'src/shared/SwipeableDrawer';
import loginRedirect from 'src/utils/auth/loginRedirect';
import lang from 'src/utils/lang';
import { styled } from 'styled-components';
import heroimage from '../images/hero.jpg';
import { RoadAssistanceContent } from './RoadAssistance';

const HeroImage = styled.img`
    width: 100%;
    object-fit: cover;
    flex-shrink: 0;
    height: 35vh;
`;

const TextBlock = styled(Column)`
    height: 100%;
    margin: var(--moller-spacing-m);
    align-items: center;
    justify-content: space-between;
    gap: var(--moller-spacing-xxs);
`;

const ButtonGroup = styled(Column)`
    gap: var(--moller-spacing-xs);
    padding-top: var(--moller-spacing-xxs);
    padding-bottom: var(--moller-spacing-xs);
    width: 100%;
    align-items: center;
`;

const LoginButton = styled(PrimaryButton)`
    width: 90%;
`;

const Container = styled(TextContainer)`
    padding: var(--moller-spacing-l);
`;

export const HeroMobile = () => {
    const [drawerIsOpen, setDrawerIsOpen] = useState(false);
    return (
        <>
            <HeroImage src={heroimage} alt="" />
            <TextBlock>
                <DisplayText>{lang.beginTitle}</DisplayText>
                <LeadText>{lang.beginDescription}</LeadText>
            </TextBlock>
            <ButtonGroup>
                <LoginButton onClick={() => loginRedirect()}>
                    {lang.login}
                </LoginButton>
                <TextButton underline onClick={() => setDrawerIsOpen(true)}>
                    {lang.beginAssistance}
                </TextButton>
            </ButtonGroup>
            <SwipeableDrawer
                title="Road assistance drawer"
                isOpen={drawerIsOpen}
                setIsOpen={setDrawerIsOpen}
                scrollHeader={lang.roadAssistance}
            >
                <Container>
                    <Headline>{lang.roadAssistance}</Headline>
                    <RoadAssistanceContent />
                </Container>
            </SwipeableDrawer>
        </>
    );
};
