import { Icon, Row } from '@moller/design-system';
import { WeightItemChip } from 'src/features/electric-car-playground/payload/payload-calculator/components/WeightItemChip';
import { WeightItem } from 'src/features/electric-car-playground/payload/payload-calculator/types/WeightItems';
import { styled } from 'styled-components';

const ContentTable = styled.div`
    display: grid;
    align-items: center;
    gap: var(--moller-spacing-s);
    padding-bottom: var(--moller-spacing-xs);
`;
const ItemContainer = styled(Row)`
    justify-content: space-between;
    align-items: center;
`;

const Driver = styled(Row)`
    align-items: center;
`;

interface PersonContainerProps {
    addItem: (item: WeightItem) => void;
    removeItem: (item: WeightItem) => void;
    clickedAmount: (value: WeightItem) => number;
    checkedItems?: WeightItem[];
}
const StyledContainer = styled(Row)`
    gap: var(--moller-spacing-m) var(--moller-spacing-xxs);
    flex-direction: column;
`;

const WeightItemsContent = ({
    addItem,
    clickedAmount,
    removeItem,
    checkedItems,
}: PersonContainerProps) => {
    return (
        <ContentTable>
            <Driver>
                <Icon icon="warning" size={'l'} />
                <p>Sjåfør skal ikke medregnes</p>
            </Driver>
            <ItemContainer>
                <StyledContainer>
                    {checkedItems
                        ?.sort((a, b) => a.kg - b.kg)
                        .map((passenger: WeightItem, index) => (
                            <WeightItemChip
                                key={index}
                                item={passenger}
                                addItem={addItem}
                                removeItem={removeItem}
                                clickedAmount={clickedAmount(passenger)}
                            />
                        ))}
                </StyledContainer>
            </ItemContainer>
        </ContentTable>
    );
};

export default WeightItemsContent;
