import { ActionText, Icon, Link, Row } from '@moller/design-system';

export const VwCompetitionContent = () => {
    return (
        <>
            <ActionText>Vinn en ID.7!</ActionText>
            <p>
                Hvis du går 613 kilometer – som er bilens rekkevidde – innen 16.
                juni kan du vinne en ID.7 til en verdi av 545 000 kr!
            </p>
            <p>
                Hent en skritt-tellende genser fra din nærmeste forhandler fra
                22. april og bli med i konkurransen.
            </p>
            <Row gap="xxs" horizontalAlign="start">
                <Link>Se hvordan du kan delta</Link>
                <Icon icon="external" />
            </Row>
        </>
    );
};
