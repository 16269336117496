import { CarStatusType, CarWithStatus } from 'src/types/CarWithStatus';
import { CarsResponse } from '../queries/useCarProfile';

export default (cars: CarsResponse | undefined): CarWithStatus[] => {
    const carList: CarWithStatus[] = [];

    cars?.existingCars.forEach((car) =>
        carList.push({
            car,
            statusType: CarStatusType.EXISTING_CAR,
        })
    );
    cars?.orderedCars.forEach((car) =>
        carList.push({
            car,
            statusType: CarStatusType.ORDERED_CAR,
        })
    );
    cars?.pendingCars.forEach((car) =>
        carList.push({
            car,
            statusType: CarStatusType.PENDING_CAR,
        })
    );
    return carList;
};
