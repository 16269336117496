import { useQuery } from '@tanstack/react-query';
import { useFetchersContext } from 'external-apis';
import { components } from 'external-apis/src/schema/port';

export type HistoricBooking = components['schemas']['HistoricBookingViewModel'];

const useFindBookings = () => {
    const [fetchers] = useFetchersContext();
    return fetchers.port.fetcher
        .path('/bookings/historic/{vin}')
        .method('get')
        .create();
};

async function getHistoryBookings(
    vin: string,
    findBookings: ReturnType<typeof useFindBookings>
) {
    const result = await findBookings({ vin });
    return result.data;
}

export const useWorkshopHistory = (vin: string | undefined) => {
    const findBookings = useFindBookings();
    return useQuery<HistoricBooking[], Error>({
        queryKey: ['WorkshopHistory', vin],
        queryFn: () => getHistoryBookings(vin || '', findBookings),
        enabled: !!vin,
    });
};
