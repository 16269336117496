import { AlertBanner, Column, Row } from '@moller/design-system';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PrizesAccordion } from 'src/features/competition/components/myPrizes/PrizesAccordion';
import { GreenStreakShirt } from 'src/features/competition/components/shirtCards/GreenStreakShirt';
import { WhiteElevationShirt } from 'src/features/competition/components/shirtCards/WhiteElevationShirt';
import { YellowTotalShirt } from 'src/features/competition/components/shirtCards/YellowTotalShirt';
import {
    TDFTextLarge,
    TDSTextSmall,
    TDSThinHeadline,
} from 'src/features/competition/textComponents/TDFText';
import { useCompetitionPrizeData } from 'src/features/competition/utils/useCompetitionPrizeData';
import { useCompetitionStartPrize } from 'src/features/competition/utils/useCompetitionStartPrize';
import { PATH_COMPETITION_ABOUT } from 'src/routing/routes';
import FeedbackPrompt from 'src/shared/feedback-card/FeedbackPrompt';
import { useProfile } from 'src/utils/hooks/useProfile';
import { styled } from 'styled-components';
import { CompetitionPageWrapper } from '../components/CompetitionPageWrapper';
import { ReconnectToStravaModal } from '../components/ReconnectToStravaModal';
import { ShareWithFriendsButton } from '../components/ShareWithFriendsButton';
import { StatisticsAccordion } from '../components/StatisticsAccordion';
import { TimeToCompetition } from '../components/TimeToCompetition';
import { UserCard } from '../components/UserCard';
import { CompetitionStageCard } from '../components/competitionStageCard/CompetitionStageCard';
import { useShowCompetitionForBetaUsers } from '../hooks/useShowCompetitionForBetaUsers';
import { AuthContext } from '../providers/AuthProvider';
import { stages } from '../utils/stages';
import { useCompetitionData } from '../utils/useCompetitionData';

const WrapperCol = styled(Column)`
    width: 100%;
`;

const GrayText = styled(TDSTextSmall)`
    color: #c4c6c7;
`;
const TicketHighlightCurrent = styled.span`
    font-size: 1.5rem;
`;

const TicketHighlightTotal = styled.span`
    font-size: 0.75rem;
`;

const StyledParagraph = styled.p`
    margin: 0;
`;

const GreenText = styled(TDSThinHeadline)`
    color: #78faae;
    text-align: center;
    font-size: 1.5rem;
`;

function calcMyStages(totalDistance: number, totalDistanceOfAllStages: number) {
    let remainingDistance = totalDistance;
    let totalTickets = 0;

    // Return all tickets if user has completed all stages
    if (totalDistance >= totalDistanceOfAllStages) {
        totalTickets = 21;
        remainingDistance = stages[stages.length - 1].km;
    } else {
        for (const stage of stages) {
            if (remainingDistance - stage.km < 0) {
                break;
            }
            totalTickets++;
            remainingDistance -= stage.km;
        }
    }

    return { totalTickets, remainingDistance };
}

export const TDTStart = new Date(2024, 5, 10);
const TDTEnd = new Date(2024, 6, 22);
const daysInCompetition = Math.ceil(
    (TDTEnd.getTime() - TDTStart.getTime()) / 1000 / 60 / 60 / 24
);
const daysRemaining = Math.ceil(
    (TDTEnd.getTime() - new Date().getTime()) / 1000 / 60 / 60 / 24
);

function Page() {
    const { data: userProfile } = useProfile();
    const showForUsers = useShowCompetitionForBetaUsers();
    const hasCompetitionStarted =
        TDTStart.getTime() < new Date().getTime() || showForUsers;
    const navigate = useNavigate();
    const {
        hasCompetitionUser,
        isHasCompetitionUserLoading,
        hasStravaAuthenticated,
        isStravaAuthLoading,
    } = useContext(AuthContext);
    const isAuthContextLoading =
        isHasCompetitionUserLoading || isStravaAuthLoading;

    const [isReconnectToStravaModalOpen, setIsReconnectToStravaModalOpen] =
        useState(false);

    useEffect(() => {
        if (
            !isAuthContextLoading &&
            hasCompetitionUser &&
            !hasStravaAuthenticated
        ) {
            setIsReconnectToStravaModalOpen(true);
        }
    }, [isAuthContextLoading, hasCompetitionUser, hasStravaAuthenticated]);

    useEffect(() => {
        if (!isAuthContextLoading && !hasCompetitionUser) {
            navigate(PATH_COMPETITION_ABOUT);
        }
    }, [hasCompetitionUser, isAuthContextLoading, navigate]);

    const { data: stravaCompetitionData, isLoading } = useCompetitionData(
        hasCompetitionStarted,
        !!hasStravaAuthenticated
    );
    const { data: competitionPrizeData } = useCompetitionPrizeData(
        hasCompetitionStarted,
        !!hasStravaAuthenticated
    );

    const { data: competitionStartPrizeData } = useCompetitionStartPrize(
        !!hasStravaAuthenticated,
        hasCompetitionStarted
    );

    const totalDistanceOfAllStages = stages.reduce((acc, stage) => {
        return acc + stage.km;
    }, 0);

    const userTotalDistance = stravaCompetitionData?.distanceInKm || 0;
    const userStreak = stravaCompetitionData?.streakInDays || 0;
    const userTotalElevation = stravaCompetitionData?.elevationInMeters || 0;

    const { totalTickets, remainingDistance } = calcMyStages(
        userTotalDistance,
        totalDistanceOfAllStages
    );
    const currentStage = Math.min(totalTickets, stages.length - 1);

    const hasFinishedTour = userTotalDistance >= totalDistanceOfAllStages;

    return (
        <WrapperCol gap={'s'}>
            <ReconnectToStravaModal
                isOpen={isReconnectToStravaModalOpen}
                handleClose={() => {
                    setIsReconnectToStravaModalOpen(false);
                }}
                returnPath={PATH_COMPETITION_ABOUT}
            />
            <UserCard firstName={userProfile?.firstname} />
            <Row gap={'xs'} verticalAlign={'center'}>
                <div>
                    <GrayText>Etappelodd</GrayText>
                    <StyledParagraph>
                        <TicketHighlightCurrent>
                            {totalTickets} stk
                        </TicketHighlightCurrent>
                        <TicketHighlightTotal> / 21</TicketHighlightTotal>
                    </StyledParagraph>
                </div>
                {hasCompetitionStarted && (
                    <>
                        <hr style={{ height: '3rem', margin: 0 }} />
                        <div>
                            <GrayText>Tid igjen av konkurransen</GrayText>
                            <StyledParagraph>
                                <TicketHighlightCurrent>
                                    {daysRemaining <= 0 ? 0 : daysRemaining}{' '}
                                    dager
                                </TicketHighlightCurrent>
                                <TicketHighlightTotal>
                                    / {daysInCompetition}
                                </TicketHighlightTotal>
                            </StyledParagraph>
                        </div>
                    </>
                )}
                {daysRemaining > 0 && <ShareWithFriendsButton />}
            </Row>
            {!isAuthContextLoading &&
                hasCompetitionUser &&
                !hasStravaAuthenticated && (
                    <AlertBanner
                        type="info"
                        message="Koblingen mellom Strava og Bilhold har blitt brutt. Gå til “Om konkurransen” for å koble til Strava på nytt."
                    />
                )}
            {daysRemaining <= 0 && (
                <div style={{ marginTop: '1rem' }}>
                    <TDFTextLarge style={{ textAlign: 'center' }}>
                        Konkurransen for i år er avsluttet. <br /> Velkommen
                        igjen til neste år!
                    </TDFTextLarge>
                    <TDSTextSmall
                        style={{ textAlign: 'center', paddingTop: '0.5rem' }}
                    >
                        Vinneren av hovedpremien er trukket, og det ble Tore,
                        som valgte taktelt fra Thule som premie. <br />
                        Vi gratulerer! <br /> Alle som har vunnet trøyepremier
                        må huske å hente premiene mellom 26. august og 15.
                        september. <br /> Se hvilke premier du har vunnet og
                        hvilken forhandler du har valgt i premieoversikten
                        under.
                    </TDSTextSmall>
                    <GreenText>Takk for deltakelsen!</GreenText>
                </div>
            )}
            <PrizesAccordion
                competitionPrizeData={competitionPrizeData}
                startPrizeData={competitionStartPrizeData}
            />
            {hasCompetitionStarted ? (
                <>
                    <CompetitionStageCard
                        isDisabled={isLoading}
                        currentKm={remainingDistance}
                        start={
                            hasFinishedTour
                                ? stages[0].start
                                : stages[currentStage].start
                        }
                        end={stages[currentStage].end}
                        stravaAthleteId={stravaCompetitionData?.athleteId}
                        currentStage={currentStage}
                        maxKm={stages[currentStage].km}
                        totalKmOfAllStages={totalDistanceOfAllStages}
                        totalKm={userTotalDistance}
                    />
                    <YellowTotalShirt
                        isDisabled={isLoading}
                        totalKm={userTotalDistance}
                    />
                    <GreenStreakShirt
                        isDisabled={isLoading}
                        streak={userStreak}
                    />
                    <WhiteElevationShirt
                        isDisabled={isLoading}
                        currentElevation={userTotalElevation}
                    />
                    <StatisticsAccordion />
                </>
            ) : (
                <>
                    <TimeToCompetition TDFStart={TDTStart} />
                    <CompetitionStageCard
                        isDisabled={true}
                        currentKm={0}
                        start={stages[0].start}
                        end={stages[0].end}
                        stravaAthleteId={undefined}
                        currentStage={0}
                        maxKm={stages[0].km}
                        totalKmOfAllStages={totalDistanceOfAllStages}
                        totalKm={0}
                    />
                    <YellowTotalShirt isDisabled={true} totalKm={0} />
                    <GreenStreakShirt isDisabled={true} streak={0} />
                    <WhiteElevationShirt
                        isDisabled={true}
                        currentElevation={0}
                    />
                </>
            )}
            <FeedbackPrompt />
        </WrapperCol>
    );
}

export function CompetitionPage() {
    return (
        <CompetitionPageWrapper>
            <Page />
        </CompetitionPageWrapper>
    );
}
