import { Middleware } from 'openapi-typescript-fetch';
import { datadogRum } from '@datadog/browser-rum';

export function sessionAppenderMiddleware(): Middleware {
    return async (url, init, next) => {
        const context = datadogRum.getInternalContext();
        if (context) {
            const sessionId = context.session_id;
            if (sessionId) {
                init.headers.append('X-DataDog-SessionId', sessionId);
            }
        }

        return await next(url, init);
    };
}
