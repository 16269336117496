import { ReasonInfos } from './types';

const reasons = {
    RelationAlreadyExisting: {
        title: 'companyRelationAlreadyExistingTitle',
        description: 'companyRelationAlreadyExistingDescription',
        buttonText: 'iUnderstand',
    },
    ProprietorNotValidOwner: {
        title: 'companyProprietorNotValidTitle',
        description: 'companyProprietorNotValidOwnerDescription',
        buttonText: 'iUnderstand',
    },
    ProprietorNotValidLeaser: {
        title: 'companyProprietorNotValidTitle',
        description: 'companyProprietorNotValidLeaserDescription',
        buttonText: 'iUnderstand',
    },
    ProprietorNotExisting: {
        title: 'companyProprietorNotExistingTitle',
        description: 'companyProprietorNotExistingDescription',
        buttonText: 'iUnderstand',
    },
} as const;

const companyReasons: ReasonInfos<typeof reasons> = reasons;

export default companyReasons;
