import { useEffect, useState } from 'react';
import {
    addTokenUpdatedEventListener,
    getAccessToken,
    parseToken,
    removeTokenUpdatedEventListener,
} from 'src/utils/auth/authenticationUtils';

function getIdsFromToken() {
    const accessToken = getAccessToken();
    const token = parseToken(accessToken ?? '');
    return { partyId: token.partyId, entryUuid: token.entryUUID };
}

export const useUserIds = () => {
    const [userId, setUserId] = useState(getIdsFromToken());
    useEffect(() => {
        const listener = () => {
            const token = getIdsFromToken();

            if (
                token.entryUuid !== userId.entryUuid ||
                token.partyId !== userId.partyId
            ) {
                setUserId(token);
            }
        };
        addTokenUpdatedEventListener(listener);
        return () => {
            removeTokenUpdatedEventListener(listener);
        };
    }, [setUserId, userId.entryUuid, userId.partyId]);

    return userId;
};
