import { createContext, ReactNode, useContext, useState } from 'react';

export const AppBundleUpdateContext = createContext<
    | {
          interruptUpdateTimestamp: number;
          setInterruptUpdateTimestamp: React.Dispatch<
              React.SetStateAction<number>
          >;
      }
    | undefined
>(undefined);

export const AppBundleUpdateContextProvider = ({
    children,
}: {
    children: ReactNode;
}) => {
    const [interruptUpdateTimestamp, setInterruptUpdateTimestamp] = useState(0);

    return (
        <AppBundleUpdateContext.Provider
            value={{
                interruptUpdateTimestamp,
                setInterruptUpdateTimestamp,
            }}
        >
            {children}
        </AppBundleUpdateContext.Provider>
    );
};

export const useAppBundleUpdateContext = () => {
    const context = useContext(AppBundleUpdateContext);
    if (context === undefined) {
        throw new Error(
            'useAppBundleUpdateContext must be used within a AppBundleUpdateContextProvider'
        );
    }
    return context;
};
