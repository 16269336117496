import { Middleware } from 'openapi-typescript-fetch';

import { getAccessToken } from 'src/utils/auth/authenticationUtils';
import { refreshAccessTokenIfNeeded } from 'src/utils/http/utils/headers';

export function authMiddleware(): Middleware {
    return async (url, init, next) => {
        await refreshAccessTokenIfNeeded();
        const token = getAccessToken();
        if (token) {
            init.headers.append('Authorization', `Bearer ${token}`);
        }
        return await next(url, init);
    };
}
