import { ReactNode } from 'react';
import { styled } from 'styled-components';
import BackButton from './BackButton';

export const CenteredPageContent = styled.div.attrs<{
    children: ReactNode;
    showBackButton?: boolean;
}>(({ children, showBackButton }) => ({
    children: (
        <>
            {showBackButton && <BackButton />}
            {children}
        </>
    ),
}))`
    max-width: 800px;
    margin: 0 auto;
    padding: var(--moller-spacing-xs) var(--moller-spacing-s)
        var(--moller-spacing-xxl);
    @media screen and (min-width: 850px) {
        padding: var(--moller-spacing-xs) 0 var(--moller-spacing-xxl);
    }
`;
