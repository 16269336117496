import { InAppReview } from '@capacitor-community/in-app-review';
import {
    Checkbox,
    Headline,
    SecondaryButton,
    Subtitle,
    TextContainer,
} from '@moller/design-system';
import { LogoBrand } from '@moller/design-system/foundation/logos/Logo.js';
import { QueryClient, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { CenteredPageContent } from 'src/shared/CenteredPageContent';
import RateAppOrNpsInput from 'src/shared/RateAppOrNpsInput';
import {
    clearAccessToken,
    clearBrowserSession,
} from 'src/utils/auth/authenticationUtils';
import { EnvironmentName } from 'src/utils/environment/environments';
import { styled } from 'styled-components';
import DevToolsMockData from '../data-mocks/dev-tools/DevToolsMockData';
import { isMobileApp } from '../mobile';
import {
    environmentOverride,
    featureToggleOverrides,
    knownKeys,
    setEnvironmentOverride,
    setThemeOverride,
    showDevTools,
    themeOverride,
    updateFeatureToggleOverride,
} from './devToolsUtils';

const EnviornementContainer = styled.div`
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
`;

function useForceUpdate() {
    const [, setValue] = useState(0);
    return () => setValue((value) => value + 1);
}

const setTheme = (theme: LogoBrand | null) => {
    setThemeOverride(theme);
    window.location.href = '/';
};

const setEnvironment = async (
    environment: EnvironmentName,
    queryClient: QueryClient
) => {
    setEnvironmentOverride(environment);
    await clearBrowserSession();
    await queryClient.invalidateQueries();
    window.location.href = '/';
};

const clearAccessTokenAndReload = async () => {
    await clearAccessToken();
    window.location.href = '/';
};

const Index = () => {
    const queryClient = useQueryClient();
    const forceUpdateComponent = useForceUpdate();
    const [showRatingPromptModal, setShowRatingPromptModal] = useState(false);

    if (!showDevTools) {
        window.location.href = '/';
        return null;
    }

    return (
        <CenteredPageContent showBackButton>
            <TextContainer>
                <Headline>Utviklervalg</Headline>
                <Subtitle>Feature toggles</Subtitle>
                {knownKeys.sort().map((key) => {
                    const isOverwritten = featureToggleOverrides[key] === true;
                    return (
                        <Checkbox
                            key={key}
                            label={key}
                            value={isOverwritten}
                            onChange={() => {
                                updateFeatureToggleOverride(
                                    key,
                                    !isOverwritten
                                );
                                forceUpdateComponent();
                            }}
                        />
                    );
                })}
                <Subtitle>Testdata</Subtitle>
                <DevToolsMockData />
                <Subtitle>Misc</Subtitle>
                <SecondaryButton
                    onClick={() => void clearAccessTokenAndReload()}
                >
                    Token refresh test
                </SecondaryButton>
                <p>
                    Sletter accessToken og laster siden på nytt for å teste at
                    vi får nytt AccessToken fra IDP.
                </p>

                {isMobileApp() && (
                    <>
                        <Subtitle>
                            {environmentOverride
                                ? `Miljø (${environmentOverride})`
                                : 'Miljø'}
                        </Subtitle>
                        <EnviornementContainer>
                            <SecondaryButton
                                onClick={() =>
                                    void setEnvironment('dev', queryClient)
                                }
                            >
                                Dev
                            </SecondaryButton>
                            <SecondaryButton
                                onClick={() =>
                                    void setEnvironment('test', queryClient)
                                }
                            >
                                Test
                            </SecondaryButton>
                            <SecondaryButton
                                onClick={() =>
                                    void setEnvironment('staging', queryClient)
                                }
                            >
                                Staging
                            </SecondaryButton>
                            <SecondaryButton
                                onClick={() =>
                                    void setEnvironment('prod', queryClient)
                                }
                            >
                                Prod
                            </SecondaryButton>
                        </EnviornementContainer>
                        <p>Velg hvilket miljø du ønsker å gå mot.</p>
                    </>
                )}
                <Subtitle>
                    {themeOverride ? `Tema (${themeOverride})` : 'Tema'}
                </Subtitle>
                <EnviornementContainer>
                    <SecondaryButton onClick={() => void setTheme(null)}>
                        Ingen
                    </SecondaryButton>
                    <SecondaryButton onClick={() => void setTheme('bilhold')}>
                        Bilhold
                    </SecondaryButton>
                    <SecondaryButton onClick={() => void setTheme('mollerbil')}>
                        Møller
                    </SecondaryButton>
                    <SecondaryButton onClick={() => void setTheme('skoda')}>
                        Škoda
                    </SecondaryButton>
                    <SecondaryButton onClick={() => void setTheme('gumpen')}>
                        Gumpen
                    </SecondaryButton>
                    <SecondaryButton onClick={() => void setTheme('vw')}>
                        VW
                    </SecondaryButton>
                    <SecondaryButton onClick={() => void setTheme('dahles')}>
                        Dahles
                    </SecondaryButton>
                    <SecondaryButton onClick={() => void setTheme('autoria')}>
                        Autoria
                    </SecondaryButton>
                    <SecondaryButton onClick={() => void setTheme('audi')}>
                        Audi
                    </SecondaryButton>
                    <SecondaryButton onClick={() => void setTheme('cupra')}>
                        Cupra
                    </SecondaryButton>
                </EnviornementContainer>
                <p>Velg hvilket tema du ønsker å bruke globalt.</p>
                <Subtitle>App review og NPS</Subtitle>
                <EnviornementContainer>
                    <SecondaryButton
                        onClick={() => void InAppReview.requestReview()}
                    >
                        Trigg app review
                    </SecondaryButton>
                    <SecondaryButton
                        onClick={() => setShowRatingPromptModal(true)}
                    >
                        Trigg NPS-modal
                    </SecondaryButton>
                </EnviornementContainer>
            </TextContainer>
            {showRatingPromptModal && (
                <RateAppOrNpsInput showUnlessDismissed trigger="Test" />
            )}
        </CenteredPageContent>
    );
};

export default Index;
