import { useContext } from 'react';
import { StyledLink } from 'src/features/competition/textComponents/TDFText';
import {
    PATH_COMPETITION,
    PATH_COMPETITION_ABOUT,
    PATH_COMPETITION_TERMS,
} from 'src/routing/routes';
import useCurrentPage from 'src/utils/useCurrentPage';
import { styled } from 'styled-components';
import { PoweredByStravaIcon } from '../assets/PoweredByStravaIcon';
import { AuthContext } from '../providers/AuthProvider';

const StyledList = styled.ul`
    padding: 0;
    margin-top: 2rem;
    margin-bottom: 2rem;
    font-size: 1rem;
`;

export function Footer() {
    const { hasStravaAuthenticated } = useContext(AuthContext);
    const page = useCurrentPage();
    return (
        <StyledList>
            {hasStravaAuthenticated && !page.url.includes(PATH_COMPETITION) && (
                <StyledLink to={PATH_COMPETITION}>Min progresjon</StyledLink>
            )}
            {!page.url.includes(PATH_COMPETITION_ABOUT) && (
                <StyledLink to={PATH_COMPETITION_ABOUT}>
                    Om konkurransen
                </StyledLink>
            )}
            {!page.url.includes(PATH_COMPETITION_TERMS) && (
                <StyledLink to={PATH_COMPETITION_TERMS}>
                    Se betingelser og konkurranseregler
                </StyledLink>
            )}
            <PoweredByStravaIcon
                style={{
                    height: '1.5rem',
                    width: 'fit-content',
                    marginTop: '0.5rem',
                }}
            />
        </StyledList>
    );
}
