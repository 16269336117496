import ETron50 from './e-tron-50-55.webp';
import ETronSportback60S from './e-tron-60s-sportback.webp';
import ETron60S from './e-tron-60s.webp';
import ETronGT from './e-tron-gt.webp';
import ETronSportback50 from './e-tron-sportback-50-55.webp';
import ETronSportback from './e-tron-sportback.webp';
import Q4ETron from './q4-e-tron.webp';
import Q4SportbackETron from './q4-sportback-e-tron.webp';
import Q8ETron from './q8-e-tron.webp';
import Q8SportbackETron from './q8-sportback-e-tron.webp';
import RSETronGT from './rs-e-tron-gt.webp';
import SQ8ETron from './sq8-e-tron.webp';
import SQ8SportbackETron from './sq8-sportback-e-tron.webp';

// import statements for all images in this current folder
import A3Sportback from './a3-sportback-tfsi-e.webp';
import A6AllRoadQuattro from './a6-allroad-quattro.webp';
import A6AvantTfsiE from './a6-avant-tfsi-e.webp';
import A8LTfsiE from './a8-l-tfsi-e.webp';
import A8L from './a8-l.webp';
import A8TfsiE from './a8-tfsi-e.webp';
import A8 from './a8.webp';
import Rs3Sportback from './rs-3-sportback.webp';
import Rs4Avant from './rs-4-avant.webp';
import Rs6Avant from './rs-6-avant.webp';
import RsQ3Sportback from './rs-q3-sportback.webp';
import RsQ3 from './rs-q3.webp';
import RsQ8 from './rs-q8.webp';
import S8Tfsi from './s8-tfsi.webp';

const eTronSportbackImage = {
    src: ETronSportback,
    name: ['e-tron', 'sportback'],
};

const eTronGTImage = {
    src: ETronGT,
    name: ['e-tron', 'gt'],
};

const q4ETronImage = {
    src: Q4ETron,
    name: ['q4', 'e-tron'],
};

const q4SportbackETronImage = {
    src: Q4SportbackETron,
    name: ['q4', 'sportback', 'e-tron'],
};

const q8ETronImage = {
    src: Q8ETron,
    name: ['q8', 'e-tron'],
};

const q8SportbackETronImage = {
    src: Q8SportbackETron,
    name: ['q8', 'sportback', 'e-tron'],
};

const rsETronGTImage = {
    src: RSETronGT,
    name: ['rs', 'e-tron', 'gt'],
};

const sq8ETronImage = {
    src: SQ8ETron,
    name: ['sq8', 'e-tron'],
};

const sq8SportbackETronImage = {
    src: SQ8SportbackETron,
    name: ['sq8', 'sportback', 'e-tron'],
};

const eTron50Image = {
    src: ETron50,
    name: ['e-tron', '50'],
};

const eTronSportback50Image = {
    src: ETronSportback50,
    name: ['e-tron', 'sportback', '50'],
};

const eTron55Image = {
    src: ETron50,
    name: ['e-tron', '55'],
};

const eTronSportback55Image = {
    src: ETronSportback50,
    name: ['e-tron', 'sportback', '55'],
};

const eTron60SImage = {
    src: ETron60S,
    name: ['e-tron', '60s'],
};

const eTronSportback60SImage = {
    src: ETronSportback60S,
    name: ['e-tron', 'sportback', '60s'],
};

const a3SportbackImage = {
    src: A3Sportback,
    name: ['a3', 'sportback', 'tfsi', 'e'],
};

const a6AllRoadQuattroImage = {
    src: A6AllRoadQuattro,
    name: ['a6', 'allroad', 'quattro'],
};

const a6AvantTfsiEImage = {
    src: A6AvantTfsiE,
    name: ['a6', 'avant', 'tfsi', 'e'],
};

const a8LTfsiEImage = {
    src: A8LTfsiE,
    name: ['a8', 'l', 'tfsi', 'e'],
};

const a8LImage = {
    src: A8L,
    name: ['a8', 'l'],
};

const a8TfsiEImage = {
    src: A8TfsiE,
    name: ['a8', 'tfsi', 'e'],
};

const a8Image = {
    src: A8,
    name: ['a8'],
};

const rs3SportbackImage = {
    src: Rs3Sportback,
    name: ['rs', '3', 'sportback'],
};

const rs4AvantImage = {
    src: Rs4Avant,
    name: ['rs', '4', 'avant'],
};

const a6AllroadQuattroImage = {
    src: A6AllRoadQuattro,
    name: ['a6', 'allroad', 'quattro'],
};

const rs6AvantImage = {
    src: Rs6Avant,
    name: ['rs', '6', 'avant'],
};

const rsQ3SportbackImage = {
    src: RsQ3Sportback,
    name: ['rs', 'q3', 'sportback'],
};

const rsQ3Image = {
    src: RsQ3,
    name: ['rs', 'q3'],
};

const rsQ8Image = {
    src: RsQ8,
    name: ['rs', 'q8'],
};

const s8TfsiImage = {
    src: S8Tfsi,
    name: ['s8', 'tfsi'],
};

// Must be in correct order to render correct car images. This is sketchy and should be fixed.
export const audiImages = [
    q4SportbackETronImage,
    q8SportbackETronImage,
    rsETronGTImage,
    eTronGTImage,
    sq8ETronImage,
    sq8SportbackETronImage,
    q8ETronImage,
    q4ETronImage,
    eTron50Image,
    eTronSportback50Image,
    eTron55Image,
    eTronSportback55Image,
    eTron60SImage,
    eTronSportback60SImage,
    eTronSportbackImage,

    a3SportbackImage,
    a6AllRoadQuattroImage,
    a6AvantTfsiEImage,
    a8LTfsiEImage,
    a8LImage,
    a8TfsiEImage,
    a8Image,
    rs3SportbackImage,
    rs4AvantImage,
    a6AllroadQuattroImage,
    rs6AvantImage,
    rsQ3SportbackImage,
    rsQ3Image,
    rsQ8Image,
    s8TfsiImage,
];
