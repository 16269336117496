export const stages = [
    {
        stage: 1,
        typ: 'HILLY',
        date: 'SAT 06/29/2024',
        start: 'Florence',
        end: 'Rimini',
        km: 20.6,
    },
    {
        stage: 2,
        typ: 'HILLY',
        date: 'SUN 06/30/2024',
        start: 'Cesenatico',
        end: 'Bologne',
        km: 20,
    },
    {
        stage: 3,
        typ: 'FLAT',
        date: 'MON 07/01/2024',
        start: 'Plaisance',
        end: 'Turin',
        km: 22.9,
    },
    {
        stage: 4,
        typ: 'MOUNTAIN',
        date: 'TUE 07/02/2024',
        start: 'Pinerolo',
        end: 'Valloire',
        km: 13.8,
    },
    {
        stage: 5,
        typ: 'FLAT',
        date: 'WED 07/03/2024',
        start: 'Saint-Jean-de-Maurienne',
        end: 'Saint-Vulbas',
        km: 17.7,
    },
    {
        stage: 6,
        typ: 'FLAT',
        date: 'THU 07/04/2024',
        start: 'Mâcon',
        end: 'Dijon',
        km: 16.3,
    },
    {
        stage: 7,
        typ: 'INDIVIDUAL TIME-TRIAL',
        date: 'FRI 07/05/2024',
        start: 'Nuits-Saint-Georges',
        end: 'Gevrey-Chambertin',
        km: 2.5,
    },
    {
        stage: 8,
        typ: 'FLAT',
        date: 'SAT 07/06/2024',
        start: 'Semur-en-Auxois',
        end: 'Colombey-les-Deux-Églises',
        km: 17.6,
    },
    {
        stage: 9,
        typ: 'HILLY',
        date: 'SUN 07/07/2024',
        start: 'Troyes',
        end: 'Troyes',
        km: 19.9,
    },
    {
        stage: 10,
        typ: 'FLAT',
        date: 'TUE 07/09/2024',
        start: 'Orléans',
        end: 'Saint-Amand-Montrond',
        km: 18.7,
    },
    {
        stage: 11,
        typ: 'MOUNTAIN',
        date: 'WED 07/10/2024',
        start: 'Évaux-les-Bains',
        end: 'Le Lioran',
        km: 21.1,
    },
    {
        stage: 12,
        typ: 'FLAT',
        date: 'THU 07/11/2024',
        start: 'Aurillac',
        end: 'Villeneuve-sur-Lot',
        km: 20.4,
    },
    {
        stage: 13,
        typ: 'FLAT',
        date: 'FRI 07/12/2024',
        start: 'Agen',
        end: 'Pau',
        km: 17.1,
    },
    {
        stage: 14,
        typ: 'MOUNTAIN',
        date: 'SAT 07/13/2024',
        start: 'Pau',
        end: "Saint-Lary-Soulan Pla d'Adet",
        km: 15.2,
    },
    {
        stage: 15,
        typ: 'MOUNTAIN',
        date: 'SUN 07/14/2024',
        start: 'Loudenvielle',
        end: 'Plateau de Beille',
        km: 19.8,
    },
    {
        stage: 16,
        typ: 'FLAT',
        date: 'TUE 07/16/2024',
        start: 'Gruissan',
        end: 'Nimes',
        km: 18.7,
    },
    {
        stage: 17,
        typ: 'MOUNTAIN',
        date: 'WED 07/17/2024',
        start: 'Saint-Paul-Trois-Châteaux',
        end: 'Superdévoluy',
        km: 17.8,
    },
    {
        stage: 18,
        typ: 'HILLY',
        date: 'THU 07/18/2024',
        start: 'Gap',
        end: 'Barcelonnette',
        km: 17.9,
    },
    {
        stage: 19,
        typ: 'MOUNTAIN',
        date: 'FRI 07/19/2024',
        start: 'Embrun',
        end: 'Isola 2000',
        km: 14.5,
    },
    {
        stage: 20,
        typ: 'MOUNTAIN',
        date: 'SAT 07/20/2024',
        start: 'Nice',
        end: 'Col de la Couillole',
        km: 13.3,
    },
    {
        stage: 21,
        typ: 'INDIVIDUAL TIME-TRIAL',
        date: 'SUN 07/21/2024',
        start: 'Monaco',
        end: 'Nice',
        km: 3.4,
    },
] as const;
