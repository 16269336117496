export const API_PREFIX = 'api';
export const PUBLIC_API_PREFIX = 'public/api';

export const OTHER_SERVICES = 'GS-3';

export const DealerType = {
    WORKSHOP: 'WORKSHOP',
    SALES: 'SALES',
    DAMAGE_CENTER: 'DAMAGE_CENTER',
    SERVICE_CENTER: 'SERVICE_CENTER',
};

// Date and time
export const DATE_FORMAT_LONG = 'DD. MMM YYYY';
export const DATE_FORMAT_SHORT_LONG = 'D. MMM YYYY';
export const TIME_FORMAT = 'HH:mm';
export const HUMAN_DATE_FORMAT = 'D. MMMM';

export const QUERY_PARAM_STORAGE = 'QUERY_PARAM_STORAGE';
export const DELETED_BOOKING = 'DELETED_BOOKING_STORAGE';
