import imageUrlBuilder from '@sanity/image-url';
import { SanityImageSource } from '@sanity/image-url/lib/types/types';
import { sanityClient } from '../useSanityData';

const builder = imageUrlBuilder(sanityClient);

export type SanityImageSourceWithAlt = SanityImageSource & { alt?: string };

interface SanityImageProps {
    sanityImageData?: SanityImageSourceWithAlt;
}

export default function SanityImage({ sanityImageData }: SanityImageProps) {
    if (!sanityImageData) {
        console.warn('Image not found');
        return <></>;
    }

    return (
        <img src={urlFor(sanityImageData).url()} alt={sanityImageData.alt} />
    );
}

function urlFor(source: SanityImageSource) {
    return builder.image(source);
}
