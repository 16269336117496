import { datadogRum } from '@datadog/browser-rum';
import {
    Headline,
    Icon,
    InfoCard,
    SecondaryButton,
} from '@moller/design-system';
import { Environment } from 'external-apis';
import { useFlag } from 'feature-toggle';
import { createContext, useContext, useEffect, useRef } from 'react';
import { styled } from 'styled-components';
import { useGetDealers } from '../_api/http/dealers';
import { useGetVehicle } from '../_api/http/vehicle';
import { useLanguageContext } from '../lib/languages/languageContext';
import { useFastTrack } from '../lib/useFastTrack';
import { useScrollToBottom } from '../lib/util/scroll';
import { vehicleOlderThanXMonths } from '../lib/util/vehicleOlderThanXMonths';

// verdivurdering does not have a stage environment.
const urls: Record<Environment, string> = {
    local: 'https://verdivurdering.dev.mollercloud.no/?registrationNumber={regNo}&phoneNumber={phoneNumber}&src=booking',
    dev: 'https://verdivurdering.dev.mollercloud.no/?registrationNumber={regNo}&phoneNumber={phoneNumber}&src=booking',
    test: 'https://verdivurdering.test.mollercloud.no/?registrationNumber={regNo}&phoneNumber={phoneNumber}&src=booking',
    stage: 'https://verdivurdering.test.mollercloud.no/?registrationNumber={regNo}&phoneNumber={phoneNumber}&src=booking',
    prod: 'https://verdivurdering.moller.no/?registrationNumber={regNo}&phoneNumber={phoneNumber}&src=booking',
};

const getUrl = (env: Environment, regNo: string, phone: string) =>
    urls[env].replace('{regNo}', regNo).replace('{phoneNumber}', phone);

const sellVehicleContext = createContext<Environment | null>(null);

function useSellVehicleContext() {
    const context = useContext(sellVehicleContext);
    if (!context) {
        throw new Error(
            'sell vehicle context was not provided with default config'
        );
    }
    return context;
}

export const SellVehicleProvider = ({
    env,
    children,
}: React.PropsWithChildren<{
    env: Environment;
}>) => (
    <sellVehicleContext.Provider value={env}>
        {children}
    </sellVehicleContext.Provider>
);

interface SellVehicleCardProps {
    id: keyof typeof rumActions;
    regNumber: string;
    dealerNumber: string;
    phone: string;
    scroll?: boolean;
}

const StyledSellVehicleCard = styled(InfoCard)`
    display: flex;
    flex-direction: column;
    padding: var(--moller-spacing-xs);
    gap: var(--moller-spacing-l);
`;

interface UseShowSellVehicleCard {
    dealerResponse: ReturnType<typeof useGetDealers>;
    vehicleResponse: ReturnType<typeof useGetVehicle>;
    dealerNumber: string;
}

function useShowSellVehicleCard({
    vehicleResponse,
    dealerResponse,
    dealerNumber,
}: UseShowSellVehicleCard) {
    const showSellVehicle = useFlag('show-sell-vehicle');
    const regStr = vehicleResponse.data?.date.registration.first;
    const fastTrackType = useFastTrack();

    if (!(regStr && dealerResponse.isSuccess) || fastTrackType) {
        return {
            showSellVehicleCard: false,
        };
    }

    const isMollerDealer = dealerResponse.data.some(
        (x) => x.id === dealerNumber && x.name.toLowerCase().includes('møller')
    );

    const newVehicle = !vehicleOlderThanXMonths(regStr, 120);
    const regYear = new Date(regStr).getFullYear();

    return {
        showSellVehicle: isMollerDealer && showSellVehicle && newVehicle,
        regYear,
    };
}

const rumActions = {
    card_in_booking: {
        seen: 'User has seen sell your car card in booking',
        click: 'User has clicked on sell your car card in booking',
    },
    card_after_booking: {
        seen: 'User has seen sell your car card after booking',
        click: 'User has clicked on sell your car card after booking',
    },
} as const;

export function SellVehicleCard({
    id,
    regNumber,
    dealerNumber,
    phone,
    scroll = false,
}: SellVehicleCardProps) {
    const [lc] = useLanguageContext();

    const env = useSellVehicleContext();
    const url = getUrl(env, regNumber, phone);

    const rendered = useRef(null);

    const dealerResponse = useGetDealers();
    const vehicleResponse = useGetVehicle(regNumber);
    const { showSellVehicle, showSellVehicleCard, regYear } =
        useShowSellVehicleCard({
            dealerResponse,
            vehicleResponse,
            dealerNumber,
        });

    useScrollToBottom(rendered, scroll);

    useEffect(() => {
        if (
            !!rendered.current &&
            (showSellVehicleCard || showSellVehicle) &&
            regYear
        ) {
            datadogRum.addAction(rumActions[id].seen, {
                data: regYear.toString(),
            });
        }
    }, [id, regYear, showSellVehicleCard, showSellVehicle]);

    if (!(showSellVehicleCard || showSellVehicle) || !regYear) {
        return null;
    }

    return (
        <StyledSellVehicleCard ref={rendered}>
            <Headline>{lc.components.sell_your_vehicle_title}</Headline>
            <p>{lc.components.sell_your_vehicle_description}</p>
            <SecondaryButton
                onClick={() => {
                    datadogRum.addAction(rumActions[id].click, {
                        data: regYear.toString(),
                    });
                    window.open(url, '_blank', 'noreferrer,noopener');
                }}
            >
                {lc.components.sell_your_vehicle_btn}
                <Icon icon="external" />
            </SecondaryButton>
        </StyledSellVehicleCard>
    );
}
