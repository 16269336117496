import { AlertBanner } from '@moller/design-system';
import { OrderedCar } from 'src/types/OrderedCar';
import { withErrorBoundary } from 'src/utils/ErrorBoundary';
import { styled } from 'styled-components';
import { ProductSlider } from '../../components/products/ProductsSlider';
import { AboutService } from './ServiceProductRequest/AboutService';
import WaitingProgramTasks from './WaitingProgramTasks';

const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
`;

interface WaitingPeriodContentProps {
    car: OrderedCar;
}

const StyledAlertBanner = styled(AlertBanner)`
    margin: 0 var(--moller-spacing-s);
`;
const fallback = (retry: () => void) => (
    <StyledAlertBanner
        type="warning"
        message="Vi greide dessverre ikke å vise innhold for din bestilte bil. Prøv igjen senere!"
        action={{ label: 'Prøv igjen', onClick: retry }}
    />
);

export const WaitingPeriodContent = withErrorBoundary(
    'WaitingPeriodContent',
    ({ car }: WaitingPeriodContentProps) => {
        return (
            <StyledContainer>
                <ProductSlider car={car} />
                <AboutService car={car} />
                <WaitingProgramTasks car={car} />
            </StyledContainer>
        );
    },
    { fallback }
);
