import { CardContent } from '@bilhold/sanity';
import { datadogRum } from '@datadog/browser-rum';
import { InfoCard } from '@moller/design-system';
import { useEffect } from 'react';
import { Variables } from 'src/sanity/components/RichText';
import { SanityImageSourceWithAlt } from 'src/sanity/components/SanityImage';
import { styled } from 'styled-components';
import { SanityCardLayout } from '../components/SanityCardLayout';

interface SanityCard {
    openDrawer?: () => void;
    isExternalArticle?: boolean;
    cardTitle: string;
    cardDescription?: CardContent;
    image?: SanityImageSourceWithAlt;
    variables?: Variables;
    buttonText?: string;
    buttonLink?: string;
    overlineText?: string;
    useReadMore?: boolean;
}

const StyledInfoCard = styled(InfoCard)`
    border: 0;
`;

const SanityCard = ({
    cardTitle,
    cardDescription,
    image,
    variables,
    buttonLink,
    buttonText,
    overlineText,
    openDrawer = () => {},
    isExternalArticle = false,
    useReadMore,
}: SanityCard) => {
    useEffect(() => {
        datadogRum.addAction(`User sees ${cardTitle} card with Sanity data`);
    }, [cardTitle]);

    return (
        <StyledInfoCard>
            <SanityCardLayout
                title={cardTitle}
                body={cardDescription}
                image={image}
                openDrawer={openDrawer}
                isExternalArticle={isExternalArticle}
                variables={variables}
                buttonText={buttonText}
                buttonLink={buttonLink}
                useReadMore={useReadMore}
                overlineText={overlineText}
            />
        </StyledInfoCard>
    );
};

export default SanityCard;
