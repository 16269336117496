import { VehicleData } from 'src/types/generated/CarInformation/CarInformationTypes';
import { MockData } from '../data';

const getCarInformation = (
    vin: string,
    mockData: MockData
): VehicleData | null => {
    const car = mockData.cars.find((car) => car.vin === vin);

    if (!car) {
        return null;
    }

    return {
        registrationNumber: car.licensePlate,
        vin: car.vin,
        brand: car.brand,
        model: car.model,
        color: 'Grå',
        minimumWeight: 1217,
        maxWeight: 1862,
        maxWeightTrailerNoBrakes: 640,
        maxWeightTrailerWithBrakes: 1500,
        engineVolume: 1395,
        engineEffect: 103,
        tireDimFront: '205/50R17 M+S',
        tireDimRear: '205/50R17 M+S, 205/50R18',
        rimDimFront: '6JX17',
        rimDimRear: '6JX17, 6JX18',
        fuelType: car.fuelType,
        energyConsumption: 1337,
        registrationDate: `${car.registrationYear}-01-01`,
        registrationDateCurrentOwner: '2021-01-29T10:45:39.096+00:00',
        periodicalVehicleControlData: {
            nextControlDate: car.nextControlDate,
            lastApprovedDate: '2021-09-01T00:00:00',
        },
        modelYear: 2015,
    };
};

export default getCarInformation;
