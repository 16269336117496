import { Headline, Subtitle, TextContainer } from '@moller/design-system';
import { CenteredPageContent } from 'src/shared/CenteredPageContent';

const WarrantyCupra = () => {
    return (
        <CenteredPageContent showBackButton>
            <TextContainer>
                <Headline>VÅRE GARANTIER</Headline>
                <p>
                    <b>5 års nybilgaranti </b> Ved kjøp av en helt ny CUPRA
                    gjennom oss, får du glede av flere garantier. Nybilgarantien
                    strekker seg over hele
                    <b> 5 år eller inntil bilen har kjørt 100 000 km</b>. For at
                    garantien skal være gyldig, må du sørge for at alle påkrevde
                    service-, vedlikeholds- og reparasjonsarbeider blir utført i
                    henhold til kjøretøyets service- og vedlikeholdsprogram i
                    løpet av garantiperioden. Du kan få utført garantiarbeider
                    enten hos et autorisert verksted eller hos et uavhengig
                    verksted, så lenge arbeidet utføres på et tilfredsstillende
                    faglig nivå. Justeringsarbeider og direkte slitasjedeler er
                    de eneste unntakene fra denne garantien.
                </p>
                <Subtitle>Batterigaranti</Subtitle>
                <p>
                    Samtlige nye elbiler fra CUPRA leveres med en imponerende
                    <b>
                        garanti på batteripakken i 8 år eller inntil bilen har
                        kjørt 160 000 km
                    </b>
                    . Batteriet er forventet å beholde minst 70% kapasitet i
                    hele 8 år eller inntil 160 000 km. For at garantien skal
                    gjelde, må du lade batteriet i tråd med bilens
                    instruksjonsbok og med originalt ladeutstyr.
                </p>

                <Subtitle>Mobilitetsgaranti – 24 timer i døgnet</Subtitle>
                <p>
                    Hvis du følger CUPRAs anbefalte service- og
                    vedlikeholdsprogram, kan du nyte godt av vår 24-timers
                    mobilitetsgaranti. Mobilitetsgarantien fornyes hver gang du
                    utfører service på bilen hos et CUPRA-sertifisert verksted.
                    Hvis merkeverkstedet ikke kan reparere bilen din
                    umiddelbart, vil garantien dekke kostnadsfri leiebil i
                    opptil 3 dager (bompenger og bensin er ikke inkludert). Vi
                    samarbeider med Viking redningstjeneste for å tilby denne
                    mobilitetsgarantien, og du kan nå dem på følgende måter:
                </p>

                <p>I Norge: 915 04 444</p>
                <p>Fra utlandet: +47 915 04 444 </p>

                <p>
                    <i>
                        Startavgiften er kun kr 0,45, og minuttprisen er kr 0,32
                        for samtaler fra fasttelefon i Norge. Du kan også bruke
                        denne kontaktinformasjonen selv om du ikke har
                        mobilitetsgaranti, men i så fall vil du bli belastet for
                        assistanseutgiftene. Mobilitetsgarantien gir deg
                        praktisk hjelp, solid beskyttelse og er kostnadsfri så
                        lenge du følger bilens planlagte serviceprogram. Den
                        dekker en rekke tjenester, inkludert hjemtransport og
                        lån av bil i utlandet (Vest-Europa og mange
                        østeuropeiske land).
                    </i>
                </p>

                <Subtitle>Hva dekker mobilitetsgarantien?</Subtitle>
                <ul>
                    <li>
                        <p>
                            <b>Starthjelp:</b> Garantien inkluderer starthjelp,
                            hjelp med dekkbytte og eventuell bilberging ved
                            punkterte dekk.
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>Hotellovernatting:</b> Vi dekker
                            hotellovernatting for både fører og passasjerer i
                            ett døgn dersom det er hensiktsmessig å vente på
                            bilen (kan ikke kombineres med erstatningsbil).
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>Kostnadsfri erstatningsbil:</b> Hvis et
                            CUPRA-sertifisert verksted ikke kan reparere bilen
                            umiddelbart, dekker garantien en erstatningsbil i
                            opptil 3 dager med ubegrenset kjørelengde (bompenger
                            og drivstoff er ikke inkludert).
                        </p>
                    </li>
                </ul>

                <Subtitle>Hva dekkes ikke av mobilitetsgaranti?</Subtitle>
                <ul>
                    <li>
                        <p>
                            <b>Leiebil ved punkterin:</b> Leie av bil dekkes
                            ikke ved punktering.
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>Selvforskyldte uhell:</b> Garantien dekker ikke
                            feilfylling, drivstoffmangel eller utilsiktet låsing
                            av bilen. Den dekker heller ikke utforkjøring,
                            kollisjon, drivstoffmangel på grunn av is eller voks
                            i drivstoffsystemet. Garantien dekker heller ikke
                            driftsstans forårsaket av reparasjoner utført av et
                            ikke-merkeverksted. Vurderinger vil bli gjort på
                            samme måte som ved vanlige verkstedsbesøk.
                        </p>
                    </li>
                </ul>
                <Subtitle>Lånebil når du trenger det</Subtitle>
                <p>
                    Vi i CUPRA er her for deg når du trenger det mest. Hvis det
                    uforutsette skulle skje med din CUPRA, tilbyr vi deg en
                    lånebil gjennom vår Mobilitetsgaranti. Samtidig vil våre
                    CUPRA-eksperter jobbe på spreng for å få bilen din tilbake
                    på veien så raskt som mulig.
                </p>
            </TextContainer>
        </CenteredPageContent>
    );
};

export default WarrantyCupra;
