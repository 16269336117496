import { Environment } from '..';

const cloudEnvironments = ['prod', 'stage', 'test', 'dev'] as const;
export const ENVIRONMENTS = [...cloudEnvironments, 'local'] as const;

interface BackendUriConfig {
    environment: Environment;
    urls: { [x in (typeof ENVIRONMENTS)[number]]: string };
    localEnvironmentOverride?: string;
}

export function backendUrl({
    environment,
    urls,
    localEnvironmentOverride,
}: BackendUriConfig) {
    if (!localEnvironmentOverride || environment === 'prod') {
        return urls[environment];
    }

    if (
        Object.entries(urls).find(
            ([_, variable]) => localEnvironmentOverride === variable
        )
    ) {
        console.log(
            `Overriding backend environment: ${localEnvironmentOverride}`
        );
        return localEnvironmentOverride;
    }

    throw new Error(
        `Attempting to ovverride backend environment with invalid variable: ${localEnvironmentOverride}.`
    );
}
