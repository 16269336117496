import { RefObject, useEffect } from 'react';

export function useScrollToBottom(
    ref: RefObject<HTMLButtonElement | null>,
    enabled = true,
    offset = 50
) {
    useEffect(() => {
        if (ref.current && enabled) {
            const offsetFromEnd = -offset; // Adjust this value to set the desired offset
            const elementRect = ref.current.getBoundingClientRect();
            const scrollYOffset = document.documentElement.scrollTop;
            const viewportHeight = document.documentElement.clientHeight;
            const scrollToPosition =
                elementRect.bottom -
                viewportHeight +
                scrollYOffset -
                offsetFromEnd;
            window.scrollTo({ top: scrollToPosition, behavior: 'auto' });
        }
    }, [enabled, offset, ref]);
}

export function useScrollRefIntoView(
    scroll: boolean,
    nextStepRef: RefObject<HTMLElement>
) {
    useEffect(() => {
        if (scroll) {
            nextStepRef.current?.scrollIntoView({
                behavior: 'auto',
                block: 'nearest',
            });
        }
    }, [nextStepRef, scroll]);
}
