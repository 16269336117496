import { useMutation, useQueryClient } from '@tanstack/react-query';
import getCurrentEnvironment from 'src/utils/environment/getCurrentEnvironment';
import patch from 'src/utils/http/patch';

export type UpdateUserDataPayload = Partial<{
    firstname: string;
    surname: string;
    addressLine: string;
    postcode?: string;
    city: string;
    countryCode?: string;
    email: string;
}>;

export const useProfileUpdate = (
    onSuccess: () => void,
    onError?: () => void
) => {
    const { bilholdApiUrl } = getCurrentEnvironment();
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (payload: UpdateUserDataPayload) =>
            patch(`${bilholdApiUrl}/v1/user`, payload),
        onSuccess: () => {
            void queryClient.invalidateQueries({ queryKey: ['userProfile'] });
            onSuccess();
        },
        onError: () => (onError ? onError() : undefined),
    });
};
