import { TextContainer } from '@moller/design-system';
import { TDFSwiperView } from 'src/features/competition/components/prizeSwiper/TDFSwiper';
import {
    TDFTextMedium,
    TDSHeadline,
    TDSSubtitle,
} from 'src/features/competition/textComponents/TDFText';
import BackButton from 'src/shared/BackButton';
import FeedbackPrompt from 'src/shared/feedback-card/FeedbackPrompt';
import { styled } from 'styled-components';
import { CompetitionPageWrapper } from '../components/CompetitionPageWrapper';
import { StravaLoginStatus } from '../components/StravaLoginStatus';

const StyledTextContainer = styled(TextContainer)`
    margin: var(--moller-spacing-xl) 0;
`;

const StyledDiv = styled.div`
    padding-bottom: var(--moller-spacing-l);
    max-width: 800px;
`;
const Page = () => {
    return (
        <StyledDiv>
            <BackButton />
            <StyledTextContainer>
                <TDSHeadline>Bli med å sykle Tour de Škoda!</TDSHeadline>
                <TDFTextMedium>
                    Tour de Škoda er sommerens sykkelkonkurranse i regi av
                    Škoda. Du registrerer syklingen din i Strava og sykler deg
                    gjennom så mange etapper i touren du klarer ila
                    konkurranseperioden. Én etappe = ett lodd til å vinne
                    hovedpremien i konkurransen! Touren starter 10. juni og
                    holder på i 6 uker. Bli med!
                </TDFTextMedium>
            </StyledTextContainer>
            <StravaLoginStatus />
            <StyledTextContainer>
                <TDSSubtitle>Konkurransesiden i Bilhold</TDSSubtitle>
                <TDFTextMedium>
                    Etter innlogging og tilkobling til Strava kommer du til
                    konkurransesiden i Bilhold. Du bør følge godt med på denne
                    siden gjennom hele konkurranseperioden, for her ser du hva
                    du har oppnådd, om du har fått noen premier, og hva som kan
                    være ditt neste mål i konkurransen.
                </TDFTextMedium>
            </StyledTextContainer>
            <TDFSwiperView />
            <StyledTextContainer>
                <TDSSubtitle>Lodd, trøyer og premier</TDSSubtitle>
                <TDFTextMedium>
                    I Tour de Škoda har du mer enn én sjanse til å vinne premie!
                    Loddene du får per gjennomført etappe gir deg mulighet til å
                    vinne selve hovedpremien, men du kan også vinne
                    oppstartspremie og trøyepremier ved å oppfylle
                    konkurransekriteriene her. Den prikkete klatretrøya får du
                    ved å sykle tilsvarende opp Galdhøpiggen i antall
                    høydemetere. Den grønne trøya får du hvis du sykler 7 dager
                    på rad. Og den den gule trøya får du hvis du fullfører alle
                    etappene i touren! Blir du blant de 250 første som oppnår en
                    trøye vinner du trøyepremie!
                </TDFTextMedium>
            </StyledTextContainer>
            <StyledTextContainer>
                <TDSSubtitle> Premie for å komme i gang!</TDSSubtitle>
                <TDFTextMedium>
                    Blir du blant de første 1000 som fullfører etappe 1 innen
                    23. juni vinner du oppstartspremien! Du kan da velge mellom
                    sykkelcaps eller sykkelflaske, og premien må hentes hos din
                    foretrukne Škodaforhandler.
                </TDFTextMedium>
            </StyledTextContainer>
            <StyledTextContainer>
                <TDSSubtitle>
                    Du trenger ikke å være idrettsutøver for å delta!
                </TDSSubtitle>
                <TDFTextMedium>
                    Du velger selv hvor ofte og hvor langt du sykler, og hvor
                    sykkelturene dine skal gå. Sykler du mye vil du ha større
                    sjanse til å vinne premier, men så lenge du fullfører én
                    etappe kan du vinne hovedpremien i konkurransen.
                </TDFTextMedium>
                <TDFTextMedium>
                    Sett dine egne mål! Om det er å fullføre én etappe, klare en
                    av trøyene, vinne en premie eller å bare nyte en deilig tur
                    på sykkelen, det er opp til deg!
                </TDFTextMedium>
            </StyledTextContainer>
            <FeedbackPrompt />
        </StyledDiv>
    );
};

export const AboutPage = () => {
    return (
        <CompetitionPageWrapper>
            <Page />
        </CompetitionPageWrapper>
    );
};
