import { Column, NoticeText } from '@moller/design-system';
import { formatDate } from 'src/utils/time/timeUtils';
import { css, styled } from 'styled-components';

function formatPrettyDate(date: string) {
    const formattedDateSplit = formatDate(date).split(' ');
    const month = formattedDateSplit[1].slice(0, 3);
    const day = formattedDateSplit[0].slice(0, -1);
    return { day: day, month: month };
}

const DateColumn = styled(Column)`
    align-items: center;
`;

const Month = styled(NoticeText)`
    color: var(--moller-color-on-surface-variant);
    text-transform: uppercase;
    flex-grow: 1;
`;

const Date = styled.div`
    ${() => {
        const type = '--moller-type-small-display';

        return css`
            font-family: var(${type}-font-family);
            font-size: var(${type}-font-size);
            font-weight: var(${type}-font-weight);
            line-height: var(${type}-line-height);
            letter-spacing: var(${type}-letter-spacing);
            text-decoration: var(${type}-text-decoration);
            margin: calc(0px - var(--moller-spacing-xxs)) 0;
            flex-grow: 1;
        `;
    }}
`;

export const DateLayout = ({ date }: { date: string }) => {
    const { day, month } = formatPrettyDate(date);
    return (
        <DateColumn>
            <Month>{month}</Month>
            <Date>{day}</Date>
        </DateColumn>
    );
};
