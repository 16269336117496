import { Column, Subtitle } from '@moller/design-system';
import { OrderedCar } from 'src/types/OrderedCar';
import { styled } from 'styled-components';
import { ProductSliderCard } from '../../../components/products/ProductsSlider';
import { svaProduct } from '../product-data/svaProduct';

const ProductContainer = styled.div`
    max-width: 160px;
`;

const StyledColumn = styled(Column)`
    margin-top: var(--moller-spacing-s);
    margin-bottom: var(--moller-spacing-s);
`;

interface Props {
    car: OrderedCar;
}

export function AboutService({ car }: Props) {
    return (
        <StyledColumn gap="s">
            <Subtitle>Et forutsigbart bilhold</Subtitle>
            <p>
                Serviceavtale eller Service- og vedlikeholdsavtale kan tegnes på
                flere kombinasjoner av tid/km. Du får tilbud om en avtale
                tilpasset dine behov.
            </p>
            <ProductContainer>
                <ProductSliderCard car={car} product={svaProduct} />
            </ProductContainer>
        </StyledColumn>
    );
}
