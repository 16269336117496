import Brand from 'src/types/Brand';

const BrandNames = {
    [Brand.VW]: 'Volkswagen',
    [Brand.NYTTE]: 'Volkswagen',
    [Brand.SEAT]: 'SEAT',
    [Brand.CUPRA]: 'CUPRA',
    [Brand.SKODA]: 'Škoda',
    [Brand.AUDI]: 'Audi',
    [Brand.UNKNOWN]: 'Ukjent',
    [Brand.OTHER]: 'Annet',
};

export default (brand?: Brand): string => {
    if (!brand) {
        return '';
    }

    return BrandNames[brand] || '';
};
