import { Column, LabelText, ProgressBar, Row } from '@moller/design-system';
import { styled } from 'styled-components';
import { TDFFlag } from '../../assets/TDFFlag';
import { TicketSmall } from './TicketSmall';

const LabelTextGray = styled(LabelText)`
    color: var(--moller-color-loader);
`;

const ProgressBarLine = styled.p`
    font-size: 0.8rem;
    color: var(--moller-color-primary);
    line-height: 0;
    margin: 2px 0 0 -2px;
`;

const TextUnderProgress = styled.p<{ value: number; maxValue: number }>`
    font-size: 0.875rem;
    position: relative;
    left: ${(props) => (props.value / props.maxValue) * 100 * 0.92 + '%'};
    text-align: start;
`;

const ColumnHeader = styled(Column)`
    width: fit-content;
    align-self: end;
`;

const ProgressHeader = styled.div`
    text-align: center;
    padding-right: 16px;
`;

interface Props {
    currentKm: number;
    maxKm: number;
    end: string;
    start: string;
    currentStage: number;
}

export const StageProgressBar = ({
    currentKm,
    maxKm,
    end,
    start,
    currentStage,
}: Props) => {
    const percentageOfMaxDistance = Math.min((currentKm / maxKm) * 100, 100);
    const isFinalStage = currentStage >= 20;
    const hasFinishedTour = currentKm >= maxKm;
    return (
        <Column gap="xxs" horizontalAlign="center">
            {!hasFinishedTour && (
                <ColumnHeader horizontalAlign={'center'} verticalAlign={'end'}>
                    <ProgressHeader>{maxKm} km</ProgressHeader>
                </ColumnHeader>
            )}
            <Column style={{ width: '95%', margin: '0' }}>
                <Column style={{ width: '100%', margin: '0 0 -6px 0' }}>
                    <Row style={{ marginTop: '14px', position: 'relative' }}>
                        <div style={{ width: '100%' }}>
                            <ProgressBar
                                animateOnChange={true}
                                percentage={percentageOfMaxDistance}
                                size={'large'}
                            />
                        </div>
                        <ProgressBarLine aria-hidden="true">
                            {' '}
                            |{' '}
                        </ProgressBarLine>
                        <div
                            style={{
                                width: '30px',
                                height: '30px',
                            }}
                        >
                            {isFinalStage ? (
                                <TDFFlag
                                    width="30px"
                                    height="30px"
                                    style={{
                                        position: 'relative',
                                        bottom: '20px',
                                        left: '-10px',
                                    }}
                                />
                            ) : (
                                <div
                                    style={{
                                        position: 'relative',
                                        bottom: '15px',
                                        left: '5px',
                                    }}
                                >
                                    <TicketSmall />
                                </div>
                            )}
                        </div>
                    </Row>
                </Column>
                <Row
                    horizontalAlign="spaceBetween"
                    style={{ margin: '-10px 0 0 0' }}
                >
                    {!hasFinishedTour && (
                        <TextUnderProgress value={currentKm} maxValue={maxKm}>
                            {Math.min(currentKm, maxKm).toFixed(1)} km
                        </TextUnderProgress>
                    )}
                </Row>
                <Row
                    horizontalAlign={'spaceBetween'}
                    verticalAlign={'end'}
                    style={{ margin: '0' }}
                >
                    <LabelTextGray>{start}</LabelTextGray>
                    <LabelTextGray>{end}</LabelTextGray>
                </Row>
            </Column>
        </Column>
    );
};
