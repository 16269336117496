import { ServiceViewModel } from 'external-apis/src/types/port';
import {
    fakeSetState,
    fakeState,
    otherRecommendedService,
    recommendedService,
    tireHotelService,
    tireService,
} from '../../../testData';
import { RecommendedServiceFormState } from './recommendedServiceFormState';

export const preselectRecommended = (
    services: ServiceViewModel[],
    selectedServiceIds: string[],
    setSelectedServiceIds: (x: string[]) => void,
    setRecommendedServices: (x: RecommendedServiceFormState) => void,
    mileage: string
) => {
    return (recommendedServiceIds: string[]) => {
        const alreadySelectedIds = selectedServiceIds;
        const selectedServicesWithoutPotentiallyPreviouslySelectedRecommendedServices =
            alreadySelectedIds.filter((x) => services.some((y) => y.id === x));

        const newSelectedIds = Array.from(
            new Set([
                ...selectedServicesWithoutPotentiallyPreviouslySelectedRecommendedServices,
                ...recommendedServiceIds,
            ])
        );
        setSelectedServiceIds(newSelectedIds);

        if (newSelectedIds.length > 0) {
            setRecommendedServices({ mileage, open: true });
        }
    };
};
if (import.meta.vitest) {
    const { it, expect } = import.meta.vitest;
    it.concurrent(
        'should open recommended services when some preselected services',
        () => {
            const selectedIds = fakeState<string[]>([]);
            const setIds = fakeSetState(selectedIds);
            const formState = fakeState<RecommendedServiceFormState>({
                mileage: '',
                open: false,
            });
            const setFormState = fakeSetState(formState);
            const preselect = preselectRecommended(
                [tireHotelService, tireService],
                [tireHotelService.id],
                setIds,
                setFormState,
                formState.value.mileage
            );
            preselect([recommendedService.id]);
            expect(formState.value.open).toBe(true);
        }
    );
    it.concurrent('should select newly fetched recommended', () => {
        const selectedIds = fakeState<string[]>([]);
        const setIds = fakeSetState(selectedIds);
        const formState = fakeState<RecommendedServiceFormState>({
            mileage: '',
            open: false,
        });
        const setFormState = fakeSetState(formState);
        const preselect = preselectRecommended(
            [tireHotelService, tireService],
            [tireHotelService.id],
            setIds,
            setFormState,
            formState.value.mileage
        );
        preselect([recommendedService.id]);
        expect(selectedIds.value).toEqual([
            tireHotelService.id,
            recommendedService.id,
        ]);
    });
    it.concurrent(
        'should not produce duplicates when preselecting existing services',
        () => {
            const selectedIds = fakeState<string[]>([]);
            const setIds = fakeSetState(selectedIds);
            const formState = fakeState<RecommendedServiceFormState>({
                mileage: '',
                open: false,
            });
            const setFormState = fakeSetState(formState);
            const preselect = preselectRecommended(
                [tireHotelService, tireService],
                [tireService.id],
                setIds,
                setFormState,
                formState.value.mileage
            );
            preselect([tireService.id]);
            expect(selectedIds.value).toEqual([tireService.id]);
        }
    );
    it.concurrent(
        'unselect previous recommended ids when new id is selected',
        () => {
            const selectedIds = fakeState<string[]>([]);
            const setIds = fakeSetState(selectedIds);
            const formState = fakeState<RecommendedServiceFormState>({
                mileage: '',
                open: false,
            });
            const setFormState = fakeSetState(formState);
            const preselect = preselectRecommended(
                [tireHotelService, tireService],
                [recommendedService.id, tireService.id],
                setIds,
                setFormState,
                formState.value.mileage
            );
            preselect([otherRecommendedService.id]);
            expect(selectedIds.value).toEqual([
                tireService.id,
                otherRecommendedService.id,
            ]);
        }
    );
}
