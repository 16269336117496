import {
    formInputs,
    FormProvider,
    InputFieldValidity,
} from '@moller/design-system';
import { LocalFormState } from '@moller/design-system/utilities/forms/useLocalFormState.js';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useEffect } from 'react';
import Brand from 'src/types/Brand';
import Car from 'src/types/Car';
import { useSetStepDone } from 'src/utils/hooks/useSetStepDone';
import get from 'src/utils/http/get';
import lang from 'src/utils/lang';
import formatBrandNames from 'src/utils/string/formatBrandNames';
import { styled } from 'styled-components';
import { useUpdateDamageAppraisal } from 'src/features/damage/utils/useDamageAppraisals';

interface GetCarResponse {
    brandCode: Brand;
    brandName: string;
    modelName: string;
    vehicleIdentificationNumber: string;
    plateNumber: string;
}

export function useCarByRegistrationNumber(registrationNumber?: string) {
    const path = `public/api/car/regNo/${registrationNumber}`;
    return useQuery({
        queryKey: ['useCarByRegistrationNumber', registrationNumber],
        queryFn: () => get<GetCarResponse>(path).then((x) => x.data),
        enabled: !!registrationNumber,
    });
}

export function mapCarResponseToCar(response: GetCarResponse): Car {
    return {
        licensePlate: response.plateNumber,
        vin: response.vehicleIdentificationNumber,
        brand: response.brandCode,
        name: response.modelName,
        mileage: 0,
    };
}

type LicensePlateForm = {
    licensePlate: string;
};

const { TextField } = formInputs<LicensePlateForm>();

const StyledTextField = styled(TextField)`
    width: 100%;
    max-width: 300px;
`;

function getCarErrorText(
    httpStatusCode: number
): InputFieldValidity<{ errorMsgRequired: true }> | undefined {
    switch (httpStatusCode) {
        case 404:
            return {
                type: 'error',
                message: lang.damageFetchingCarError404,
            };

        case 409:
            return {
                type: 'error',
                message: lang.damageFetchingCarError409,
            };

        default:
            return;
    }
}

interface SearchOtherCarViewProps {
    setCar: (car: Car) => void;
    damageId: number | null;
    form: LocalFormState<LicensePlateForm>;
    setVehicleStepDone: (value: boolean) => void;
}
const SearchOtherCarView = ({
    setCar,
    damageId,
    form,
    setVehicleStepDone,
}: SearchOtherCarViewProps) => {
    const { mutate: updateDamage, isSuccess } =
        useUpdateDamageAppraisal(damageId);

    const car = useCarByRegistrationNumber(form.state.validated.licensePlate);
    const invalid =
        car.isError && axios.isAxiosError(car.error) && car.error.response
            ? getCarErrorText(car.error.response.status)
            : undefined;

    const carData =
        form.state.isValid && car.isSuccess
            ? mapCarResponseToCar(car.data)
            : undefined;

    const success = carData && {
        type: 'success' as const,
        message: `${formatBrandNames(carData.brand).toLocaleUpperCase()} ${
            carData.name
        }`,
    };

    useEffect(() => {
        if (car.data && form.state.isValid) {
            updateDamage({
                vin: car.data.vehicleIdentificationNumber,
                licensePlate: car.data.plateNumber,
                title: 'Karosseri',
            });
            setCar(mapCarResponseToCar(car.data));
            return;
        }
    }, [car.data, form.state.isValid, setCar, updateDamage]);

    // Set vehicle step done when a car plate number is set
    useSetStepDone(isSuccess, setVehicleStepDone);

    return (
        <div>
            <p>{lang.damageCarNotRegistered}</p>
            <FormProvider
                hideNecessityText
                form={form}
                id="damageAppraisal-searchOtherCar"
            >
                <StyledTextField
                    field="licensePlate"
                    label={lang.damageCarInputLabel}
                    style={{ textTransform: 'uppercase' }}
                    validity={invalid || success}
                />
            </FormProvider>
        </div>
    );
};

export default SearchOtherCarView;
