import {
    clearTokens,
    isPendingLogout,
} from 'src/utils/auth/authenticationUtils';
import { AUTH_EVENT_ERROR } from 'src/utils/auth/constants';
import loginRedirect from 'src/utils/auth/loginRedirect';

export default async () => {
    if (isPendingLogout) {
        return;
    }

    document.dispatchEvent(new Event(AUTH_EVENT_ERROR));
    await clearTokens();
    loginRedirect();
};
