import { InputFieldValidity, TextArea } from '@moller/design-system';
import { useLanguageContext } from '../../lib/languages/languageContext';

const MAX_COMMENT_LENGTH = 1000;

type CommentFieldProps = {
    comment: string;
    setComment: (comment: string) => void;
    validity: InputFieldValidity | undefined;
};

export function CommentField({
    comment,
    setComment,
    validity,
}: CommentFieldProps) {
    const [lc] = useLanguageContext();
    return (
        <TextArea
            label={lc.standardServices.other_service_label}
            onChange={(e) => {
                e.preventDefault();
                setComment(e.target.value);
            }}
            value={comment}
            maxLength={MAX_COMMENT_LENGTH}
            counterText={counterLabel({
                count: comment.length,
            })}
            validity={validity}
        />
    );
}

function counterLabel({ count = 0, max = 1000 }) {
    return `${count} / ${max}`;
}
