import { Coordinates } from 'external-apis/src/types/port';

const toRadians = (degrees: number) => degrees * (Math.PI / 180);

type LatLongCoordinates = { latitude: number; longitude: number };

const haversineDistance = (
    position1: LatLongCoordinates,
    position2: LatLongCoordinates
) => {
    const earthRadius = 6371; // Radius of the earth in kilometers
    const { latitude: lat1, longitude: lon1 } = position1;
    const { latitude: lat2, longitude: lon2 } = position2;

    if (!(lat1 && lat2 && lon1 && lon2)) {
        return;
    }

    const dLat = toRadians(lat2 - lat1);
    const dLon = toRadians(lon2 - lon1);
    const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(toRadians(lat1)) *
            Math.cos(toRadians(lat2)) *
            Math.sin(dLon / 2) *
            Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = earthRadius * c; // Distance in kilometers

    return parseFloat(distance.toFixed(1));
};

export const getKilometersBetween = (
    position1: Coordinates | undefined,
    position2: Coordinates | undefined
) => {
    if (
        position1 &&
        position2 &&
        position1.latitude &&
        position1.longitude &&
        position2.latitude &&
        position2.longitude
    ) {
        return haversineDistance(
            { latitude: position1.latitude, longitude: position1.longitude },
            { latitude: position2.latitude, longitude: position2.longitude }
        );
    }

    return undefined;
};
