import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useFetchersContext } from 'external-apis';
import {
    AddCarRelationBody,
    AddCarRelationFeasibilityResult,
    AddUserRelationError,
    RenewCodeError,
} from 'external-apis/src/types/bilhold';
import { useNavigate } from 'react-router-dom';
import isRegNoValid from 'src/features/add-car/utils/isRegNoValid';
import { PATH_HOME } from 'src/routing/routes';
import { useCarSwiperIndex } from '../store/useCarSwiperIndex';
import { carProfileQueryKey, fetchAndSortCars } from './useCarProfile';

export function approveCodeErrorMessage(
    errorReason?: AddUserRelationError,
    statusCode?: number
) {
    if (statusCode && statusCode !== 200) {
        return 'general_error_contact_support';
    }

    switch (errorReason) {
        case 'InvalidCode':
            return 'approveCodeBadRequest';
        case 'RequestHasExpired':
            return 'approveCodeHasExpired';
        case 'MaxAttemptsReached':
            return 'approveCodeTooManyRequests';
        case 'NoPendingRequest':
        case 'VehicleNotFound':
        default:
            return 'general_error_contact_support';
    }
}

export function checkRenewError(
    errorReason?: RenewCodeError,
    statusCode?: number
) {
    if (statusCode && statusCode !== 200) {
        return 'general_error_contact_support';
    }

    switch (errorReason) {
        case 'TooManyRequests':
            return 'renewCodeExceededDailyTries';
        case 'PendingUserRelationRequestNotFound':
        case 'VehicleNotFound':
        default:
            return 'general_error_contact_support';
    }
}

const useAddCarFetcher = () => {
    const [fetchers] = useFetchersContext();
    return fetchers.bilhold.fetcher
        .path('/v1/cars/{RegistrationNumber}/relations')
        .method('post')
        .create();
};

interface AddCarMutationInterface {
    registrationNumber: string;
    body: AddCarRelationBody;
}

export const useAddCar = () => {
    const postAddCar = useAddCarFetcher();
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const setCarSwiperIndex = useCarSwiperIndex(
        (state) => state.setCarSwiperIndex
    );

    return useMutation({
        mutationFn: async (x: AddCarMutationInterface) => {
            const response = await postAddCar({
                RegistrationNumber: x.registrationNumber,
                type: x.body.type,
                organizationNumber: x.body.organizationNumber,
            });

            return response.data;
        },

        onSuccess: async (_, variables) => {
            await queryClient.invalidateQueries({
                queryKey: [carProfileQueryKey],
            });

            const carsList = await queryClient.fetchQuery(
                [carProfileQueryKey],
                fetchAndSortCars
            );

            // Cars are sorted as existing, ordered and pending
            const existingCarsLength = carsList.existingCars.length;
            const orderedCarsLength = carsList.orderedCars.length;

            const addedCarInPendingListIndex = carsList.pendingCars.findIndex(
                (car) => car.licensePlate === variables.registrationNumber
            );

            const addedCarInCarListIndex =
                existingCarsLength +
                orderedCarsLength +
                addedCarInPendingListIndex;

            setCarSwiperIndex(addedCarInCarListIndex);

            navigate(PATH_HOME);
        },
    });
};

// Add car request to check the feasibility of whether this car is possible to add for this user
const useAddCarRequestFetcher = () => {
    const [fetchers] = useFetchersContext();
    return fetchers.bilhold.fetcher
        .path('/v1/cars/add-car/{RegistrationNumber}/request')
        .method('get')
        .create();
};

export const useAddCarRequestFeasibility = (registrationNumber: string) => {
    const addCarRequest = useAddCarRequestFetcher();

    return useQuery<AddCarRelationFeasibilityResult>({
        queryKey: ['addCarFeasibility', registrationNumber],
        queryFn: () =>
            addCarRequest({ RegistrationNumber: registrationNumber }).then(
                (x) => x.data
            ),
        enabled: isRegNoValid(registrationNumber),
    });
};

const useRenewCodeFetcher = () => {
    const [fetchers] = useFetchersContext();
    return fetchers.bilhold.fetcher
        .path('/v1/cars/add-car/{RegistrationNumber}/renew')
        .method('put')
        .create();
};

interface RenewCodeErrorResponse {
    message?: RenewCodeError;
    response?: ErrorStatus;
}

export const useRenewCode = (registrationNumber: string) => {
    const renewCode = useRenewCodeFetcher();
    return useMutation<unknown, RenewCodeErrorResponse>({
        mutationFn: async () => {
            const response = await renewCode({
                RegistrationNumber: registrationNumber,
            });

            if (!response.data.success) {
                if (response.data.error) {
                    throw new Error(response.data.error);
                }
            }

            return response.data;
        },
    });
};

const useConfirmCodeFetcher = () => {
    const [fetchers] = useFetchersContext();
    return fetchers.bilhold.fetcher
        .path('/v1/cars/add-car/{RegistrationNumber}/approve')
        .method('put')
        .create();
};

interface ErrorStatus {
    status?: number;
}

interface ConfirmCodeError {
    message?: AddUserRelationError;
    response?: ErrorStatus;
}

export const useConfirmCode = (
    registrationNumber: string,
    code: string,
    onSuccess: () => void
) => {
    const confirmCode = useConfirmCodeFetcher();
    const queryClient = useQueryClient();
    const setCarSwiperIndex = useCarSwiperIndex(
        (state) => state.setCarSwiperIndex
    );

    return useMutation<unknown, ConfirmCodeError>({
        mutationFn: async () => {
            const response = await confirmCode({
                RegistrationNumber: registrationNumber,
                value: code,
            });

            if (!response.data.isSuccess) {
                if (response.data.errorReason) {
                    throw new Error(response.data.errorReason);
                }
            }

            return response.data;
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: [carProfileQueryKey],
            });

            const carsList = await queryClient.fetchQuery(
                [carProfileQueryKey],
                fetchAndSortCars
            );

            const addedCarInExistingListIndex = carsList.existingCars.findIndex(
                (car) => car.licensePlate === registrationNumber
            );

            setCarSwiperIndex(addedCarInExistingListIndex);

            onSuccess();
        },
    });
};

const useDeleteCodeFetcher = () => {
    const [fetchers] = useFetchersContext();
    return fetchers.bilhold.fetcher
        .path('/v1/cars/add-car/{RegistrationNumber}')
        .method('delete')
        .create();
};

export const useDeletePendingCar = (
    registrationNumber: string,
    onSuccess: () => void
) => {
    const deleteCode = useDeleteCodeFetcher();
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: () =>
            deleteCode({ RegistrationNumber: registrationNumber }).then(
                (x) => x.data
            ),
        onSuccess: () => {
            void queryClient.invalidateQueries({
                queryKey: [carProfileQueryKey],
            });
            onSuccess();
        },
    });
};
