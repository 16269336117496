import {
    AlertBanner,
    Column,
    Headline,
    IconButton,
    LinkCard,
    Modal,
    Subtitle,
    TextContainer,
    breakpointTablet,
} from '@moller/design-system';
import { LogoBrand } from '@moller/design-system/foundation/logos/Logo.js';
import moment from 'moment';
import { useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useCarInformation } from 'src/features/home/queries/useCarInformation';
import { themeOverride } from 'src/utils/dev-tools/devToolsUtils';
import DamageStatus from 'src/utils/enums/DamageStatus';
import { DAY_MONTH_YEAR_FORMAT } from 'src/utils/string/formatTime';
import { selectTheme } from 'src/utils/themes/selectTheme';
import { styled } from 'styled-components';
import { ChildThemeProvider } from 'theming';
import lang from 'utils/lang';
import { PageLayout } from '../components/PageLayout';
import { useDamageAppraisers } from '../query/useDamageAppraisers';
import { useDeleteDamageAppraiser } from '../query/useDeleteDamageAppraiser';

const DamageAppraiserCard = styled(LinkCard)`
    padding: var(--moller-spacing-xs);
    cursor: pointer;
    display: flex;
    flex-direction: column;
    * {
        display: flex;
    }
    @media screen and (min-width: ${breakpointTablet}) {
        padding: var(--moller-spacing-m);
    }
    height: 100%;
    margin-top: var(--moller-spacing-xs);
`;

const StartedAppraiserContainer = styled(Column)`
    gap: 8px;
    margin-bottom: 12px;
`;

export const DamageAppraiser = () => {
    const { data } = useDamageAppraisers();
    const vin = useParams().vin;
    const navigate = useNavigate();

    const { data: technicalDetails } = useCarInformation(vin);

    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [selectedDa, setSelectedDa] = useState<number>(0);
    const {
        mutate: deleteReport,
        isLoading,
        isError,
        reset,
    } = useDeleteDamageAppraiser(setModalIsOpen);

    const carTheme = selectTheme(technicalDetails?.brand);
    const carDamageAppraisers = data?.filter((p) => p.vin === vin) ?? [];

    const startedAppraisals = carDamageAppraisers.filter(
        (p) => p.status === DamageStatus.STARTED
    );
    const sentAppraisals = carDamageAppraisers.filter(
        (p) => p.status === DamageStatus.SENT && p.davStatus != 'COMPLETED'
    );
    const completedAppraisals = carDamageAppraisers.filter(
        (p) => p.davStatus === 'COMPLETED'
    );

    const closeModal = () => {
        reset();
        setModalIsOpen(false);
    };
    return (
        <ChildThemeProvider
            theme={carTheme.toLocaleLowerCase() as LogoBrand}
            overrideTheme={themeOverride}
        >
            <PageLayout>
                <TextContainer>
                    <IconButton
                        icon={'chevron_left'}
                        label="Tilbake"
                        showLabel={'right'}
                        onClick={() => navigate(-1)}
                    />
                    {carDamageAppraisers?.length === 0 ? (
                        <p>Fant ingen skadesaker for din bil</p>
                    ) : (
                        <>
                            {startedAppraisals.length > 0 && (
                                <Headline>{lang.draftedAppraisers}</Headline>
                            )}
                            {startedAppraisals.map((da) => (
                                <StartedAppraiserContainer key={da.id}>
                                    <DamageAppraiserCard
                                        forwardedAs={Link}
                                        to={`/damage/${da.id}`}
                                    >
                                        <Subtitle>{da.title}</Subtitle>
                                        <p>
                                            {`${lang.damageAppraiserStarted} ${lang.lastChanged} `}
                                            {moment(da.updatedAt)
                                                .format(DAY_MONTH_YEAR_FORMAT)
                                                .trim()}
                                        </p>
                                        <p>{lang.clickToContinue}</p>
                                    </DamageAppraiserCard>
                                    <IconButton
                                        icon="trash"
                                        label={lang.delete_draft}
                                        showLabel="left"
                                        onClick={() => {
                                            setSelectedDa(da.id);
                                            setModalIsOpen(true);
                                        }}
                                    />
                                </StartedAppraiserContainer>
                            ))}
                            {sentAppraisals.length > 0 && (
                                <Headline>{lang.damageAppraiserSent}</Headline>
                            )}
                            {sentAppraisals.map((da) => (
                                <DamageAppraiserCard
                                    key={da.id}
                                    forwardedAs={Link}
                                    to={`/damage/${da.id}`}
                                >
                                    <Subtitle>{da.title}</Subtitle>
                                    <p>{`${lang.damageAppraiserSent} ${moment(
                                        da.sentAt
                                    )
                                        .format(DAY_MONTH_YEAR_FORMAT)
                                        .trim()}`}</p>
                                </DamageAppraiserCard>
                            ))}
                            {completedAppraisals.length > 0 && (
                                <Headline>
                                    {lang.damageAppraisersFinished}
                                </Headline>
                            )}
                            {completedAppraisals.map((da) => (
                                <DamageAppraiserCard
                                    key={da.id}
                                    forwardedAs={Link}
                                    to={`/damage/${da.id}`}
                                >
                                    <Subtitle>{da.title}</Subtitle>
                                    <p>{`${lang.lastChanged} ${moment(
                                        da.updatedAt
                                    )
                                        .format(DAY_MONTH_YEAR_FORMAT)
                                        .trim()}`}</p>
                                </DamageAppraiserCard>
                            ))}
                        </>
                    )}
                </TextContainer>
                <Modal
                    isOpen={modalIsOpen}
                    handleClose={() => closeModal()}
                    title={lang.delete_report}
                    primaryAction={{
                        label: lang.delete_report,
                        onClick: () => deleteReport(selectedDa),
                        loading: {
                            isLoading: isLoading,
                            loadingText: 'Loading',
                        },
                    }}
                    secondaryAction={{
                        label: lang.cancel,
                        onClick: () => closeModal(),
                    }}
                >
                    {lang.confirm_delete_report}
                    {isError && (
                        <AlertBanner
                            type="error"
                            message={lang.deleteDamageAppraiserError}
                        />
                    )}
                </Modal>
            </PageLayout>
        </ChildThemeProvider>
    );
};
