import { LogoBrand } from '@moller/design-system/foundation/logos/Logo.js';
import { EnvironmentName } from 'src/utils/environment/environments';

export let showDevTools = localStorage.getItem('ShowDevTools') == 'true';

export const knownKeys = [
    'Debug_ShowEuControlCard',
    'Debug_ShowInspectionServiceCard',
    'Debug_ShowTireServiceCard',
    'ShowTireChange',
    'ShowProducts',
] as string[];

export const activate = () => {
    localStorage.setItem('ShowDevTools', 'true');
    showDevTools = true;
};

export type FlagValue = boolean | string | number;

export interface FlagSet {
    [key: string]: FlagValue;
}

const initFeatureToggles = (): FlagSet => {
    const json = localStorage.getItem('devTools.featureToggles') as string;

    if (json == null) {
        return {};
    }

    return JSON.parse(json) as FlagSet;
};

export const featureToggleOverrides: FlagSet = initFeatureToggles();

export const isFeatureToggleOverrides = (key: string): boolean => {
    if (!knownKeys.includes(key)) {
        knownKeys.push(key);
    }

    return featureToggleOverrides[key] == true;
};

export const updateFeatureToggleOverride = (key: string, value: boolean) => {
    if (value && !featureToggleOverrides[key]) {
        featureToggleOverrides[key] = true;
    } else if (featureToggleOverrides[key]) {
        delete featureToggleOverrides[key];
    }

    localStorage.setItem(
        'devTools.featureToggles',
        JSON.stringify(featureToggleOverrides)
    );
};

export const environmentOverride: EnvironmentName | null = localStorage.getItem(
    'devTools.environmentOverride'
) as EnvironmentName;

export const setEnvironmentOverride = (environment: EnvironmentName) => {
    localStorage.setItem('devTools.environmentOverride', environment);
};

export const setAppReviewLoginOverride = () => {
    localStorage.setItem('devTools.appReviewLoginEnabled', 'true');
};

export const isAppReviewLoginOverrideEnabled = () => {
    return localStorage.getItem('devTools.appReviewLoginEnabled') === 'true';
};

export const themeOverride: LogoBrand | null = localStorage.getItem(
    'devTools.themeOverride'
) as LogoBrand;

export const setThemeOverride = (theme: LogoBrand | null) => {
    if (theme == null) {
        localStorage.removeItem('devTools.themeOverride');
    } else {
        localStorage.setItem('devTools.themeOverride', theme);
    }
};
