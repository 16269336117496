interface ServiceType {
    text: string;
    isIncludedInOrdinaryService: boolean;
    onlyForFossilCars?: boolean;
}
export const services: ServiceType[] = [
    {
        text: 'Alle servicer ihht. bilens serviceprogram',
        isIncludedInOrdinaryService: true,
    },
    {
        text: 'Skift av motorolje',
        isIncludedInOrdinaryService: true,
        onlyForFossilCars: true,
    },
    {
        text: 'Skift av girolje',
        isIncludedInOrdinaryService: true,
        onlyForFossilCars: true,
    },
    {
        text: 'Skift av differensialolje',
        isIncludedInOrdinaryService: true,
        onlyForFossilCars: true,
    },
    {
        text: 'Skift av luftfilter',
        isIncludedInOrdinaryService: true,
        onlyForFossilCars: true,
    },
    { text: 'Skift av pollenfilter', isIncludedInOrdinaryService: true },
    { text: 'Nøkkelbatteri', isIncludedInOrdinaryService: true },
    {
        text: 'Kontroll av eventuelle lagrede feil i styreenheter',
        isIncludedInOrdinaryService: true,
    },
    {
        text: 'Kontroll av sikkerhetsrelaterte komponenter',
        isIncludedInOrdinaryService: true,
    },
    {
        text: 'Nødvendige reparasjoner for å opprettholde bilens drift- og trafikksikkerhet',
        isIncludedInOrdinaryService: false,
    },
    {
        text: 'Skifte av bremseskiver og klosser',
        isIncludedInOrdinaryService: false,
    },
    {
        text: 'Skifte av slitedeler som hjullager, endeledd og bærekuler',
        isIncludedInOrdinaryService: false,
    },
    {
        text: 'Etterfylling av spylevæske, Adblue, servoolje mm. ved service',
        isIncludedInOrdinaryService: false,
    },
    {
        text: 'Periodisk kjøretøykontroll dersom dette er påkrevd i avtaleperioden',
        isIncludedInOrdinaryService: false,
    },
    {
        text: 'Skifte av vinduspusserblader ved service',
        isIncludedInOrdinaryService: false,
    },
    {
        text: 'Skifte av lyspærer ved service',
        isIncludedInOrdinaryService: false,
    },
    { text: 'Erstatningsbil ved service', isIncludedInOrdinaryService: true },
];
