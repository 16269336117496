import {
    AllowedIcons,
    Description,
    Icon,
    LeadText,
    LinkCard,
} from '@moller/design-system';
import { Link } from 'react-router-dom';
import { useMediaQueries } from 'src/utils/hooks/useMediaQuery';
import { styled } from 'styled-components';

const CardContent = styled.div`
    display: flex;
    flex-direction: column;
`;

const StyledIcon = styled(Icon)`
    > svg {
        width: 22px;
    }
`;

const StyledTitleText = styled(LeadText)`
    margin: 0;
    font-weight: 500;
    font-size: var(--moller-size-s);

    @media (min-width: 450px) {
        font-size: var(--moller-size-m);
    }
`;

const StyledDescription = styled(Description)``;

interface ActionBarCardProps {
    icon: AllowedIcons;
    title: string;
    description: string;
    link: string;
}

const ActionBarCard = ({
    icon,
    title,
    description,
    link,
}: ActionBarCardProps) => {
    const { desktop } = useMediaQueries();

    return (
        <LinkCard
            density={desktop ? 'default' : 'compact'}
            $as={Link}
            to={link}
            style={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                backgroundColor: 'var(--moller-color-primary-container)',
                color: 'var(--moller-color-on-primary-container)',
            }}
        >
            <CardContent>
                <StyledIcon icon={icon} />
                <StyledTitleText>{title}</StyledTitleText>
                <StyledDescription>{description}</StyledDescription>
            </CardContent>
        </LinkCard>
    );
};

export default ActionBarCard;
