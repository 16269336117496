import { ServiceViewModel } from 'external-apis/src/types/port';
import { StdServicesForm, useStandardServicesForm } from './useSectionForm';
import { fakeSetState, fakeState, serviceWithoutPrices } from '../testData';
import { CommentField } from './CommentField';

type AdditionalServiceInfoProps = {
    serviceForm: ReturnType<typeof useStandardServicesForm>;
    service: ServiceViewModel;
};

export function AdditionalServiceInfo({
    serviceForm,
    service,
}: AdditionalServiceInfoProps) {
    const info = service.additionalInfo;
    const noAdditionalInfo = !info || info.length === 0;
    if (noAdditionalInfo) {
        return null;
    }
    return (
        <>
            {info.map((x, i) => (
                <AdditionalInfoContent
                    key={`${x.infoType}=${i}`}
                    serviceForm={serviceForm}
                    service={service}
                    info={x}
                />
            ))}
        </>
    );
}

type AdditionalInfoContentProps = {
    serviceForm: ReturnType<typeof useStandardServicesForm>;
    info: NonNullable<ServiceViewModel['additionalInfo']>[number];
    service: ServiceViewModel;
};

function AdditionalInfoContent({
    service,
    serviceForm,
    info,
}: AdditionalInfoContentProps) {
    const setServiceInfo = serviceForm.setValue('additionalInfo');
    const additionalInfo = serviceForm.state.raw.additionalInfo;
    const value = additionalInfo.find(
        existingInfo({
            adapterId: service.adapterId,
            serviceId: service.id,
        })
    );
    const setComment = (comment: string) => {
        setAdditionalInfo({
            setInfo: setServiceInfo,
            currentInfo: additionalInfo,
            value: value
                ? { ...value, comment }
                : {
                      adapterId: service.adapterId,
                      serviceId: service.id,
                      comment,
                  },
        });
    };
    const validity = serviceForm.inputProps('additionalInfo').validity;
    const selectedServices = serviceForm.state.raw.selectedIds;
    if (info.infoType === 'Comment' && selectedServices.includes(service.id)) {
        return (
            <CommentField
                comment={value?.comment ?? ''}
                setComment={setComment}
                validity={validity}
            />
        );
    }
}

type AdditionalInfo = ReturnType<
    typeof useStandardServicesForm
>['state']['raw']['additionalInfo'];
type SetAdditionalInfo = {
    setInfo: (x: AdditionalInfo) => void;
    currentInfo: AdditionalInfo;
    value: AdditionalInfo[number];
};

function similarInfo<
    T extends Pick<AdditionalInfo[number], 'adapterId' | 'serviceId'>
>(x: T, y: T) {
    return x.serviceId === y.serviceId && x.adapterId === y.adapterId;
}

export function existingInfo<
    T extends Pick<AdditionalInfo[number], 'adapterId' | 'serviceId'>
>(x: T) {
    return (y: T) => similarInfo(x, y);
}

export function setAdditionalInfo({
    value,
    currentInfo,
    setInfo,
}: SetAdditionalInfo) {
    const filteredValues = currentInfo.filter((x) => !similarInfo(x, value));
    setInfo([...filteredValues, value]);
}

if (import.meta.vitest) {
    const { describe, it, expect } = import.meta.vitest;
    describe('Additional info', () => {
        const info: AdditionalInfo[number] = {
            serviceId: serviceWithoutPrices.id,
            adapterId: serviceWithoutPrices.adapterId,
        };
        it('Add info for service', () => {
            const state = fakeState<StdServicesForm['additionalInfo']>([]);
            const setState = fakeSetState(state);
            setAdditionalInfo({
                value: info,
                currentInfo: [],
                setInfo: setState,
            });
            expect(state.value).toEqual([info]);
        });
        it('Update comment for service', () => {
            const state = fakeState<StdServicesForm['additionalInfo']>([]);
            const setState = fakeSetState(state);
            const comment = 'Dette er en ny kommentar';
            setAdditionalInfo({
                value: {
                    ...info,
                    comment,
                },
                currentInfo: [info],
                setInfo: setState,
            });
            expect(state.value[0].comment).toBe(comment);
        });
    });
}
