import { Description, InfoCard } from '@moller/design-system';
import { DealerViewModel } from 'external-apis/src/types/port';
import { useGetServices } from '../../_api/http/services';
import { useGetVehicle } from '../../_api/http/vehicle';
import { QueryError } from '../../components/QueryError';
import { ServicesDone } from '../../components/services/ServicesDone';
import { useBookingStepAction } from '../../lib/datadog/hooks';
import { useLanguageContext } from '../../lib/languages/languageContext';
import { ChooseDealerData } from '../choose-dealer/ChooseDealerSection';
import { AdditionalServicesLoader } from './AdditionalServicesLoader';
import { AdditionalServicesData } from './AdditionalServicesSection';

type AdditionalServicesDoneProps = {
    data: AdditionalServicesData;
    regNr: string;
    selectedDealer: ChooseDealerData;
    dealers: DealerViewModel[] | undefined;
};

export function AdditionalServicesDone({
    data: { selectedServices },
    regNr,
    selectedDealer,
    dealers,
}: AdditionalServicesDoneProps) {
    const additionalActions = [];

    const dealer = dealers?.find((x) => x.id === selectedDealer.dealerNumber);
    if (dealer) {
        additionalActions.push(`Choose additional services ${dealer.name}`);
    }

    useBookingStepAction('Choose additional services', {
        additionalActions,
    });

    const vehicle = useGetVehicle(regNr);

    const [lc] = useLanguageContext();
    const serviceResponse = useGetServices({
        vin: vehicle.data?.vehicleIdentificationNumber,
        dealerNumbers: [selectedDealer.dealerNumber],
    });

    if (serviceResponse.isError || vehicle.isError) {
        return (
            <QueryError
                error={serviceResponse.error || vehicle.error}
                isError
            />
        );
    }

    if (serviceResponse.isLoading) {
        return <AdditionalServicesLoader />;
    }

    if (selectedServices.length === 0) {
        return (
            <InfoCard density="compact">
                <Description $as="div">
                    {lc.additionalServices.no_services_chosen}
                </Description>
            </InfoCard>
        );
    }

    return (
        <>
            {dealer && (
                <ServicesDone
                    selectedServices={selectedServices}
                    selectedDealerNumber={dealer.id}
                />
            )}
        </>
    );
}
