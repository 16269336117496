export function normalizeDealerName(dealerName: string): string {
    let x = dealerName.toLowerCase();
    x = x.replace('ø', 'o');
    x = x.includes('erik arnesen') ? 'moller' : x;
    x = x.includes('moller') ? 'mollerbil' : x;
    x = x.includes('grenland') ? 'gumpen' : x;
    x = x.includes('autosenteret notodden') ? 'gumpen' : x;
    x = x.includes('svebjorn') ? 'svebjorn' : x;
    x = x.includes('autoria') ? 'autoria' : x;
    x = x.includes('dahles') ? 'dahles' : x;
    x = x.includes('gumpen') ? 'gumpen' : x;
    return x;
}
