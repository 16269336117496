import { Browser } from '@capacitor/browser';
import { PATH_BEGIN } from 'src/routing/routes';
import { clearBrowserSession } from 'src/utils/auth/authenticationUtils';
import { isMobileApp } from 'src/utils/mobile';
import getCurrentEnvironment from '../environment/getCurrentEnvironment';

export default async () => {
    await clearBrowserSession();
    const environment = getCurrentEnvironment();

    if (isMobileApp()) {
        const url = `https://${environment.auth0.domain}/v2/logout?returnTo=carcare://auth/begin&client_id=${environment.auth0.clientId}`;
        await Browser.open({
            url,
            presentationStyle: 'fullscreen',
            windowName: '_self',
        });
        window.location.href = PATH_BEGIN;
    } else {
        const url = `https://${environment.auth0.domain}/v2/logout?returnTo=${window.location.origin}/begin&client_id=${environment.auth0.clientId}`;
        window.location.href = url;
    }
};
