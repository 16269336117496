import { Capacitor } from '@capacitor/core';
import { Modal } from '@moller/design-system';
import { adobeEvent } from 'adobe-utils';
import { useState } from 'react';
import { PATH_HOME } from 'src/routing/routes';
import checkUserForRegistration from 'src/utils/auth/checkUserForRegistration';
import { UserInfo } from 'src/utils/auth/mapMdmUserInfo';
import lang from 'src/utils/lang';
import { OneTimePasswordForm } from './OneTimePasswordForm';

interface EnrollUserProps {
    phoneNumber: string;
    setPersonDataResponse: (userInfo: UserInfo) => void;
    setPhoneNumber: (phoneNumber: string) => void;
    setToken: (token: string) => void;
}

export const EnrollUser = ({
    phoneNumber,
    setPersonDataResponse,
    setPhoneNumber,
    setToken,
}: EnrollUserProps): JSX.Element => {
    const [userExistsModalShowing, showUserExistsModal] = useState(false);

    const pushOtpConfirmedEvent = () =>
        adobeEvent.push(
            'formStep',
            {
                formName: 'Create new user',
                stepName: 'OTP confirmed',
                stepNumber: 1,
                formId: phoneNumber,
            },
            Capacitor.getPlatform()
        );

    const checkOtpForUser = (otpCode: string) =>
        checkUserForRegistration({ phoneNumber, otpCode }).then(
            ({ partyId, otpToken, result }) => {
                if (partyId) {
                    showUserExistsModal(true);
                } else {
                    setPersonDataResponse(result);
                    setToken(otpToken);
                    pushOtpConfirmedEvent();
                }
            }
        );

    return (
        <>
            <OneTimePasswordForm
                checkOtpForUser={checkOtpForUser}
                phoneNumber={phoneNumber}
                setPhoneNumber={setPhoneNumber}
            />
            <Modal
                isOpen={userExistsModalShowing}
                title={lang.userAlreadyExistsTitle}
                handleClose={() => showUserExistsModal(false)}
                primaryAction={{
                    onClick: () => (window.location.href = PATH_HOME),
                    label: lang.login,
                }}
            >
                {lang.userAlreadyExistsDescription}
            </Modal>
        </>
    );
};
