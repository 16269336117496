import { IconButton } from '@moller/design-system';
import { useState } from 'react';
import Car from 'src/types/Car';
import { styled } from 'styled-components';
import { DeleteCar } from '../delete-car';

const SubmenuDropdown = styled.div<{ open: boolean }>`
    display: ${({ open }) => (open ? 'block' : 'none')};
    position: absolute;
    background-color: var(--moller-color-inverse-background);
    color: var(--moller-color-on-inverse-background);
    border-radius: var(--moller-radius-card);
    right: 0;
    top: 30px;
    padding: var(--moller-spacing-base) var(--moller-spacing-s);
`;

const SubmenuContainer = styled.div`
    position: relative;
    display: inline;
`;

export const Submenu = ({ car }: { car: Car }) => {
    const [open, setOpen] = useState(false);
    let blockOnBlur = false;
    return (
        <SubmenuContainer
            onBlur={() => {
                if (!blockOnBlur) {
                    setOpen(false);
                }
                blockOnBlur = false;
            }}
        >
            <IconButton
                onClick={() => setOpen(!open)}
                label="Flere valg"
                icon={'more_vertical'}
            />
            <SubmenuDropdown
                open={open}
                onPointerDown={() => {
                    blockOnBlur = true;
                }}
            >
                <DeleteCar car={car} />
            </SubmenuDropdown>
        </SubmenuContainer>
    );
};
