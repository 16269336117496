import { Calendar } from '@awesome-cordova-plugins/calendar';
import { Capacitor } from '@capacitor/core';
import { Icon, TextButton } from '@moller/design-system';
import {
    ReservedTimeSlotViewModel,
    ServiceViewModel,
} from 'external-apis/src/types/port';
import ical from 'ical-generator';
import { styled } from 'styled-components';
import { useGetApiLocaleConfig } from '../_api/apiLocale';
import { useGetDealers } from '../_api/http/dealers';
import { useBookingSearchParameters } from '../_api/urlSearchParameters';
import { FlexRow } from '../components/FlexRow';
import { useLanguageContext } from './languages/languageContext';
import { useGetVehicle } from '../_api/http/vehicle';
import { useGetServices } from '../_api/http/services';

const CalendarFlexRow = styled(FlexRow)`
    justify-content: start;
`;

type IcsCalendarProps = {
    timeSlot: ReservedTimeSlotViewModel;
    regNumber: string;
};

export function IcsCalendar({ timeSlot, regNumber }: IcsCalendarProps) {
    const { dealerGroup } = useBookingSearchParameters();
    const [lc] = useLanguageContext();
    const timeZone = useGetApiLocaleConfig().timeZone;
    const { data: dealers } = useGetDealers(dealerGroup);

    const vehicleResponse = useGetVehicle(regNumber);
    const vin = vehicleResponse.data?.vehicleIdentificationNumber;

    const dealerNumbers = [timeSlot.dealerNumber];

    const serviceResponse = useGetServices({
        vin,
        dealerNumbers,
    });
    const hasData = serviceResponse.data && dealers;

    if (!hasData) {
        return null;
    }

    const selectedServices = [
        ...serviceResponse.data.Standard,
        ...serviceResponse.data.Additional,
        ...serviceResponse.data.Transport,
        ...serviceResponse.data.Recommended,
    ].filter((x) => timeSlot.selectedServiceIds.includes(x.id));

    const selectedDealer = dealers.find(
        (dealer) => dealer.id === timeSlot.dealerNumber
    );

    if (!selectedDealer) {
        return null;
    }

    const introduction = lc.confirmBooking.ics_calendar.introduction.replace(
        '{dealer}',
        selectedDealer.name
    );
    const servicesHeadline = lc.confirmBooking.ics_calendar.services_headline;
    const servicesPlainText = convertServicesToPlainText(selectedServices);
    const editOrder = lc.confirmBooking.done_edit_order;
    const readyForDelivery = lc.confirmBooking.ics_calendar.ready_for_delivery;
    const regards = lc.confirmBooking.ics_calendar.regards;

    const summary = lc.confirmBooking.ics_calendar.subject.replace(
        '{dealer}',
        selectedDealer.name
    );
    const plainText = `${introduction}\n\n${servicesHeadline}${servicesPlainText}\n\n${editOrder}\n\n${readyForDelivery}\n\n${regards}\n${selectedDealer.name}`;

    const location = `${selectedDealer.streetAddress}, ${selectedDealer.zipCode} ${selectedDealer.city}`;

    const reader = new FileReader();

    const createAppEvent = (): Promise<unknown> => {
        return Calendar.createEventInteractively(
            summary,
            location,
            plainText,
            new Date(timeSlot.startTime),
            new Date(timeSlot.estimatedFinished)
        );
    };

    const createWebEvent = () => {
        const calendar = ical({ name: 'ical' });
        calendar.createEvent({
            start: timeSlot.startTime,
            end: timeSlot.estimatedFinished,
            summary,
            description: { plain: plainText },
            location,
            timezone: timeZone,
        });
        const fileName = `event-${timeSlot.startTime}.ics`;
        const blob = new Blob([calendar.toString()], {
            type: 'text/calendar',
        });

        reader.readAsDataURL(blob);

        reader.onloadend = (event) => {
            if (!event.target?.result) {
                throw new Error(lc.errors.ics_download);
            }
            const save = document.createElement('a');
            save.href =
                typeof event.target.result == 'string'
                    ? event.target.result
                    : new TextDecoder().decode(event.target.result);
            save.download = fileName;
            save.click();
        };
    };

    return (
        <>
            <CalendarFlexRow>
                <Icon icon="calendar" />
                <TextButton
                    onClick={() => {
                        if (isMobileApp()) {
                            void createAppEvent();
                        } else {
                            createWebEvent();
                        }
                    }}
                    underline
                >
                    {lc.components.ics_add_to_calendar}
                </TextButton>
            </CalendarFlexRow>
        </>
    );
}

const convertServicesToPlainText = (services: ServiceViewModel[]) =>
    services.reduce<string>(
        (text, service) => (text += `\n- ${service.name}`),
        ''
    );

const isMobileApp = () => {
    return Capacitor.getPlatform() !== 'web';
};
