import { ProactiveArticleSanity } from '../SanityProactiveArticle';

export const BuyUsedCar = () => {
    const buttonLink = 'https://finn.bruktbil.no/';

    return (
        <ProactiveArticleSanity
            variables={{}}
            name="Ser du etter ny bruktbil?"
            buttonLink={buttonLink}
        />
    );
};
