// https://blog.testdouble.com/posts/2019-05-14-locking-with-promises/
// Lets us lock a function so that it can only be called once at a time.
const lockify = (f: { (): Promise<void>; (): unknown }) => {
    let lock = Promise.resolve();
    return () => {
        const result = lock.then(() => f());
        lock = result.catch(() => {});
        return result;
    };
};

export default lockify;
