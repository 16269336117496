import { CapacitorUpdater } from '@capgo/capacitor-updater';
import { datadogRum } from '@datadog/browser-rum';
import { useCallback, useEffect } from 'react';
import { getIsPendingRefreshToken } from 'src/utils/auth/refreshAccessToken';
import { useAppBundleUpdateContext } from './AppBundleUpdaterContext';

let retrtyCount = 0;

const waitUntilRefreshTokenIsUpdated = async (bundleId: string) => {
    // Don't reload the app if we still don't have a token after 10 seconds. Update on next launch instead.
    if (retrtyCount > 10) {
        await CapacitorUpdater.next({ id: bundleId });
        return;
    }

    if (getIsPendingRefreshToken()) {
        setTimeout(() => {
            void waitUntilRefreshTokenIsUpdated(bundleId);
        }, 1000);
    } else {
        await CapacitorUpdater.set({ id: bundleId });
    }

    retrtyCount += 1;
};

const checkForUpdates = async () => {
    console.log('Checking for app bundle updates...');
    const isAutoUpdateEnabled = await CapacitorUpdater.isAutoUpdateEnabled();

    if (isAutoUpdateEnabled?.enabled !== true) {
        return;
    }

    const latestBundleInfo = await CapacitorUpdater.getLatest();
    const current = await CapacitorUpdater.current();

    if (
        latestBundleInfo?.url == null ||
        latestBundleInfo?.version == current?.bundle?.version
    ) {
        return;
    }

    const latestBundle = await CapacitorUpdater.download({
        url: latestBundleInfo.url,
        version: latestBundleInfo.version,
    });

    await waitUntilRefreshTokenIsUpdated(latestBundle.id);

    datadogRum.addAction('AppBundleUpdater', {
        version: latestBundle.version,
    });
};

function getDiffInMinutes(timestampA: number, timestampB: number) {
    const diff = Math.abs(timestampA - timestampB);
    const diffInMinutes = Math.round(diff / 1000 / 60);
    return diffInMinutes;
}

// This component ensures that the app bundle is updated when the app is opened.
const AppBundleUpdater = () => {
    const { interruptUpdateTimestamp, setInterruptUpdateTimestamp } =
        useAppBundleUpdateContext();

    const updateCheckInterval = 1000 * 60 * 60;
    const thresholdInMin = 45;

    const checkAppUpdate = useCallback(() => {
        const minSinceUpdateInterruption = getDiffInMinutes(
            Date.now(),
            interruptUpdateTimestamp
        );

        const isThresholdExceeded = minSinceUpdateInterruption > thresholdInMin;

        if (isThresholdExceeded) {
            setInterruptUpdateTimestamp(0);
            try {
                void checkForUpdates();
            } catch (error) {
                console.error(error);
                datadogRum.addError(error);
            }
        } else return;
    }, [setInterruptUpdateTimestamp, interruptUpdateTimestamp]);

    useEffect(() => {
        checkAppUpdate();
        const timer = setInterval(() => {
            checkAppUpdate();
        }, updateCheckInterval);
        return () => clearInterval(timer);
    }, [checkAppUpdate, updateCheckInterval]);

    return null;
};

export default AppBundleUpdater;
