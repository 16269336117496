import { Column, Subtitle, TextContainer } from '@moller/design-system';
import Brand from 'src/types/Brand';
import { styled } from 'styled-components';

const StyledSubtitle = styled(Subtitle)`
    margin-top: var(--moller-spacing-m);
`;

interface DeliveryProcessContentProps {
    carBrand: Brand;
}

export const DeliveryProcessContent = ({
    carBrand,
}: DeliveryProcessContentProps) => {
    const renderContent = () => {
        switch (carBrand) {
            case Brand.NYTTE:
                return <NytteContent />;
            default:
                return <DefaultContent />;
        }
    };

    const NytteContent = () => {
        return (
            <Column>
                <TextContainer>
                    <p>Innhold kommer.</p>
                </TextContainer>
            </Column>
        );
    };

    const DefaultContent = () => {
        return (
            <Column>
                <TextContainer>
                    <StyledSubtitle>Leveringsprosess</StyledSubtitle>
                    <ol>
                        <li>
                            <p>
                                <b>Bestilling bekreftet — </b>
                                Vi mottar bestillingen din og behandler den.
                                Dette innebærer at vi er i dialog med merket om
                                produksjon av din bil. De arbeider da med å
                                planlegge for at en fabrikk og produksjonslinje
                                skal dedikeres for din bil og hvilke deler som
                                må anskaffes og transporteres.
                            </p>
                        </li>
                        <li>
                            <p>
                                <b>Produksjonsbekreftet — </b>
                                En fabrikk har satt av tid til å bygge din bil
                                slik du har bestilt den! Det tar vanligvis noen
                                måneder før bilen er ferdig produsert.
                            </p>
                        </li>
                        <li>
                            <p>
                                <b>Under transport — </b>
                                Din bil skal transporteres fra fabrikken til
                                Norge. Bilen pakkes inn, og fraktes vanligvis
                                med tog ut fra fabrikken og videre over på
                                spesialbygde skip. Når den ankommer Norge skal
                                bilen pakkes ut, vaskes og klargjøres for deg.
                                Dette tar vanligvis noen uker.
                            </p>
                        </li>
                        <li>
                            <p>
                                <b>Ankomst forhandler — </b>
                                Bilen din er på vei til din forhandler for de
                                siste forberedelsene før du kan få den. Din
                                forhandler kontakter deg når de har fått bilen
                                på plass og avtaler tidspunkt for overlevering.
                            </p>
                        </li>
                    </ol>
                </TextContainer>
            </Column>
        );
    };

    return renderContent();
};
