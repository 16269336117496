import { CheckboxCardGroup } from '@moller/design-system';
import { DelayedRender } from '../../lib/DelayedRender';
import { SingleCard } from '@moller/design-system/components/surfaces/cards/internal/SelectionCardGroup.js';

export function StandardServicesLoader() {
    const cards = [1, 2, 3, 4, 5].map(
        (v): SingleCard => ({ label: '', value: `${v}` })
    );
    return (
        <DelayedRender>
            <CheckboxCardGroup
                id="standardserviceslist-selectedIds"
                onChange={() => {
                    return;
                }}
                cards={cards}
                gap="xxs"
                isLoading
            />
        </DelayedRender>
    );
}
