import { datadogRum } from '@datadog/browser-rum';
import { ActionChip, Headline, Row } from '@moller/design-system';
import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { CenteredPageContent } from 'src/shared/CenteredPageContent';
import FeedbackPrompt from 'src/shared/feedback-card/FeedbackPrompt';
import { ddRumPrivacySetting } from 'src/utils/datadog';
import { themeOverride } from 'src/utils/dev-tools/devToolsUtils';
import { useMessageThreads } from 'src/utils/hooks/useMessageThreads';
import lang from 'src/utils/lang';
import { styled } from 'styled-components';
import { ChildThemeProvider } from 'theming';
import ContactInfoList from './ContactInfoList';
import { MessagesChat } from './MessagesChat';
import { MessagesDamageAppraisal } from './MessagesDamageAppraisal';
import { UnreadMessagesBadge } from './components/UnreadMessagesBadge';
import { messageIsSeen } from './utils/messagesUtils';

const MessageHeadline = styled(Headline)`
    margin: var(--moller-spacing-m) 0;
`;

const StyledRow = styled(Row)`
    margin-bottom: var(--moller-spacing-s);
`;

const TabAndBadgeContainer = styled.div`
    position: relative;
`;

export const CONTACT_LIST = 'contactList';
export const MESSAGE_LIST = 'messageList';
export const CHAT_LIST = 'chatList';
export type ContactTabCategories =
    | typeof CONTACT_LIST
    | typeof MESSAGE_LIST
    | typeof CHAT_LIST;

const Index = () => {
    const { data: messageThreads, isLoading, isError } = useMessageThreads();
    const [searchParams] = useSearchParams();
    const selectedCategory = searchParams.get('category');
    const navigate = useNavigate();

    const getNumberOfUnseenMessages = (
        category: 'DAMAGE_APPRAISER' | 'CHAT'
    ) => {
        const filteredMessageThreads = messageThreads?.filter(
            (thread) => thread.category === category
        );

        return filteredMessageThreads?.reduce(
            (acc, thread) => (messageIsSeen(thread) ? acc : acc + 1),
            0
        );
    };

    const navigateToTab = (tab: ContactTabCategories) => {
        navigate(`/messages/?category=${tab}`);
    };

    useEffect(() => {
        datadogRum.addAction('selectedContactCategory', {
            contactCategory: selectedCategory,
        });
    }, [selectedCategory]);

    const chatMessages = messageThreads?.filter((x) => x.category === 'CHAT');
    const damageMessages = messageThreads?.filter(
        (x) => x.category === 'DAMAGE_APPRAISER'
    );

    return (
        <ChildThemeProvider theme="bilhold" overrideTheme={themeOverride}>
            <CenteredPageContent {...ddRumPrivacySetting('allow')}>
                <MessageHeadline>{lang.myMessages}</MessageHeadline>
                <StyledRow role="group" aria-label="Velg kontaktfane" gap="xxs">
                    <ActionChip
                        selected={selectedCategory === CONTACT_LIST}
                        onClick={() => navigateToTab(CONTACT_LIST)}
                        label="Kontaktliste"
                    />
                    <TabAndBadgeContainer>
                        <ActionChip
                            selected={selectedCategory === MESSAGE_LIST}
                            onClick={() => navigateToTab(MESSAGE_LIST)}
                            label="Skadesaker"
                        />
                        <UnreadMessagesBadge
                            unread={getNumberOfUnseenMessages(
                                'DAMAGE_APPRAISER'
                            )}
                        />
                    </TabAndBadgeContainer>
                    {chatMessages && chatMessages?.length > 0 && (
                        <TabAndBadgeContainer>
                            <ActionChip
                                selected={selectedCategory === CHAT_LIST}
                                onClick={() => navigateToTab(CHAT_LIST)}
                                label="Meldinger"
                            />
                            <UnreadMessagesBadge
                                unread={getNumberOfUnseenMessages('CHAT')}
                            />
                        </TabAndBadgeContainer>
                    )}
                </StyledRow>
                {(() => {
                    if (selectedCategory === MESSAGE_LIST) {
                        return (
                            <MessagesDamageAppraisal
                                messageThreads={damageMessages}
                                hasError={isError}
                                isLoading={isLoading}
                            />
                        );
                    } else if (selectedCategory === CHAT_LIST) {
                        return (
                            <MessagesChat
                                messageThreads={chatMessages}
                                hasError={isError}
                            />
                        );
                    } else {
                        return <ContactInfoList />;
                    }
                })()}

                <FeedbackPrompt
                    customTitle={
                        selectedCategory === CHAT_LIST
                            ? 'Hva synes du om meldingstjenesten?'
                            : undefined
                    }
                    specifiedService={
                        selectedCategory === CHAT_LIST
                            ? 'meldingstjenesten'
                            : undefined
                    }
                />
            </CenteredPageContent>
        </ChildThemeProvider>
    );
};

export default Index;
