import { styled } from 'styled-components';
import { FlexColumn } from '../../../components/FlexColumn';
import { ConfirmBookingEditProps } from './ConfirmBookingEdit';
import { FlexRow } from '../../../components/FlexRow';
import { useLanguageContext } from '../../../lib/languages/languageContext';
import { useEffect, useState } from 'react';
import { InputFieldValidity } from '@moller/design-system';
import { ConfirmButton } from '../ConfirmBookingSection';
import { CancelButton } from '../../../components/bilhold/CancelButton';
import { BilholdErrorText } from '../../../components/bilhold/BilholdErrorText';

const ConfirmButtonWithValidationFlexColumn = styled(FlexColumn)`
    align-items: flex-end;
`;

type ConfirmBookingWithoutDoneDependenciesProps = {
    dependencies: ConfirmBookingEditProps['dependencies'];
};

export const FlexRowConfirmButtons = styled(FlexRow)`
    align-items: center;
    gap: var(--moller-spacing-m);
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
`;

export function ConfirmBookingWithoutDoneDependencies({
    dependencies,
}: ConfirmBookingWithoutDoneDependenciesProps) {
    const [lc] = useLanguageContext();
    const [showErrorText, setShowErrorText] = useState<boolean>(false);
    const validity: InputFieldValidity = {
        type: 'error',
        message: lc.errors.confirm_booking_validation,
    };

    useEffect(() => {
        setShowErrorText(false);
    }, [dependencies]);

    return (
        <ConfirmButtonWithValidationFlexColumn>
            <FlexRowConfirmButtons>
                <CancelButton />
                <ConfirmButton
                    isLoading={false}
                    onClick={() => {
                        setShowErrorText(true);
                    }}
                    hidden
                />
            </FlexRowConfirmButtons>
            {showErrorText && (
                <BilholdErrorText
                    id={'confirm-error-text'}
                    validity={validity}
                />
            )}
        </ConfirmButtonWithValidationFlexColumn>
    );
}
