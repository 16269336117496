import React from 'react';
import { css, styled } from 'styled-components';

export const BilholdViewLayout = styled.div<{ showBorder?: boolean }>`
    display: flex;
    flex-direction: column;

    ${({ showBorder }) =>
        showBorder &&
        css`
            border-bottom: var(--moller-stroke-small) solid
                var(--moller-palette-primary-90);
            padding-bottom: var(--moller-spacing-l);
        `}

    gap: var(--moller-spacing-xxs);
`;

export const BilholdInnerViewLayout = styled.div`
    display: flex;
    flex-direction: column;
    gap: var(--moller-spacing-xxs);
`;

type BilholdViewHiddenProps = {
    desc: string;
};

export const BilholdViewHidden: React.FC<BilholdViewHiddenProps> = ({
    desc,
}) => {
    return (
        <BilholdViewLayout showBorder>
            <p>{desc}</p>
        </BilholdViewLayout>
    );
};
