import { AxiosResponse } from 'axios';
import { RequestConfig, doRequest } from './doRequest';

const put = <T = unknown>(
    path: string,
    data?: unknown,
    config?: RequestConfig
): Promise<AxiosResponse<T>> => doRequest('PUT', path, data, config);

export default put;
