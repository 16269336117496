import DetailsList from 'src/features/my-ordered-car/components/DetailsList';
import {
    CarContractCar,
    CarContractDealer,
} from 'external-apis/src/types/bilhold';
import capitalize from 'src/utils/string/capitalize';
import lang from 'utils/lang';
import { formatBrand } from 'src/utils/string/formatCarVariables';

interface OrderSummaryProps {
    readonly dealer: CarContractDealer;
    readonly number: number | undefined;
    readonly version: number | undefined;
    readonly car: CarContractCar;
}

interface OrderSummaryRow {
    readonly key: string;
    readonly value: string;
}
const OrderSummary = ({ dealer, number, version, car }: OrderSummaryProps) => {
    const contractNumber =
        version == undefined || number == undefined
            ? ''
            : `${number}/${version}`;

    const summaryValues: OrderSummaryRow[] = [
        { key: 'label-dealerName', value: dealer.name ?? '' },
        { key: 'label-contractNumber', value: contractNumber },
        {
            key: 'label-brand',
            value: formatBrand(car.brand),
        },
        { key: 'label-model', value: capitalize(car?.model) || '' },
        { key: 'label-modelYear', value: car?.modelYear?.toString() ?? '' },
        { key: 'label-color', value: capitalize(car?.color ?? '') || '' },
        {
            key: 'label-fuelType',
            value: lang[`contract_fuel_type_${car.fuelType}`],
        },
    ];

    return <DetailsList items={summaryValues} />;
};

export default OrderSummary;
