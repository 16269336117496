import { useEffect } from 'react';
import { activate } from './devToolsUtils';
import useFeatureToggle from './useFeatureToggle';

const DevToolsActivator = () => {
    const showDevTools = useFeatureToggle('ShowDevTools');

    useEffect(() => {
        if (showDevTools) {
            activate();
        }
    }, [showDevTools]);

    return null;
};

export default DevToolsActivator;
