import {
    Column,
    Icon,
    LabelText,
    SecondaryButton,
    TextContainer,
} from '@moller/design-system';
import ReactMarkdown from 'react-markdown';
import lang from 'src/utils/lang';
import { formatMessageDate } from 'src/utils/time/timeUtils';
import { styled } from 'styled-components';

const MessageContainer = styled.div<{ $isSentByMe: boolean }>`
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: ${({ $isSentByMe }) =>
        $isSentByMe
            ? `var(--moller-color-info-container)`
            : `var(--moller-color-surface)`};
    border: 1px solid var(--moller-color-outline);
    border-radius: var(--moller-border-radius-large);
    word-break: break-word;
    padding: var(--moller-spacing-m);

    p:first-child {
        margin-top: 0px;
    }
    width: 100%;
    max-width: 500px;
    box-sizing: border-box;
`;

const Container = styled(Column)<{
    $isSentByMe: boolean;
    $isOptimistic?: boolean;
}>`
    ${({ $isOptimistic }) => $isOptimistic && 'opacity: 0.3;'}
    width: 100%;
    gap: var(--moller-spacing-base);
    align-items: ${({ $isSentByMe }) =>
        $isSentByMe ? `flex-end` : `flex-start`};
`;

interface Attachment {
    filePath: string;
    mimeType: string;
    fileName?: string;
}

interface MessageProps {
    messageText: string;
    attachments?: Attachment[];
    downloadAttachment?: (attachment: Attachment) => void;
    senderName?: string;
    isSentByMe: boolean;
    sentTime: string;
    isOptimistic?: boolean;
}

const Message = ({
    messageText,
    attachments,
    downloadAttachment,
    senderName,
    isSentByMe,
    sentTime,
    isOptimistic,
}: MessageProps) => {
    const sentDate = formatMessageDate(sentTime);
    const fromName = senderName ? `- ${lang.from} ${senderName}` : '';

    return (
        <Container $isSentByMe={isSentByMe} $isOptimistic={isOptimistic}>
            <LabelText>{`${sentDate} ${fromName}`}</LabelText>
            <MessageContainer $isSentByMe={isSentByMe}>
                <TextContainer>
                    <ReactMarkdown>{messageText}</ReactMarkdown>
                </TextContainer>
                {downloadAttachment &&
                    attachments?.map((file, index) => {
                        const numberText =
                            attachments.length === 1
                                ? ''
                                : ` (${index + 1}/${attachments.length})`;
                        const attachmentText = lang.download + numberText;
                        return (
                            <div key={file.filePath}>
                                <SecondaryButton
                                    onClick={() => downloadAttachment(file)}
                                >
                                    {attachmentText}{' '}
                                    <Icon icon="file_download" />
                                </SecondaryButton>
                            </div>
                        );
                    })}
            </MessageContainer>
        </Container>
    );
};

export default Message;
