import {
    Accordion,
    AlertBanner,
    Headline,
    LoadingSpinner,
    PrimaryButton,
    TextArea,
    TextContainer,
} from '@moller/design-system';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { PageLayout } from 'src/features/my-car/components/PageLayout';
import { Header } from 'src/features/my-car/sticky-header/StickyHeader';
import { useGetContract } from 'src/features/my-ordered-car/http/useGetContract';
import { getPartyId } from 'src/utils/auth/authenticationUtils';
import { useStickyScroll } from 'src/utils/browser/useStickyScroll';
import { ddRumPrivacySetting } from 'src/utils/datadog';
import { themeOverride } from 'src/utils/dev-tools/devToolsUtils';
import { useProfile } from 'src/utils/hooks/useProfile';
import { getThemeForCarBrand } from 'src/utils/themes/selectTheme';
import { styled } from 'styled-components';
import { ChildThemeProvider } from 'theming';
import { ManualProductRequest } from './utils/sendProductRequestManually';
import { useSendProductRequest } from './utils/useSendProductRequest';

const StyledHeadline = styled(Headline)`
    margin-top: calc(var(--moller-spacing-3xl) + var(--moller-spacing-m));
`;

const StyledTextContainer = styled(TextContainer)`
    margin-bottom: var(--moller-spacing-l);
`;

const StyledTextArea = styled(TextArea)`
    width: 100%;
`;

const StyledPrimaryButton = styled(PrimaryButton)`
    margin-bottom: var(--moller-spacing-xxl);
`;

const CustomProduct = () => {
    const [isScrollActive, setIsScrollActive] = useState(false);
    const [inputValue, setInputValue] = useState('');

    const navigate = useNavigate();

    const { carId } = useParams<{
        carId: string;
    }>();
    const { data, isError: isContractError } = useGetContract(carId);

    const isStickyScrollActive = useStickyScroll(
        isScrollActive,
        setIsScrollActive
    );

    const {
        mutate: sendProductRequest,
        isLoading,
        isError: isSendProductRequestError,
        isSuccess: hasBeenSubmitted,
    } = useSendProductRequest();

    // Get user data and party id.
    const { data: user } = useProfile();
    const partyId = getPartyId();

    if (isContractError) {
        return (
            <AlertBanner
                type={'error'}
                message={
                    'Vi klarte dessverre ikke å hente kontrakten din. Vennligst prøv igjen senere eller kontakt din forhandler for å legge til produkter på din kontrakt.'
                }
            />
        );
    }

    const isAllDataReady = !!data && !!user && !!partyId && !!carId;

    if (!isAllDataReady) {
        return <LoadingSpinner isFullScreen />;
    }

    const { carContractCar, carContractDealer } = data;

    const carTheme = getThemeForCarBrand(carContractCar?.brand);

    // 532 dealer does not have the same nybil-mail as others
    const dealerEmail =
        carContractDealer.number === '532'
            ? 'nybil.532@mobile-larvik.no'
            : `nybil.${carContractDealer.number}@moller.no`;

    const handleSendProductRequest = () => {
        sendProductRequest({
            dealerEmail: dealerEmail,
            contractId: `${data.contractNumber}/${data.version}`,
            brand: carContractCar.brand,
            model: carContractCar.model || 'Ukjent',
            firstName: user.firstname,
            dealerNumber: carContractDealer.number,
            vin: carId,
            partyId: parseInt(partyId),
            customProductRequest: inputValue,
        });
    };

    const renderSubmittedState = () => {
        return (
            <PageLayout>
                <StyledHeadline>Takk for din henvendelse!</StyledHeadline>
                <StyledTextContainer>
                    <p>
                        Vi har mottatt forespørselen og {carContractDealer.name}{' '}
                        vil kontakte deg på e-post så fort som mulig.
                    </p>
                </StyledTextContainer>
                <StyledPrimaryButton
                    onClick={() =>
                        navigate('/,', {
                            replace: true,
                        })
                    }
                >
                    Tilbake til forsiden
                </StyledPrimaryButton>
            </PageLayout>
        );
    };

    const renderFormState = () => {
        return (
            <PageLayout>
                <StyledHeadline>Send forespørsel om produkt</StyledHeadline>
                <StyledTextContainer>
                    <p>
                        Vi har et stort utvalg av biltilbehør som kan gjøre din
                        hverdag enklere og hjelper deg i å utnytte bilens fulle
                        potensial. Send oss en forespørsel om produktet du
                        ønsker å legge til på kontrakten, så hjelper vi deg så
                        fort vi kan.
                    </p>
                </StyledTextContainer>
                <StyledTextArea
                    id="product-request-text-area"
                    label="Skriv forespørsel"
                    onChange={(e) => setInputValue(e.target.value)}
                    value={inputValue}
                />
                <StyledPrimaryButton
                    loading={{
                        isLoading: isLoading,
                        loadingText: 'Sender forespørsel',
                    }}
                    onClick={handleSendProductRequest}
                >
                    Send forespørsel
                </StyledPrimaryButton>

                <Accordion
                    head="Forhandlerinformasjon"
                    withBackground
                    withDivider
                >
                    <TextContainer>
                        <p>Forhandler: {carContractDealer.name}</p>
                        <p>Adresse: {carContractDealer.streetAddress}</p>
                        <p>
                            Postnummer: {carContractDealer.postalCode}{' '}
                            {carContractDealer.city}
                        </p>
                        <p>Telefonnummer: {carContractDealer.phoneNumber}</p>
                    </TextContainer>
                </Accordion>
                {isSendProductRequestError && (
                    <div
                        {...ddRumPrivacySetting('mask')}
                        style={{ marginTop: 'var(--moller-spacing-m' }}
                    >
                        <ManualProductRequest
                            orderedCarDealerEmail={dealerEmail}
                            customProductRequest={inputValue}
                            contract={data}
                            userFirstName={user.firstname}
                        />
                    </div>
                )}
            </PageLayout>
        );
    };

    return (
        <ChildThemeProvider
            {...ddRumPrivacySetting('allow')}
            theme={carTheme}
            overrideTheme={themeOverride}
        >
            <Header
                brand={carContractCar?.brand}
                isLoading={!isAllDataReady}
                carName={carContractCar?.model}
                isScrollActive={isStickyScrollActive}
            />
            {hasBeenSubmitted ? renderSubmittedState() : renderFormState()}
        </ChildThemeProvider>
    );
};

export default CustomProduct;
