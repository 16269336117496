import { Column, IconButton, LabelText, Row } from '@moller/design-system';
import { Skeleton } from '@mui/material';
import React, { useState } from 'react';
import { useGetCarMileage } from 'src/features/home/queries/useCorrectDetails';
import { formatMileage } from 'src/utils/string/formatMilage';
import { formatDateWithFormat } from 'src/utils/time/timeUtils';
import { styled } from 'styled-components';
import { EditMileage } from './EditMileage';

const MileageContainer = styled(Column)`
    gap: var(--moller-spacing-base);
    margin-top: var(--moller-spacing-s);
    ${Row.selector} {
        gap: var(--moller-spacing-xxs);
    }
`;

const Header = styled(LabelText)`
    display: flex;
    color: var(--moller-color-on-disabled);
    gap: var(--moller-spacing-base);
`;

interface LoadinSpanProp {
    content: string | undefined | null | React.ReactNode;
    isLoading: boolean;
    isError: boolean;
}

const LoadingSpan = ({ content, isLoading, isError }: LoadinSpanProp) => {
    if (isLoading) {
        return <Skeleton variant="rounded" height={'1.5rem'} width={'77px'} />;
    }
    if (isError) {
        return <span>--</span>;
    }
    return <span>{content}</span>;
};

export const Mileage = ({ vin }: { vin: string }) => {
    const {
        data: mileage,
        isLoading: mileageLoading,
        isError: mileageError,
    } = useGetCarMileage(vin);
    const [editMode, setEditMode] = useState(false);
    return (
        <MileageContainer id="km">
            <Header>
                Kilometerstand –
                <LoadingSpan
                    content={`sist oppdatert 
                                ${formatDateWithFormat(
                                    mileage?.lastUpdatedMileage,
                                    'DD.MM.YYYY'
                                )}
                                `}
                    isLoading={mileageLoading}
                    isError={mileageError}
                />
            </Header>

            {!editMode ? (
                <>
                    <LoadingSpan
                        content={
                            <Row>
                                {formatMileage(`${mileage?.currentMileage}`)} km
                                <IconButton
                                    icon={'edit'}
                                    label={'Rediger kilometerstand'}
                                    onClick={() => setEditMode(true)}
                                />
                            </Row>
                        }
                        isLoading={mileageLoading}
                        isError={mileageError}
                    />
                </>
            ) : (
                <EditMileage
                    vin={vin}
                    exitEditMode={() => setEditMode(false)}
                />
            )}
        </MileageContainer>
    );
};
