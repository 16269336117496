import { useMutation } from '@tanstack/react-query';
import { useFetchersContext } from 'external-apis';
import { DavStatusUpdate } from 'external-apis/.typescript/src/types/damageappraisal';

// This is also typed in bilhold-admin
export const DamageValuationStatus = {
    NEW: 'NEW',
    PROCESSING: 'PROCESSING',
    REQUESTED_CHANGES: 'REQUESTED_CHANGES',
    RECEIVED_CHANGES: 'RECEIVED_CHANGES',
    VALUATION_SENT: 'VALUATION_SENT',
    COMPLETED: 'COMPLETED',
    UNKNOWN: 'UNKNOWN',
} as const;

const usePutDavStatusUpdate = () => {
    const [fetchers] = useFetchersContext();
    return fetchers.damageappraisal.fetcher
        .path('/dav-status/id/{id}')
        .method('put')
        .create();
};

// This is used for setting case in bilhold-admin to "PÅGÅENDE SAKER"
export const useDavStatusUpdate = (id?: string | number) => {
    const putDavStatusUpdate = usePutDavStatusUpdate();
    const body: DavStatusUpdate = {
        davStatus: DamageValuationStatus.RECEIVED_CHANGES,
    };
    return useMutation({
        mutationFn: () => putDavStatusUpdate({ id: id, ...body }),
    });
};
