import { datadogRum } from '@datadog/browser-rum';
import {
    clearTokens,
    getRefreshToken,
    hasValidRefreshToken,
    setAccessToken,
    setRefreshToken,
} from 'src/utils/auth/authenticationUtils';
import loginRedirect from 'src/utils/auth/loginRedirect';
import getCurrentEnvironment from 'src/utils/environment/getCurrentEnvironment';
import post from 'src/utils/http/post';

interface RefreshTokenResponse {
    access_token: string;
    refresh_token: string;
}

let isPendingRefreshToken = false;

export const getIsPendingRefreshToken = () => isPendingRefreshToken;

export default async () => {
    isPendingRefreshToken = true;
    const environment = getCurrentEnvironment();
    const refreshToken = getRefreshToken();

    datadogRum.addAction('refreshAccessToken.post', {
        refreshToken: refreshToken?.slice(refreshToken.length - 10),
    });

    const clearTokensAndRedirect = async () => {
        await clearTokens();
        loginRedirect();
        isPendingRefreshToken = false;
    };

    if (!hasValidRefreshToken()) {
        isPendingRefreshToken = false;
        return;
    }

    try {
        const response = await post<RefreshTokenResponse>(
            `https://${environment.auth0.domain}/oauth/token`,
            {
                grant_type: 'refresh_token',
                refresh_token: refreshToken ?? '',
                client_id: environment.auth0.clientId,
            },
            {
                onAuthError: () => {
                    return true;
                },
                isAnonymous: true,
            }
        );

        if (response?.status === 200) {
            datadogRum.addAction('Access token updated successfully');
            await setAccessToken(response.data.access_token);
            await setRefreshToken(response.data.refresh_token);
        }
    } catch (err) {
        datadogRum.addError(err, {
            refreshToken: refreshToken?.slice(refreshToken.length - 10),
        });

        await clearTokensAndRedirect();
    }

    isPendingRefreshToken = false;
};
