import CountryCode from 'src/types/CountryCode';

export default (): CountryCode => {
    const { hostname } = window.location;
    const countryCode = hostname
        ?.split('.')
        ?.pop()
        ?.toUpperCase() as keyof typeof CountryCode;

    if (countryCode && CountryCode[countryCode]) {
        return CountryCode[countryCode];
    }

    return CountryCode.NO;
};
