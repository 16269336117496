import { AlertBanner, Headline, LoadingSpinner } from '@moller/design-system';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PATH_PROFILE_OVERVIEW } from 'src/routing/routes';
import BackButton from 'src/shared/BackButton';
import { CenteredPageContent } from 'src/shared/CenteredPageContent';
import { useProfile } from 'src/utils/hooks/useProfile';
import lang from 'src/utils/lang';
import getCountryCode from 'src/utils/region/getCountryCode';
import { ProfileDataUpdate, UpdateProfile } from './UpdateProfile';
import { useProfileUpdate } from './utils/updateProfile';

const UserSettings = () => {
    const countryCode = getCountryCode();
    const { data: profile, isLoading } = useProfile();
    const [updatedProfileData, setUpdatedProfileData] =
        useState<ProfileDataUpdate>();
    const navigate = useNavigate();

    const {
        mutate: updateProfile,
        isLoading: isUpdating,
        isError: isUpdatingError,
    } = useProfileUpdate(() => navigate(PATH_PROFILE_OVERVIEW));

    const handleOnClick = () => {
        if (!updatedProfileData) {
            return;
        }
        const changedUserData = {
            firstname: updatedProfileData.firstname,
            surname: updatedProfileData.surname,
            addressLine: updatedProfileData.addressLine,
            postcode: updatedProfileData.postcode,
            city: updatedProfileData.city,
            countryCode: countryCode,
            email: updatedProfileData.email,
        };
        updateProfile(changedUserData);
    };

    return (
        <CenteredPageContent>
            {isLoading ? (
                <LoadingSpinner isFullScreen />
            ) : (
                profile && (
                    <>
                        <BackButton />
                        <Headline> Min profil</Headline>
                        <UpdateProfile
                            profile={profile}
                            onSubmit={handleOnClick}
                            setUpdatedProfileData={setUpdatedProfileData}
                            submitButton
                            isUpdating={isUpdating}
                        />
                        {isUpdatingError && (
                            <AlertBanner
                                type="error"
                                message={lang.general_error_contact_support}
                            />
                        )}
                    </>
                )
            )}
        </CenteredPageContent>
    );
};

export default UserSettings;
