import { components } from '../schema/port';

export type ServiceViewModel = components['schemas']['ServiceViewModel'];

export type ServiceCampaign = components['schemas']['Campaign'];

export type AdditionalInfo = components['schemas']['AdditionalInfoViewModel'];

export type AvailableServicesQuery =
    components['schemas']['AvailableServicesQuery'];

export type TimeSlotViewModel = components['schemas']['TimeSlotViewModel'];

export type ReservedTimeSlotViewModel =
    components['schemas']['ReservedTimeSlotViewModel'];

export type TimeslotReserveModelQuery =
    components['schemas']['TimeslotReserveModelQuery'];

export type AvailableTimeSlotsQuery =
    components['schemas']['AvailableTimeSlotsQuery'];

export type ServiceType = components['schemas']['ServiceType'];

export type DealerViewModel = components['schemas']['DealerViewModel'];

export type DealerServiceCertifications = components['schemas']['Brand'];

export type DealerOpeningHours = components['schemas']['OpeningHours'];

export type CountryCode = components['schemas']['CountryCode'];

export type DealerGroup = components['schemas']['DealerGroup'];

export type DealerType = components['schemas']['DealerType'];

export type CurrencyType = components['schemas']['Currency'];

export type ErrorResponse = components['schemas']['ErrorResponse'];
export type CustomerInfoViewModel =
    components['schemas']['CustomerInfoViewModel'];

export type BookingCreateModelQuery =
    components['schemas']['BookingCreateModelQuery'];

export type BookedServiceModel = components['schemas']['BookedServiceModel'];

export type BookingViewModel = components['schemas']['BookingViewModel'];
export const CURRENCY_NOK: CurrencyType = 'Nok';

export const DEALER_TYPE_SERVICE: DealerType = 'Service';
export const DEALER_TYPE_DAMAGE: DealerType = 'Damage';

export type Coordinates = components['schemas']['Coordinates'];

export type ServiceCategory = components['schemas']['ServiceCategory'];
export type DealerSpecificInformation =
    components['schemas']['DealerSpecificInformation'];
export type ServiceBookletItemViewModel =
    components['schemas']['ServiceBookletItemViewModel'];
