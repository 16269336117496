import { AlertBanner, LoadingSpinner } from '@moller/design-system';
import { useParams } from 'react-router-dom';
import MyOrderedCar from 'src/features/my-ordered-car/MyOrderedCar';
import { useGetContract } from 'src/features/my-ordered-car/http/useGetContract';
import lang from 'src/utils/lang';

const Index = () => {
    const { contractId } = useParams<{ contractId: string }>();
    const { data, isLoading, isError } = useGetContract(contractId);

    if (isLoading) return <LoadingSpinner isFullScreen />;
    if (isError)
        return (
            <AlertBanner
                type={'error'}
                message={lang.general_error_contact_support}
            />
        );
    if (!data) return null;

    return <MyOrderedCar isLoading={isLoading} contractDetails={data} />;
};

export default Index;
