import { Geolocation } from '@capacitor/geolocation';
import { SimpleCoordinates } from 'src/types/SimpleCoordinates';

export const useClientLocation = async () => {
    let location: SimpleCoordinates | undefined = undefined;
    const res = await Geolocation.getCurrentPosition();
    if (res.coords.latitude && res.coords.longitude) {
        location = res.coords;
    }
    return location;
};
